import PropTypes from 'prop-types';
import head from 'lodash/head';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { lifecycle, withProps, getContext, compose } from 'recompose';

import { resetScene } from '../actions';

import {
  loadCampaignMatch,
  loadApplications,
} from '../components/CampaignDetails/actions';

import { getAsyncWorkflow, getAsyncCampaignMatch } from '../selectors';

import CampaignDetails from '../components/CampaignDetails';

import Workflow from './Workflow';

const withSceneReset = lifecycle({
  componentWillUnmount() {
    this.props.onResetScene();
  },
});

const withRouter = getContext({
  router: PropTypes.object.isRequired,
});

const withDependencyFetch = lifecycle({
  componentDidMount() {
    const {
      router,
      onLoadApplications,
      onLoadCampaign,
      params: { campaignId },
      location: {
        query: { clubId },
      },
    } = this.props;

    onLoadApplications(campaignId).then(({ data }) => {
      const campaignApplication = head(data);
      if (campaignApplication) {
        router.push(`/sponsored-posts/applications/${campaignApplication.id}`);
      } else {
        onLoadCampaign(campaignId, clubId).then((response) => {
          // If there is no match for the campaign, just redirect the user to
          // the explore view
          if (response.status === 404) {
            router.push('/sponsored-posts');
          }
        });
      }
    });
  },
});

const withWorkflow = withProps({
  components: { Workflow },
});

export default compose(
  connect(
    createStructuredSelector({
      campaignMatch: getAsyncCampaignMatch,
      workflow: getAsyncWorkflow,
    }),
    {
      onLoadApplications: loadApplications,
      onLoadCampaign: loadCampaignMatch,
      onResetScene: resetScene,
    },
  ),
  withRouter,
  withDependencyFetch,
  withSceneReset,
  withWorkflow,
)(CampaignDetails);
