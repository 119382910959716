import { createReducer } from 'source/utils';
import constants from 'source/constants';

export const getInitialState = () =>
  // it's recommended to use default values for variations, in case of optimizely load failures..

  ({
    signup: {
      imageUrl: '/images/account/variant_a.png',
    },
  });

const actionHandlers = {
  [constants.EXPERIMENTS_SET_CURRENT_EXPERIMENT]: (
    state,
    { container, experiment, variation },
  ) => ({
    ...state,
    [container]: {
      ...state[container],
      [experiment]: variation,
    },
  }),
};

export default createReducer(getInitialState(), actionHandlers);
