import React from 'react';
import PropTypes from 'prop-types';

/**
 * Implements injection of global theme data to specific components
 * (using React context and HOCs)
 *
 * Use `themeProvider` on a top level component to set up the context
 * Then use `injectTheme` or `withThemeWrapper` on a nested component
 * to give it the theme information
 */

export const themeProps = {
  theme: PropTypes.shape({
    themeClass: PropTypes.string,
    viewClass: PropTypes.string, // @sean TODO Remove
  }).isRequired,
};

export const themeProvider = (getTheme) => (Wrapped) => {
  class ThemeProvider extends React.Component {
    getChildContext() {
      return {
        theme: getTheme(this.props.location.pathname),
      };
    }

    render() {
      return <Wrapped {...this.props} />;
    }
  }

  ThemeProvider.childContextTypes = themeProps;

  ThemeProvider.propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  };

  return ThemeProvider;
};

export const injectTheme = (Wrapped) => {
  function ThemeInjector(props, context) {
    return <Wrapped {...props} theme={context.theme} />;
  }

  ThemeInjector.contextTypes = themeProps;

  return ThemeInjector;
};

export function ThemeWrapper({ className, children }, context) {
  return (
    <div className={`${context.theme.themeClass} ${className}`}>{children}</div>
  );
}

ThemeWrapper.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

ThemeWrapper.contextTypes = themeProps;

ThemeWrapper.defaultProps = {
  className: '',
};

export const withThemeWrapper = (Component, ...extraClasses) =>
  function (props) {
    return (
      <ThemeWrapper className={extraClasses.join(' ')}>
        <Component {...props} />
      </ThemeWrapper>
    );
  };
