import PropTypes from 'prop-types';
import React from 'react';
import isUndefined from 'lodash/isUndefined';

function LinearGradient({ stops, ...rest }) {
  return (
    <linearGradient {...rest}>
      {stops.map(({ offset, color, opacity }, i) => (
        <stop
          // The stop elements inside linearGradient are not going to get removed,
          // reordered, or filtered.
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          offset={`${offset * 100}%`}
          stopColor={color}
          stopOpacity={isUndefined(opacity) ? 1 : opacity}
        />
      ))}
    </linearGradient>
  );
}

const stopShape = PropTypes.shape({
  offset: PropTypes.number.isRequired, // 0 - 1
  color: PropTypes.string.isRequired, // Valid CSS color string
  opacity: PropTypes.number, // 0 - 1
});

LinearGradient.propTypes = {
  stops: PropTypes.arrayOf(stopShape),
};

export default LinearGradient;
