import { createReducer } from 'source/utils';
import constants from 'source/constants';

export const getInitialState = () => ({
  stateLoaded: false,
  user: null,
  acceptStatus: null,
});

const actionHandlers = {
  [constants.ACCEPT_TERMS_STATE_LOADING]: (state) => ({
    ...state,
    stateLoaded: false,
  }),

  [constants.ACCEPT_TERMS_STATE_LOADED]: (state, { user }) => ({
    ...state,
    user,
    stateLoaded: true,
  }),

  [constants.ACCEPT_TERMS_SUBMITTING]: (state) => ({
    ...state,
    acceptStatus: 'submitting',
  }),

  [constants.ACCEPT_TERMS_SUBMITTED]: (state, { user }) => ({
    ...state,
    user,
    acceptStatus: 'submitted',
  }),

  [constants.ACCEPT_TERMS_FAILED]: (state) => ({
    ...state,
    acceptStatus: 'failed',
  }),
};

export default createReducer(getInitialState(), actionHandlers);
