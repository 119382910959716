import { asyncActionCreator } from '@blogfoster/redux-async-utils';

import { actionAuthRequest } from 'source/utils/axios';
import constants from 'source/constants';

const namespace = 'exploreCampaigns';

export const actionTypes = {
  CAMPAIGN_MATCHES_FETCH: `${namespace}/CAMPAIGN_MATCHES_FETCH`,
  CAMPAIGNS_FETCH: `${namespace}/CAMPAIGNS_FETCH`,
  CAMPAIGN_TYPE_FILTER_CHANGE: `${namespace}/CAMPAIGN_TYPE_FILTER_CHANGE`,
  ENABLE_CAMPAIGN_EMAILS: `${namespace}/ENABLE_CAMPAIGN_EMAILS`,
};

export const loadCampaignMatches = asyncActionCreator(
  actionTypes.CAMPAIGN_MATCHES_FETCH,
  () => (dispatch, getState) =>
    actionAuthRequest(dispatch, getState).get('/v2/campaigns/matches'),
);

export const loadAllCampaigns = asyncActionCreator(
  actionTypes.CAMPAIGNS_FETCH,
  () => (dispatch, getState) =>
    actionAuthRequest(dispatch, getState).get('/v2/campaigns'),
);

export const enableCampaignEmails = asyncActionCreator(
  actionTypes.ENABLE_CAMPAIGN_EMAILS,
  () => (dispatch, getState) =>
    actionAuthRequest(dispatch, getState)
      .patch('/v1/users/settings', {
        recommendedCampaignEmails: true,
      })
      .then((res) => {
        dispatch({
          type: constants.APPLICATION_STATE_USER_SETTINGS_UPDATED,
          payload: {
            settings: res.data,
          },
        });
      }),
);

export const changeCampaignTypeFilter = (filter) => ({
  type: actionTypes.CAMPAIGN_TYPE_FILTER_CHANGE,
  payload: filter,
});
