import PropTypes from 'prop-types';
import React, { Children, cloneElement } from 'react';
import classNames from 'classnames';

function FormGroup({ children, className, row, hasDanger, ...props }) {
  className = classNames(
    'form-group',
    {
      'has-danger': hasDanger,
      row,
    },
    className,
  );

  if (row) {
    // Pass down the column flag property to its children, if this form group is also a row.
    children = Children.map(children, (child) =>
      cloneElement(child, {
        column: true,
      }),
    );
  }

  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
}

FormGroup.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  row: PropTypes.bool,
  hasDanger: PropTypes.bool,
};

FormGroup.defaultProps = {
  row: false,
  hasDanger: false,
};

export default FormGroup;
