import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import { Link, withRouter } from 'react-router';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import get from 'lodash/get';
import cx from 'classnames';

import {
  ActivityStreamItem,
  ActivityStreamHeader,
  ActivityStreamBody,
  ActivityStreamSelectionOptions,
  ActivityStreamRadioSelection,
  ActivityStreamCheckboxSelection,
} from 'source/components/common/activityStream';
import Button, { SubmitButton } from 'source/components/common/button';
import Icon from 'source/components/common/icon';
import { MessageCard } from 'source/components/common/message';
import { Checkbox } from 'source/components/common/form';

class Accept extends Component {
  constructor(props) {
    super(props);

    this.statusToRender = {
      inactive: this.renderInactive,
      active: this.renderActive,
      refused: this.renderRefused,
      done: this.renderDone,
      expired: this.renderExpired,
    };
  }

  handleDateSelection = (e) => {
    const {
      target: { value },
    } = e;
    const { formName, onChange } = this.props;

    onChange(formName, 'acceptedPublishingDateId', value);
  };

  handleCheckboxChange = (e) => {
    const {
      target: { id, checked },
    } = e;
    const { formName, onChange } = this.props;

    onChange(formName, id, checked);
  };

  handleConfirm = () => {
    const { formName, onChange, onSubmit } = this.props;

    onChange(formName, 'accept', true);

    return onSubmit(formName);
  };

  handleRefuse = () => {
    const { formName, onChange, onSubmit } = this.props;

    onChange(formName, 'accept', false);

    return onSubmit(formName);
  };

  trackPaymentRedirect = () => {
    // get current pathname either from the injected react-router prop
    // or use the global window object
    const pathname =
      get(this.props, 'location.pathname') || window.location.pathname;

    this.props.onTrackLinkToAccountSettings({
      pathname,
      label: get(this.props, 'campaign.details.headline', ''),
    });
  };

  shouldRenderPaymentIncomplete() {
    return !this.props.workflow.payload.isBillingInformationCompleted;
  }

  renderInactive() {
    const { intl } = this.props;

    return (
      <ActivityStreamItem key="inactive" iconName="mail">
        <ActivityStreamHeader
          title={intl.formatMessage({
            id: 'sponsoredPosts.campaigns.accept',
            defaultMessage: 'Accept mission',
          })}
        />
      </ActivityStreamItem>
    );
  }

  renderConfirmDates({
    disabled = false,
    onChange,
    value = true,
    selectedDate,
  }) {
    const {
      intl,
      campaign: {
        timeline: { previewDueDays },
      },
    } = this.props;

    const normalizedPreviewDate = moment(selectedDate.date, 'YYYY-MM-DD')
      .subtract(previewDueDays, 'day')
      .toDate();
    const normalizedPublishDate = moment(
      selectedDate.date,
      'YYYY-MM-DD',
    ).toDate();

    const previewDueDate = (
      <strong>
        {intl.formatDate(normalizedPreviewDate, {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })}
      </strong>
    );

    const publishDate = (
      <strong>
        {intl.formatDate(normalizedPublishDate, {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })}
      </strong>
    );

    return (
      <Checkbox
        id="acceptedPublishingDate"
        key="acceptedPublishingDate"
        className="activity-stream-option"
        checked={value}
        onChange={onChange}
        disabled={disabled}
      >
        <FormattedMessage
          id="sponsoredPosts.campaigns.accept.publishingDate.confirmation.date"
          defaultMessage="I agree to fulfill the requirements given in the sponsored posting description as well as to submit a preview of my post by {previewDueDate} and publish it on {publishDate}."
          values={{ previewDueDate, publishDate }}
        />
      </Checkbox>
    );
  }

  renderConfirmTerms({ disabled = false, onChange, value = true }) {
    const { intl } = this.props;

    const termsAndConditions = (
      <a
        href={intl.formatMessage({
          id: 'signup.termsUrl',
          defaultMessage: 'https://www.eqolot.com/agb/blogger-influencer/',
        })}
      >
        <FormattedMessage
          id="signup.termsAndConditions"
          defaultMessage="Terms & Conditions"
        />
      </a>
    );

    return (
      <Checkbox
        id="acceptedTerms"
        key="acceptedTerms"
        className="activity-stream-option"
        checked={value}
        onChange={onChange}
        disabled={disabled}
      >
        <FormattedMessage
          id="sponsoredPosts.campaigns.accept.publishingDate.confirmation.terms"
          defaultMessage="I accept eqolot's {termsAndConditions}."
          values={{ termsAndConditions }}
        />
      </Checkbox>
    );
  }

  renderConfirmAdditionalTerms({ disabled = false, onChange, value = true }) {
    const {
      workflow: {
        payload: { additionalTerms },
      },
    } = this.props;

    // don't show this section if no additional terms assigned
    if (!additionalTerms) {
      return null;
    }

    return (
      <Checkbox
        id="acceptedAdditionalTerms"
        key="acceptedAdditionalTerms"
        className="activity-stream-option"
        checked={value}
        onChange={onChange}
        disabled={disabled}
      >
        <FormattedMessage
          id="sponsoredPosts.campaigns.accept.publishingDate.confirmation.additionalTerms"
          defaultMessage="I understand and accept these <b>special terms for this collaboration</b>:"
        />
        <article className="m-t-1">{additionalTerms}</article>
      </Checkbox>
    );
  }

  renderConfirmTermsForm({ selectedDate }) {
    const { formName, form } = this.props;

    const { acceptedPublishingDate, acceptedTerms, acceptedAdditionalTerms } =
      form[formName];

    const selectOptions = [
      this.renderConfirmDates({
        onChange: this.handleCheckboxChange,
        value: acceptedPublishingDate,
        selectedDate,
      }),

      this.renderConfirmTerms({
        onChange: this.handleCheckboxChange,
        value: acceptedTerms,
      }),

      this.renderConfirmAdditionalTerms({
        onChange: this.handleCheckboxChange,
        value: acceptedAdditionalTerms,
      }),
    ];

    return (
      <ActivityStreamCheckboxSelection>
        <h6>
          <FormattedMessage
            id="sponsoredPosts.campaigns.accept.publishingDate.confirmation"
            defaultMessage="You must agree to the terms of this sponsored posting offer as well as eqolot's Terms & Conditions in order to participate:"
          />
        </h6>
        <ActivityStreamSelectionOptions options={selectOptions} />
      </ActivityStreamCheckboxSelection>
    );
  }

  renderActive() {
    const {
      intl,
      campaign,
      application,
      workflow: {
        payload: { availableDates, additionalTerms },
      },

      formName,
      form,
    } = this.props;

    const campaignName = campaign.details.headline;
    const applicationId = application.id;
    const mailSubject = `Campaign: ${campaignName}; Application: ${applicationId}`;
    const mailtoLink = `mailto:influencerteam@eqolot.com?subject=${encodeURIComponent(
      mailSubject,
    )}`;

    if (isEmpty(availableDates)) {
      // render error message and contact button
      return (
        <ActivityStreamItem key="active" status="active" iconName="mail">
          <ActivityStreamHeader
            title={intl.formatMessage({
              id: 'sponsoredPosts.campaigns.accept',
              defaultMessage: 'Accept mission',
            })}
          />

          <ActivityStreamBody>
            <MessageCard type="info" className="m-b-1">
              <strong>
                <FormattedMessage
                  id="sponsoredPosts.campaigns.accept.publishingDate.empty"
                  defaultMessage="Woops! It seems there are no available publish slots for this campaign. Please contact us to to resolve this issue."
                />
              </strong>
            </MessageCard>
            <div className="btn-toolbar">
              <a className="btn" href={mailtoLink}>
                <FormattedMessage
                  id="sponsoredPosts.campaigns.accept.contact"
                  defaultMessage="Contact"
                />
              </a>
            </div>
          </ActivityStreamBody>
        </ActivityStreamItem>
      );
    }

    const {
      acceptedPublishingDateId,
      acceptedPublishingDate,
      acceptedTerms,
      acceptedAdditionalTerms,
      submitting,
    } = form[formName];

    const paymentInfoIncomplete = this.shouldRenderPaymentIncomplete();

    const dateOptions = availableDates.map(({ id, date }) => (
      <label
        className={cx('activity-stream-option', {
          'activity-stream-option--selected': id === acceptedPublishingDateId,
          'activity-stream-option--disabled': paymentInfoIncomplete,
        })}
        htmlFor={`acceptedPublishingDateId:${id}`}
        key={id}
      >
        <input
          type="radio"
          name="acceptedPublishingDateId"
          id={`acceptedPublishingDateId:${id}`}
          value={id}
          checked={id === acceptedPublishingDateId}
          onChange={this.handleDateSelection}
          disabled={paymentInfoIncomplete}
        />

        <FormattedDate
          value={new Date(date)}
          weekday="long"
          day="numeric"
          month="long"
          year="numeric"
        />
      </label>
    ));

    const selectedDate = find(availableDates, { id: acceptedPublishingDateId });

    return (
      <ActivityStreamItem key="active" status="active" iconName="mail">
        <ActivityStreamHeader
          title={intl.formatMessage({
            id: 'sponsoredPosts.campaigns.accept',
            defaultMessage: 'Accept mission',
          })}
        />

        <ActivityStreamBody>
          {paymentInfoIncomplete && (
            <MessageCard type="info" className="m-b-1">
              <strong>
                <FormattedMessage
                  id="sponsoredPosts.campaigns.accept.paymentInfoMissing.strong"
                  defaultMessage="Your payment data is not complete."
                />
              </strong>
              <FormattedMessage
                id="sponsoredPosts.campaigns.accept.paymentInfoMissing.message.1"
                defaultMessage="Please insert your "
              />
              <Link
                to="/account-settings/payment"
                onClick={this.trackPaymentRedirect}
              >
                <FormattedMessage
                  id="sponsoredPosts.campaigns.accept.paymentInfoMissing.message.2"
                  defaultMessage="payment data here"
                />
              </Link>
              <FormattedMessage
                id="sponsoredPosts.campaigns.accept.paymentInfoMissing.message.3"
                defaultMessage=", so we can process your earnings."
              />
            </MessageCard>
          )}

          <ActivityStreamRadioSelection>
            <h6>
              <FormattedMessage
                id="sponsoredPosts.campaigns.accept.publishingDate.selection"
                defaultMessage="Please choose a date on which to publish your post. We encourage you to have another look on the the briefing section at the top of this page to make sure you can deliver until this date:"
              />
            </h6>
            <ActivityStreamSelectionOptions options={dateOptions} />
          </ActivityStreamRadioSelection>
          {selectedDate && this.renderConfirmTermsForm({ selectedDate })}
          <div className="btn-toolbar m-t-1">
            <SubmitButton
              onClick={this.handleConfirm}
              disabled={
                !acceptedTerms ||
                !acceptedPublishingDate ||
                !acceptedPublishingDateId ||
                (additionalTerms ? !acceptedAdditionalTerms : false) ||
                paymentInfoIncomplete ||
                submitting
              }
            >
              <Icon name="accept" />
              <FormattedMessage
                id="sponsoredPosts.campaigns.accept.accept"
                defaultMessage="Accept"
              />
            </SubmitButton>
            <SubmitButton
              secondary
              onClick={this.handleRefuse}
              disabled={paymentInfoIncomplete || submitting}
            >
              <Icon name="decline" />
              <FormattedMessage
                id="sponsoredPosts.campaigns.accept.refuse"
                defaultMessage="Decline"
              />
            </SubmitButton>
            <Button secondary href={mailtoLink}>
              <FormattedMessage
                id="sponsoredPosts.campaigns.accept.contact"
                defaultMessage="Contact"
              />
            </Button>
          </div>
        </ActivityStreamBody>
      </ActivityStreamItem>
    );
  }

  renderRefused() {
    const {
      intl,
      workflow: { lastUpdate },
    } = this.props;

    return (
      <ActivityStreamItem key="refused" status="failed" iconName="mail">
        <ActivityStreamHeader
          title={intl.formatMessage({
            id: 'sponsoredPosts.campaigns.accept.refused',
            defaultMessage: 'Mission declined',
          })}
          date={lastUpdate}
        />

        <ActivityStreamBody>
          <MessageCard type="info">
            <strong>
              <FormattedMessage
                id="sponsoredPosts.campaigns.accept.refused.message"
                defaultMessage="You declined the mission."
              />
            </strong>
          </MessageCard>
        </ActivityStreamBody>
      </ActivityStreamItem>
    );
  }

  renderDone() {
    const {
      intl,
      workflow: {
        lastUpdate,
        payload: { publishDate },
      },
    } = this.props;

    const acceptedOptions = [
      this.renderConfirmDates({
        disabled: true,
        selectedDate: { date: publishDate },
      }),

      this.renderConfirmTerms({ disabled: true }),
      this.renderConfirmAdditionalTerms({ disabled: true }),
    ];

    return (
      <ActivityStreamItem
        key="done"
        status="done"
        iconName="mail"
        expandable
        initialExpanded={false}
      >
        <ActivityStreamHeader
          title={intl.formatMessage({
            id: 'sponsoredPosts.campaigns.accept.accepted',
            defaultMessage: 'Mission accepted',
          })}
          date={lastUpdate}
        />

        <ActivityStreamBody>
          <ActivityStreamRadioSelection>
            <h6>
              <FormattedMessage
                id="sponsoredPosts.campaigns.accept.datesAgreement"
                defaultMessage="You chose this date to publish your post"
              />
            </h6>
            <ActivityStreamSelectionOptions
              options={[
                <label
                  htmlFor="nothing"
                  key="acceptedPublishingDate"
                  className="activity-stream-option disabled"
                >
                  <FormattedDate
                    value={new Date(publishDate)}
                    weekday="long"
                    day="numeric"
                    month="long"
                    year="numeric"
                  />
                </label>,
              ]}
            />
          </ActivityStreamRadioSelection>
          <ActivityStreamCheckboxSelection>
            <h6>
              <FormattedMessage
                id="sponsoredPosts.campaigns.accept.termsAgreement"
                defaultMessage="You agreed to the terms of this sponsored posting offer as well as eqolot's Terms & Conditions in order to participate"
              />
            </h6>
            <ActivityStreamSelectionOptions options={acceptedOptions} />
          </ActivityStreamCheckboxSelection>
        </ActivityStreamBody>
      </ActivityStreamItem>
    );
  }

  renderExpired() {
    const { intl } = this.props;

    return (
      <ActivityStreamItem key="expired" status="failed" iconName="mail">
        <ActivityStreamHeader
          title={intl.formatMessage({
            id: 'sponsoredPosts.campaigns.accept.expired',
            defaultMessage: 'Mission expired',
          })}
        />

        <ActivityStreamBody>
          <MessageCard type="info">
            <strong>
              <FormattedMessage
                id="sponsoredPosts.campaigns.accept.expired.title"
                defaultMessage="What a pity!"
              />
            </strong>
            <span>
              <FormattedMessage
                id="sponsoredPosts.campaigns.accept.expired.message"
                defaultMessage="The deadline for application has expired, unfortunately."
              />
            </span>
          </MessageCard>
        </ActivityStreamBody>
      </ActivityStreamItem>
    );
  }

  render() {
    const {
      workflow: { status },
    } = this.props;

    const renderFn = this.statusToRender[status];
    if (!renderFn) {
      return this.renderInactive();
    }

    return renderFn.call(this);
  }
}

Accept.propTypes = {
  formName: PropTypes.string,
  workflow: PropTypes.shape({
    status: PropTypes.oneOf([
      'inactive',
      'active',
      'refused',
      'done',
      'expired',
    ]).isRequired,
    payload: PropTypes.shape({
      availableDates: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          date: PropTypes.string.isRequired,
        }),
      ),

      isBillingInformationCompleted: PropTypes.bool,
      additionalTerms: PropTypes.string,
      publishDate: PropTypes.string,
    }).isRequired,
    lastUpdate: PropTypes.string,
  }).isRequired,
  form: PropTypes.object.isRequired,
  campaign: PropTypes.shape({
    timeline: PropTypes.shape({
      previewDueDays: PropTypes.number.isRequired,
    }).isRequired,
    details: PropTypes.shape({
      headline: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  application: PropTypes.shape({
    id: PropTypes.string,
  }),

  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onTrackLinkToAccountSettings: PropTypes.func.isRequired,
};

Accept.defaultProps = {
  application: { id: undefined },
  formName: 'accept',
};

export default withRouter(injectIntl(Accept));
