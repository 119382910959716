import React from 'react';
import PropTypes from 'prop-types';

function YearPickerOld({
  currentYear,
  onPrevYear,
  onNextYear,
  disablePrev,
  disableNext,
  className,
}) {
  return (
    <div className={`btn-group pull-sm-right ${className}`} role="group">
      <button
        type="button"
        className="btn btn-link handler"
        onClick={onPrevYear}
        disabled={disablePrev}
      >
        <svg className="icon">
          <use xlinkHref="#icon-chevron-left" />
        </svg>
      </button>
      <div className="picker">{currentYear}</div>
      <button
        type="button"
        className="btn btn-link handler"
        onClick={onNextYear}
        disabled={disableNext}
      >
        <svg className="icon">
          <use xlinkHref="#icon-chevron-right" />
        </svg>
      </button>
    </div>
  );
}

YearPickerOld.propTypes = {
  currentYear: PropTypes.number.isRequired,
  onPrevYear: PropTypes.func.isRequired,
  onNextYear: PropTypes.func.isRequired,
  disablePrev: PropTypes.bool.isRequired,
  disableNext: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

YearPickerOld.defaultProps = {
  className: '',
};

/**
 * Small wrapper around the previous implementation of `YearPicker`
 */
class YearPicker extends React.Component {
  onPrevYear = () => {
    this.props.onChange(this.props.value - 1);
  };

  onNextYear = () => {
    this.props.onChange(this.props.value + 1);
  };

  render() {
    return (
      <YearPickerOld
        currentYear={this.props.value}
        onPrevYear={this.onPrevYear}
        onNextYear={this.onNextYear}
        disablePrev={false}
        disableNext={false}
        className={this.props.className}
      />
    );
  }
}

YearPicker.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

YearPicker.defaultProps = {
  className: undefined,
};

export default YearPicker;
