export const isVideoMIMEType = (mimeType) => mimeType.includes('video');
export const isImageMIMEType = (mimeType) => mimeType.includes('image');

export const getMediaTypeFromFile = (file) => {
  if (isImageMIMEType(file.type)) {
    return 'image';
  }

  if (isVideoMIMEType(file.type)) {
    return 'video';
  }

  return null;
};
