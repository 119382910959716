import { asyncActionCreator } from '@blogfoster/redux-async-utils';

import { actionAuthRequest } from 'source/utils/axios';

// Actions

export const namespace = 'campaignDetails/details';

export const actionTypes = {
  CAMPAIGN_MATCH_FETCH: `${namespace}/CAMPAIGN_MATCH_FETCH`,
  APPLICATIONS_FETCH: `${namespace}/APPLICATIONS_FETCH`,
  APPLICATION_FETCH: `${namespace}/APPLICATION_FETCH`,
  APPLICATION_CREATE: `${namespace}/APPLICATION_CREATE`,
  APPLICATION_UPDATE: `${namespace}/APPLICATION_UPDATE`,
};

// Action creators

export const loadCampaignMatch = asyncActionCreator(
  actionTypes.CAMPAIGN_MATCH_FETCH,
  (campaignId, clubId) => (dispatch, getState) =>
    actionAuthRequest(dispatch, getState, {
      dispatchErrors: false,
      dispatch404: true,
    }).get(`/v2/campaigns/${campaignId}/matches`, {
      params: { privateNetworkId: clubId },
    }),
);

export const loadApplication = asyncActionCreator(
  actionTypes.APPLICATION_FETCH,
  (applicationId) => (dispatch, getState) =>
    actionAuthRequest(dispatch, getState, {
      dispatchErrors: false,
      dispatch404: true,
    }).get(`/v2/applications/${applicationId}`),
);

export const loadApplications = asyncActionCreator(
  actionTypes.APPLICATIONS_FETCH,
  (campaignId) => (dispatch, getState) =>
    actionAuthRequest(dispatch, getState).get(`/v2/applications`, {
      params: { campaignId },
    }),
);

export const createApplication = asyncActionCreator(
  actionTypes.APPLICATION_CREATE,
  (payload) => (dispatch, getState) =>
    actionAuthRequest(dispatch, getState, { dispatchErrors: false })
      .post('/v2/applications', payload)
      .then((response) => {
        // @sean TODO Update event to be non-website centric
        dispatch({ type: 'SPONSORED_POSTS_CAMPAIGN_APPLIED' });
        return response;
      }),
);

export const updateApplication = asyncActionCreator(
  actionTypes.APPLICATION_UPDATE,
  (applicationId, payload = {}) =>
    (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, { dispatchErrors: false }).patch(
        `/v2/applications/${applicationId}`,
        payload,
      ),
);

export const updateApplicationContentPreview = asyncActionCreator(
  actionTypes.APPLICATION_UPDATE,
  (applicationId, payload = {}) =>
    (dispatch, getState) =>
      actionAuthRequest(dispatch, getState, { dispatchErrors: false }).post(
        `/v2/applications/${applicationId}/content-previews`,
        payload,
      ),
);
