import PropTypes from 'prop-types';

export const idType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);
export const dateType = PropTypes.instanceOf(Date);
export const platform = PropTypes.oneOf([
  'website',
  'ga',
  'youtube',
  'instagram',
  'pinterest',
  'facebook',
  'twitter',
  'tiktok',
]);

// message for the alert bar
export const messageProp = PropTypes.shape({
  type: PropTypes.string.isRequired,
  textId: PropTypes.string.isRequired,
});

export const category = PropTypes.shape({
  code: PropTypes.string.isRequired,
  id: PropTypes.number,
  parent: PropTypes.string,
});

export const channel = PropTypes.shape({
  id: PropTypes.string.isRequired,
  platform,
  ready: PropTypes.bool,
  data: PropTypes.object,
});

export const form = PropTypes.shape({
  name: PropTypes.string.isRequired,
  disconnect: PropTypes.shape({
    confirmation: PropTypes.bool,
    submitFailed: PropTypes.bool,
    submitting: PropTypes.bool,
  }),
  readyState: PropTypes.shape({
    accountSettingsAvailable: PropTypes.bool,
    hasCachedData: PropTypes.bool,
  }),
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ),
  selectedCategories: PropTypes.arrayOf(category),
  selectedSubCategories: PropTypes.arrayOf(category),
  weightDistribution: PropTypes.object,
  submitting: PropTypes.bool,
});

export const screenshotName = PropTypes.oneOf([
  'storiesImpressions',
  'postsImpressions',
  'countries',
  'ageAndGender',
]);

export const screenshot = PropTypes.shape({
  url: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
});

export const screenshots = PropTypes.shape({
  storiesImpressions: screenshot,
  postsImpressions: screenshot,
  countries: screenshot,
  ageAndGender: screenshot,
  oldestUpdatedAt: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
});

export const ref = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
]);

export const userGroup = PropTypes.shape({
  createdAt: PropTypes.string.isRequired,
  details: PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
  }).isRequired,
  groupId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  pitch: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
});

export const user = PropTypes.shape({
  acceptedTerms: PropTypes.bool.isRequired,
  boarded: PropTypes.bool.isRequired,
  confirmed: PropTypes.bool.isRequired,
  country: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(PropTypes.userGroup).isRequired,
  id: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  lastLogin: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    recommendedCampaignEmails: PropTypes.bool.isRequired,
    promotionalEmails: PropTypes.bool.isRequired,
  }).isRequired,
  updatedAt: PropTypes.string.isRequired,
});

export const workflowStatus = PropTypes.oneOf([
  'inactive',
  'active',
  'refused',
  'done',
  'expired',
]);

export const workflowStep = PropTypes.shape({
  name: PropTypes.string.isRequired,
  status: workflowStatus.isRequired,
  lastUpdate: PropTypes.string.isRequired,
  payload: PropTypes.object,
});

export const workflow = PropTypes.shape({
  type: platform,
  steps: PropTypes.arrayOf(workflowStep),
});
