import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cx from 'classnames';
import { Link } from 'react-router';

import Clipboard from './clipboard';
import Icon from './icon';
import Tether from './tether';
import Tooltip from './tooltip';

const injectButton = (Component) => {
  function InjectedButton({ className, secondary, active, ...props }) {
    className = cx(
      'btn',
      {
        'btn-secondary': secondary,
        active,
      },
      className,
    );

    return <Component className={className} {...props} />;
  }

  InjectedButton.propTypes = {
    className: PropTypes.string,
    secondary: PropTypes.bool,
    active: PropTypes.bool,
  };

  InjectedButton.defaultProps = {
    className: '',
    secondary: false,
    active: false,
  };

  return InjectedButton;
};

const Button = injectButton(({ children, ...props }) => (
  <a {...props}>{children}</a>
));

export const ButtonLink = injectButton((props) => <Link {...props} />);

export const SubmitButton = injectButton((props) => (
  <button type="submit" {...props} />
));

export class CopyButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
      error: false,
    };
  }

  handleClipboardSuccess = () => {
    this.setState({ success: true });
  };

  handleClipboardError = () => {
    // set the error state to show a tooltip ...
    this.setState({ error: true });

    // ... and disable the tooltip in 4 seconds again
    setTimeout(() => {
      this.setState({ error: false });
    }, 4000);
  };

  renderIcon() {
    const { success } = this.state;

    return success ? <Icon name="accept" /> : null;
  }

  render() {
    const { target, text, children, className, ...props } = this.props;
    const { error } = this.state;

    const tooltip = (
      <Tooltip className="small" placement="bottom">
        press ⌘-c to copy
      </Tooltip>
    );

    return (
      <Tether element={tooltip} placement="bottom" enabled={error}>
        <Clipboard
          target={target}
          text={text}
          onSuccess={this.handleClipboardSuccess}
          onError={this.handleClipboardError}
        >
          <Button className={cx('btn-copy', className)} {...props}>
            {children}
            {this.renderIcon()}
          </Button>
        </Clipboard>
      </Tether>
    );
  }
}

CopyButton.propTypes = {
  target: PropTypes.string,
  text: PropTypes.string.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
};

export default Button;
