import PropTypes from 'prop-types';
import React from 'react';
import { Line } from 'react-progress-bar.js';

function ProgressBar({ duration }) {
  const options = {
    duration,
    color: null,
  };

  return (
    <Line
      initialAnimate
      progress={1}
      options={options}
      containerClassName="main-progressbar"
    />
  );
}

ProgressBar.propTypes = {
  duration: PropTypes.number,
};

ProgressBar.defaultProps = {
  duration: 600,
};

export default ProgressBar;
