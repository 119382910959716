import checker from 'bd-password-strength';

const LOW_STRENGTH_SCORE = 25;
const HIGH_STRENGTH_SCORE = 55;

export default function passwordScorer(password) {
  let strength = 'weak';

  const score = checker.checkPassword(password);

  if (score < LOW_STRENGTH_SCORE) {
    strength = 'weak';
  } else if (score >= LOW_STRENGTH_SCORE && score < HIGH_STRENGTH_SCORE) {
    strength = 'moderate';
  } else {
    strength = 'strong';
  }

  return {
    score,
    strength,
  };
}
