import React from 'react';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { MessageCard } from 'source/components/common/message';

export default function () {
  const revenuesLink = (
    <Link to="/revenues">
      <FormattedMessage
        id="sponsoredPosts.campaigns.mission.accomplished.revenuesLink"
        defaultMessage="Revenues"
      />
    </Link>
  );

  return (
    <div className="sponsored-posts-mission-accomplished">
      <FormattedMessage
        id="sponsoredPosts.campaigns.mission.accomplished.title"
        defaultMessage="Mission accomplished!"
        tagName="h2"
      />

      <MessageCard type="info">
        <strong>
          <FormattedMessage
            id="sponsoredPosts.campaigns.mission.accomplished.info.title"
            defaultMessage="Thank you!"
          />
        </strong>
        <span>
          <FormattedMessage
            id="sponsoredPosts.campaigns.mission.accomplished.info.body"
            defaultMessage="Your payment will soon be credited to your account. It will appear in the {revenuesLink} section."
            values={{
              revenuesLink,
            }}
          />
        </span>
      </MessageCard>
    </div>
  );
}
