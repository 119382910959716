import React from 'react';
import { Field, FormSection } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import { Input } from './reduxFormComponents';

function BankAccountFields() {
  return (
    <div style={{ marginTop: '40px' }}>
      <h4 className="headline-form">
        <FormattedMessage
          id="accountSettings.payment.bankAccount"
          defaultMessage="Bank Account"
        />
      </h4>

      <FormSection name="account">
        <Field
          name="owner"
          component={Input}
          labelId="accountSettings.payment.bankAccountOwner"
        />

        <Field
          name="iban"
          component={Input}
          labelId="accountSettings.payment.bankAccountNumber"
        />

        <Field
          name="bic"
          component={Input}
          labelId="accountSettings.payment.bankAccountCode"
        />
      </FormSection>
    </div>
  );
}

export default BankAccountFields;
