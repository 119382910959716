import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { defaultMemoize as memoize } from 'reselect';
import keyBy from 'lodash/keyBy';
import noop from 'lodash/noop';
import filter from 'lodash/filter';

import { FormError, NextPrevButtonList } from 'source/components/common';
import { validateCategories } from 'source/utils/validators/categories';
import { SubCategories } from 'source/components/common/categories';

/**
 * creates the subCategories object per channel
 *
 * @typedef Category Object<{ id: Number, code: String, [parent: String] }>
 * @typedef Categories Array<Category>
 * @typedef SubCategories Array<Object<{ rootCategory: Category, subCategories: Categories }>>
 *
 * @param {Categories} selectedCategories - selected root categories
 * @param {Categories} categories - categories API response
 *
 * @result {SubCategories}
 */
const getSubCategories = memoize((selectedCategories, categories) =>
  selectedCategories.map((rootCategory) => {
    const subCategories = filter(categories, { parent: rootCategory.code });

    return { rootCategory, subCategories };
  }),
);

class SubCategoriesWizardStep extends React.PureComponent {
  handleOnNext = () => {
    const { form, onErrors, onNextStep } = this.props;
    const errors = [
      ...validateCategories(form, {
        maxCategories: 3,
        key: 'selectedSubCategories',
      }),
    ];

    return errors.length > 0 ? onErrors(errors) : onNextStep();
  };

  handleChange = (selectedCategories) => {
    const { onChange } = this.props;

    onChange('selectedSubCategories', selectedCategories);
  };

  renderErrorIfExists = (name) => {
    const { form } = this.props;
    const indexedErrors = keyBy(form.errors, 'id');
    const error = indexedErrors[name];

    return error ? <FormError error={error} /> : null;
  };

  render() {
    const { form, categories, onPrevStep } = this.props;
    const {
      selectedCategories = [], // selected root categories
      selectedSubCategories = [], // selected sub categories
    } = form;

    const subCategories = getSubCategories(selectedCategories, categories);

    return (
      <div className="website-form">
        <div className="text-center">
          <h4>
            <FormattedMessage
              id="preferences.categories.whatCategories"
              defaultMessage="What topics is your channel about?"
            />
          </h4>
          {this.renderErrorIfExists('selectedSubCategories')}
        </div>

        <div className="pull-md-right">
          <FormattedMessage
            id="preferences.categories.selectedOf"
            defaultMessage="{selected} of {max} categories selected"
            values={{ selected: `${selectedSubCategories.length}`, max: 3 }}
          />
        </div>

        <SubCategories
          className="m-t-3 p-x-2"
          categories={categories}
          subCategories={subCategories}
          selectedCategories={selectedSubCategories}
          maxCategories="3"
          onChange={this.handleChange}
        />

        <div className="modal-footer">
          <NextPrevButtonList
            onPrevStep={onPrevStep}
            onNextStep={this.handleOnNext}
          />
        </div>
      </div>
    );
  }
}

SubCategoriesWizardStep.propTypes = {
  form: PropTypes.object,
  categories: PropTypes.array,
  onChange: PropTypes.func,
  onErrors: PropTypes.func,
  onNextStep: PropTypes.func,
  onPrevStep: PropTypes.func,
};

SubCategoriesWizardStep.defaultProps = {
  form: {},
  categories: [],
  onChange: noop,
  onErrors: noop,
  onNextStep: noop,
  onPrevStep: noop,
};

export default SubCategoriesWizardStep;
