import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Link } from 'react-router';
import cx from 'classnames';

import { Icon } from 'source/components/common';

class InvoiceTableAccordion extends React.Component {
  constructor(props) {
    super(props);

    const lines = props.invoice.lines || [];
    const toggleStatusByPaymentId = lines.reduce((acc, line) => {
      acc[line.payment.id] = false;

      return acc;
    }, {});

    this.state = toggleStatusByPaymentId;
  }

  openRow = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const paymentId = e.currentTarget.id;

    this.setState((prevState) => ({
      [paymentId]: !prevState[paymentId],
    }));
  };

  render() {
    if (this.props.invoice.lines.length === 0) {
      return null;
    }

    const { invoice } = this.props;

    // NOTE: This table shares most of the classNames with the `OpenPayments`
    // component. We should either rename the classNames to be more generic
    // or use independently styled components that don't share global CSS rules
    // with each other.
    return (
      <div className="invoice-table">
        <div className="invoice-table__header">
          <h3>
            <FormattedMessage
              id="revenues.openPayments.description"
              defaultMessage="Description"
            />
          </h3>
          <h3>
            <FormattedMessage
              id="revenues.openPayments.amount"
              defaultMessage="Amount"
            />
          </h3>
        </div>
        <div className="invoice-table__body">
          <table>
            <tbody>
              {invoice.lines.map(
                ({
                  currency,
                  price,
                  description,
                  payment: { id: paymentId, references = {} } = {},
                }) => {
                  const hasApplication = Boolean(references.application);
                  const hasCampaign = Boolean(references.campaign);
                  const hasChannel = Boolean(references.channel);
                  const allowToggle =
                    (hasApplication && hasCampaign) || hasChannel;
                  const isOpened = this.state[paymentId];

                  return (
                    <tr
                      className={cx('open-payments-table__row', {
                        expandable: allowToggle,
                        opened: isOpened,
                      })}
                      key={paymentId}
                      id={paymentId}
                      onClick={this.openRow}
                    >
                      <td className="open-payment-toggle">
                        {allowToggle && (
                          <Icon
                            name="arrow"
                            id={`invoice-toggler-${paymentId}`}
                          />
                        )}
                      </td>
                      <td className="open-payment-details">
                        <span className="open-payments-details__name">
                          {description}
                        </span>
                        <ul className="open-payment-details__references">
                          {hasApplication && hasCampaign && (
                            <li>
                              Campaign:{' '}
                              <Link
                                to={`/sponsored-posts/applications/${references.application.id}`}
                              >
                                {references.campaign.details.headline}
                              </Link>
                            </li>
                          )}

                          {hasChannel && (
                            <li>
                              Channel:{' '}
                              <Link to="/profile">
                                {references.channel.name}
                              </Link>
                            </li>
                          )}
                        </ul>
                      </td>
                      <td className="open-payment-amount">
                        <FormattedNumber
                          currency={currency}
                          // eslint-disable-next-line react/style-prop-object
                          style="currency"
                          value={price / 100}
                        />
                      </td>
                    </tr>
                  );
                },
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

InvoiceTableAccordion.propTypes = {
  invoice: PropTypes.shape({
    lines: PropTypes.arrayOf(
      PropTypes.shape({
        currency: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
        payment: PropTypes.shape({
          id: PropTypes.string.isRequired,
          references: PropTypes.shape({
            application: PropTypes.shape({
              id: PropTypes.string.isRequired,
            }),

            campaign: PropTypes.shape({
              details: PropTypes.shape({
                headline: PropTypes.string.isRequired,
              }).isRequired,
            }),

            channel: PropTypes.shape({
              name: PropTypes.string.isRequired,
            }),
          }),
        }),
      }),
    ),
  }).isRequired,
};

export default InvoiceTableAccordion;
