import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  defaultProps,
  setPropTypes,
  withHandlers,
  withProps,
} from 'recompose';
import { FormattedMessage, injectIntl } from 'react-intl';
import noop from 'lodash/noop';
import get from 'lodash/get';

import {
  ActivityStreamItem,
  ActivityStreamHeader,
  ActivityStreamItemWithSubHeading,
} from 'source/components/common/activityStream';
import { SubmitButton } from 'source/components/common/button';
import Form, {
  FormGroup,
  Input,
  Label,
  Checkbox,
  FormattedError,
} from 'source/components/common/form';

const getSocialMediaDisplayName = (type) => {
  const displayNamesByType = {
    facebook: 'Facebook',
    instagram: 'Instagram',
    twitter: 'Twitter',
    pinterest: 'Pinterest',
    youtube: 'YouTube',
  };

  return displayNamesByType[type] || '';
};

function SocialMediaSharingForm({
  disabled,
  formName,
  formData,
  onChange,
  onCheckboxChange,
  onSubmit,
}) {
  return (
    <Form id={formName} onChange={onChange} onSubmit={onSubmit}>
      <ul className="input-checkbox-list">
        {formData.data.map(({ type, url, notApplicable = false }, index) => (
          <li className="input-checkbox-list__item" key={type}>
            <fieldset className="form-group" disabled={disabled}>
              <FormGroup>
                <Label htmlFor={type}>
                  <span>{getSocialMediaDisplayName(type)}</span>
                  <Input
                    id={`data.[${index}].url`}
                    value={notApplicable || !url ? '' : url}
                    disabled={notApplicable}
                  />
                </Label>
                <FormattedError
                  id={get(formData.errors, `data.[${index}].url`)}
                  hide={notApplicable}
                />

                <Checkbox
                  id={`data.[${index}].notApplicable`}
                  checked={notApplicable}
                  onChange={onCheckboxChange}
                >
                  <span className="text-muted">
                    <FormattedMessage
                      id="sponsoredPosts.campaigns.socialMediaSharing.notApplicable"
                      defaultMessage="I don't have {platform}."
                      values={{ platform: getSocialMediaDisplayName(type) }}
                    />
                  </span>
                </Checkbox>
              </FormGroup>
            </fieldset>
          </li>
        ))}
      </ul>
      <SubmitButton disabled={formData.submitting}>
        <FormattedMessage
          id="sponsoredPosts.campaigns.socialMediaSharing.submit"
          defaultMessage="Save"
        />
      </SubmitButton>
    </Form>
  );
}

SocialMediaSharingForm.propTypes = {
  disabled: PropTypes.bool,
  formName: PropTypes.string.isRequired,
  formData: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        url: PropTypes.string,
        notApplicable: PropTypes.bool,
      }),
    ).isRequired,
    errors: PropTypes.object,
    submitting: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

SocialMediaSharingForm.defaultProps = {
  disabled: false,
};

const SocialMediaSharingFormEnhanced = withHandlers(
  ({ onChange, formName }) => ({
    onCheckboxChange: () => (e) =>
      onChange(formName, e.target.id, e.target.checked),
  }),
)(SocialMediaSharingForm);

function Inactive({ intl }) {
  return (
    <ActivityStreamItem iconName="share">
      <ActivityStreamHeader
        title={intl.formatMessage({
          id: 'sponsoredPosts.campaigns.socialMediaSharing.heading',
          defaultMessage: 'Social Media Sharing',
        })}
      />
    </ActivityStreamItem>
  );
}

Inactive.propTypes = {};

function Active({
  intl,
  formName,
  formData,
  onChange,
  onSubmit,
  workflow: { lastUpdate },
}) {
  return (
    <ActivityStreamItemWithSubHeading
      intl={intl}
      status="active"
      iconName="share"
      headingDate={lastUpdate}
      headingId="sponsoredPosts.campaigns.socialMediaSharing.heading"
      subHeadingId="sponsoredPosts.campaigns.socialMediaSharing.active.subHeading.title"
      subHeadingContentId="sponsoredPosts.campaigns.socialMediaSharing.active.subHeading.content"
    >
      <SocialMediaSharingFormEnhanced
        formName={formName}
        formData={formData}
        onChange={onChange}
        onSubmit={onSubmit}
      />
    </ActivityStreamItemWithSubHeading>
  );
}

Active.propTypes = {
  workflow: PropTypes.shape({
    lastUpdate: PropTypes.string.isRequired,
  }),

  formName: PropTypes.string.isRequired,
  formData: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        url: PropTypes.string,
        notApplicable: PropTypes.bool,
      }),
    ).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

function Pending({ intl, workflow: { lastUpdate } }) {
  return (
    <ActivityStreamItemWithSubHeading
      intl={intl}
      status="active"
      iconName="share"
      headingId="sponsoredPosts.campaigns.socialMediaSharing.heading"
      subHeadingId="sponsoredPosts.campaigns.socialMediaSharing.pending.subHeading.title"
      subHeadingContentId="sponsoredPosts.campaigns.socialMediaSharing.pending.subHeading.content"
      headingDate={lastUpdate}
    />
  );
}

Pending.propTypes = {
  workflow: PropTypes.shape({
    lastUpdate: PropTypes.string.isRequired,
  }),
};

function Rejected({
  intl,
  workflow: {
    lastUpdate,
    payload: { feedback },
  },

  formName,
  formData,
  onChange,
  onSubmit,
}) {
  return (
    <ActivityStreamItemWithSubHeading
      intl={intl}
      status="active"
      iconName="share"
      headingId="sponsoredPosts.campaigns.socialMediaSharing.heading"
      subHeadingId="sponsoredPosts.campaigns.socialMediaSharing.rejected.subHeading.title"
      subHeadingContentId="sponsoredPosts.campaigns.socialMediaSharing.rejected.subHeading.content"
      subHeadingContent={feedback}
      headingDate={lastUpdate}
    >
      <SocialMediaSharingFormEnhanced
        formName={formName}
        formData={formData}
        onChange={onChange}
        onSubmit={onSubmit}
      />
    </ActivityStreamItemWithSubHeading>
  );
}

Rejected.propTypes = {
  workflow: PropTypes.shape({
    lastUpdate: PropTypes.string.isRequired,
    payload: PropTypes.shape({
      feedback: PropTypes.string,
      socialMediaSharing: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  }).isRequired,
  formName: PropTypes.string.isRequired,
  formData: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        url: PropTypes.string,
        notApplicable: PropTypes.bool,
      }),
    ).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

function Done({
  intl,
  workflow: {
    lastUpdate,
    payload: { feedback },
  },
}) {
  return (
    <ActivityStreamItemWithSubHeading
      intl={intl}
      status="active"
      iconName="share"
      headingId="sponsoredPosts.campaigns.socialMediaSharing.heading"
      subHeadingId="sponsoredPosts.campaigns.socialMediaSharing.done.subHeading.title"
      subHeadingContentId="sponsoredPosts.campaigns.socialMediaSharing.done.subHeading.content"
      subHeadingContent={feedback}
      headingDate={lastUpdate}
    />
  );
}

Done.propTypes = {
  workflow: PropTypes.shape({
    lastUpdate: PropTypes.string.isRequired,
    payload: PropTypes.shape({
      feedback: PropTypes.string,
      socialMediaSharing: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  }).isRequired,
};

function SocialMediaSharing(props) {
  const Component =
    {
      inactive: Inactive,
      active: Active,
      pending: Pending,
      rejected: Rejected,
      done: Done,
    }[props.workflow.status] || noop;

  return <Component {...props} />;
}

SocialMediaSharing.propTypes = {
  workflow: PropTypes.shape({
    status: PropTypes.oneOf([
      'inactive',
      'active',
      'pending',
      'rejected',
      'done',
    ]).isRequired,
  }).isRequired,
};

const withFormData = compose(
  setPropTypes({
    form: PropTypes.object.isRequired,
    formName: PropTypes.string.isRequired,
  }),

  withProps(({ form, formName }) => ({ formData: form[formName] })),
);

export default compose(
  injectIntl,
  defaultProps({ formName: 'socialMediaSharing' }),
  withFormData,
)(SocialMediaSharing);
