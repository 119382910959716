import groupBy from 'lodash/groupBy';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { createAsyncSelector } from '@blogfoster/redux-async-utils';

import { campaignMatchesByTypeSelectorCreator } from '../exploreCampaigns/selectors';

const getUserConfirmed = (state) => state.application.user.confirmed;
const getBrandClubs = (state) => state.scenes.brandClubs.brandClubs;
const getJoinForm = (state) => state.scenes.brandClubs.joinForm;
const getSelectedBrandClub = (state) =>
  state.scenes.brandClubs.brandClubsModal.selectedBrandClub;
const getCampaignMatches = (state) => state.scenes.brandClubs.campaignMatches;
const getApplications = (state) => state.scenes.brandClubs.applications;
const getChannels = (state) => state.scenes.brandClubs.channels;
const getCampaignTypeFilter = (state) =>
  state.scenes.brandClubs.campaignTypeFilter;

const eligiblePlatforms = ['instagram', 'tiktok', 'ga', 'youtube'];
const getHasEligibleChannels = (state) => {
  const channelsState = getChannels(state);

  return (
    !isEmpty(channelsState.data) &&
    channelsState.data.some((channel) =>
      eligiblePlatforms.includes(channel.platform),
    )
  );
};

const createApplicationMatchesByTypeSelector = (applicationsSelector) =>
  createAsyncSelector(
    { applications: applicationsSelector },
    ({ applications }) => {
      const applicationMatches = applications.map(
        ({ id, campaign, channel, match }) => ({
          campaign,
          applicationId: id,
          matches: [{ channel, payment: match.payment }],
        }),
      );

      return {
        ...groupBy(applicationMatches, 'campaign.type'),
        all: applicationMatches,
      };
    },
  );

export const getBrandClubsState = createStructuredSelector({
  selectedBrandClub: getSelectedBrandClub,
  userConfirmed: getUserConfirmed,
  brandClubs: getBrandClubs,
  joinForm: getJoinForm,
  hasEligibleChannels: getHasEligibleChannels,
});

export const getBrandClubCampaignsMatchState = createStructuredSelector({
  campaignMatchesByType:
    campaignMatchesByTypeSelectorCreator(getCampaignMatches),
  applicationMatchesByType:
    createApplicationMatchesByTypeSelector(getApplications),
  campaignTypeFilter: getCampaignTypeFilter,
  userConfirmed: getUserConfirmed,
  brandClub: getSelectedBrandClub,
});
