import React from 'react';
import PropTypes from 'prop-types';
import { locationShape, routerShape } from 'react-router';
import config from 'config';

import Feature from 'source/components/common/feature';
import BrandClubsCard from './BrandClubsCard';
import BrandClubsNoContent from './BrandClubsNoContent';
import BrandClubsModal from './modal/BrandClubsModal';

class BrandClubs extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      location: { pathname },
    } = this.props;
    const {
      location: { pathname: prevPathname },
    } = prevProps;
    const [, , prevClubId] = prevPathname.split('/');
    const [, , clubId] = pathname.split('/');

    if (prevClubId === clubId) {
      return false;
    }
    if (!clubId) {
      return this.props.onCloseBrandModal();
    }
    if (clubId && clubId !== prevClubId) {
      return this.props.onOpenBrandModal(clubId);
    }
    return false;
  }

  closeBrandModal = () => {
    const { router } = this.props;
    router.push('/brand-clubs');
  };

  render() {
    const {
      selectedBrandClub,
      brandClubs,
      joinForm,
      onJoinFormChange,
      onJoinFormSubmit,
      hasEligibleChannels,
    } = this.props;

    return (
      <div className="brand-clubs">
        <div className="container">
          {brandClubs.map((brandClub) => {
            if (
              config.privateNetworks?.demoPrivateNetworks?.includes(
                brandClub.id,
              )
            ) {
              return (
                <Feature feature="demoPrivateNetworks">
                  <BrandClubsCard key={brandClub.id} brandClub={brandClub} />
                </Feature>
              );
            }

            if (
              config.privateNetworks?.hiddenPrivateNetworks?.includes(
                brandClub.id,
              )
            ) {
              return (
                <Feature feature="hiddenPrivateNetworks">
                  <BrandClubsCard key={brandClub.id} brandClub={brandClub} />
                </Feature>
              );
            }

            return <BrandClubsCard key={brandClub.id} brandClub={brandClub} />;
          })}
          {brandClubs.length === 0 && <BrandClubsNoContent />}
        </div>
        <BrandClubsModal
          brandClub={selectedBrandClub}
          joinForm={joinForm}
          onClose={this.closeBrandModal}
          onJoinFormChange={onJoinFormChange}
          onJoinFormSubmit={onJoinFormSubmit}
          hasEligibleChannels={hasEligibleChannels}
        />
      </div>
    );
  }
}

BrandClubs.propTypes = {
  router: routerShape.isRequired,
  location: locationShape.isRequired,
  selectedBrandClub: PropTypes.object,
  brandClubs: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired }),
  ),
  hasEligibleChannels: PropTypes.bool,
  joinForm: PropTypes.object.isRequired,
  onOpenBrandModal: PropTypes.func.isRequired,
  onCloseBrandModal: PropTypes.func.isRequired,
  onJoinFormChange: PropTypes.func.isRequired,
  onJoinFormSubmit: PropTypes.func.isRequired,
};

BrandClubs.defaultProps = {
  brandClubs: [],
  selectedBrandClub: null,
};

export default BrandClubs;
