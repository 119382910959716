import PropTypes from 'prop-types';
import React, { Component, Children } from 'react';
import ReactDOM from 'react-dom';
import Tether from 'tether';
import omit from 'lodash/omit';

import { hasParent } from 'source/utils/dom';

const ATTACHMENTS = {
  top: 'bottom center',
  right: 'middle left',
  bottom: 'top center',
  left: 'middle right',
};

const TARGET_ATTACHMENTS = {
  top: 'top center',
  right: 'middle right',
  bottom: 'bottom center',
  left: 'middle left',
};

const TRIGGER_HOVER = 'hover';
const TRIGGER_CLICK = 'click';

class TetherComponent extends Component {
  constructor(props) {
    super(props);

    this.tether = null;
    this.elementNode = null;

    this.state = {
      enabled: this.props.enabled,
    };

    this.triggerEvents = {
      [TRIGGER_HOVER]: {
        onMouseEnter: this.enable,
        onMouseLeave: this.disable,
      },
      [TRIGGER_CLICK]: {
        onClick: this.toggle,
      },
    };
  }

  componentDidMount() {
    this.renderTether();

    window.addEventListener('click', this.disableOnClick);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { props, state } = this;

    if (
      nextProps.enabled !== props.enabled &&
      nextProps.enabled !== state.enabled
    ) {
      this.setState({ enabled: nextProps.enabled });
    }
  }

  componentDidUpdate() {
    this.removeTetherElement();

    this.renderTether();
  }

  componentWillUnmount() {
    if (this.tether != null) {
      this.tether.destroy();
    }

    this.removeTetherElement();

    window.removeEventListener('click', this.disableOnClick);
  }

  disableOnClick = (event) => {
    // Do not care if this tether component is not enabled.
    if (!this.state.enabled || this.props.trigger !== TRIGGER_CLICK) {
      return;
    }

    // TODO: `findDOMNode` is going to get deprecated in the future. Try to
    // make this work with callback refs:
    // https://github.com/yannickcr/eslint-plugin-react/issues/678#issue-165177220
    // eslint-disable-next-line react/no-find-dom-node
    const triggerNode = ReactDOM.findDOMNode(this);
    const { elementNode } = this;

    // Do not disable tether element when the user clicked on either the trigger or tether element node.
    if (
      !hasParent(event.target, triggerNode) &&
      !hasParent(event.target, elementNode)
    ) {
      this.setState({ enabled: false });
    }
  };

  enable = () => this.setState({ enabled: true });

  disable = () => this.setState({ enabled: false });

  toggle = () => {
    const { enabled } = this.state;

    return enabled ? this.disable() : this.enable();
  };

  removeTetherElement() {
    const { elementNode } = this;

    if (elementNode != null && elementNode.parentNode != null) {
      elementNode.parentNode.removeChild(elementNode);
    }
  }

  renderTether() {
    const { enabled } = this.state;
    let { elementNode } = this;

    if (enabled) {
      const { placement, element, ...options } = this.props;
      const tetherDefaults = omit(options, ['enabled']);

      // Get trigger node.
      // TODO: `findDOMNode` is going to get deprecated in the future. Try to
      // make this work with callback refs:
      // https://github.com/yannickcr/eslint-plugin-react/issues/678#issue-165177220
      // eslint-disable-next-line react/no-find-dom-node
      const triggerNode = ReactDOM.findDOMNode(this);
      // Create temporary portal node.
      const renderNode = document.createElement('div');

      // Insert render node before trigger node (fixes not appearing tooltips in Firefox and IE).
      triggerNode.parentNode.insertBefore(renderNode, triggerNode);

      // Render react element inside temporary portal.
      ReactDOM.render(element, renderNode, () => {
        // Get element node from react render.
        elementNode = renderNode.childNodes[0];
        this.elementNode = elementNode;

        const tetherOptions = {
          ...tetherDefaults,
          element: elementNode,
          target: triggerNode,
          attachment: ATTACHMENTS[placement],
          targetAttachment: TARGET_ATTACHMENTS[placement],
          classPrefix: 'bs-tether',
          constraints: [
            {
              to: 'scrollParent',
              attachment: 'together',
            },
          ],
        };

        if (this.tether == null) {
          this.tether = new Tether(tetherOptions);
        } else {
          this.tether.setOptions(tetherOptions);
        }

        // Remove temporary portal from DOM.
        triggerNode.parentNode.removeChild(renderNode);
      });
    } else {
      if (this.tether != null) {
        this.tether.disable();
      }

      this.removeTetherElement();
    }
  }

  render() {
    const { children, trigger } = this.props;
    const triggerElement = Children.only(children);

    const props = this.triggerEvents[trigger] || {};

    return React.cloneElement(triggerElement, props);
  }
}

TetherComponent.propTypes = {
  element: PropTypes.element.isRequired,
  children: PropTypes.element.isRequired,
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']).isRequired,
  trigger: PropTypes.oneOf([TRIGGER_HOVER, TRIGGER_CLICK]),
  enabled: PropTypes.bool,
};

TetherComponent.defaultProps = {
  enabled: false,
};

export default TetherComponent;
