import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import cx from 'classnames';
import { withStateHandlers, compose, setDisplayName } from 'recompose';
import { injectIntl, FormattedMessage } from 'react-intl';
import { selectUnit } from '@formatjs/intl-utils';

import { MessageCard } from 'source/components/common/message';

import Icon from './icon';
import Collapsible from './collapsible';

function ActivityStream({ className, ...props }) {
  className = cx('activity-stream', className);

  return <div className={className} {...props} />;
}

ActivityStream.propTypes = {
  className: PropTypes.string,
};

function ActivityStreamIcon({ className, iconName, status }) {
  let streamSymbol = 'activity-stream';

  if (status) {
    streamSymbol += `-${status}`;
  }

  return (
    <div className={cx('activity-stream-icon', className)}>
      <svg className="activity-stream-status">
        <use xlinkHref={`#${streamSymbol}`} />
      </svg>
      <Icon name={iconName} />
    </div>
  );
}

ActivityStreamIcon.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['done', 'failed']),
};

const withExpandState = withStateHandlers(
  ({ initialExpanded = true }) => ({
    expanded: initialExpanded,
  }),
  {
    onToggleExpand:
      ({ expanded }, { expandable }) =>
      () => ({
        expanded: expandable ? !expanded : expanded,
      }),
  },
);

export const ActivityStreamItem = compose(
  withExpandState,
  setDisplayName('ActivityStreamItem'),
)(
  ({
    className,
    iconName,
    status,
    expanded,
    expandable,
    onToggleExpand,
    ...props
  }) => {
    className = cx('activity-stream-item', status, className);

    // Do not pass status active to the icon component.
    const iconStatus = status !== 'active' ? status : null;
    const divProps = omit(props, [
      'isLeaving',
      'active',
      'done',
      'failed',
      'initialExpanded',
    ]);
    // Slightly strange API, but the first child is always the header. The rest of
    // the children may optionally be controlled by an expanding panel
    const [header, ...children] = React.Children.toArray(props.children);

    return (
      <div className={className} {...divProps}>
        <ActivityStreamIcon iconName={iconName} status={iconStatus} />
        {expandable ? (
          <div
            className={cx('activity-expand', 'panel-icon', {
              active: expanded,
            })}
            onClick={onToggleExpand}
          >
            <Icon name="arrow" />
          </div>
        ) : null}
        {header}
        <Collapsible collapsed={!expanded}>{children}</Collapsible>
      </div>
    );
  },
);

ActivityStreamItem.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string.isRequired,
  children: PropTypes.any,
  status: PropTypes.oneOf(['active', 'done', 'failed']),
};

ActivityStreamItem.defaultProps = {
  active: false,
  done: false,
  failed: false,
  expandable: false,
};

export function ActivityStreamItemWithSubHeading({
  intl,
  status,
  iconName,
  headingId,
  headingDate,
  subHeadingId,
  subHeadingContentId,
  subHeadingContent,
  children,
}) {
  return (
    <ActivityStreamItem status={status} iconName={iconName}>
      <ActivityStreamHeader
        title={intl.formatMessage({
          id: headingId,
        })}
        date={headingDate}
      />

      <ActivityStreamBody>
        <ActivityStreamSubHeader
          subHeadingId={subHeadingId}
          subHeadingContentId={subHeadingContentId}
          subHeadingContent={subHeadingContent}
        />
        {children}
      </ActivityStreamBody>
    </ActivityStreamItem>
  );
}

ActivityStreamItemWithSubHeading.propTypes = {
  status: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  headingId: PropTypes.string.isRequired,
  headingDate: PropTypes.string.isRequired,
  subHeadingId: PropTypes.string.isRequired,
  subHeadingContentId: PropTypes.string,
  subHeadingContent: PropTypes.string,
  children: PropTypes.node,
};

ActivityStreamItemWithSubHeading.defaultProps = {
  subHeadingContentId: null,
  subHeadingContent: null,
  children: null,
};

export function ActivityStreamHistory({
  className,
  children,
  collapsed,
  ...props
}) {
  className = cx(
    'activity-stream',
    'activity-stream-history',
    { collapsed },
    className,
  );

  const [firstChild, ...restChildren] = children;

  return (
    <div className={className} {...props}>
      {firstChild}
      <Collapsible collapsed={collapsed}>{restChildren}</Collapsible>
    </div>
  );
}

ActivityStreamHistory.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  collapsed: PropTypes.bool.isRequired,
};

ActivityStreamHistory.defaultProps = {
  collapsed: false,
};

export function ActivityStreamHeader({
  className,
  title,
  date,
  children,
  ...props
}) {
  if (title != null) {
    title = (
      <ActivityStreamTitle date={date}>
        <strong>{title}</strong>
      </ActivityStreamTitle>
    );
  }

  return (
    <div className={cx('activity-stream-heading', className)} {...props}>
      {title}
      {children}
    </div>
  );
}

ActivityStreamHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  children: PropTypes.any,
};

function ActivityStreamSubHeader({
  subHeadingId,
  subHeadingContentId,
  subHeadingContent,
}) {
  return (
    <MessageCard type="info">
      <strong>
        <FormattedMessage id={subHeadingId} />
      </strong>
      <span>
        {subHeadingContent || <FormattedMessage id={subHeadingContentId} />}
      </span>
    </MessageCard>
  );
}

ActivityStreamSubHeader.propTypes = {
  subHeadingId: PropTypes.string.isRequired,
  subHeadingContentId: PropTypes.string,
  subHeadingContent: PropTypes.string,
};

ActivityStreamSubHeader.defaultProps = {
  subHeadingContentId: null,
  subHeadingContent: null,
};

function ActivityStreamDate({ className, ...props }) {
  return <small className={cx('activity-stream-date', className)} {...props} />;
}

ActivityStreamDate.propTypes = {
  className: PropTypes.string,
};

export function ActivityStreamBody({ className, ...props }) {
  return (
    <Collapsible className={cx('activity-stream-body', className)} {...props} />
  );
}

ActivityStreamBody.propTypes = {
  className: PropTypes.string,
};

export const ActivityStreamTitle = injectIntl(
  ({ intl, className, date, children, ...props }) => {
    if (date != null) {
      const { value, unit } = selectUnit(new Date(date));

      date = (
        <ActivityStreamDate>
          {intl.formatRelativeTime(value, unit, { numeric: 'auto' })}
        </ActivityStreamDate>
      );
    }

    return (
      <div className={cx('activity-stream-title', className)} {...props}>
        {date}
        <h4>{children}</h4>
      </div>
    );
  },
);

ActivityStreamTitle.propTypes = {
  className: PropTypes.string,
  date: PropTypes.string,
  children: PropTypes.any,
};

export const ActivityStreamSubtitle = injectIntl(
  ({ intl, className, children, ...props }) => (
    <div className={cx('activity-stream-sub-title', className)} {...props}>
      {children}
    </div>
  ),
);

ActivityStreamSubtitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export function ActivityStreamSelectionOptions({ options }) {
  return <div className="activity-stream-selection__options">{options}</div>;
}

ActivityStreamSelectionOptions.propTypes = {
  options: PropTypes.array.isRequired,
};

export function ActivityStreamRadioSelection({ className, children }) {
  return (
    <div className={cx('activity-stream-radio-selection', className)}>
      {children}
    </div>
  );
}

ActivityStreamRadioSelection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export function ActivityStreamCheckboxSelection({ className, children }) {
  return (
    <div className={cx('activity-stream-checkbox-selection', className)}>
      {children}
    </div>
  );
}

ActivityStreamCheckboxSelection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default ActivityStream;
