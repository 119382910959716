import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';

import formController, { renderError } from 'source/utils/forms';

import { withGatewayRoot } from './root';

function ResetPassword({ id, intl, form, onChange, onSubmit }) {
  return (
    <div className="gateway__card">
      <h5>
        <FormattedMessage
          id="signup.caption.resetPasswordTitle"
          defaultMessage="Password reset"
        />
      </h5>
      <form id={id} onSubmit={onSubmit} onChange={onChange}>
        <fieldset className="form-group">
          <label htmlFor="password">
            <FormattedMessage
              id="resetPassword.password"
              defaultMessage="New Password"
            />
            :
          </label>
          <input
            className="form-control"
            id="password"
            type="password"
            placeholder={intl.formatMessage({
              id: 'resetPassword.password',
              defaultMessage: 'New Password',
            })}
            value={form.password}
            onChange={noop}
          />

          <div className="error-block">{renderError(form.errors.password)}</div>
        </fieldset>
        <button className="btn btn-primary" type="submit">
          <FormattedMessage
            id="signin.resetPassword"
            defaultMessage="Reset my password"
          />
        </button>
      </form>
    </div>
  );
}

ResetPassword.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.shape({
    password: PropTypes.string.isRequired,
    errors: PropTypes.object.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const withFormController = formController({
  validateForm(form) {
    const errors = {};

    if (isEmpty(form.password)) {
      errors.push({
        id: 'password',
        messageId: 'signup.errors.missingPassword',
      });
    }

    return errors;
  },
});

export default injectIntl(withGatewayRoot(withFormController(ResetPassword)));
