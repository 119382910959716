import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

const defaultAppContext = { scrollIntoView: noop };
const { Provider, Consumer } = React.createContext(defaultAppContext);

export const AppProvider = Provider;
export const AppConsumer = Consumer;
export const appContextShape = PropTypes.shape({
  scrollIntoView: PropTypes.func.isRequired,
});
