import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { defaultMemoize as memoize } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { compose, pure, withHandlers } from 'recompose';
import cx from 'classnames';

import { FormError, NextPrevButtonList } from 'source/components/common';
import PointDistributionForm, {
  selectors as pointsFormSelectors,
} from 'source/components/common/pointDistributionForm';
import * as CustomPropTypes from 'source/utils/propTypes';

const MAX_POINTS = 13;

const { getTotalPoints } = pointsFormSelectors;

/*
 * The selector for transforming the selectedSubCategories to the correct
 * shape for our points form.
 */
const getValues = memoize(
  (selectedSubCategories = [], weightDistribution = {}) =>
    selectedSubCategories.map(({ code }) => ({
      name: code,
      label: <FormattedMessage id={`preferences.categories.${code}`} />,
      value: weightDistribution[code] || 0,
    })),
);

const validate = ({ selectedSubCategories, weightDistribution = {} }) => {
  const errors = [];
  const values = getValues(selectedSubCategories, weightDistribution);

  if (getTotalPoints(values) < MAX_POINTS) {
    errors.push({
      id: 'weightDistribution',
      type: 'root',
      messageId: 'channels.categoryWeightStep.notWeighted',
    });
  }

  return errors;
};

const withChangeHandler = withHandlers({
  onChange:
    ({ onChange }) =>
    (values) =>
      onChange(
        'weightDistribution',
        values.reduce((acc, { name, value }) => {
          acc[name] = value;
          return acc;
        }, {}),
      ),
});

const withNextStepHandler = withHandlers({
  onNextStep:
    ({ id, platform, form, onErrors, onSubmit, onNextStep }) =>
    () => {
      const errors = validate(form);

      if (errors.length) {
        return onErrors(errors);
      }

      const submit = () => onSubmit({ id, platform, form });

      return Promise.resolve().then(submit).then(onNextStep);
    },
});

const enhance = compose(pure, withChangeHandler, withNextStepHandler);

function CategoryWeightWizardStep(props) {
  const {
    form: {
      errors,
      selectedSubCategories = [],
      weightDistribution = {},
      submitting = false,
    },

    onChange,
    onPrevStep,
    onNextStep,
    isLastStep,
  } = props;

  const values = getValues(selectedSubCategories, weightDistribution);
  const error = find(errors, { id: 'weightDistribution' });
  const totalPoints = getTotalPoints(values);

  return (
    <div className="website-form">
      <div className="text-center">
        <h4>
          <FormattedMessage
            id="channels.categoryWeightStep.title"
            defaultMessage="Category Weight"
          />
        </h4>
        {error ? <FormError error={error} /> : null}
      </div>

      <p className="text-center">
        <FormattedMessage
          id="channels.categoryWeightStep.description"
          defaultMessage="Please weight the relative importance of each of the categories for your blog by assigning points to each."
        />
      </p>

      <div className="clearfix m-b-2">
        <div className="pull-md-right">
          <FormattedMessage
            id={
              totalPoints === MAX_POINTS
                ? 'channels.categoryWeightStep.weightAllocated'
                : 'channels.categoryWeightStep.weightAllocation'
            }
            values={{
              current: String(MAX_POINTS - totalPoints),
              max: MAX_POINTS,
            }}
          />
        </div>
      </div>

      <PointDistributionForm
        className="m-b-2"
        maxPoints={MAX_POINTS}
        values={values}
        onChange={onChange}
      />

      <div className="modal-footer">
        <NextPrevButtonList
          onPrevStep={onPrevStep}
          onNextStep={onNextStep}
          disableNext={submitting}
          disablePrev={submitting}
          classNameNext={cx({ 'btn-saving': submitting })}
          isLastStep={isLastStep}
        />
      </div>
    </div>
  );
}

CategoryWeightWizardStep.propTypes = {
  form: CustomPropTypes.form.isRequired,
  onChange: PropTypes.func.isRequired,
  onPrevStep: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  isLastStep: PropTypes.bool.isRequired,
};

export default enhance(CategoryWeightWizardStep);
