import { asyncActionCreator } from '@blogfoster/redux-async-utils';

import { actionAuthRequest } from 'source/utils/axios';

export const namespace = 'myCampaigns';

export const actionTypes = {
  APPLICATIONS_FETCH: `${namespace}/APPLICATIONS_FETCH`,
  APPLICATION_STATUS_FILTER_CHANGE: `${namespace}/APPLICATION_STATUS_FILTER_CHANGE`,
  SHOW_MORE: `${namespace}/SHOW_MORE`,
};

export const loadApplications = asyncActionCreator(
  actionTypes.APPLICATIONS_FETCH,
  () => (dispatch, getState) =>
    actionAuthRequest(dispatch, getState).get('/v2/applications'),
);

export const changeApplicationStatusFilter = (filter) => ({
  type: actionTypes.APPLICATION_STATUS_FILTER_CHANGE,
  payload: filter,
});

export const showMore = () => ({
  type: actionTypes.SHOW_MORE,
});
