import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import {
  ActivityStreamItem,
  ActivityStreamHeader,
  ActivityStreamBody,
} from 'source/components/common/activityStream';

import ContentPreview from './contentPreview';

function VerificationPending({
  intl,
  form,
  formName,
  children,
  workflow: { lastUpdate },
  application: { contentPreview, type },
}) {
  return (
    <ActivityStreamItem status="active" iconName="check-document" expandable>
      <ActivityStreamHeader
        title={intl.formatMessage({
          id: 'sponsoredPosts.campaigns.verification',
          defaultMessage: 'Verification',
        })}
        date={lastUpdate}
      />
      <ActivityStreamBody>
        {children}
        {['instagram', 'tiktok'].includes(type) && (
          <ContentPreview
            contentPreview={form[formName] || contentPreview}
            platform={type}
          />
        )}
      </ActivityStreamBody>
    </ActivityStreamItem>
  );
}

VerificationPending.propTypes = {
  form: PropTypes.object.isRequired,
  formName: PropTypes.string.isRequired,
  workflow: PropTypes.shape({
    lastUpdate: PropTypes.string,
  }).isRequired,
  application: PropTypes.shape({
    type: PropTypes.string.isRequired,
    contentPreview: PropTypes.object.isRequired,
  }).isRequired,
  children: PropTypes.node,
};

export default injectIntl(VerificationPending);
