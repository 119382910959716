const { developers } = require('./shared');

module.exports = {
  app: {
    url: process.env.SELF_APP_URL || 'https://app.staging.eqolot.com',
  },

  api: {
    url: process.env.IRON_URL || 'https://iron.staging.eqolot.com',
    timeout: 25000,
  },

  sentry: {
    dsn: 'https://ddebee8bb67a439db2025967bc63e653@o30840.ingest.sentry.io/75016',
  },

  facebook: {
    disabled: false,
  },

  features: {
    counterOffer: {
      // allowedUsers: [...developers],
      allowedGroups: ['5fa06a3f10b11f2a05d0e867'],
    },

    insightsMaintenance: {
      enabled: false,
    },

    instagramApi: {
      allowedUsers: [],
      allowedGroups: ['5ffecda4591c967567494b95'],
    },

    demoPrivateNetworks: {
      allowedUsers: [...developers],
    },
    hiddenPrivateNetworks: {
      allowedUsers: [...developers],
    },
  },

  frontappKey: '25bdd27e4976545e88ec829c9c34aedf',

  optimizely: {
    writeKey: 6495521950,
  },

  GA: {
    id: 'GTM-WJQN6JF',
  },

  experiments: {
    imageUrl: 6501651152,
  },

  insights: {
    instagram: {
      insightsTtl: 30,
      insightsIntermediateTtl: 23,
    },
    tiktok: {
      insightsTtl: 30,
      insightsIntermediateTtl: 23,
    },
  },

  privateNetworks: {
    demoPrivateNetworks: ['623b3b9b52dc6e73d9d84b14'],
    hiddenPrivateNetworks: [],
  },
};
