import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage, defineMessages } from 'react-intl';
import get from 'lodash/get';
import config from 'config';

import Modal from 'source/components/common/modal';
import Icon from 'source/components/common/icon';
import QRCode from 'source/components/common/qrCode';
import * as propTypes from 'source/utils/propTypes';

import { screenshotNames, screenshotDefaults } from '../../../utils/channel';
import ScreenshotPanel from './ScreenshotPanel';

const headerTitles = defineMessages({
  instagram: {
    id: 'channels.instagram.insights.uploadModal.title',
    defaultMessage: 'Upload Instagram Insights for {channelName}',
  },

  tiktok: {
    id: 'channels.tiktok.insights.uploadModal.title',
    defaultMessage: 'Upload TikTok Insights for {channelName}',
  },
});

const infoTexts = defineMessages({
  instagram: {
    id: 'channels.instagram.insights.uploadModal.infoText',
    defaultMessage:
      'ℹ️ Tell us more about your audience to get the best matching sponsored post campaigns. This brings transparency towards our clients and ensures a fair compensation for your impact.',
  },

  tiktok: {
    id: 'channels.tiktok.insights.uploadModal.infoText',
    defaultMessage:
      'ℹ️ Tell us more about your audience to get the best matching sponsored post campaigns. This brings transparency towards our clients and ensures a fair compensation for your impact.',
  },
});

const videoInstructionsTexts = defineMessages({
  instagram: {
    id: 'channels.instagram.insights.uploadModal.instructionsVideoText',
    defaultMessage: 'Video: How to capture Instagram Insights',
  },

  tiktok: {
    id: 'channels.tiktok.insights.uploadModal.instructionsVideoText',
    defaultMessage: 'Video: How to capture TikTok Insights',
  },
});

function Header({ platform, channelName, onClose }) {
  return (
    <header>
      <Icon name={platform} />
      <FormattedMessage {...headerTitles[platform]} values={{ channelName }} />
      <div className="close-btn clickable" onClick={onClose}>
        <Icon name="cross" />
      </div>
    </header>
  );
}

Header.propTypes = {
  platform: PropTypes.string.isRequired,
  channelName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

class UploadInsightsModal extends React.Component {
  handleSubmitInsights = (data) => {
    const { channelId, onSubmitInsights } = this.props;
    return onSubmitInsights({ channelId, ...data });
  };

  render() {
    const {
      isOpen,
      className,
      platform,
      channelId,
      channelName,
      screenshots,
      instructionsVideoUrl,
      onClose,
    } = this.props;

    const modalProps = {
      isOpen,
      overlayClassName: 'bf-plain',
      className: cx(
        'insights-upload-modal',
        `insights-upload-modal-${platform}`,
        className,
      ),

      onRequestClose: onClose,
    };

    const channelUrl = `${config.app.url}/profile?id=${channelId}&uploadInsights=true`;

    return (
      <Modal {...modalProps}>
        <Header
          platform={platform}
          channelName={channelName}
          onClose={onClose}
        />

        <main>
          {infoTexts[platform] && (
            <section className="info-section">
              <div className="info-text-container">
                <FormattedMessage {...infoTexts[platform]} />
              </div>
              <div className="qr-code-container">
                <QRCode className="qr-code" data={channelUrl} />
                <FormattedMessage
                  id="channels.insights.uploadModal.qrCodeHint"
                  defaultMessage="Scan this to upload from your phone"
                >
                  {(text) => (
                    <div className="qr-code-label">
                      <span>{text}</span>
                    </div>
                  )}
                </FormattedMessage>
              </div>
            </section>
          )}

          {instructionsVideoUrl && (
            <section className="instructions-section">
              <a href={instructionsVideoUrl} target="_blank" rel="noreferrer">
                <FormattedMessage {...videoInstructionsTexts[platform]} />
              </a>
            </section>
          )}

          <section className="panels-grid">
            {(screenshotNames[platform] || []).map((name) => (
              <ScreenshotPanel
                key={name}
                name={name}
                platform={platform}
                screenshot={get(screenshots, name)}
                defaultScreenshotUrl={get(
                  screenshotDefaults,
                  `${platform}.${name}`,
                )}
                onSubmitInsights={this.handleSubmitInsights}
              />
            ))}
          </section>
        </main>
      </Modal>
    );
  }
}

UploadInsightsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  platform: propTypes.platform,
  channelId: PropTypes.string.isRequired,
  channelName: PropTypes.string.isRequired,
  instructionsVideoUrl: PropTypes.string.isRequired,
  screenshots: propTypes.screenshots,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmitInsights: PropTypes.func.isRequired,
};

UploadInsightsModal.defaultProps = {
  children: undefined,
  className: undefined,
};

export default UploadInsightsModal;
