import { connect } from 'react-redux';

import ResetPasswordRequest from 'source/components/gateway/resetPasswordRequest';

import { changeLanguage } from 'source/actions/account';

const mapStateToProps = (state) => ({
  language: state.application.locale,
  languageOptions: state.account.languageOptions,
});

const mapDispatchToProps = {
  onChangeLanguage: changeLanguage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPasswordRequest);
