import { combineReducers } from 'redux';
import { loadStateReducer } from '@blogfoster/redux-async-utils';

import constants from 'source/constants';

export const getInitialState = () => ({
  channels: {
    data: [],
    loading: false,
    loaded: false,
  },
});

const channelsReducer = loadStateReducer(constants.CHANNELS_FETCH, []);

export default combineReducers({
  channels: channelsReducer,
});
