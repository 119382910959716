import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import first from 'lodash/first';
import pick from 'lodash/pick';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  compose,
  withHandlers,
  withStateHandlers,
  defaultProps,
} from 'recompose';

import Form, {
  FormGroup,
  FormActions,
  Textarea,
  FormControlFeedback,
} from 'source/components/common/form';
import { ActivityStreamBody } from 'source/components/common/activityStream';
import { SubmitButton } from 'source/components/common/button';
import MediaUpload from 'source/components/common/form/mediaUpload';
import { MessageCard } from 'source/components/common/message';
import { Alert } from 'source/components/common';
import { hasAttributes } from 'source/utils';
import FileUploadError from 'source/components/common/form/fileUploadError';

import {
  VerificationInactive,
  VerificationActive,
  VerificationPending,
  VerificationRejected,
  VerificationDone,
  VerificationExpired,
} from './verificationSteps';

// Helpers

/**
 * Wrap the given file upload handler to be compatible with our
 * image upload component API
 */
const withUploadHandler = withHandlers({
  onFileUpload:
    ({ onFileUpload }) =>
    ({ file }, onUploadProgress) =>
      onFileUpload(file, onUploadProgress).then((response) => {
        if (response.code === 'ECONNABORTED') {
          return Promise.reject(new Error('Timeout'));
        }
        if (response.status >= 400) {
          return Promise.reject(response.data);
        }
        return pick(first(response.data), ['url', 'filename', 'assetId']);
      }),
});

const withFileChangeHandler = withHandlers({
  onFileChange:
    ({ formName, onChange }) =>
    (urls) =>
      onChange(formName, 'media', urls),
});

const withUploadError = withStateHandlers(() => ({ uploadError: null }), {
  setUploadError: () => (err) => ({ uploadError: err }),
});

// Components

/* eslint-disable react/prop-types */

function VerificationForm({
  intl,
  form,
  formName,
  uploadError,
  setUploadError,
  onFileUpload,
  onFileChange,
  onChange,
  onSubmit,
}) {
  const data = form[formName] || {};
  const notEnoughData = isEmpty(data.caption) || isEmpty(data.media);

  return (
    <Form id={formName} onChange={onChange} onSubmit={onSubmit}>
      {data.errors._error && <Alert type="error" textId={data.errors._error} />}
      <FormGroup hasDanger={hasAttributes(data.errors, ['caption'])}>
        <p className="m-t-1">
          <strong>
            <FormattedMessage
              id="tiktok.campaigns.verification.caption.name"
              defaultMessage="Post Caption:"
            />
          </strong>
          <span>
            <FormattedMessage
              id="tiktok.campaigns.verification.caption.message"
              defaultMessage="Enter the description which you will attach to your post. Please take into account any requirements given in the briefing and tasks."
            />
          </span>
        </p>
        <Textarea
          id="caption"
          placeholder={intl.formatMessage({
            id: 'tiktok.campaigns.verification.caption.placeholder',
            defaultMessage: 'Post caption',
          })}
          rows="5"
          value={data.caption || ''}
          danger={!!get(data, 'errors.caption')}
          className="m-t-1"
        />

        <FormControlFeedback
          message={
            get(data, 'errors.caption')
              ? intl.formatMessage({ id: get(data, 'errors.caption') })
              : null
          }
        />
      </FormGroup>
      <FormGroup>
        <p className="m-t-1">
          <strong>
            <FormattedMessage
              id="tiktok.campaigns.verification.media.name"
              defaultMessage="Post video:"
            />
          </strong>
          <span>
            <FormattedMessage
              id="tiktok.campaigns.verification.media.message"
              defaultMessage="Upload the images you will use in your post."
            />
          </span>
        </p>
        <MediaUpload
          errors={data.errors}
          onFileUpload={onFileUpload}
          onError={setUploadError}
          onChange={onFileChange}
          initialValues={data.media}
          allowedMIMETypes={['image/*', 'video/*']}
          maxFileSize={1024 * 1024 * 2000} // 2000MB
        />
        {uploadError ? (
          <MessageCard type="warning">
            <FileUploadError error={uploadError} />
          </MessageCard>
        ) : null}
      </FormGroup>
      <p className="m-y-1">
        <strong>
          <FormattedMessage
            id="tiktok.campaigns.verification.reorder.name"
            defaultMessage="IMPORTANT:"
          />
        </strong>
        <strong>
          <FormattedMessage
            id="tiktok.campaigns.verification.reorder.message"
            defaultMessage="Drag and drop the images to order them correctly."
          />
        </strong>
      </p>
      <FormActions>
        <SubmitButton
          disabled={
            hasAttributes(data.errors) || notEnoughData || data.submitting
          }
        >
          <FormattedMessage
            id="sponsoredPosts.campaigns.verification.submit"
            defaultMessage="Send"
          />
        </SubmitButton>
      </FormActions>
    </Form>
  );
}

function Active(props) {
  return (
    <VerificationActive {...props}>
      <ActivityStreamBody>
        <p>
          <FormattedMessage
            id="tiktok.campaigns.verification.message"
            defaultMessage="Almost there! Send us a preview of your post."
          />
        </p>
        <VerificationForm {...props} />
      </ActivityStreamBody>
    </VerificationActive>
  );
}

function Pending(props) {
  return (
    <VerificationPending {...props}>
      <MessageCard type="info">
        <strong>
          <FormattedMessage
            id="tiktok.campaigns.verification.pending"
            defaultMessage="We have received your post."
          />
        </strong>
        <span>
          <FormattedMessage
            id="tiktok.campaigns.verification.pending.message"
            defaultMessage="We will contact you once we complete verification."
          />
        </span>
      </MessageCard>
    </VerificationPending>
  );
}

function Rejected(props) {
  return (
    <VerificationRejected workflow={props.workflow}>
      <VerificationForm {...props} />
    </VerificationRejected>
  );
}

/* eslint-enable react/prop-types */

function TiktokVerification(props) {
  const {
    workflow: { status },
  } = props;

  const stepsByStatus = {
    inactive: VerificationInactive,
    active: Active,
    pending: Pending,
    rejected: Rejected,
    done: VerificationDone,
    expired: VerificationExpired,
  };

  const Component = stepsByStatus[status] || VerificationInactive;

  return <Component {...props} />;
}

TiktokVerification.propTypes = {
  formName: PropTypes.string.isRequired,
  workflow: PropTypes.shape({
    status: PropTypes.oneOf([
      'inactive',
      'active',
      'pending',
      'rejected',
      'done',
      'expired',
    ]).isRequired,
    payload: PropTypes.shape({
      showHint: PropTypes.bool,
      note: PropTypes.string,
    }),
  }).isRequired,
  form: PropTypes.object.isRequired,
  campaign: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onAction: PropTypes.any,
};

export default compose(
  defaultProps({
    formName: 'tiktokVerification',
  }),

  injectIntl,
  withFileChangeHandler,
  withUploadError,
  withUploadHandler,
)(TiktokVerification);
