import URI from 'urijs';
import omit from 'lodash/omit';
import get from 'lodash/get';

import constants from 'source/constants';
import { createReducer } from 'source/utils';
import { languageOptions } from 'source/utils/languages';

export const getInitialState = (accessToken = null) => ({
  accessToken,
  languageOptions,
  redirectingToSignIn: false,
  signin: {
    errors: {},
    username: '',
    password: '',
    progress: false,
    serverError: null,
  },
  signup: {
    errors: {},
    firstname: '',
    email: '',
    password: '',
    country: 'de',
    acceptedTerms: false,
    serverError: null,
  },
  forgotPassword: {
    errors: {},
    email: '',
    serverError: null,
  },
  resetPassword: {
    errors: {},
    password: '',
    serverError: null,
  },
  confirmEmail: {
    errors: {},
    serverError: null,
    stateLoaded: false,
    status: '',
  },
});

const actionHandlers = {
  [constants.ACCOUNT_FORM_RESET]: (state) => {
    const initialState = getInitialState();

    return {
      ...state,
      signin: initialState.signin,
      signup: initialState.signup,
      forgotPassword: initialState.forgotPassword,
      resetPassword: initialState.resetPassword,
    };
  },

  [constants.ACCOUNT_FORM_CHANGE]: (state, { form, field, value }) => ({
    ...state,
    [form]: { ...state[form], [field]: value },
  }),

  [constants.ACCOUNT_FORM_ERRORS]: (state, { form, errors }) => ({
    ...state,
    [form]: { ...state[form], errors },
  }),

  [constants.ACCOUNT_FORM_ERRORS_CLEAN]: (state, { form, field }) => {
    const { errors } = state[form];

    return {
      ...state,
      [form]: {
        ...state[form],
        errors: omit(errors, field),
      },
    };
  },

  [constants.ACCOUNT_SERVER_ERRORS_CLEAN]: (state, form) => ({
    ...state,
    [form]: { ...state[form], serverError: null },
  }),

  [constants.ACCOUNT_BLOG_TITLE_CHANGED]: (state, title) => ({
    ...state,
    signup: {
      ...state.signup,
      websiteName: title,
      resolvingBlogTitle: false,
      resolvingBlogTitleFailed: false,
    },
  }),

  [constants.ACCOUNT_BLOG_TITLE_RESOLVE_FAILED]: (
    state,
    { resolveSucceeded = false } = {},
  ) => {
    const uri = new URI(state.signup.websiteUrl);

    return {
      ...state,
      signup: {
        ...state.signup,
        websiteName: uri.domain(),
        resolvingBlogTitle: false,
        resolvingBlogTitleFailed: !resolveSucceeded,
      },
    };
  },

  [constants.ACCOUNT_ACCESS_TOKEN_FETCHING]: (state, form) => ({
    ...state,
    [form]: { ...state[form], progress: true },
  }),

  [constants.ACCOUNT_ACCESS_TOKEN_FETCH_FAILED]: (
    state,
    { form, response },
  ) => {
    const serverErrors = {
      'email-already-exists': {
        id: 'email',
        messageId: 'signup.errors.emailAlreadyExists',
      },
    };

    const errors = {};
    let serverError = response;
    // for custom error code response
    const code = get(response, 'data.code');

    // for 401, Unauthorized
    if (response.status === 401) {
      serverError = {
        ...serverError,
        translatedError: { id: 'signin.errors.emailOrPasswordIsWrong' },
      };
    }

    const translatedError = serverErrors[code];

    if (translatedError) {
      errors[translatedError.id] = translatedError.messageId;
      return { ...state, [form]: { ...state[form], errors, progress: false } };
    }

    return {
      ...state,
      [form]: { ...state[form], errors, serverError, progress: false },
    };
  },

  [constants.ACCOUNT_REQUEST_PASSWORD_RESET_FAILED]: (state, data) => {
    const { form, response } = data;

    const serverErrors = {
      'reset-token-invalid': 'errors.message.invalidToken',
      'reset-token-expired': 'errors.message.expiredToken',
    };

    let serverError = response;

    if (response.status === 404) {
      serverError = {
        ...serverError,
        translatedError: { id: 'signup.errors.userNotFound' },
      };
    }

    if (response.code && serverErrors[response.code]) {
      serverError = {
        ...serverError,
        translatedError: { id: serverErrors[response.code] },
      };
    }

    return { ...state, [form]: { ...state[form], serverError } };
  },

  [constants.ACCOUNT_ACCESS_TOKEN_FETCHED]: (state, { form, account }) => ({
    ...state,
    ...account,
    [form]: { ...state[form], progress: false },
  }),

  [constants.ACCOUNT_EMAIL_CONFIRMED]: (state) => ({
    ...state,
    confirmEmail: {
      ...state.confirmEmail,
      status: 'confirmed',
      stateLoaded: true,
    },
  }),

  [constants.ACCOUNT_EMAIL_CONFIRMATION_FAILED]: (state) => ({
    ...state,
    confirmEmail: {
      ...state.confirmEmail,
      status: 'failed',
      stateLoaded: true,
    },
  }),

  [constants.ACCOUNT_REDIRECT_TO_SIGNIN]: (state, status) => ({
    ...state,
    redirectingToSignIn: status === 'pending',
  }),
};

export default createReducer(getInitialState(), actionHandlers);
