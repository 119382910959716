import { connect } from 'react-redux';

import ForgotPassword from 'source/components/gateway/forgotPassword';

import {
  formChange,
  formErrors,
  cleanupErrors,
  cleanupServerErrors,
  forgotPasswordRequest,
  changeLanguage,
} from 'source/actions/account';

const mapStateToProps = (state) => ({
  id: 'forgotPassword',
  form: state.account.forgotPassword,
  language: state.application.locale,
  languageOptions: state.account.languageOptions,
  serverError: state.account.forgotPassword.serverError,
});

const mapDispatchToProps = (dispatch) => ({
  onErrors: (form, errors) => dispatch(formErrors(form, errors)),
  onChange(form, field, value) {
    dispatch(formChange(form, field, value));
    dispatch(cleanupErrors(form, field));
  },
  onSubmit: (id, form) =>
    dispatch(forgotPasswordRequest(form, '/reset-password-requested')),
  onErrorClose: () => dispatch(cleanupServerErrors('forgotPassword')),
  onChangeLanguage: (language) => dispatch(changeLanguage(language)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
