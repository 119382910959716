import get from 'lodash/get';
import omit from 'lodash/omit';
import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';

import { isEuropeanCountry } from './validate';

const buildPayload = (values, initialValues) => {
  // We need to omit the field `vatNotRegistered` and add the field
  // `vatRegistered` instead because the UI asks the question the other way
  // around.
  const vatNotRegistered = get(values, 'tax.vatNotRegistered');
  let vatRegistered = vatNotRegistered && vatNotRegistered !== 'yes';

  // If the user had already submitted `iban` and `bic` before, the server
  // will respond with encrypted human-readable values back, like XX23.
  // We don't want to submit those.
  const initialIban = get(initialValues, 'account.iban');
  const initialBic = get(initialValues, 'account.bic');
  const iban = get(values, 'account.iban');
  const bic = get(values, 'account.bic');

  // For European countries the API expects `vatRegistered` to be set. If
  // `tax.hasVatId` says 'yes', we set `vatRegistered` to `true`. If it says
  // 'no', we can't really assume anything about the `vatRegistered` value but
  // set it to `false` anyway (although `null` or similar would be better).
  const country = get(values, 'address.country');

  if (isEuropeanCountry(country) && country !== 'DE') {
    const hasVatId = get(values, 'tax.hasVatId');
    vatRegistered = hasVatId === 'yes';
  }

  const omits = [
    'tax.vatNotRegistered',
    'tax.hasVatId',
    'account.ibanEncrypted',
    'account.bicEncrypted',
  ];

  if (iban === initialIban) {
    omits.push('account.iban');
  }

  if (bic === initialBic) {
    omits.push('account.bic');
  }

  if (!vatRegistered) {
    omits.push('tax.vatId');
  }

  const allowedValues = omit(values, omits);
  const payload = {
    ...allowedValues,
    tax: {
      ...allowedValues.tax,
      vatRegistered,
    },
  };

  const payloadWithoutNestedEmptyStrings = mapValues(payload, (value) => {
    if (typeof value === 'object') {
      return omitBy(value, (str) => str === '');
    }

    return value;
  });

  return payloadWithoutNestedEmptyStrings;
};

export default buildPayload;
