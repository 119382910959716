import React from 'react';
import { arrayOf, bool, func, shape, string, object, array } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, getFormValues, reduxForm } from 'redux-form';

import { compose } from 'redux';
import { connect } from 'react-redux';
import urlParse from 'url-parse';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import get from 'lodash/get';

import ConnectButton from '../common/connectButton';

const isPreSelectable = (accountSummaries) => {
  if (accountSummaries.length === 1) {
    const webProperties = get(accountSummaries, '[0].webProperties', []);
    const profiles = get(accountSummaries, '[0].webProperties[0].profiles', []);
    return webProperties.length === 1 && profiles.length === 1;
  }
  return false;
};

const headlineDefaultMessageId = 'channels.ga.accountSelection.headlineDefault';
const headlinePreselectedMessageId =
  'channels.ga.accountSelection.headlinePreselected';
const headlineUpgradeMessageId = 'channels.ga.accountSelection.headlineUpgrade';
const selectAccountMessageId = 'channels.ga.accountSelection.selectAccount';
const selectPropertyMessageId = 'channels.ga.accountSelection.selectProperty';
const selectViewMessageId = 'channels.ga.accountSelection.selectView';

const hostName = (value) => {
  let { host, pathname } = urlParse(value.toLowerCase());

  // remove www. subdomain
  if (host.substr(0, 4) === 'www.') {
    host = host.substr(4);
  }

  // make sure pathname does not end with /
  if (pathname.slice(-1) === '/') {
    pathname = pathname.slice(0, -1);
  }

  return host + pathname;
};
const compareUrls = (url1, url2) => hostName(url1) === hostName(url2);

const getPropertyOptionsByAccountId = (propertyOptions, accountId) =>
  propertyOptions.filter(
    (propertyOption) => propertyOption.accountId === accountId,
  );

const getPropertyOptionsByPropertyId = (propertyOptions, propertyId) =>
  propertyOptions.filter((propertyOption) => propertyOption.id === propertyId);

const getViewOptionsByPropertyId = (viewOptions, propertyId) =>
  viewOptions.filter((viewOption) => viewOption.propertyId === propertyId);

const getAccountIdSelectValue = (accountOptions, selectedAccount) => {
  if (selectedAccount && selectedAccount.accountId) {
    return selectedAccount.accountId;
  }

  return accountOptions.length === 1 ? accountOptions[0].id : '';
};

const getPropertyIdSelectValue = (
  propertyOptions,
  accountId,
  selectedAccount,
) => {
  if (selectedAccount && selectedAccount.propertyId) {
    return selectedAccount.propertyId;
  }

  const matchingOptions = getPropertyOptionsByAccountId(
    propertyOptions,
    accountId,
  );

  return matchingOptions.length === 1 ? matchingOptions[0].id : '';
};

const getUrlSelectValue = (propertyOptions, propertyId, selectedAccount) => {
  if (selectedAccount && selectedAccount.url) {
    return selectedAccount.url;
  }

  const matchingOptions = getPropertyOptionsByPropertyId(
    propertyOptions,
    propertyId,
  );

  return matchingOptions.length === 1 ? matchingOptions[0].url : '';
};

const getViewIdSelectValue = (viewOptions, propertyId, selectedAccount) => {
  if (selectedAccount && selectedAccount.viewId) {
    return selectedAccount.viewId;
  }

  const matchingOptions = getViewOptionsByPropertyId(viewOptions, propertyId);

  return matchingOptions.length === 1 ? matchingOptions[0].id : '';
};

let BlogGaSelectionStepForm = class BlogGaSelectionStepForm extends React.Component {
  componentDidUpdate(prevProps) {
    if (isEmpty(prevProps.formValues)) {
      return;
    }

    const accountIdChanged =
      prevProps.formValues.accountId !== this.props.formValues.accountId;

    const propertyIdChanged =
      prevProps.formValues.propertyId !== this.props.formValues.propertyId;

    const viewIdChanged =
      prevProps.formValues.viewId !== this.props.formValues.viewId;

    let nextPropertyId;
    let nextViewId;

    if (accountIdChanged) {
      nextPropertyId = getPropertyIdSelectValue(
        this.props.propertyOptions,
        this.props.formValues.accountId,
      );
    }

    if (propertyIdChanged) {
      nextViewId = getViewIdSelectValue(
        this.props.viewOptions,
        this.props.formValues.propertyId,
      );
    }

    if (viewIdChanged) {
      this.props.change(
        'url',
        getUrlSelectValue(
          this.props.propertyOptions,
          this.props.formValues.propertyId,
        ),
      );
    }

    if (
      nextPropertyId !== undefined &&
      nextPropertyId !== this.props.formValues.propertyId
    ) {
      this.props.change('propertyId', nextPropertyId);
    }

    if (
      nextViewId !== undefined &&
      nextViewId !== this.props.formValues.viewId
    ) {
      this.props.change('viewId', nextViewId);
    }
  }

  onSubmit = (values) => {
    const { accountOptions, propertyOptions, viewOptions } = this.props;

    const account = accountOptions.find(
      (accountOptions) => accountOptions.id === values.accountId,
    );

    const property = propertyOptions.find(
      (propertyOption) => propertyOption.id === values.propertyId,
    );

    const view = viewOptions.find(
      (viewOption) => viewOption.id === values.viewId,
    );

    return this.props.onSubmit({
      ...values,
      accountName: account.name,
      propertyName: property.name,
      internalWebPropertyId: property.internalWebPropertyId,
      viewName: view.name,
    });
  };

  render() {
    const { accountSummaries, referenceChannel } = this.props;
    const headlineId = isPreSelectable(accountSummaries)
      ? headlinePreselectedMessageId
      : headlineDefaultMessageId;
    const referenceChannelUrl = get(referenceChannel, 'data.url');
    const channelUrl = this.props.formValues.url;

    return (
      <div className="ga-selection-step">
        <h5 className="m-b-1">
          {referenceChannel ? (
            <FormattedMessage
              id={headlineUpgradeMessageId}
              values={{ url: referenceChannelUrl }}
            />
          ) : (
            <FormattedMessage id={headlineId} />
          )}
        </h5>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div className="select-container m-b-1">
            <div className="select-item-container">
              <Field
                name="accountId"
                component="select"
                className="select-item"
              >
                <option value="" disabled>
                  {this.props.intl.formatMessage({
                    id: selectAccountMessageId,
                  })}
                </option>
                {this.props.accountOptions.map((accountOptions) => (
                  <option key={accountOptions.id} value={accountOptions.id}>
                    {accountOptions.name}
                  </option>
                ))}
              </Field>
            </div>
            <div className="select-item-container">
              <Field
                name="propertyId"
                component="select"
                className="select-item"
              >
                <option value="" disabled>
                  {this.props.intl.formatMessage({
                    id: selectPropertyMessageId,
                  })}
                </option>
                {this.props.propertyOptions
                  .filter(
                    (propertyOption) =>
                      propertyOption.accountId ===
                      this.props.formValues.accountId,
                  )
                  .map((propertyOption) => (
                    <option key={propertyOption.id} value={propertyOption.id}>
                      {propertyOption.name}
                    </option>
                  ))}
              </Field>
            </div>
            <div className="select-item-container">
              <Field name="viewId" component="select" className="select-item">
                <option value="" disabled>
                  {this.props.intl.formatMessage({ id: selectViewMessageId })}
                </option>
                {this.props.viewOptions
                  .filter(
                    (viewOption) =>
                      viewOption.propertyId ===
                      this.props.formValues.propertyId,
                  )
                  .map((viewOption) => (
                    <option key={viewOption.id} value={viewOption.id}>
                      {viewOption.name}
                    </option>
                  ))}
              </Field>
            </div>
          </div>
          <div className="select-item-container">
            {this.props.error && (
              <div className="m-b-1">
                <small className="text-danger">
                  <FormattedMessage id={this.props.error} />
                </small>
              </div>
            )}
          </div>
          {channelUrl !== '' && (
            <div className="container m-b-1 p-x-0">
              <div className="row">
                <div className="col-md-2 col-xs-12">
                  <FormattedMessage
                    id="channels.ga.selectedWebsite"
                    defaultMessage="Website URL"
                  />
                </div>
                <div className="col-md-10 col-xs-12">
                  <Field
                    name="url"
                    component="input"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="warning-message">
                <div className="p-t-1">
                  <FormattedMessage
                    id="channels.ga.selectedWebsiteNotice"
                    defaultMessage="Make sure that the URL matches your current website. It will be used to verify the content you post later."
                  />
                </div>
              </div>
              {referenceChannelUrl && (
                <>
                  {channelUrl &&
                    referenceChannelUrl &&
                    !compareUrls(channelUrl, referenceChannelUrl) && (
                      <div className="validation-message">
                        <div className="p-t-1">
                          <FormattedMessage
                            id="channels.ga.selectedUrlDoesnotMatchMigrated"
                            defaultMessage="ℹ️️ The selected Google Analytics view URL doesn’t match your Blog URL. This is not necessarily a problem, just make sure you select the right Google Analytics account, property and view before you continue."
                          />
                        </div>
                      </div>
                    )}
                </>
              )}
            </div>
          )}

          <ConnectButton
            platform="ga"
            disconnect
            id={this.props.id}
            messageId="cancel"
            onClick={this.props.onCancel}
          />

          <div className="pull-xs-right">
            <button
              type="submit"
              disabled={this.props.invalid || this.props.submitting}
              className="btn btn-primary m-b-1"
            >
              <FormattedMessage
                id="controls.captions.nextButton"
                defaultMessage="Next"
              />
            </button>
          </div>
        </form>
      </div>
    );
  }
};

BlogGaSelectionStepForm.propTypes = {
  id: string.isRequired,
  referenceChannel: object,
  accountSummaries: array,
  accountOptions: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
    }).isRequired,
  ).isRequired,
  propertyOptions: arrayOf(
    shape({
      accountId: string.isRequired,
      id: string.isRequired,
      name: string.isRequired,
      url: string.isRequired,
      internalWebPropertyId: string.isRequired,
    }).isRequired,
  ).isRequired,
  viewOptions: arrayOf(
    shape({
      propertyId: string.isRequired,
      id: string.isRequired,
      name: string.isRequired,
    }).isRequired,
  ).isRequired,
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
  // Provided by connect
  formValues: shape({
    accountId: string,
    propertyId: string,
    viewId: string,
    url: string,
  }),

  // Provided by reduxForm
  change: func.isRequired,
  invalid: bool.isRequired,
  submitting: bool.isRequired,
  error: string,
  handleSubmit: func.isRequired,
};

BlogGaSelectionStepForm.defaultProps = {
  formValues: {},
};

BlogGaSelectionStepForm = compose(
  connect((state, ownProps) => ({
    formValues: getFormValues(ownProps.form)(state),
  })),

  reduxForm({
    validate: (values) => {
      const errors = {};

      // We don't set the `_errors` property in the case for missing values to
      // support using `_errors` for submit validations.
      const requiredValues = ['accountId', 'propertyId', 'viewId'];
      requiredValues.forEach((requiredValueName) => {
        if (!values[requiredValueName]) {
          errors[requiredValueName] = true;
        }
      });

      return errors;
    },
  }),

  injectIntl,
)(BlogGaSelectionStepForm);

/**
 * Prepares data and initialValues for the Form component
 */
function BlogGaSelectionStep(props) {
  const accountOptions = props.accountSummaries.map((account) => ({
    id: account.id,
    name: account.name,
  }));

  // webProperties could be undefined
  // it is valid state for Google Analytics
  const propertyOptions = flatMap(props.accountSummaries, (account) =>
    get(account, 'webProperties', []).map((property) => ({
      accountId: account.id,
      id: property.id,
      name: property.name,
      url: property.websiteUrl,
      internalWebPropertyId: property.internalWebPropertyId,
    })),
  );

  // webProperties and profiles could be undefined
  // it is valid state for Google Analytics
  const viewOptions = flatMap(props.accountSummaries, (account) =>
    flatMap(get(account, 'webProperties', []), (property) =>
      get(property, 'profiles', []).map((view) => ({
        propertyId: property.id,
        id: view.id,
        name: view.name,
      })),
    ),
  );

  const { selectedAccount } = props;

  const initialAccountId = getAccountIdSelectValue(
    accountOptions,
    selectedAccount,
  );

  const initialPropertyId = getPropertyIdSelectValue(
    propertyOptions,
    initialAccountId,
    selectedAccount,
  );

  const initialViewId = getViewIdSelectValue(
    viewOptions,
    initialPropertyId,
    selectedAccount,
  );

  // we take url value from property, not from view as it was before..
  const initialUrl = getUrlSelectValue(
    propertyOptions,
    initialPropertyId,
    selectedAccount,
  );

  const initialValues = {
    url: initialUrl,
    accountId: initialAccountId,
    propertyId: initialPropertyId,
    viewId: initialViewId,
  };

  return (
    <BlogGaSelectionStepForm
      id={props.id}
      form={props.form}
      accountSummaries={props.accountSummaries}
      referenceChannel={props.referenceChannel}
      accountOptions={accountOptions}
      propertyOptions={propertyOptions}
      viewOptions={viewOptions}
      onSubmit={props.onSubmit}
      onCancel={props.onCancel}
      initialValues={initialValues}
    />
  );
}

BlogGaSelectionStep.propTypes = {
  id: string.isRequired,
  form: string.isRequired,
  referenceChannel: object,
  accountSummaries: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      webProperties: arrayOf(
        shape({
          id: string.isRequired,
          name: string.isRequired,
          profiles: arrayOf(
            shape({
              id: string.isRequired,
              name: string.isRequired,
            }).isRequired,
          ),
        }).isRequired,
      ),
    }).isRequired,
  ).isRequired,
  selectedAccount: shape({
    url: string,
    accountId: string,
    propertyId: string,
    viewId: string,
  }),

  onSubmit: func.isRequired,
  onCancel: func,
};

BlogGaSelectionStep.defaultProps = {
  onCancel: noop,
};

export default BlogGaSelectionStep;
