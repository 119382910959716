import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';

import ProgressButton from './progressButton';

function SaveProgressButton({ style, intl, status, onClick }) {
  const savingClass = {
    default: '',
    saving: 'btn-saving',
    saved: 'btn-saved',
    failed: 'btn-error',
  };

  return (
    <ProgressButton
      caption={intl.formatMessage({ id: `saveProgressButton.${status}` })}
      progressClass={savingClass[status]}
      onClick={onClick}
      style={style}
    />
  );
}

SaveProgressButton.propTypes = {
  style: PropTypes.object,

  status: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

SaveProgressButton.defaultProps = {
  style: undefined,
  onClick: () => {},
};

export default injectIntl(SaveProgressButton);
