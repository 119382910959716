import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

function DisconnectConfirm({
  name,
  id,
  userId,
  submitting,
  onConfirm,
  onCancel,
}) {
  const mailToHref = `mailto:support@eqolot.com?subject=User:${userId}&body=Channel:${id}`;

  return (
    <div className="container">
      <h5 className="m-b-1">
        <FormattedMessage
          id="channels.disconnect.confirm.title"
          defaultMessage="Disconnect channel {name}?"
          values={{ name }}
        />
      </h5>
      <p className="">
        <FormattedMessage
          id="channels.disconnect.confirm.body"
          defaultMessage="This will remove your channel from your account permanently and can't be undone.<br></br><br></br>IMPORTANT: Our system uses your channel's past performance as a base to calculate your reward for new campaigns. If you delete a channel, its performance bonus goes away and you will revert to the default pricing.<br></br><br></br>If you would prefer to change the channel URL or <i>@handle,</i> please {supportLink}"
          values={{
            supportLink: (
              <a href={mailToHref}>
                <FormattedMessage
                  id="channels.disconnect.confirm.supportLink.label"
                  defaultMessage="contact our Support team."
                />
              </a>
            ),
          }}
        />
      </p>
      <div className="m-t-2" style={{ float: 'right' }}>
        <button
          className="btn btn-primary"
          onClick={onCancel}
          disabled={submitting}
        >
          <FormattedMessage
            id="channels.disconnect.cancelDisconnectBtn"
            defaultMessage="No, I changed my mind"
          />
        </button>
        <button
          className="btn btn-primary m-l-1"
          onClick={onConfirm}
          disabled={submitting}
        >
          <FormattedMessage
            id="channels.disconnect.confirmDisconnectBtn"
            defaultMessage="Disconnect channel"
          />
        </button>
      </div>
    </div>
  );
}

DisconnectConfirm.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  submitting: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DisconnectConfirm;
