import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

function GaUpgradePrompt({ channels, intl: { formatMessage } }) {
  const academyLinkUrl = formatMessage({
    id: 'channels.website.connect.how-to.url',
    defaultMessage: 'https://eqolot.com/support/google-analytics-integration',
  });

  const piwikWebsites = channels.filter(
    (channel) => channel.platform === 'website',
  );

  if (piwikWebsites.length === 0) {
    return null;
  }

  return (
    <div className="container">
      <div className="card card-block channel-body prompt-to-upgrade">
        <div className="container">
          <h5>
            <FormattedMessage
              id="channels.promptToUpgrade.warning.title"
              defaultMessage="⚠️ Upgrade to Google Analytics tracking"
            />
          </h5>
          <p>
            <FormattedMessage
              id="channels.promptToUpgrade.warning.firstLine"
              defaultMessage="EQOLOT has changed the blog reporting system to a Google Analytics solution. <b>You must upgrade your channels to continue to participate in campaigns.</b>"
            />
          </p>
          <p>
            <FormattedMessage
              id="channels.promptToUpgrade.warning.bulletOne"
              defaultMessage="<b>• What does it mean?</b> Google Analytics is the industry standard analytics tool, and is used by the vast majority of blogs; you probably already use it. By sharing your blog’s Google Analytics data with EQOLOT, we are really on the same page."
            />
          </p>
          <p>
            <FormattedMessage
              id="channels.promptToUpgrade.warning.bulletTwo"
              defaultMessage="<b>• Is the upgrade required?</b> Yes. The classic tracking script method is no longer available for new channels, and it will be phased out for older channels."
            />
          </p>
          <FormattedMessage
            id="channels.promptToUpgrade.warning.closingLine"
            defaultMessage="Learn more about the reasons for this change and its impact on the {academyLink}"
            values={{
              academyLink: (
                <a target="_blank" rel="noreferrer" href={academyLinkUrl}>
                  <FormattedMessage
                    id="channels.website.connect.how-to.url.label"
                    defaultMessage="EQOLOT website »"
                  />
                </a>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
}

GaUpgradePrompt.propTypes = {
  channels: PropTypes.array.isRequired,
};

export default injectIntl(GaUpgradePrompt);
