import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from './icon';
import Card, { CardBlock } from './card';

const messageType = PropTypes.oneOf(['info', 'ask', 'warning']);

function Message({ type, children, className }) {
  className = classNames(`message message-${type}`, className);

  return (
    <div className={className}>
      <Icon name={type} className="message-icon" />
      {children}
    </div>
  );
}

Message.propTypes = {
  type: messageType.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
};

Message.defaultProps = {
  children: undefined,
  className: undefined,
};

export function MessageCard({ type, hero, children, className, onDismiss }) {
  className = classNames(
    'card-message',
    `card-message-${type}`,
    { 'card-message-hero': hero },
    className,
  );

  return (
    <Card className={className}>
      <CardBlock>
        <Message type={type}>{children}</Message>
        {onDismiss ? (
          <button className="card-message__dismiss" onClick={onDismiss}>
            <Icon name="cross" />
          </button>
        ) : null}
      </CardBlock>
    </Card>
  );
}

MessageCard.propTypes = {
  type: messageType.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
  hero: PropTypes.bool,
  onDismiss: PropTypes.func,
};

MessageCard.defaultProps = {
  className: undefined,
  children: undefined,
  hero: undefined,
  onDismiss: undefined,
};

export default Message;
