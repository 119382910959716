import { push } from 'react-router-redux';
import pick from 'lodash/pick';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import constants from 'source/constants';
import { mapObject, createStorage } from 'source/utils';
import { actionAuthRequest, requestFlow } from 'source/utils/axios';
import { auth } from 'source/utils/auth';

import { loadedApplicationState } from 'source/actions/application';

const resetStore = (source) => ({
  type: constants.GLOBAL_RESET_STORE,
  payload: { source },
});

const setLanguage = (language) => {
  // save language also to localstorage
  createStorage().save('language', language);

  return { type: constants.APPLICATION_SET_LANGUAGE, payload: language };
};

// Common Actions

export const changeTabSelection = (index) => ({
  type: constants.ACCOUNT_SETTINGS_TAB_CHANGED,
  payload: index,
});

export const formChange = (form, field, value) => ({
  type: constants.ACCOUNT_SETTINGS_FORM_CHANGE,
  payload: { form, field, value },
});

export const formErrors = (form, errors) => ({
  type: constants.ACCOUNT_SETTINGS_FORM_ERRORS,
  payload: { form, errors },
});

export const clearMessage = (form) => ({
  type: constants.ACCOUNT_SETTINGS_ALERT_CLEAR,
  payload: form,
});

const resetForms = () => ({
  type: constants.ACCOUNT_SETTINGS_FORM_RESET,
});

const submittingForm = (id, payload) => ({
  type: constants.ACCOUNT_SETTINGS_FORM_SUBMITTING,
  payload: { id, payload },
});

const submittedForm = (id, payload) => ({
  type: constants.ACCOUNT_SETTINGS_FORM_SUBMITTED,
  payload: { id, payload },
});

const submittingFailed = (id, error) => ({
  type: constants.ACCOUNT_SETTINGS_FORM_SUBMITTING_FAILED,
  payload: { id, error },
});

// State Actions

const loadingAccountSettingsState = () => ({
  type: constants.ACCOUNT_SETTINGS_STATE_LOADING,
});

const accountSettingsStateLoaded = (data) => ({
  type: constants.ACCOUNT_SETTINGS_STATE_LOADED,
  payload: data,
});

const accountSettingsStateLoadingFailed = (err, resp) => ({
  type: constants.ACCOUNT_SETTINGS_STATE_LOADING_FAILED,
  payload: { err, resp },
});

// Change-Password Form

const appliedPasswordChangeSuccessfully = () => ({
  type: constants.ACCOUNT_SETTINGS_PASSWORD_CHANGE_APPLIED_SUCCESSFULLY,
});

const failedToApplyPasswordChange = (error, response) => ({
  type: constants.ACCOUNT_SETTINGS_PASSWORD_CHANGE_FAILED,
  payload: { error, response },
});

// Delete-Account Form

export const toggleDeleteAccountConfirm = () => ({
  type: constants.ACCOUNT_SETTINGS_DELETE_ACCOUNT_TOGGLE_CONFIRM,
});

const accountDeletedSuccessfully = () => ({
  type: constants.ACCOUNT_SETTINGS_DELETED_ACCOUNT,
});

const failedToApplyDeleteAccount = (error, response) => ({
  type: constants.ACCOUNT_SETTINGS_DELETE_ACCOUNT_FAILED,
  payload: { error, response },
});

// Payment Form

const applyingPaymentData = () => ({
  type: constants.ACCOUNT_SETTINGS_PAYMENT_DATA_SAVING,
});

const appliedPaymentDataSuccessfully = () => ({
  type: constants.ACCOUNT_SETTINGS_PAYMENT_DATA_SAVED,
});

const failedToApplyPaymentData = (error, response) => ({
  type: constants.ACCOUNT_SETTINGS_PAYMENT_DATA_SAVE_FAILED,
  payload: { error, response },
});

// Async State Action

export const loadAccountSettingsState = () => (dispatch, getState) => {
  const onBefore = () => {
    dispatch(resetForms());

    return dispatch(loadingAccountSettingsState());
  };
  const onSuccess = (payload) => dispatch(accountSettingsStateLoaded(payload));
  const onError = (resp) => {
    const error = new Error('failed to load accountSettings state');

    return dispatch(accountSettingsStateLoadingFailed(error, resp));
  };

  const fetchUser = () =>
    actionAuthRequest(dispatch, getState)
      .get('/v1/users')
      .then((resp) => resp.data);

  const handlePaymentError = (resp) => {
    if (resp.status === 404) {
      // 404 status for payment-data endpoint means, user hasn't yet submitted his payment data,
      // we treat it as successful state load, leaving form in default state.

      return Promise.resolve({});
    }

    return Promise.reject(resp);
  };

  const fetchPaymentData = () =>
    actionAuthRequest(dispatch, getState)
      .get('/v1/payment-data')
      .then((resp) => resp.data)
      .catch(handlePaymentError);

  const request = () =>
    Promise.all([fetchUser(), fetchPaymentData()]).then(
      ([user, paymentData]) => ({ user, paymentData }),
    );

  return requestFlow(request, { onBefore, onSuccess, onError });
};

// Async Profile Form

export const submitProfile = (id, form) => (dispatch, getState) => {
  const onBefore = () => dispatch(submittingForm(id, form));
  const onSuccess = (payload) => {
    dispatch(submittedForm(id, payload));
    // update application state, which is the main source of user data
    dispatch(loadedApplicationState(payload));
    // update application language
    return dispatch(setLanguage(payload.language));
  };
  const onError = (error) => dispatch(submittingFailed(id, error));

  const selectedData = pick(form, [
    'phone',
    'language',
    'salutation',
    'firstname',
    'lastname',
    'streetName',
    'streetNumber',
    'postalCode',
    'city',
    'country',
  ]);
  const data = omitBy(selectedData, isNil);

  // format form data
  if (form.yearOfBirth) {
    data.yearOfBirth = parseInt(form.yearOfBirth, 10);
  } else if (form.yearOfBirth === '') {
    data.yearOfBirth = null;
  }

  const request = () =>
    actionAuthRequest(dispatch, getState)
      .patch('/v1/users', data)
      .then((resp) => resp.data);
  return requestFlow(request, { onBefore, onSuccess, onError });
};

const failedToUpdateNotifications = (error) => ({
  type: constants.ACCOUNT_SETTINGS_NOTIFICATIONS_UPDATE_FAILED,
  error,
});

const submitNotifications = (id, form) => (dispatch, getState) => {
  const payload = pick(form, [
    'recommendedCampaignEmails',
    'promotionalEmails',
  ]);

  const onBefore = () => dispatch(submittingForm(id, payload));
  const onSuccess = (payload) => dispatch(submittedForm(id, payload));
  const onError = (error) => {
    dispatch(failedToUpdateNotifications(error));
    dispatch(submittingFailed(id, error));
  };

  const request = () =>
    actionAuthRequest(dispatch, getState)
      .patch('/v1/users/settings', payload)
      .then((resp) => resp.data);

  return requestFlow(request, { onBefore, onSuccess, onError });
};

// Async Payment Form

const submitPayment = (id, form) => (dispatch, getState) => {
  let mapping = {
    owner: 'account.owner',
    salutation: 'name.salutation',
    firstname: 'name.firstname',
    lastname: 'name.lastname',
    street: 'address.street',
    number: 'address.number',
    city: 'address.city',
    postalCode: 'address.postalCode',
    country: 'address.country',
  };

  if (form.accountType === 'business') {
    mapping = { ...mapping, name: 'company.name', vat: 'company.vat' };
  }

  // NOTE:  Don't send back IBAN or BIC, if it was received from the server without any modifications,
  //        as the server will always respond with an obfuscated string.
  if (!form.ibanFromServer) {
    mapping = { ...mapping, iban: 'account.iban' };
  }

  if (!form.bicFromServer) {
    mapping = { ...mapping, bic: 'account.bic' };
  }

  const payload = mapObject(mapping, form);

  const onBefore = () => dispatch(applyingPaymentData());
  const onSuccess = (resp) => {
    dispatch(appliedPaymentDataSuccessfully());

    // update form based on server response (with masked values, etc..)
    return dispatch(accountSettingsStateLoaded({ paymentData: resp.data }));
  };

  const onError = (err) =>
    dispatch(
      failedToApplyPaymentData(
        new Error('payment data submit failed'),
        err.data,
      ),
    );

  const request = () => {
    const axoisRequest = actionAuthRequest(dispatch, getState);

    // when data is submitted for a first time, we need to post it, afterwards we use patch
    const method = form.paymentDataNotProvided
      ? axoisRequest.post
      : axoisRequest.patch;

    return method.call(request, '/v1/payment-data', payload);
  };

  return requestFlow(request, { onBefore, onSuccess, onError });
};

// Async Change-Password Form

const submitChangePassword = (id, form) => (dispatch, getState) => {
  const payload = {
    currentPassword: form.currentPassword,
    newPassword: form.newPassword,
  };

  const onSuccess = () => {
    dispatch(resetForms());
    return dispatch(appliedPasswordChangeSuccessfully());
  };

  const onError = (err) =>
    dispatch(
      failedToApplyPasswordChange(
        new Error('password change failed'),
        err.data,
      ),
    );

  const request = () =>
    actionAuthRequest(dispatch, getState).post(
      '/v1/users/change-password',
      payload,
    );

  return requestFlow(request, { onSuccess, onError });
};

// Async Delete-Account Form

const submitDeleteAccount = (id, form) => (dispatch, getState) => {
  const payload = {
    reason: form.reason,
  };

  const onSuccess = () => {
    auth.reset();

    dispatch(accountDeletedSuccessfully(payload.reason));
    dispatch(resetStore('accountSettings:applyDeleteAccount'));
    dispatch(push('/sorry-see-you-go'));
  };

  const onError = (err) =>
    dispatch(
      failedToApplyDeleteAccount(new Error('delete account failed'), err.data),
    );

  const request = () =>
    actionAuthRequest(dispatch, getState).delete('/v1/users', {
      params: payload,
    });

  return requestFlow(request, { onSuccess, onError });
};

// Async Submit Wrapper

export const submitForm = (id, form) => {
  const submitActions = {
    profile: submitProfile,
    notifications: submitNotifications,
    payment: submitPayment,
    changePassword: submitChangePassword,
    deleteAccount: submitDeleteAccount,
  };

  const action = submitActions[id];

  if (!action) {
    throw new Error('no accountSettings submit action configured for', id);
  }

  return action(id, form);
};
