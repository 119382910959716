import PropTypes from 'prop-types';
import React from 'react';
import { VelocityComponent } from 'velocity-react';

function Collapsible({ collapsed, runOnMount, noAnimation, ...props }) {
  const animation = collapsed ? 'slideUp' : 'slideDown';
  const content = <div {...props} />;

  // @TODO Where to put configuration for aurora theme (duration)?
  return !collapsed && noAnimation ? (
    content
  ) : (
    <VelocityComponent
      animation={animation}
      duration={400}
      runOnMount={runOnMount}
    >
      {content}
    </VelocityComponent>
  );
}

Collapsible.propTypes = {
  collapsed: PropTypes.bool,
  runOnMount: PropTypes.bool,
  noAnimation: PropTypes.bool,
};

Collapsible.defaultProps = {
  collapsed: false,
  noAnimation: false,
};

export default Collapsible;
