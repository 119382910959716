import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { lifecycle, withProps, compose } from 'recompose';

import { resetScene } from '../actions';

import { loadApplication } from '../components/CampaignDetails/actions';

import { getAsyncWorkflow, getAsyncApplication } from '../selectors';

import CampaignDetails from '../components/CampaignDetails';

import Workflow from './Workflow';

const withSceneReset = lifecycle({
  componentWillUnmount() {
    this.props.onResetScene();
  },
});

const withDependencyFetch = lifecycle({
  componentDidMount() {
    const {
      params: { applicationId },
      onLoadApplication,
    } = this.props;

    onLoadApplication(applicationId);
  },
});

const withWorkflow = withProps({
  components: { Workflow },
});

export default compose(
  connect(
    createStructuredSelector({
      application: getAsyncApplication,
      workflow: getAsyncWorkflow,
    }),
    {
      onLoadApplication: loadApplication,
      onResetScene: resetScene,
    },
  ),
  withDependencyFetch,
  withSceneReset,
  withWorkflow,
)(CampaignDetails);
