import React from 'react';
import ReactDom from 'react-dom';
import domready from 'domready';
import Modal from 'react-modal';
import { CookiesProvider } from 'react-cookie';
import App from 'source/app';
import { ensureIntl } from 'source/i18n';

// this is where apple touch icon is being loaded
import 'source/images/apple-touch.png';

Modal.setAppElement('#root-container');

const renderApp = (App) => {
  ReactDom.render(
    <CookiesProvider>
      <App />
    </CookiesProvider>,
    document.getElementById('root-container'),
  );
};

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./app', () => {
    const NextApp = require('./app').default; // eslint-disable-line global-require
    renderApp(NextApp);
  });
}

domready(() => ensureIntl().then(() => renderApp(App)));
