import PropTypes from 'prop-types';
import React, { cloneElement } from 'react';
import classNames from 'classnames';

export const CardBlock = ({ element, className, ...props }) => {
  className = classNames('card-block', className, element.props.className);

  return cloneElement(element, {
    className,
    ...props,
  });
};

CardBlock.propTypes = {
  className: PropTypes.string,
  element: PropTypes.element,
};

CardBlock.defaultProps = {
  element: <div />,
};

export function CardTitle({ children, className }) {
  className = classNames('card-title', className);

  return <h4 className={className}>{children}</h4>;
}

CardTitle.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export function CardImage({ position, className, alt, ...props }) {
  if (position != null) {
    className = classNames(`card-img-${position}`, className);
  }

  return <img className={className} {...props} alt={alt} />;
}

CardImage.propTypes = {
  className: PropTypes.string,
  position: PropTypes.oneOf(['top', 'bottom']),
  alt: PropTypes.string,
};

function Card({ children, className, loading }) {
  className = classNames('card', className, { loading });

  return <div className={className}>{children}</div>;
}

Card.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};

Card.defaultProps = {
  loading: false,
};

export default Card;
