import { createReducer } from 'source/utils';
import constants from 'source/constants';
import { isSmallWindow } from 'source/utils/window';
import { defaultLocale } from 'source/i18n';

export const getInitialState = (
  locale = defaultLocale,
  env = 'development',
) => ({
  locale,
  env,
  sidebar: {
    collapsed: isSmallWindow(),
  },
  usersettings: {
    visible: false,
  },
  stateLoaded: false,
  user: null,
  confirmEmail: {
    state: '',
    error: null,
  },
});

const actionHandlers = {
  [constants.APPLICATION_SIDEBAR_NAVIGATION_TOGGLE]: (state) => {
    const toggledSidebar = !state.sidebar.collapsed;

    return {
      ...state,
      sidebar: { collapsed: isSmallWindow() && toggledSidebar },
    };
  },

  [constants.APPLICATION_USER_SETTINGS_TOGGLE]: (state) => {
    const toggledUserSettings = !state.usersettings.visible;

    return { ...state, usersettings: { visible: toggledUserSettings } };
  },

  [constants.APPLICATION_STATE_LOADING]: (state) => ({
    ...state,
    stateLoaded: false,
  }),

  [constants.APPLICATION_STATE_LOADED]: (state, { user }) => ({
    ...state,
    user: {
      ...state.user,
      ...user,
    },
    stateLoaded: true,
  }),

  [constants.APPLICATION_STATE_USER_SETTINGS_UPDATED]: (
    state,
    { settings },
  ) => ({
    ...state,
    user: {
      ...state.user,
      settings,
    },
    stateLoaded: true,
  }),

  [constants.APPLICATION_STATE_LOADING_FAILED]: (state) => ({
    ...state,
    stateLoaded: false,
    selectedWebsite: null,
  }),

  [constants.APPLICATION_SET_LANGUAGE]: (state, language) => ({
    ...state,
    locale: language,
  }),

  [constants.CONFIRM_EMAIL_SENDING]: (state) => ({
    ...state,
    confirmEmail: {
      ...state.confirmEmail,
      state: 'sending',
      error: null,
    },
  }),

  [constants.CONFIRM_EMAIL_SENT]: (state) => ({
    ...state,
    confirmEmail: {
      ...state.confirmEmail,
      state: 'success',
      error: null,
    },
  }),
};

export default createReducer(getInitialState(), actionHandlers);
