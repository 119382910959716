import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { lifecycle, compose, branch, renderComponent } from 'recompose';
import isEmpty from 'lodash/isEmpty';

import { ProgressBar } from 'source/components/common';

import {
  loadCampaignMatches,
  loadAllCampaigns,
  changeCampaignTypeFilter,
  enableCampaignEmails,
} from '../components/ExploreCampaigns/actions';

import {
  getCampaignMatchesByType,
  getAllCampaignsByType,
  getCampaignTypeFilter,
} from '../selectors';

import ExploreCampaigns from '../components/ExploreCampaigns';

const withLoadingGuard = branch(
  (props) => !props.loadedUserChannels,
  renderComponent(ProgressBar),
);

const withCampaignsFetch = lifecycle({
  UNSAFE_componentWillMount() {
    if (!this.props.userConfirmed || !this.props.userHasChannels) {
      this.props.onLoadAllCampaigns();
    } else {
      this.props.onLoadCampaignMatches();
    }
  },
});

export default compose(
  connect(
    createStructuredSelector({
      campaignMatchesByType: getCampaignMatchesByType,
      allCampaignsByType: getAllCampaignsByType,
      campaignTypeFilter: getCampaignTypeFilter,
      loadedUserChannels: (state) => state.sponsoredPosts.channels.loaded,
      userConfirmed: (state) => state.application.user.confirmed,
      userHasChannels: (state) => !isEmpty(state.sponsoredPosts.channels.data),
      userSettings: (state) => state.application.user.settings,
    }),
    {
      onLoadCampaignMatches: loadCampaignMatches,
      onLoadAllCampaigns: loadAllCampaigns,
      onSelectCampaignTypeFilter: changeCampaignTypeFilter,
      onEnableCampaignEmails: enableCampaignEmails,
    },
  ),
  withLoadingGuard,
  withCampaignsFetch,
)(ExploreCampaigns);
