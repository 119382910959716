import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

function Label({ className, column, ...props }) {
  className = classNames(
    {
      'col-xs-2 col-form-label': column,
    },
    className,
  );

  return <label className={className} {...props} />; // eslint-disable-line jsx-a11y/label-has-for
}

Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  column: PropTypes.bool,
};

Label.defaultProps = {
  column: false,
};

export default Label;
