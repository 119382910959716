import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  defaultProps,
  withReducer,
  withHandlers,
  mapProps,
} from 'recompose';
import noop from 'lodash/noop';
import omit from 'lodash/omit';

import redux from './redux';

/**
 * A "Wizard" component that organizes rendering multiple components
 * sequnetially after each when calling calling specific actions.
 *
 * Works with redux.
 */

function WizardStep({ disabled, children, ...props }) {
  // extract `disabled` so it's not passed down
  const updatedChildren = React.Children.map(children, (child) =>
    React.cloneElement(child, props),
  );

  return <div>{updatedChildren}</div>;
}

WizardStep.__klass_id = Math.random();

WizardStep.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

WizardStep.defaultProps = {
  disabled: false,
  children: null,
};

class Wizard extends React.PureComponent {
  get totalSteps() {
    const { children } = this.props;

    return React.Children.count(children);
  }

  getStepProps() {
    const { children } = this.props;

    return React.Children.toArray(children).map((child) => ({
      ...omit(child.props, ['children']),
      /**
       * NOTE @alexspri
       *    Somehow the comparison `child.type === WizardStep` stopped
       *    to work. So this hack should ensure the child type check works
       *    again.
       */
      isWizardStep: child.type.__klass_id === WizardStep.__klass_id,
    }));
  }

  findAvailableStep({ direction = 1 } = {}) {
    const { step } = this.props;
    const stepProps = this.getStepProps();

    let nextStep;
    let stepIndex = step;
    let forwarding = true;
    let valid = false;
    while (nextStep === undefined) {
      stepIndex = forwarding ? stepIndex + direction : stepIndex - direction;

      if (!stepProps[stepIndex]) {
        // we reaced the end
        if (forwarding) {
          // it was the first time
          forwarding = false;
          continue; // eslint-disable-line no-continue
        } else {
          // it is the back-direction
          break;
        }
      }

      // skip disabled `Steps`
      if (stepProps[stepIndex].isWizardStep && stepProps[stepIndex].disabled) {
        continue; // eslint-disable-line no-continue
      }

      // all is fine we take it
      valid = true;
      nextStep = stepIndex;
    }

    // for now we just log an error, but return at least an existing step
    if (nextStep === undefined) {
      nextStep = step + direction;
    }

    // add boundary checks
    if (nextStep >= this.totalSteps) {
      nextStep = this.totalSteps - 1;
    } else if (nextStep < 0) {
      nextStep = 0;
    }

    return { nextStep, prevStep: step, last: !forwarding, valid };
  }

  handleNextStep = () => {
    const { id, onSetStep, onSuccess } = this.props;

    const { nextStep, last } = this.findAvailableStep({ direction: 1 });

    // `findAvailableStep` has boundary checks and will not return a too big
    if (last) {
      return onSuccess({ id });
    }

    return onSetStep({ id, step: nextStep });
  };

  handlePrevStep = () => {
    const { id, onSetStep } = this.props;

    const { nextStep, prevStep } = this.findAvailableStep({ direction: -1 });

    // `findAvailableStep` has boundary checks and will not return a too small value
    if (nextStep === prevStep) {
      return null;
    }

    return onSetStep({ id, step: nextStep });
  };

  dispatchNextAvailableStep() {
    const { id, onSetStep } = this.props;

    // dispatch, that we should render another step
    const { nextStep } = this.findAvailableStep();
    onSetStep({ id, step: nextStep });

    return nextStep;
  }

  render() {
    const { step, children, props: childProps } = this.props;

    const stepChildren = React.Children.toArray(children);
    // We're going to be rendering one child at a time. The `step` prop is the
    // index of the child to render.
    let givenChild = stepChildren[step];

    // NOTE
    //    We're trying to access a wizard step which somehow doesn't exist
    //    anymore.
    if (!givenChild || givenChild.props.disabled) {
      const nextStep = this.dispatchNextAvailableStep();

      // optimistically render nextStep already
      givenChild = stepChildren[nextStep];
    }

    const isLastStep = this.props.step === this.totalSteps - 1;

    // Inject `onNextStep` and `onPrevStep` props into child component. The
    // child can call these functions to signal control-flow changes to the
    // Wizard.
    const updatedChild = React.cloneElement(givenChild, {
      ...childProps,
      onNextStep: this.handleNextStep,
      onPrevStep: this.handlePrevStep,
      isLastStep,
    });

    return <div className="wizard">{updatedChild}</div>;
  }
}

Wizard.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
  step: PropTypes.number,
  props: PropTypes.object, // props that are passed to the rendered child
  onSetStep: PropTypes.func,
  onSuccess: PropTypes.func,
};

Wizard.defaultProps = {
  children: null,
  step: 0,
  props: {},
  onSetStep: noop,
  onSuccess: noop,
};

Wizard.Step = WizardStep;
export default Wizard;

// for the lazy ones we export also a fully state managed version
export const ManagedWizard = compose(
  defaultProps({ id: 'default' }),
  withReducer('wizard', 'dispatch', redux.reducers.handleSteps('default'), {}),
  withHandlers({
    onSetStep: (props) => (e) =>
      props.dispatch(redux.actions.setStep('default')(e)),
  }),
  mapProps(({ wizard, ...props }) => ({
    ...props,
    step: redux.selectors.getStep(wizard, { id: props.id }),
  })),
)(Wizard);
