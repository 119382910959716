import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { changeLanguage } from 'source/actions/account';
import { loadUser, acceptTerms } from 'source/actions/acceptTerms';
import { loadApplicationState } from 'source/actions/application';

import AcceptTerms from 'source/components/gateway/acceptTerms';

const mapStateToProps = createStructuredSelector({
  language: (state) => state.application.locale,
  languageOptions: (state) => state.account.languageOptions,
});

const mapDispatchToProps = {
  onMount: loadUser,
  onAgree: () => (dispatch) =>
    dispatch(acceptTerms()).then(() => dispatch(loadApplicationState())),
  onChangeLanguage: changeLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptTerms);
