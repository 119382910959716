import React from 'react';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import * as Sentry from '@sentry/browser';

import config from 'config';
import { hot } from 'react-hot-loader/root';

import routes from './routes';
import reducer from './reducers';

import createApplicationStore from './store';

if (!config.sentry.disabled) {
  Sentry.init({
    dsn: config.sentry.dsn,
  });
}

const store = createApplicationStore(reducer, browserHistory);
const history = syncHistoryWithStore(browserHistory, store);

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>{routes}</Router>
    </Provider>
  );
}

export default hot(App);
