import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import QR from 'qrcode';
import { FormattedMessage } from 'react-intl';

class QRCode extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.updateImageSrc();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.data !== prevProps.data ||
      this.props.options !== prevProps.options
    ) {
      this.updateImageSrc();
    }
  }

  options = {
    type: 'image/png',
    margin: 0,
    quality: 0.5,
  };

  updateImageSrc = () => {
    const { data, options: optsFromProps } = this.props;

    if (!data || !this.containerRef || !this.containerRef.current) {
      return;
    }

    const opts = {
      ...this.options,
      ...optsFromProps,
    };

    QR.toDataURL(data, opts, (err, base64Data) => {
      if (err) {
        return;
      }

      this.containerRef.current.style.backgroundImage = `url('${base64Data}')`;
    });
  };

  render() {
    const { className, data, options, ...otherProps } = this.props;

    if (isEmpty(data)) {
      return (
        <div>
          <FormattedMessage
            id="common.qrCode.missingData"
            defaultMessage="Error generating QR Code"
          />
        </div>
      );
    }

    return (
      <div
        ref={this.containerRef}
        className={cx('scaled-background-image', className)}
        {...otherProps}
      />
    );
  }
}

QRCode.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  options: PropTypes.shape({
    type: PropTypes.string,
    margin: PropTypes.number,
    quality: PropTypes.number,
  }),

  className: PropTypes.string,
};

QRCode.defaultProps = {
  options: {},
  className: '',
};

export default QRCode;
