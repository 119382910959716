import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from 'source/components/common/icon';

import { typesToLabel } from './campaignCardHelpers';

function CampaignTypeLabel({ type }) {
  return (
    <div className={cx('sponsored-posts-campaign-card-type', 'label', type)}>
      <Icon name={type} />
      &nbsp;&nbsp;<span>{typesToLabel[type]}</span>
    </div>
  );
}

CampaignTypeLabel.propTypes = {
  type: PropTypes.string.isRequired,
};

export default CampaignTypeLabel;
