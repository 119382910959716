import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { CountrySelection, LanguageSelection } from 'source/components/common';

import { withGatewayRoot } from './root';

class CheckProfile extends Component {
  constructor(...props) {
    super(...props);

    this.state = {
      country: '-',
      language: '-',
    };
  }

  handleFormChange = (e) => {
    const selected = { [e.target.id]: e.target.value };

    this.setState(selected);
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    this.props.onSubmit(this.props.id, this.state);
    this.props.onRedirect();
  };

  shouldSubmit = () => {
    const { country, language } = this.state;

    return country !== '-' && language !== '-';
  };

  render() {
    const { id, languageOptions: languages } = this.props;

    const { country, language } = this.state;

    return (
      <div className="gateway__card gateway__card--profile">
        <h5>
          <FormattedMessage
            id="accountSettings.check.title"
            defaultMessage="Please answer the following questions"
          />
        </h5>
        <p className="m-t-1 m-b-1 text-muted">
          <FormattedMessage
            id="accountSettings.check.text"
            defaultMessage="In order to improve our service, we now require information about your country of residence. Please select a country as well as confirm your preferred language."
          />
        </p>
        <form
          id={id}
          onChange={this.handleFormChange}
          onSubmit={this.handleFormSubmit}
        >
          <fieldset className="form-group">
            <fieldset className="form-group">
              <label htmlFor="country">
                <FormattedMessage
                  id="accountSettings.check.country"
                  defaultMessage="In what country do you reside?"
                />
              </label>
              <CountrySelection
                id="country"
                onChange={noop}
                value={country}
                emptyOption
              />
            </fieldset>
            <fieldset className="form-group">
              <label htmlFor="language">
                <FormattedMessage
                  id="accountSettings.check.language"
                  defaultMessage="What language do you prefer?"
                />
              </label>
              <LanguageSelection
                id="language"
                languages={languages}
                onChange={noop}
                value={language}
                emptyOption
              />
            </fieldset>
          </fieldset>
          <button
            className="m-t-2 btn btn-primary"
            type="submit"
            disabled={!this.shouldSubmit()}
          >
            <FormattedMessage
              id="accountSettings.check.submit"
              defaultMessage="Proceed to my account"
            />
          </button>
        </form>
      </div>
    );
  }
}

CheckProfile.propTypes = {
  id: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRedirect: PropTypes.func,
  languageOptions: PropTypes.array.isRequired,
};

CheckProfile.defaultProps = {
  onRedirect: noop,
};

export default withGatewayRoot(CheckProfile);
