import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { compose, withHandlers } from 'recompose';
import noop from 'lodash/noop';
import find from 'lodash/find';

import * as CustomPropTypes from 'source/utils/propTypes';

import Category from './category';
import { onCategoryChange } from './utils';

// test if to arrays are the same (shallow compares)
const arrayShallowEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const l = arr1.length;
  for (let i = 0; i < l; i += 1) {
    const val = arr1[i];
    if (!arr2.includes(val)) {
      return false;
    }
  }

  return true;
};

/**
 * validates the current `selectedSubCategories` and returns a new
 * array if `subCategories` have to change.
 */
const getSelectedCategories = (subCategories = [], selectedCategories = []) => {
  const codes = subCategories.reduce(
    (memo, { subCategories }) => [
      ...memo,
      ...subCategories.map(({ code }) => code),
    ],
    [],
  );

  const nextSelectedCategories = selectedCategories.filter((c) =>
    codes.includes(c.code),
  );

  const currentCodes = selectedCategories.map((c) => c.code);
  const nextCodes = nextSelectedCategories.map((c) => c.code);

  return arrayShallowEqual(currentCodes, nextCodes)
    ? selectedCategories
    : nextSelectedCategories;
};

const enhance = compose(
  withHandlers({
    handleChange: onCategoryChange,
  }),
);

class SubCategories extends React.PureComponent {
  /**
   * NOTE
   *   We need to re-evaluate the `selectedSubCategories` on mount and
   *   on update, as they depend on the `selectedCategories` (root). So
   *   if the root categories changed we need to update the "subCategroies"
   *   accordingly (remove those which don't have a selected root category).
   */
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { subCategories, selectedCategories, onChange } = this.props;

    const nextSelectedCategories = getSelectedCategories(
      subCategories,
      selectedCategories,
    );

    if (nextSelectedCategories !== selectedCategories) {
      onChange(nextSelectedCategories);
    }
  }

  // NOTE @see comment above
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps) {
    const { subCategories, selectedCategories } = nextProps;

    const { subCategories: oldSubCategories, onChange } = this.props;

    if (subCategories !== oldSubCategories) {
      const nextSelectedCategories = getSelectedCategories(
        subCategories,
        selectedCategories,
      );

      if (nextSelectedCategories !== selectedCategories) {
        onChange(nextSelectedCategories);
      }
    }
  }

  render() {
    const {
      className,
      subCategories,
      selectedCategories,
      maxCategories: maxCategoriesRaw,
      handleChange,
    } = this.props;

    const maxCategories = parseInt(maxCategoriesRaw, 10);

    return (
      <div
        className={cx('sub-categories', className)}
        style={{ display: 'flex' }}
      >
        {subCategories.map(({ rootCategory, subCategories }) => (
          <div
            className="sub-category"
            key={`root-${rootCategory.code}`}
            style={{ flexGrow: '1' }}
          >
            <h5>
              <FormattedMessage
                className="category-name"
                id={`preferences.categories.${rootCategory.code}`}
              />
            </h5>
            <ul style={{ paddingTop: '0.5rem', listStyleType: 'none' }}>
              {subCategories.map(({ code }) => {
                const selected = find(selectedCategories, { code });
                const checked = Boolean(selected);
                const disabled =
                  maxCategories > 0 &&
                  selectedCategories.length >= maxCategories &&
                  !checked;

                return (
                  <li key={`sub-${code}`}>
                    <Category
                      code={code}
                      checked={checked}
                      disabled={disabled}
                      onChange={handleChange}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        ))}
      </div>
    );
  }
}

SubCategories.propTypes = {
  className: PropTypes.string,
  subCategories: PropTypes.arrayOf(
    PropTypes.shape({
      rootCategory: CustomPropTypes.category.isRequired,
      subCategories: PropTypes.arrayOf(CustomPropTypes.category).isRequired,
    }),
  ),
  selectedCategories: PropTypes.arrayOf(CustomPropTypes.category),
  maxCategories: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
};

SubCategories.defaultProps = {
  className: '',
  subCategories: [],
  selectedCategories: [],
  maxCategories: null,
  onChange: noop,
};

export default enhance(SubCategories);
