import React from 'react';
import PropTypes from 'prop-types';
import { Field, Fields } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';

import { getSignupCountries } from 'source/i18n/signupCountries.js';
import { Input, Select, Checkbox, Choices } from './reduxFormComponents';

import { fieldPropTypes } from './propTypes';
import { isEuropeanCountry } from './validate';

/**
 * Welcome!
 *
 * If you are looking for the `TaxFields` component scroll all the way down
 * to the bottom of this file and read this code in your natural reading style,
 * bottom-to-top.
 *
 * Have a beautiful day!
 *
 * – eslint/no-use-before-define
 */

class GermanFields extends React.Component {
  shouldRenderVatNotRegisteredFields() {
    return this.props.vatNotRegistered.input.value === 'yes';
  }

  shouldRenderVatRegisteredFields() {
    return this.props.vatNotRegistered.input.value === 'no';
  }

  render() {
    return (
      <div>
        <Choices
          {...this.props.vatNotRegistered}
          labelId="accountSettings.payment.question.vatNotRegistered"
          choices={[
            { nameId: 'yes', value: 'yes' },
            { nameId: 'no', value: 'no' },
          ]}
        />

        {this.shouldRenderVatNotRegisteredFields() && (
          <div>
            <Checkbox
              labelId="accountSettings.payment.confirmation.taxDeclarationConfirmation"
              {...this.props.taxDeclarationConfirmation}
            />

            <div>
              <strong>
                <FormattedMessage
                  id="accountSettings.payment.germanTaxMessage"
                  defaultMessage="Please fill in the relevant information from one of the below:"
                />
              </strong>
            </div>
            <Input
              {...this.props.taxId}
              labelId="accountSettings.payment.taxId"
              tooltipId="accountSettings.payment.tooltip.taxId"
              placeholderId="accountSettings.payment.placeholder.taxId"
            />

            <Input
              {...this.props.taxIdCode}
              labelId="accountSettings.payment.taxIdCode"
              tooltipId="accountSettings.payment.tooltip.taxIdCode"
              placeholderId="accountSettings.payment.placeholder.taxIdCode"
            />
          </div>
        )}

        {this.shouldRenderVatRegisteredFields() && (
          <div>
            <div>
              <strong>
                <FormattedMessage
                  id="accountSettings.payment.germanTaxMessage"
                  defaultMessage="Please fill in the relevant information from one of the below:"
                />
              </strong>
            </div>
            <Input
              {...this.props.vatId}
              labelId="accountSettings.payment.vatId"
              tooltipId="accountSettings.payment.tooltip.vatId"
              placeholderId="accountSettings.payment.placeholder.vatId"
            />

            <Input
              {...this.props.taxId}
              labelId="accountSettings.payment.taxId"
              tooltipId="accountSettings.payment.tooltip.taxId"
              placeholderId="accountSettings.payment.placeholder.taxId"
            />

            <Input
              {...this.props.taxIdCode}
              labelId="accountSettings.payment.taxIdCode"
              tooltipId="accountSettings.payment.tooltip.taxIdCode"
              placeholderId="accountSettings.payment.placeholder.taxIdCode"
            />
          </div>
        )}
      </div>
    );
  }
}

GermanFields.propTypes = {
  vatNotRegistered: PropTypes.shape(fieldPropTypes).isRequired,
  taxDeclarationConfirmation: PropTypes.shape(fieldPropTypes).isRequired,
  vatId: PropTypes.shape(fieldPropTypes).isRequired,
  taxId: PropTypes.shape(fieldPropTypes).isRequired,
  taxIdCode: PropTypes.shape(fieldPropTypes).isRequired,
};

class EUFields extends React.Component {
  shouldRenderHasVatIdFields() {
    return Boolean(this.props.hasVatId.input.value === 'yes');
  }

  shouldRenderNoVatIdFields() {
    return Boolean(this.props.hasVatId.input.value === 'no');
  }

  render() {
    return (
      <div>
        <Choices
          {...this.props.hasVatId}
          labelId="accountSettings.payment.question.hasVatId"
          choices={[
            { nameId: 'yes', value: 'yes' },
            { nameId: 'no', value: 'no' },
          ]}
        />

        {this.shouldRenderHasVatIdFields() && (
          <Input
            {...this.props.vatId}
            labelId="accountSettings.payment.vatId"
            tooltipId="accountSettings.payment.tooltip.vatId"
            placeholderId="accountSettings.payment.placeholder.vatId"
          />
        )}

        {this.shouldRenderNoVatIdFields() && (
          <Checkbox
            labelId="accountSettings.payment.confirmation.taxDeclarationConfirmationEU"
            {...this.props.taxDeclarationConfirmation}
          />
        )}
      </div>
    );
  }
}

EUFields.propTypes = {
  hasVatId: PropTypes.shape(fieldPropTypes).isRequired,
  vatId: PropTypes.shape(fieldPropTypes).isRequired,
  taxDeclarationConfirmation: PropTypes.shape(fieldPropTypes).isRequired,
};

class LocationSpecificTaxFields extends React.Component {
  shouldRenderEUFields() {
    return (
      isEuropeanCountry(this.props.address.country.input.value) &&
      this.props.address.country.input.value !== 'DE'
    );
  }

  shouldRenderGermanFields() {
    return (
      Boolean(this.props.address.country.input.value) &&
      this.props.address.country.input.value === 'DE'
    );
  }

  render() {
    const signupCountries = getSignupCountries(this.props.intl.locale);

    return (
      <div>
        <Select
          labelId="accountSettings.payment.companyCountry"
          {...this.props.address.country}
        >
          <option value="">
            {this.props.intl.formatMessage({
              id: 'accountSettings.payment.country.select',
              defaultMessage: '– Choose country –',
            })}
          </option>
          {signupCountries.map((country) => (
            <option key={country.code} value={country.code}>
              {country.name[this.props.intl.locale]}
            </option>
          ))}
        </Select>
        {this.shouldRenderEUFields() && <EUFields {...this.props.tax} />}
        {this.shouldRenderGermanFields() && (
          <GermanFields {...this.props.tax} />
        )}
      </div>
    );
  }
}

LocationSpecificTaxFields.propTypes = {
  address: PropTypes.shape({
    country: PropTypes.shape(fieldPropTypes).isRequired,
  }).isRequired,
  tax: PropTypes.shape({
    vatNotRegistered: PropTypes.shape(fieldPropTypes).isRequired,
    taxDeclarationConfirmation: PropTypes.shape(fieldPropTypes).isRequired,
    vatId: PropTypes.shape(fieldPropTypes).isRequired,
    taxId: PropTypes.shape(fieldPropTypes).isRequired,
  }).isRequired,
};

const LocationSpecificTaxFieldsEnhanced = injectIntl(LocationSpecificTaxFields);

class TaxFields extends React.Component {
  constructor(props) {
    super(props);

    const fieldNames = ['address.country', ...props.taxFields];

    this.state = {
      fieldNames,
    };
  }

  render() {
    return (
      <div>
        <h4 className="headline-form">
          <FormattedMessage
            id="accountSettings.payment.business"
            defaultMessage="Business information"
          />
        </h4>

        <Field
          name="address.company"
          component={Input}
          labelId="accountSettings.payment.companyName"
          tooltipId="accountSettings.payment.tooltip.companyName"
        />

        <Fields
          names={this.state.fieldNames}
          component={LocationSpecificTaxFieldsEnhanced}
        />
      </div>
    );
  }
}

TaxFields.propTypes = {
  taxFields: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TaxFields;
