import PropTypes from 'prop-types';
import React, { Component, Children, cloneElement } from 'react';
import cx from 'classnames';
import noop from 'lodash/noop';
import omit from 'lodash/omit';

import Icon from './icon';
import Collapsible from './collapsible';

export function PanelTitle({ children, className, ...props }) {
  className = cx('panel-title', className);

  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
}

PanelTitle.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export class PanelTrigger extends Component {
  static propTypes = {
    collapsible: PropTypes.bool.isRequired,
    children: PropTypes.any,
    className: PropTypes.string,
    toggleIcon: PropTypes.bool,
    active: PropTypes.bool,
    onTogglePanel: PropTypes.func.isRequired,
  };

  static defaultProps = {
    collapsible: true,
    toggleIcon: false,
    active: true,
    onTogglePanel: noop,
  };

  renderIcon() {
    const { collapsible, toggleIcon, active, onTogglePanel } = this.props;

    if (!collapsible) {
      return null;
    }

    return (
      <div
        className="panel-icon"
        onClick={active && toggleIcon ? onTogglePanel : null}
      >
        {active ? <Icon name="arrow" /> : null}
      </div>
    );
  }

  render() {
    const { children, className, toggleIcon, active, onTogglePanel } =
      this.props;
    return (
      <div
        className={cx('panel-trigger', className, {
          'panel-trigger--active': active,
        })}
        onClick={!toggleIcon && active ? onTogglePanel : null}
      >
        <div className="panel-trigger-content">{children}</div>
        {this.renderIcon()}
      </div>
    );
  }
}

export class PanelHeader extends Component {
  static propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    className: PropTypes.string,
    allowToggle: PropTypes.bool,
    onTogglePanel: PropTypes.func.isRequired,
  };

  static defaultProps = {
    allowToggle: true,
    onTogglePanel: noop,
  };

  renderTitle() {
    const { title } = this.props;

    if (title == null) {
      return null;
    }

    return <PanelTitle>{title}</PanelTitle>;
  }

  render() {
    const { children, className, allowToggle, ...props } = this.props;

    return (
      <PanelTrigger
        className={cx('panel-heading', className)}
        active={allowToggle}
        {...props}
      >
        {this.renderTitle()}
        {children}
      </PanelTrigger>
    );
  }
}

export function PanelContent({ className, collapsed, noAnimation, ...props }) {
  className = cx('panel-content', className);

  const divProps = omit(props, ['onTogglePanel', 'collapsible']);

  return (
    <Collapsible collapsed={collapsed} noAnimation={noAnimation}>
      <div className={className} {...divProps} />
    </Collapsible>
  );
}

PanelContent.propTypes = {
  className: PropTypes.string,
  collapsed: PropTypes.bool.isRequired,
  noAnimation: PropTypes.bool,
};

PanelContent.defaultProps = {
  collapsed: false,
  noAnimation: false,
};

export class Panel extends Component {
  static propTypes = {
    collapsible: PropTypes.bool.isRequired,
    collapsed: PropTypes.bool.isRequired,
    children: PropTypes.any,
    className: PropTypes.string,
    onToggle: PropTypes.func.isRequired,
    name: PropTypes.string,
  };

  static defaultProps = {
    collapsed: false,
    collapsible: true,
    onToggle: noop,
  };

  handleToggle = () => {
    const { collapsible, onToggle, name } = this.props;

    if (!collapsible) {
      return null;
    }

    return onToggle(name);
  };

  renderChildren() {
    const { children, collapsed, collapsible } = this.props;

    return Children.map(children, (child, key) =>
      cloneElement(child, {
        onTogglePanel: this.handleToggle,
        collapsed: !collapsible ? false : collapsed,
        collapsible,
        key,
      }),
    );
  }

  render() {
    const { className, collapsible, collapsed } = this.props;

    return (
      <div className={cx('panel', { collapsible, in: !collapsed }, className)}>
        {this.renderChildren()}
      </div>
    );
  }
}

class Accordion extends Component {
  static propTypes = {
    defaultPanelName: PropTypes.string,
    panelName: PropTypes.string,
    onTogglePanel: PropTypes.func.isRequired,
    children: PropTypes.any,
    className: PropTypes.string,
  };

  static defaultProps = {
    onTogglePanel: noop,
  };

  constructor(props) {
    super(props);

    const { defaultPanelName } = this.props;

    this.state = {
      panelName: defaultPanelName,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ panelName }) {
    const { panelName: statePanelName } = this.state;
    const { panelName: oldPanelName } = this.props;

    if (oldPanelName !== panelName && statePanelName !== panelName) {
      this.setState({ panelName });
    }
  }

  handleTogglePanel = (name) => {
    const { onTogglePanel } = this.props;
    const { panelName } = this.state;

    // Close panel if already open.
    const nextPanelName = panelName === name ? null : name;

    this.setState({ panelName: nextPanelName });

    return onTogglePanel(nextPanelName);
  };

  renderChildren() {
    const { panelName } = this.state;
    const { children } = this.props;

    return Children.toArray(children)
      .filter((c) => c)
      .map((child, key) => {
        const { name } = child.props;

        // Let's assume we are dealing with panels only. So we can simply pass collapsed property to it.
        return cloneElement(child, {
          collapsed: name !== panelName,
          onToggle: this.handleTogglePanel,
          key,
        });
      });
  }

  render() {
    const { className } = this.props;

    return (
      <div className={cx('accordion', className)}>{this.renderChildren()}</div>
    );
  }
}

export default Accordion;
