import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

function FormError({ error }) {
  return (
    <small className="text-danger">
      <FormattedMessage id={error.messageId} values={error.values || {}} />
    </small>
  );
}

FormError.propTypes = {
  error: PropTypes.shape({
    messageId: PropTypes.string.isRequired,
    values: PropTypes.object,
  }).isRequired,
};

export default FormError;
