import { connect } from 'react-redux';
import { lifecycle, compose } from 'recompose';

import { loadPayments } from './actions';
import OpenPayments from './index';
import mapStateToProps from './mapStateToProps';

export default compose(
  connect(mapStateToProps, { onLoadPayments: loadPayments }),
  lifecycle({
    componentDidMount() {
      if (!this.props.paymentsRequestState.data) {
        this.props.onLoadPayments(this.props.selectedYear);
      }
    },
    componentDidUpdate(prevProps) {
      if (this.props.selectedYear !== prevProps.selectedYear) {
        this.props.onLoadPayments(this.props.selectedYear);
      }
    },
  }),
)(OpenPayments);
