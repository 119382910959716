import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapProps, compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import { loadChannels } from 'source/actions/sponsoredPosts';

import { getAsyncHasReadyOrPendingChannel } from 'source/selectors/sponsoredPosts';

import { ProgressBar } from 'source/components/common';

class SponsoredPostsRoot extends React.Component {
  componentDidMount() {
    this.props.onLoadChannels();
  }

  render() {
    const { loaded } = this.props;

    // @sean TODO Let components deal with loading state
    if (!loaded) {
      return <ProgressBar />;
    }

    return this.props.children;
  }
}

SponsoredPostsRoot.propTypes = {
  loaded: PropTypes.bool.isRequired,
  children: PropTypes.any,
  onLoadChannels: PropTypes.func.isRequired,
};

SponsoredPostsRoot.defaultProps = {
  children: null,
};

const withLoadedState = mapProps(({ hasReadyOrPendingChannel, ...props }) => ({
  ...props,
  loaded: hasReadyOrPendingChannel.loaded,
}));

export default compose(
  connect(
    createStructuredSelector({
      hasReadyOrPendingChannel: getAsyncHasReadyOrPendingChannel,
    }),
    {
      onLoadChannels: loadChannels,
    },
  ),
  withLoadedState,
)(SponsoredPostsRoot);
