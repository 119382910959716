import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SignOut from 'source/components/gateway/signout';

import { logout, changeLanguage } from 'source/actions/account';

// Logout is set up currently such that it is the logout screen which
// is responsible for performing the acutal logout request
const withLogout = (Component) => {
  class WithLogout extends React.Component {
    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount() {
      this.props.logout();
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  WithLogout.propTypes = {
    logout: PropTypes.func.isRequired,
  };

  return WithLogout;
};

const mapStateToProps = (state) => ({
  language: state.application.locale,
  languageOptions: state.account.languageOptions,
});

const mapDispatchToProps = {
  logout,
  onChangeLanguage: changeLanguage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLogout(SignOut));
