import PropTypes from 'prop-types';
import { compose, setPropTypes, withHandlers } from 'recompose';
import { SubmissionError } from 'redux-form';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

/**
 * NOTE @alexspri
 *    The request actions unfortunately eat up errors, so we have to do manual
 *    error validation again.
 *
 *    We also directly wrap the error into a redux-form `SubmissionError`, so
 *    the form data is better handled.
 */
const errorInspection = (res) => {
  if (res && res.status >= 400) {
    const _error = new Error(res.data.message);
    _error.statusCode = res.data.statusCode;

    throw new SubmissionError({ _error });
  }

  return res;
};

/**
 * The `onSubmit` action creator for the /v2/users/{userId}/billingInformation
 * endpoints for the `PaymentForm` redux-form component.
 */
const onSubmitCreator =
  ({ onPostUserBillingInformation, onPatchUserBillingInformation }) =>
  (payload, dispatch, { userData }) => {
    /**
     * NOTE @alexspri
     *    If the user (`userData`) does not have billing information configured
     *    yet (there is no `billingInformation` property on the `userData`
     *    object), we have to create it (POST request) and otherwise update it
     *    (PATCH request).
     */
    const request = isNil(get(userData, 'billingInformation'))
      ? onPostUserBillingInformation
      : onPatchUserBillingInformation;

    return Promise.resolve(payload).then(request).then(errorInspection);
  };

/**
 * Enhancer for the `PaymentForm` which adds an `onSubmit` handler.
 */
const withOnSubmit = compose(
  setPropTypes({
    onPostUserBillingInformation: PropTypes.func.isRequired,
    onPatchUserBillingInformation: PropTypes.func.isRequired,
    userData: PropTypes.shape({
      billingInformation: PropTypes.any,
    }),
  }),
  withHandlers({
    onSubmit: onSubmitCreator,
  }),
);

export default withOnSubmit;
