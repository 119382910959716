import { checkVAT, countries } from 'jsvat';

// `jsvat` by default supports a weird country called 'Europe' which allows
// VAT ID's that start with the prefix `EU`.
// All other supported countries match the ones we do want.
const supportedCountries = countries.filter(
  (country) => country.name !== 'Europe',
);

/**
 * Validates the format of a European VAT ID.
 *
 * @param  {string} vatId       The VAT ID
 * @param  {string=} countryCode       The VAT ID
 *
 * @return {boolean}            `true` if the VAT ID is valid, `false` otherwise
 */
export default function isValidVatId(vatId, countryCode) {
  let jsVatResult = {};

  if (countryCode) {
    const country = countries.find((country) =>
      country.codes.includes(countryCode),
    );
    if (!country) {
      return false;
    }
    jsVatResult = checkVAT(vatId, [country]);
  } else {
    jsVatResult = checkVAT(vatId, supportedCountries);
  }

  return jsVatResult.isValid;
}
