import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import noop from 'lodash/noop';

class ConnectButton extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    messageId: PropTypes.string,
    platform: PropTypes.string,
    disconnect: PropTypes.bool,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    messageId: null,
    platform: '',
    disconnect: false,
    onClick: noop,
  };

  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onClick({ id: this.props.id, platform: this.props.platform });
  };

  render() {
    let messageId;

    if (this.props.messageId) {
      messageId = this.props.messageId;
    } else {
      messageId = this.props.disconnect
        ? `channels.${this.props.platform}.disconnect`
        : `channels.${this.props.platform}.connect`;
    }

    return (
      <button className="btn btn-primary" onClick={this.handleClick}>
        <FormattedMessage id={messageId} />
      </button>
    );
  }
}

export default ConnectButton;
