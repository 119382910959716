import { combineReducers } from 'redux';

import { loadStateReducer } from '@blogfoster/redux-async-utils';

import { actionTypes } from './actions';

// Selectors

export const selectors = {
  getAsyncCampaignMatches: (state) => state.campaignMatches,
  getAsyncAllCampaigns: (state) => state.allCampaigns,
  getCampaignTypeFilter: (state) => state.campaignTypeFilter,
};

// Reducers

const campaignMatchesReducer = loadStateReducer(
  actionTypes.CAMPAIGN_MATCHES_FETCH,
);

const allCampaignsReducer = loadStateReducer(actionTypes.CAMPAIGNS_FETCH);

const campaignTypeFilterReducer = (state = 'all', action) =>
  action.type === actionTypes.CAMPAIGN_TYPE_FILTER_CHANGE
    ? action.payload
    : state;

export default combineReducers({
  campaignMatches: campaignMatchesReducer,
  allCampaigns: allCampaignsReducer,
  campaignTypeFilter: campaignTypeFilterReducer,
});
