import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

function ProgressButton({
  style,
  caption,
  progressClass,
  children,
  onClick,
  ...rest
}) {
  return (
    <button
      type="submit"
      className={cx('btn', 'btn-primary', progressClass)}
      onClick={onClick}
      style={style}
      {...rest}
    >
      {caption || children}
    </button>
  );
}

ProgressButton.propTypes = {
  style: PropTypes.object,
  caption: PropTypes.string,
  progressClass: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
};

ProgressButton.defaultProps = {
  style: {},
  caption: '',
  progressClass: '',
  onClick: () => {},
  children: null,
};

export default ProgressButton;
