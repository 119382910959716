import { asyncActionCreator } from '@blogfoster/redux-async-utils';

import { actionAuthRequest } from 'source/utils/axios';
import constants from 'source/constants';

export const loadChannels = asyncActionCreator(
  constants.CHANNELS_FETCH,
  () => (dispatch, getState) =>
    actionAuthRequest(dispatch, getState).get('/v2/channels', {
      params: {
        fields: JSON.stringify([
          'id',
          'name',
          'details',
          'data',
          'pricePerPost',
          'pricePerPostV2',
          'platform',
          'ready',
          'readyState',
          'metrics',
          'referenceChannel',
          'extension',
          'keyMetrics',
        ]),
      },
    }),
);
