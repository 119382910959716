import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { compose, defaultProps } from 'recompose';

import { ActivityStreamBody } from 'source/components/common/activityStream';
import { SubmitButton } from 'source/components/common/button';
import Form, {
  FormGroup,
  FormActions,
  Textarea,
  Input,
  FormControlFeedback,
} from 'source/components/common/form';
import { MessageCard } from 'source/components/common/message';
import { Alert } from 'source/components/common';
import { hasAttributes } from 'source/utils';
import Markdown from 'source/components/common/markdown';

import {
  VerificationInactive,
  VerificationActive,
  VerificationPending,
  VerificationRejected,
  VerificationDone,
  VerificationExpired,
} from './verificationSteps';

// Components

/* eslint-disable react/prop-types */
function VerificationForm({ intl, form, formName, onChange, onSubmit }) {
  const data = form[formName] || {};
  const notEnoughData = isEmpty(data.caption) || isEmpty(data.videoUrl);

  return (
    <Form id={formName} onChange={onChange} onSubmit={onSubmit}>
      {data.errors._error && <Alert type="error" textId={data.errors._error} />}
      <FormGroup>
        <p className="m-t-1">
          <strong>
            <FormattedMessage
              id="youtube.campaigns.verification.media.name"
              defaultMessage="Video URL:"
            />
          </strong>
          <span>
            <FormattedMessage
              id="youtube.campaigns.verification.media.message"
              defaultMessage="Enter the URL of your video"
            />
          </span>
        </p>
        <Input
          id="videoUrl"
          placeholder={intl.formatMessage({
            id: 'youtube.campaigns.verification.media.placeholder',
            defaultMessage: 'https://www.youtube.com/watch?v=abc123',
          })}
          value={data.videoUrl || ''}
          danger={!!get(data, 'errors.videoUrl')}
          className="m-t-1"
        />
      </FormGroup>
      <FormGroup hasDanger={hasAttributes(data.errors, ['caption'])}>
        <p className="m-t-1">
          <strong>
            <FormattedMessage
              id="youtube.campaigns.verification.caption.name"
              defaultMessage="Video Description and Tags:"
            />
          </strong>
          <span>
            <FormattedMessage
              id="youtube.campaigns.verification.caption.message"
              defaultMessage="Enter the YouTube video description as well as the tags. Please take into account any requirements given in the briefing and tasks."
            />
          </span>
        </p>
        <Textarea
          id="caption"
          placeholder={intl.formatMessage({
            id: 'youtube.campaigns.verification.caption.placeholder',
            defaultMessage: 'Video description and tags',
          })}
          rows="5"
          value={data.caption || ''}
          danger={!!get(data, 'errors.caption')}
          className="m-t-1"
        />

        <FormControlFeedback
          message={
            get(data, 'errors.caption')
              ? intl.formatMessage({ id: get(data, 'errors.caption') })
              : null
          }
        />
      </FormGroup>
      <FormActions>
        <SubmitButton
          disabled={
            hasAttributes(data.errors) || notEnoughData || data.submitting
          }
        >
          <FormattedMessage
            id="sponsoredPosts.campaigns.verification.submit"
            defaultMessage="Send"
          />
        </SubmitButton>
      </FormActions>
    </Form>
  );
}

const createPreviewHelpLink = defineMessages({
  url: {
    id: 'createPreviewHelp.youtube.url',
    defaultMessage:
      'https://www.academy.blogfoster.com/en/how-to-youtube-collaboration-verified/',
  },

  label: {
    id: 'createPreviewHelp.youtube.label',
    defaultMessage: 'this tutorial',
  },
});

function Active(props) {
  return (
    <VerificationActive>
      <ActivityStreamBody>
        <MessageCard type="ask">
          <p>
            <FormattedMessage
              id="youtube.campaigns.verification.message"
              defaultMessage="Almost there! Send us a preview of your post."
            />
          </p>
          <p>
            <Markdown
              typeName="span"
              markdown={props.intl.formatMessage({
                id: 'youtube.campaigns.verification.message.details',
                defaultMessage:
                  '**Do not yet publish your video!**\n\nInstead, please mark the video as “Unlisted” in order to allow us to review your post before publication.',
              })}
            />
          </p>
          <p>
            <strong>
              <FormattedMessage
                id="sponsoredPosts.campaigns.verification.help"
                defaultMessage="Not sure what to do?"
              />
            </strong>
            <span>
              <FormattedMessage
                id="sponsoredPosts.campaigns.verification.help.message"
                defaultMessage="Check out {createPreviewHelpLink} in our Academy."
                values={{
                  createPreviewHelpLink: (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={props.intl.formatMessage(createPreviewHelpLink.url)}
                    >
                      <FormattedMessage {...createPreviewHelpLink.label} />
                    </a>
                  ),
                }}
              />
            </span>
          </p>
        </MessageCard>
        <VerificationForm {...props} />
      </ActivityStreamBody>
    </VerificationActive>
  );
}

function Pending(props) {
  return (
    <VerificationPending {...props}>
      <MessageCard type="info">
        <strong>
          <FormattedMessage
            id="youtube.campaigns.verification.pending"
            defaultMessage="We have received your post."
          />
        </strong>
        <span>
          <FormattedMessage
            id="youtube.campaigns.verification.pending.message"
            defaultMessage="We will contact you once we complete verification."
          />
        </span>
      </MessageCard>
    </VerificationPending>
  );
}

function Rejected(props) {
  return (
    <VerificationRejected workflow={props.workflow}>
      <VerificationForm {...props} />
    </VerificationRejected>
  );
}

/* eslint-enable react/prop-types */

function YouTubeVerification(props) {
  const {
    workflow: { status },
  } = props;

  const stepsByStatus = {
    inactive: VerificationInactive,
    active: Active,
    pending: Pending,
    rejected: Rejected,
    done: VerificationDone,
    expired: VerificationExpired,
  };

  const Component = stepsByStatus[status] || VerificationInactive;

  return <Component {...props} />;
}

YouTubeVerification.propTypes = {
  formName: PropTypes.string.isRequired,
  workflow: PropTypes.shape({
    status: PropTypes.oneOf([
      'inactive',
      'active',
      'pending',
      'rejected',
      'done',
      'expired',
    ]).isRequired,
    payload: PropTypes.shape({
      showHint: PropTypes.bool,
      note: PropTypes.string,
    }),
  }).isRequired,
  form: PropTypes.object.isRequired,
  campaign: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onAction: PropTypes.any,
};

export default compose(
  defaultProps({
    formName: 'youtubeVerification',
  }),

  injectIntl,
)(YouTubeVerification);
