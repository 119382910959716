import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  lifecycle,
  withHandlers,
  getContext,
  withProps,
  branch,
  compose,
} from 'recompose';

import {
  loadWorkflow,
  uploadFile,
  toastError,
  formInit,
  formAction,
  formSubmit,
  formChange,
  formValidate,
  formError,
} from '../components/Workflow/actions';
import { setPreviousPath } from '../../accountSettings/actions';

import {
  getAsyncWorkflow,
  getCampaignForm,
  getToastError,
  getUser,
} from '../selectors';

import Workflow from '../components/Workflow';

// @sean NOTE Many of the following functions extend the container with generic form
//       handling logic. This can later be abstracted out if need be

const withWorkflowFetch = lifecycle({
  componentDidMount() {
    const { campaign, application, onLoadWorkflow } = this.props;
    onLoadWorkflow(campaign.id, application && application.id);
  },
});

// Wraps onSubmit handler such that it also has access to
// the application and campaign
const withSubmitInjections = withHandlers({
  onSubmit:
    ({ onSubmit, campaign, application, workflow }) =>
    (...args) =>
      onSubmit(...args, { campaign, application, workflow }),
});

const withValidateInjections = withHandlers({
  onValidate:
    ({ onValidate, campaign, application }) =>
    (...args) =>
      onValidate(...args, { campaign, application }),
});

// Wrap onSubmit with extended form processing capabilities, including
// `submitting` state and validation
// Assumes all onSubmit handlers are HTTP requests!
/* eslint-disable no-unused-expressions,no-console */
const withSubmitHandler = withHandlers({
  onSubmit:
    ({
      onSubmit,
      onSubmitSuccess,
      onSubmitFailure,
      onChange,
      onValidate,
      onError,
    }) =>
    (formName, ...args) =>
      onValidate(formName)
        // If valid, submit
        .then(() => {
          onChange(formName, 'submitting', true);
          onSubmit(formName, ...args)
            .then((response) => {
              onChange(formName, 'submitting', false);
              if (response.status >= 400) {
                onChange(formName, 'submitError', response);
                onSubmitFailure && onSubmitFailure(formName, response);
              } else {
                onSubmitSuccess && onSubmitSuccess(formName, response);
              }
            })
            .catch((err) => {
              onChange(formName, 'submitting', false);
              console.error(`Form ${formName} failed to submit`, err);
            });
        })
        // If invalid, dispatch errors
        .catch((errors) => {
          onError(formName, errors);
        }),
});
/* eslint-enable no-unused-expressions,no-console */

// Inject data deps into action handler
const withActionInjections = withHandlers({
  onAction:
    ({ onAction, campaign, application }) =>
    (formName, actionName, payload) =>
      onAction(formName, actionName, payload, { campaign, application }),
});

// Also wrap action handlers with request state information
// Assumes all onAction handlers are HTTP requests!
const withActionHandler = withHandlers({
  onAction:
    ({ onAction, onChange }) =>
    (formName, actionName, payload) =>
      onAction(formName, actionName, payload).then((response) => {
        if (response.status >= 400) {
          onChange(formName, `${actionName}Error`, response);
        }
        return response;
      }),
});

// Extends component form capabilities to allow form initialization
const withFormInit = lifecycle({
  componentDidMount() {
    const { campaign, matches, application, user, onInit } = this.props;
    onInit({ campaign, matches, application, user });
  },
});

const withRouter = getContext({
  router: PropTypes.object.isRequired,
});

// We want to reload the workflow whenever the application is patched
// to always show the latest workflow state.
// We also want to redirect when the application is created
const withSubmitSuccess = withHandlers({
  onSubmitSuccess:
    ({ onLoadWorkflow, router, campaign, application }) =>
    (formName, response) => {
      // If this is the application form, redirect
      if (formName === 'application') {
        router.push(
          `/sponsored-posts/applications/${get(response, 'data.id')}`,
        );
        // If no redirect, reload the workflow. Here we always have the
        // application
      } else {
        onLoadWorkflow(campaign.id, application.id);
      }
    },
});

const withSubmitFailure = withHandlers({
  onSubmitFailure:
    ({ campaign, application, onToastError, onLoadWorkflow }) =>
    (formName, response) => {
      let messageId = 'errors.message.generic';

      if (formName === 'accept') {
        const errorMessage = get(response, 'data.message', '');

        if (errorMessage.includes('additional terms do not match')) {
          messageId =
            'sponsoredPosts.campaigns.application.updateError.additionalTerms';
        } else {
          // Here we assume our request is well formed and take all publishing
          // date PATCH failures to indicate a date conflict
          messageId =
            'sponsoredPosts.campaigns.application.updateError.conflict';
        }
        // Reload workflow to get updated data
        onLoadWorkflow(campaign.id, application.id);
      }

      onToastError({
        messageId,
        error: response.data,
      });
    },
});

// Since this is a "polymorphic" container, we may either
// have a campaign with matches or an application with nested
// campaign. Here we normalize the shape
const withDataDependencies = branch(
  (props) => Boolean(props.application),
  withProps(({ application }) => ({
    campaign: application.campaign,
    matches: [],
  })),
);

export default compose(
  connect(
    createStructuredSelector({
      submitting: () => false,
      workflow: getAsyncWorkflow,
      toastError: getToastError,
      form: getCampaignForm,
      user: getUser,
    }),
    {
      onLoadWorkflow: loadWorkflow,
      onFileUpload: uploadFile,
      onToastError: toastError,
      onInit: formInit,
      onSubmit: formSubmit,
      onValidate: formValidate,
      onError: formError,
      onAction: formAction,
      onChange: formChange,
      onTrackLinkToAccountSettings: setPreviousPath,
    },
  ),
  withRouter,
  withDataDependencies,
  withWorkflowFetch,
  withSubmitSuccess,
  withSubmitFailure,
  withSubmitInjections,
  withValidateInjections,
  withSubmitHandler,
  withActionInjections,
  withActionHandler,
  withFormInit,
)(Workflow);
