import some from 'lodash/some';

/**
 * copied from aurum@1.138.1
 *
 * Validates the format of a German Tax ID a.k.a. "Steuernummer". Both the old
 * and the new format is supported. For more details about the Tax ID format,
 * check out this Wikipedia article:
 *
 * https://de.wikipedia.org/wiki/Steuernummer
 *
 * @param  {string} taxId The Tax ID
 *
 * @return {boolean}      `true` if the Tax ID is valid, `false` otherwise
 */
export default function isValidGermanTaxId(taxId) {
  if (typeof taxId !== 'string') {
    return false;
  }

  const regexList = [
    // new format (always 13 digits but with different prefixes)
    /^(5|9)\d{3}0\d{8}$/,
    /^(28|11|30|24|22|26|40|23|27|10|32|31|21|41)\d{2}0\d{8}$/,
    // old format (10 to 11 digits, separation by slash '/')
    /^\d{5}(\/|\s)\d{5}$/,
    /^\d{3}(\/|\s)\d{3}(\/|\s)\d{5}$/,
    /^\d{2}(\/|\s)\d{3}(\/|\s)\d{5}$/,
    /^\d{3}(\/|\s)\d{4}(\/|\s)\d{4}$/,
    // old format without separation
    /^\d{10}$/,
    /^\d{11}$/,
  ];
  const matchesRegex =
    (str = '') =>
    (regex) =>
      regex.test(str);

  return some(regexList, matchesRegex(taxId.trim()));
}
