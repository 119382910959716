import React from 'react';
import PropTypes from 'prop-types';
import {
  clearSubmitErrors,
  getFormValues,
  reduxForm,
  SubmissionError,
} from 'redux-form';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';
import { Link } from 'react-router';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';

import { Alert, Message, SaveProgressButton } from 'source/components/common';

import TaxFields from './taxFields';
import NameFields from './nameFields';
import AddressFields from './addressFields';
import BankAccountFields from './bankAccountFields';
import { validateValues } from './validate';
import buildPayload from './buildPayload';
import {
  withTaxFields,
  withTaxClear,
  withInitialValues,
  withOnSubmit,
} from './enhancers';

class PaymentForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { formUpdated: false };
  }

  onChange = () => {
    if (!this.state.formUpdated) {
      this.setState(() => ({
        formUpdated: true,
      }));
    }
  };

  getSaveStatus() {
    if (this.state.formUpdated) {
      // if the form was updated / touched, always show default "Save" text
      return 'default';
    }

    if (this.props.invalid) {
      // for any errors (validation or request), always show "Save failed"
      return 'failed';
    }

    // revert back to update request status for everything else
    return this.props.billingInformationSaveStatus;
  }

  validate = (values, dispatch, props) => {
    const { hasErrors, errors } = validateValues(values, props.initialValues);

    const updateState = () =>
      new Promise((resolve) =>
        this.setState(() => ({ formUpdated: false }), resolve),
      );

    const assertFormValid = () => {
      if (hasErrors) {
        throw new SubmissionError(errors);
      }
    };

    const submit = () => {
      const payload = buildPayload(values, props.initialValues);

      return this.props.onSubmit(payload, dispatch, props);
    };

    const handleGenericErrors = (err) => {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(err);
      }

      // we dont need to wrap it if it's a submission error already
      if (err instanceof SubmissionError) {
        throw err;
      }

      // for all other cases we need to wrap it so redux-form understands it
      throw new SubmissionError({ _error: err });
    };

    return Promise.resolve()
      .then(updateState)
      .then(assertFormValid)
      .then(submit)
      .catch(handleGenericErrors);
  };

  renderRedirectBack() {
    const { previousPath } = this.props;

    if (this.getSaveStatus() !== 'saved' || !previousPath) {
      return null;
    }

    return (
      <Message type="info">
        <FormattedMessage
          id="accountSettings.payment.redirectBack.message"
          defaultMessage="Thanks! Back to:"
        />{' '}
        &quot;<Link to={previousPath.pathname}>{previousPath.label}</Link>&quot;
      </Message>
    );
  }

  render() {
    const { error, handleSubmit } = this.props;

    return (
      <form
        onSubmit={handleSubmit(this.validate)}
        onChange={this.onChange}
        className="payment-form"
      >
        <TaxFields taxFields={this.props.taxFields} />
        {this.props.shouldRenderAllForms && (
          <div>
            <NameFields />
            <AddressFields />
            <BankAccountFields />
            {this.renderRedirectBack()}
            <SaveProgressButton
              style={{ marginTop: '40px' }}
              status={this.getSaveStatus()}
            />

            {error && !this.state.formUpdated && (
              <Alert
                style={{ marginTop: '20px' }}
                type="error"
                textId="accountSettings.payment.updateError"
              />
            )}
          </div>
        )}
      </form>
    );
  }
}

PaymentForm.propTypes = {
  billingInformationSaveStatus: PropTypes.string.isRequired,
  taxFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  shouldRenderAllForms: PropTypes.bool.isRequired,
  // injected by `withOnSubmit` enhancer
  onSubmit: PropTypes.func.isRequired,
  // injected by reduxForm
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),

  invalid: PropTypes.bool.isRequired,
  previousPath: PropTypes.any,
  handleSubmit: PropTypes.func.isRequired,
};

PaymentForm.defaultProps = {
  error: undefined,
  previousPath: undefined,
};

export default compose(
  pure,
  withOnSubmit,
  withTaxFields,
  withTaxClear,
  withInitialValues,
  reduxForm({
    form: 'paymentForm',
    touchOnBlur: false,
  }),

  connect(
    (state) => ({
      shouldRenderAllForms: Boolean(
        get(getFormValues('paymentForm')(state), 'address.country'),
      ),

      previousPath: get(state, 'scenes.accountSettings.previousPath'),
    }),

    {
      onClearSubmitErrors: () => clearSubmitErrors('paymentForm'),
    },
  ),
)(PaymentForm);
