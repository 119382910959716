import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import JoinBrandClubFrom from './JoinBrandClubForm';

const newReviewOrSentToClientRegexp = /^new$|^review$|^sentToClient$/;

function ThankYouMessage({ translationKey }) {
  return (
    <>
      <FormattedMessage id={`brandClubs.${translationKey}.thankYou`} />
      <Link to="/brand-clubs" className="back-link m-t-2">
        <FormattedMessage
          id="brandClubs.join.thankYou.backToOffers"
          defaultMessage="← Back to All Offers"
        />
      </Link>
    </>
  );
}

ThankYouMessage.propTypes = {
  translationKey: PropTypes.string.isRequired,
};

class JoinBrandClub extends React.PureComponent {
  constructor(props) {
    super(props);
    this.container = React.createRef();
  }

  render() {
    const { brandClub, form, onFormChange, onFormSubmit, hasEligibleChannels } =
      this.props;
    const {
      translationKey,
      theme: { joinHeaderUrl, className } = {},
      application: { status } = {},
    } = brandClub;
    const showThankYouMessage =
      form.submitted || newReviewOrSentToClientRegexp.test(status);

    return (
      <div
        className={cx('container join-container', className)}
        ref={this.container}
      >
        <div className="close-bar">
          <Link className="close-btn" to="/brand-clubs">
            <svg className="icon">
              <use xlinkHref="#icon-cross" />
            </svg>
          </Link>
        </div>
        <div className="row">
          <div className="col-md-7 left">
            <div className="join-header">
              <img src={joinHeaderUrl} alt="" />
            </div>
            <div className="brand-club-join-info">
              <FormattedMessage
                id={`brandClubs.${translationKey}.joinContent`}
              />
            </div>
          </div>
          <div className="col-md-5 right">
            {showThankYouMessage ? (
              <ThankYouMessage translationKey={translationKey} />
            ) : (
              <JoinBrandClubFrom
                brandClub={brandClub}
                form={form}
                onChange={onFormChange}
                onSubmit={onFormSubmit}
                hasEligibleChannels={hasEligibleChannels}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

JoinBrandClub.propTypes = {
  brandClub: PropTypes.shape({
    translationKey: PropTypes.string.isRequired,
    theme: PropTypes.shape({
      className: PropTypes.string.isRequired,
      joinHeaderUrl: PropTypes.string.isRequired,
    }),
    application: PropTypes.shape({
      status: PropTypes.oneOf(['new', 'review', 'sentToClient', 'approved']),
    }),
  }),
  hasEligibleChannels: PropTypes.bool,
  form: PropTypes.object.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

export default JoinBrandClub;
