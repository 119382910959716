export const radians = (degrees) => degrees * (Math.PI / 180);

// Maps a number from one range to another.
export const scale = (value, start1, stop1, start2, stop2) =>
  start2 + (stop2 - start2) * ((value - start1) / (stop1 - start1));

/**
 * Easing method of quadratic acceleration (until halfway, then deceleration)
 *
 * @param {number} t Current time
 * @param {number} b Start value
 * @param {number} c Change in value
 * @param {number} d Duration
 */
export const quadraticEasingInOut = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t -= 1;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};
