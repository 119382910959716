import React from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatic from 'hoist-non-react-statics';
import getDisplayName from 'react-display-name';
import { withRouter, locationShape, routerShape } from 'react-router';
import { compose } from 'redux';

export const withChannelHandlers = compose(withRouter, (WrappedComponent) => {
  class EnhancedComponent extends React.PureComponent {
    onChange = (field, value) => {
      this.props.onChange(this.props.id, field, value);
    };

    onErrors = (field, value) => {
      this.props.onErrors(this.props.id, field, value);
    };

    onWizardSuccess = () => {
      setTimeout(
        () => this.props.onWizardSetStep({ id: this.props.id, step: 0 }),
        3000,
      );
    };

    onInsightsUploadModalOpen = (id) =>
      this.props.onInsightsUploadModalOpen(
        id,
        this.props.location,
        this.props.router,
      );

    onInsightsUploadModalClose = (id) =>
      this.props.onInsightsUploadModalClose(
        id,
        this.props.location,
        this.props.router,
      );

    channelHandlers = {
      onChange: this.onChange,
      onErrors: this.onErrors,
      onWizardSuccess: this.onWizardSuccess,
      onInsightsUploadModalOpen: this.onInsightsUploadModalOpen,
      onInsightsUploadModalClose: this.onInsightsUploadModalClose,
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          channelHandlers={this.channelHandlers}
        />
      );
    }
  }

  EnhancedComponent.propTypes = {
    id: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    onErrors: PropTypes.func.isRequired,
    onWizardSetStep: PropTypes.func.isRequired,
    onInsightsUploadModalOpen: PropTypes.func,
    onInsightsUploadModalClose: PropTypes.func,
    // Provided by withRouter
    location: locationShape.isRequired,
    router: routerShape.isRequired,
  };

  EnhancedComponent.displayName = `withChannelHandlers(${getDisplayName(
    WrappedComponent,
  )})`;

  hoistNonReactStatic(EnhancedComponent, WrappedComponent);

  return EnhancedComponent;
});

export const channelHandlersShape = PropTypes.shape({
  onChange: PropTypes.func.isRequired,
  onErrors: PropTypes.func.isRequired,
  onWizardSuccess: PropTypes.func.isRequired,
  onInsightsUploadModalOpen: PropTypes.func,
  onInsightsUploadModalClose: PropTypes.func,
});
