import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';

const messages = defineMessages({
  missingMessage: {
    id: 'channels.insights.missingMessage',
    defaultMessage:
      '<b>Upload your latest Insights to apply to campaigns ⚠️</b>',
  },
  expiredMessage: {
    id: 'channels.insights.expiredMessage',
    defaultMessage: '<b>Upload your new Insights to apply to campaigns ⚠️</b>',
  },
  freshDateMessage: {
    id: 'channels.insights.freshDateMessage',
    defaultMessage: 'Your Insights are valid for {ageInDays} days.',
  },
  freshMessage: {
    id: 'channels.insights.freshMessage',
    defaultMessage: "You're all set ✔️",
  },
  freshAgeHint: {
    id: 'channels.insights.freshAgeHint',
    defaultMessage: 'Upload your new Insights after {ageInDays} days.',
  },
  staleAgeHint: {
    id: 'channels.insights.staleAgeHint',
    defaultMessage: 'Upload your new Insights in the next {ageInDays} days.',
  },
  staleMessage: {
    id: 'channels.insights.staleMessage',
    defaultMessage: 'Expiring soon ⚠️',
  },
  'staleMessage.1day': {
    id: 'channels.insights.staleMessage.1day',
    defaultMessage: 'You have one day left to upload your Insights.',
  },
});

export function StatusMessageHtml({ messageHtml }) {
  return <FormattedMessage {...messages[messageHtml]} />;
}

export function StatusMessage({ message }) {
  return <FormattedMessage {...messages[message]} />;
}

export function StatusMessageCombined({ messageHtml, message }) {
  return (
    <>
      {StatusMessageHtml({ messageHtml })}
      <br />
      {StatusMessage({ message })}
    </>
  );
}

export function StatusMessageDays({ messageDays, ageInDays }) {
  return <FormattedMessage {...messages[messageDays]} values={{ ageInDays }} />;
}

export function StatusMessageDaysCombined({ message, messageDays, ageInDays }) {
  return (
    <>
      {StatusMessage({ message })}
      <br />
      {StatusMessageDays({ messageDays, ageInDays })}
    </>
  );
}

StatusMessageHtml.propTypes = {
  messageHtml: PropTypes.oneOf([
    'staleMessage',
    'missingMessage',
    'expiredMessage',
  ]),
};

StatusMessage.propTypes = {
  message: PropTypes.oneOf(['staleMessage.1day', 'freshMessage']),
};

StatusMessageCombined.propTypes = {
  messageHtml: PropTypes.oneOf(['staleMessage']),
  message: PropTypes.oneOf(['staleMessage.1day']),
};

StatusMessageDays.propTypes = {
  messageDays: PropTypes.oneOf(['freshDateMessage', 'staleAgeHint']),
  ageInDays: PropTypes.number,
};

StatusMessageDaysCombined.propTypes = {
  message: PropTypes.oneOf(['staleMessage', 'freshMessage']),
  messageDays: PropTypes.oneOf(['freshDateMessage', 'staleAgeHint']),
  ageInDays: PropTypes.number,
};
