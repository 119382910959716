// eslint-disable-next-line no-unused-vars
import React from 'react';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isFeatureAvailable } from 'source/utils/features';

const Feature = ({ feature, user, hide, disabled, children, otherwise }) => {
  // user might not yet be loaded yet, then we don't show the feature
  if (!user) {
    return otherwise;
  }

  if (disabled) {
    return children;
  }

  if (!isFeatureAvailable(feature)(user) || hide) {
    return isFunction(otherwise) ? otherwise() : otherwise;
  }

  return children;
};

Feature.propTypes = {
  feature: PropTypes.string.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }),
  disabled: PropTypes.bool,
  hide: PropTypes.bool,
  otherwise: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  children: PropTypes.node,
};

Feature.defaultProps = {
  hide: false,
  disabled: false,
  otherwise: null,
  children: null,
};

const mapStateToProps = (state, ownProps) => ({
  user: ownProps.user || state.application.user,
});

export default connect(mapStateToProps)(Feature);
