import defaultTo from 'lodash/defaultTo';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { createSelector } from 'reselect';

import { languageOptions } from 'source/utils/languages';
import { getCountry } from 'source/i18n/signupCountries.js';

const getUser = (state) => state.application.user;
const getAccountSettings = (state) => state.scenes.accountSettings;
const getLegacyAccountSettings = (state) => getAccountSettings(state).legacy;
const getUserDataResponse = (state) => getAccountSettings(state).userData;
const getBillingInformationState = (state) =>
  getAccountSettings(state).billingInformationState;

const getStateLoaded = createSelector(
  getUserDataResponse,
  (userData) => Boolean(userData.data) || Boolean(userData.error),
);

const getUserData = createSelector(getUserDataResponse, (resp) =>
  isNil(resp.data) ? {} : resp.data,
);

const getBillingInformationSaveStatus = createSelector(
  getBillingInformationState,
  (billingInformationState) => {
    const saving = billingInformationState.loading;
    const saved = Boolean(billingInformationState.data);
    const failed = Boolean(billingInformationState.error);

    // eslint-disable-next-line no-nested-ternary
    return saving ? 'saving' : saved ? 'saved' : failed ? 'failed' : 'default';
  },
);

export default createSelector(
  getUser,
  getLegacyAccountSettings,
  getUserData,
  getStateLoaded,
  getBillingInformationSaveStatus,
  (
    user,
    accountSettings,
    userData,
    stateLoaded,
    billingInformationSaveStatus,
  ) => ({
    ...accountSettings,
    userData,
    stateLoaded,
    billingInformationSaveStatus,
    profile: {
      ...accountSettings.profile,
      email: defaultTo(accountSettings.profile.email, user.email),
      phone: defaultTo(accountSettings.profile.phone, user.phone),
      language: defaultTo(accountSettings.profile.language, user.language),
      yearOfBirth: defaultTo(
        accountSettings.profile.yearOfBirth,
        user.yearOfBirth,
      ),
      salutation: defaultTo(
        accountSettings.profile.salutation,
        user.salutation,
      ),
      firstname: defaultTo(accountSettings.profile.firstname, user.firstname),
      lastname: defaultTo(accountSettings.profile.lastname, user.lastname),
      streetName: defaultTo(
        accountSettings.profile.streetName,
        user.streetName,
      ),
      streetNumber: defaultTo(
        accountSettings.profile.streetNumber,
        user.streetNumber,
      ),
      postalCode: defaultTo(
        accountSettings.profile.postalCode,
        user.postalCode,
      ),
      city: defaultTo(accountSettings.profile.city, user.city),
      country: defaultTo(accountSettings.profile.country, user.country),
    },
    notifications: {
      ...accountSettings.notifications,
      recommendedCampaignEmails: defaultTo(
        accountSettings.notifications.recommendedCampaignEmails,
        user.settings.recommendedCampaignEmails,
      ),
      promotionalEmails: defaultTo(
        accountSettings.notifications.promotionalEmails,
        user.settings.promotionalEmails,
      ),
    },
  }),
);

export const userProfileSelector = createSelector(
  getUser,
  getLegacyAccountSettings,
  (user, accountSettings) => {
    const userData = pick(user, [
      'salutation',
      'firstname',
      'lastname',
      'email',
      'yearOfBirth',
    ]);

    const address = pick(user, [
      'streetName',
      'streetNumber',
      'postalCode',
      'city',
    ]);

    const languageCode = defaultTo(
      accountSettings.profile.language,
      user.language,
    );
    const languageItem = languageOptions.find((l) => l.value === languageCode);
    const language = get(languageItem, 'label');

    const countryCode = defaultTo(
      accountSettings.profile.country,
      user.country,
    );
    const countryItem = getCountry(countryCode);
    const country = get(countryItem, `name.${languageCode}`);

    return {
      ...userData,
      address,
      language,
      country,
    };
  },
);
