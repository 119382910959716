import PropTypes from 'prop-types';
import React, { Component, Children, cloneElement } from 'react';
import { pure } from 'recompose';
import cx from 'classnames';
import { VelocityComponent } from 'velocity-react';
import noop from 'lodash/noop';
import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';
import { FormattedMessage } from 'react-intl';
import config from 'config';

import * as propTypes from 'source/utils/propTypes';
import { Icon } from 'source/components/common';

import ChannelStatus from '../ChannelStatus';

class ChannelPanel extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      expanded: this.props.expanded,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { expanded } = this.props;

    if (expanded !== nextProps.expanded) {
      this.setState({ expanded: nextProps.expanded });
    }
  }

  handleClick = (e) => {
    const { disabled, onClick } = this.props;
    const { expanded } = this.state;

    e.preventDefault();

    if (disabled) {
      return null;
    }

    this.toggleExpanded();

    return onClick(this.props.id, !expanded);
  };

  toggleExpanded() {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  }

  renderBody() {
    const { bodyClassName, children } = this.props;
    const { expanded } = this.state;

    const updatedChildren = Children.map(children, (child) =>
      cloneElement(child, { expanded }),
    );

    return (
      <VelocityComponent
        animation={expanded ? 'slideDown' : 'slideUp'}
        duration={400}
      >
        <div className={cx('card-block', bodyClassName)}>{updatedChildren}</div>
      </VelocityComponent>
    );
  }

  render() {
    const {
      id,
      index,
      icon,
      status,
      platform,
      newChannel,
      readyState,
      className,
      headline,
      subHeadline,
      disabled,
      showWarning,
      screenshots,
      closeButton,
    } = this.props;
    const { expanded } = this.state;
    const { features } = config;

    const isInsightsMaintenance =
      get(features, 'insightsMaintenance.enabled', false) &&
      platform === 'website' &&
      newChannel;

    const panelClassName = cx('collapsible-panel', 'card', {
      'panel-collapsed': !expanded,
    });

    const headerClassName = cx(
      'collapsible-panel-header',
      'card-header',
      className,
      {
        'collapsible-panel-header-disabled': disabled,
      },
    );

    const indexClassName = cx('collapsible-index', 'tag', 'm-r-1', {
      'tag-primary': expanded,
      'tag-default': !expanded,
    });

    const subHeadlineClassName = cx({
      'text-muted': !expanded,
    });

    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <div className={panelClassName}>
        <div id={id} className={headerClassName} onClick={this.handleClick}>
          {icon && <Icon name={icon} className="small-icon" />}

          {isInsightsMaintenance ? (
            <h5>
              <FormattedMessage
                id="channels.website.insightsMaintenance.title"
                defaultMessage="Sorry, this section is currently not available"
              />
            </h5>
          ) : (
            <>
              <h5 className="panel-title text-truncate">
                {index && <span className={indexClassName}>{index}</span>}
                {headline}
                {subHeadline && (
                  <small className={subHeadlineClassName}>
                    : {subHeadline}
                  </small>
                )}
              </h5>

              <div className="card-header-status">
                {showWarning && (
                  <div className="alert-icon">
                    <FormattedMessage
                      id="channels.header.alert"
                      defaultMessage="⚠️"
                    />
                  </div>
                )}

                {isBoolean(status) ? (
                  <ChannelStatus
                    ready={status}
                    readyState={readyState}
                    platform={platform}
                    screenshots={screenshots}
                  />
                ) : null}

                {closeButton}
              </div>
            </>
          )}
        </div>
        {this.renderBody()}
      </div>
    );

    /* eslint-enable jsx-a11y/no-static-element-interactions */
  }
}

ChannelPanel.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  headline: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  subHeadline: PropTypes.string,
  expanded: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
  onClick: PropTypes.func,
  status: PropTypes.bool,
  platform: PropTypes.string,
  newChannel: PropTypes.bool,
  readyState: PropTypes.object,
  showWarning: PropTypes.bool,
  children: PropTypes.any,
  screenshots: propTypes.screenshots,
  closeButton: PropTypes.node,
};

ChannelPanel.defaultProps = {
  expanded: false,
  disabled: false,
  bodyClassName: '',
  onClick: noop,
  showWarning: false,
  closeButton: null,
};

export default pure(ChannelPanel);
