import { combineReducers } from 'redux';
import get from 'lodash/get';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import compose from 'lodash/fp/compose';
import setCurried from 'lodash/fp/set';
import omitCurried from 'lodash/fp/omit';

import {
  asyncStates,
  loadStateReducer,
  asyncActionReducer,
} from '@blogfoster/redux-async-utils';

import { createReducer } from 'source/utils';
import { combineReducersFlat } from 'source/utils/redux';

import { actionTypes } from './actions';
import { actionTypes as detailsActionTypes } from '../CampaignDetails/actions';

export const campaignFormInitialState = {
  application: {
    errors: {},
    submitting: false,
  },

  accept: {
    accept: false,
    acceptedPublishingDateId: null,
    acceptedPublishingDate: false,
    acceptedTerms: false,
    acceptedAdditionalTerms: false,
    errors: {},
  },

  productShipment: {
    errors: {},
  },

  'tasks-preTasks': {
    errors: {},
  },

  'tasks-tasks': {
    errors: {},
  },

  'tasks-postTasks': {
    errors: {},
  },

  verification: {
    errors: {},
  },

  instagramVerification: {
    caption: '',
    media: [],
    errors: {},
  },

  tiktokVerification: {
    caption: '',
    media: [],
    errors: {},
  },

  pinterestVerification: {
    caption: '',
    media: [],
    errors: {},
  },

  youtubeVerification: {
    caption: '',
    videoUrl: '',
    errors: {},
  },

  websitePublication: {
    errors: {},
  },

  instagramPublication: {
    errors: {},
  },

  tiktokPublication: {
    errors: {},
  },

  pinterestPublication: {
    errors: {},
  },

  youtubePublication: {
    errors: {},
  },

  socialMediaSharing: {
    errors: {},
  },

  afterPublicationUploads: {
    screenshots: [],
    errors: {},
  },
};

// Helpers

const getTotal = ({ totals: { price } }) => price;

const getDefaultSelectedChannelId = (matches) =>
  get(
    last(sortBy(matches), (match) => getTotal(match.payment)),
    'channel.id',
  );

const populateTasksForm = (tasks, list) =>
  tasks.reduce((memo, task, index) => {
    if (task.list === list) {
      memo[index] = task.completed || false;
    }

    return memo;
  }, {});

// Populate our form from remote data. Used for both initialization and after
// PATCH calls
const populateForm = (state, { campaign, matches, application, user }) => {
  const tasks = application ? application.tasks : campaign.tasks;

  const userAddress = {
    firstname: user.firstname,
    lastname: user.lastname,
    street: user.streetName,
    streetNumber: user.streetNumber,
    postalCode: user.postalCode,
    city: user.city,
    country: user.country,
  };

  return {
    ...state,
    application: {
      ...state.application,
      selectedChannelId:
        state.application.selectedChannelId ||
        getDefaultSelectedChannelId(matches),
      counterOfferActive: false,
    },
    productShipment: {
      ...userAddress,
      ...state.productShipment,
    },
    'tasks-preTasks': {
      ...state['tasks-preTasks'],
      ...populateTasksForm(tasks, 'preTasks'),
    },
    'tasks-tasks': {
      ...state['tasks-tasks'],
      ...populateTasksForm(tasks, 'tasks'),
    },
    'tasks-postTasks': {
      ...state['tasks-postTasks'],
      ...populateTasksForm(tasks, 'postTasks'),
    },
    instagramVerification: {
      ...state.instagramVerification,
      caption: get(application, 'contentPreview.caption', ''),
      media: get(application, 'contentPreview.media', []),
    },
    tiktokVerification: {
      ...state.tiktokVerification,
      caption: get(application, 'contentPreview.caption', ''),
      media: get(application, 'contentPreview.media', []),
    },
    pinterestVerification: {
      ...state.pinterestVerification,
      caption: get(application, 'contentPreview.caption', ''),
      media: get(application, 'contentPreview.media', []),
    },
    youtubeVerification: {
      ...state.youtubeVerification,
      caption: get(application, 'contentPreview.caption', ''),
      videoUrl: get(application, 'contentPreview.media.0.url', ''),
    },
    socialMediaSharing: {
      ...state.socialMediaSharing,
      data: get(application, 'socialMediaSharing', []),
    },
    afterPublicationUploads: {
      ...state.afterPublicationUploads,
      screenshots: get(application, 'afterPublicationUploads.screenshots', []),
    },
  };
};

// Reducers

const workflowReducer = loadStateReducer(actionTypes.WORKFLOW_FETCH);

const campaignFormInitReducer = createReducer(undefined, {
  [actionTypes.FORM_INIT]: populateForm,
});

const campaignFormUpdateReducer = asyncActionReducer(
  detailsActionTypes.APPLICATION_UPDATE,
  {
    [asyncStates.success]: (state, { payload: { data: application } }) =>
      populateForm(state, { application }),
  },
);

const campaignFormChangeReducer = createReducer(undefined, {
  [actionTypes.FORM_CHANGE]: (state, { form: field, attr, value }) => {
    let formState = state[field];

    if (!formState) {
      console.error('no form state given for:', field); // eslint-disable-line no-console
      formState = {};
    }

    // We are using lodash/fp/set so we can use lodash's path syntax as id's for
    // certain fields. That way we can give the fields id's like `[index].prop`
    // and only change the values at that path with `fpSet(attr)`.
    const nextFormState = compose(
      setCurried(attr, value),
      omitCurried(`errors.${attr}`),
    )(formState);

    return { ...state, [field]: nextFormState };
  },
});

const campaignFormErrorReducer = createReducer(undefined, {
  [actionTypes.FORM_ERROR]: (state, { form: field, errors }) => ({
    ...state,
    [field]: {
      ...state[field],
      errors,
    },
  }),
});

const campaignFormReducer = combineReducersFlat(
  [
    campaignFormInitReducer,
    campaignFormUpdateReducer,
    campaignFormChangeReducer,
    campaignFormErrorReducer,
  ],
  campaignFormInitialState,
);

const toastErrorReducer = (state = null, { type, payload }) =>
  type === actionTypes.TOAST_ERROR ? payload : state;

export default combineReducers({
  workflow: workflowReducer,
  campaignForm: campaignFormReducer,
  toastError: toastErrorReducer,
});
