import { setCurrentExperiment } from 'source/actions/experiments';

const experiments = {
  setStore(store) {
    this.store = store;
  },

  activate(name, experimentId) {
    if (!window.optimizely) {
      throw new Error(
        'window.optimizely is not defined, forgot to include Optimizely snippet?',
      );
    }

    if (experimentId) {
      window.optimizely.push(['activate', experimentId]);
    }
  },

  set(container, experiment, variation) {
    if (!this.store) {
      throw new Error(
        'experiments not initialized, forgot to call .setStore()?',
      );
    }

    this.store.dispatch(setCurrentExperiment(container, experiment, variation));
  },
};

// expose this module globally, optimizely can call it through injected code
window._experiments = experiments;

export default experiments;
