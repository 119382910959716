import noop from 'lodash/noop';
import axios from 'axios';

import config from 'config';

import {
  resourceNotFound,
  genericRequestErrorHappened,
  serverErrorRequestErrorHappened,
  unauthorizedRequestHappend,
} from 'source/actions/error';

export const getAxios = () => axios;

const create = (baseURL, headers = {}, options = {}) => {
  const _headers = { ...headers, 'Content-Type': 'application/json' };

  const instance = axios.create({
    baseURL,
    timeout: options.timeout || config.api.timeout,
    headers: _headers,
    ...options,
  });

  const methods = {
    auth(token) {
      instance.defaults.headers.Authorization = `Bearer ${token}`;

      return instance;
    },
  };

  return { ...instance, ...methods };
};

const initRequest = (
  request,
  dispatch,
  {
    handleUnauthorizedRequest = false,
    dispatchErrors = false,
    dispatch404 = false,
    dispatch503 = false,
  } = {},
) => {
  request.interceptors.response.use(
    // valid response
    (response) => response,
    // error response
    (error) => {
      // always redirect generic response errors (ECONREFUSED, etc.) to the error page
      if (!error.response) {
        dispatchErrors && dispatch(genericRequestErrorHappened(error));
        return Promise.reject(error);
      }

      const { response } = error;

      if (handleUnauthorizedRequest && response.status === 401) {
        dispatch(unauthorizedRequestHappend(response));

        return Promise.reject(response);
      }

      if (dispatch404 && response.status === 404) {
        dispatch(resourceNotFound(response));

        return Promise.reject(response);
      }

      if (
        (dispatch503 && response.status === 503) ||
        (response.status >= 500 && response.status !== 503)
      ) {
        dispatch(serverErrorRequestErrorHappened(response));

        return Promise.reject(response);
      }

      if (dispatchErrors) {
        dispatch(genericRequestErrorHappened(response));

        return Promise.reject(response);
      }

      return Promise.reject(response);
    },
  );

  return request;
};

export const actionRequest = (dispatch, requestOpts = {}) => {
  const request = create(
    config.api.url,
    requestOpts.headers,
    requestOpts.options,
  );

  return initRequest(request, dispatch, requestOpts);
};

export const actionAuthRequest = (dispatch, getState, requestOpts) => {
  const {
    account: { accessToken },
  } = getState();

  return actionRequest(dispatch, {
    handleUnauthorizedRequest: true,
    ...requestOpts,
  }).auth(accessToken);
};

export const requestFlow = (
  request,
  { onBefore = noop, onSuccess = noop, onError = noop } = {},
) =>
  Promise.resolve().then(onBefore).then(request).then(onSuccess).catch(onError);

/* eslint-disable no-console */
const logMockRequest = (method, path, response, ...args) => {
  console.groupCollapsed(
    `%cMOCK: %c${method} ${path}`,
    'color: #636363',
    'color: #0000FF',
  );
  args.forEach((arg) => {
    console.log('%cParam', 'color: #ADADAD', arg);
  });
  console.log('%cResponse', 'color: #ADADAD', response);
  console.groupEnd();
};
/* eslint-enable no-console */

const LOG_MOCK_REQUESTS = false;

// Creates a mock for an auth action request
export const authRequestMock =
  ({
    payload = {},
    status = 200,
    error = false,
    timeout = 100,
    sideEffect = noop,
  } = {}) =>
  (/* dispatch, getState */) => {
    const response = (data) => ({ status, data });

    const getPayload = typeof payload === 'function' ? payload : () => payload;

    const request =
      (method) =>
      (path, ...args) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            sideEffect();
            const payload = getPayload();
            LOG_MOCK_REQUESTS &&
              logMockRequest(method, path, response(payload), ...args);
            error ? reject(response(payload)) : resolve(response(payload));
          }, timeout);
        });

    return {
      get: request('GET'),
      post: request('POST'),
      delete: request('DELETE'),
      patch: request('PATCH'),
    };
  };
