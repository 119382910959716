import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Link } from 'react-router';
import cx from 'classnames';

import { Icon, ProgressBar } from 'source/components/common';

import Card from '../Card';

class PaymentListAccordion extends React.Component {
  constructor(props) {
    super(props);

    const toggleStatusByPaymentId = props.payments.reduce((acc, payment) => {
      acc[payment.id] = false;

      return acc;
    }, {});

    this.state = toggleStatusByPaymentId;
  }

  openRow = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const paymentId = e.currentTarget.id;

    this.setState((prevState) => ({
      [paymentId]: !prevState[paymentId],
    }));
  };

  render() {
    return (
      <tbody>
        {this.props.payments.map(
          ({ id, description, amount, currency, references = {} }) => {
            const hasApplication = Boolean(references.application);
            const hasCampaign = Boolean(references.campaign);
            const hasChannel = Boolean(references.channel);
            const allowToggle = (hasApplication && hasCampaign) || hasChannel;
            const isOpened = this.state[id];

            return (
              <tr
                className={cx('open-payments-table__row', {
                  expandable: allowToggle,
                  opened: isOpened,
                })}
                key={id}
                id={id}
                onClick={this.openRow}
              >
                <td className="open-payment-toggle">
                  {allowToggle && (
                    <Icon name="arrow" id={`payment-toggler-${id}`} />
                  )}
                </td>
                <td className="open-payment-details">
                  <span className="open-payments-details__name">
                    {description}
                  </span>
                  <ul className="open-payment-details__references">
                    {hasApplication && hasCampaign && (
                      <li>
                        Campaign:{' '}
                        <Link
                          to={`/sponsored-posts/applications/${references.application.id}`}
                        >
                          {references.campaign.details.headline}
                        </Link>
                      </li>
                    )}

                    {hasChannel && (
                      <li>
                        Channel:{' '}
                        <Link to="/profile">{references.channel.name}</Link>
                      </li>
                    )}
                  </ul>
                </td>
                <td className="open-payment-amount">
                  <FormattedNumber
                    currency={currency}
                    // eslint-disable-next-line react/style-prop-object
                    style="currency"
                    value={amount / 100}
                  />
                </td>
              </tr>
            );
          },
        )}
      </tbody>
    );
  }
}

PaymentListAccordion.propTypes = {
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      references: PropTypes.shape({
        application: PropTypes.object,
        campaign: PropTypes.object,
        channel: PropTypes.object,
        website: PropTypes.object,
      }),
    }),
  ),
};

PaymentListAccordion.defaultProps = {
  payments: [],
};

function OpenPayments({ paymentsRequestState, openPayments }) {
  if (paymentsRequestState.error) {
    return (
      <Card className="open-payments-card">
        <h2 className="open-payments-title">
          <FormattedMessage
            id="revenues.openPayments.title"
            defaultMessage="Outstanding payments"
          />
        </h2>

        <div className="m-t-1">
          <FormattedMessage
            id="revenues.openPayments.fetchError"
            defaultMessage="Sorry, the data is currently not available. Please check again later."
          />
        </div>
      </Card>
    );
  }
  if (!paymentsRequestState.loaded) {
    return (
      <Card className="open-payments-card">
        <h2 className="open-payments-title">
          <FormattedMessage
            id="revenues.openPayments.title"
            defaultMessage="Outstanding payments"
          />
        </h2>

        <ProgressBar />
      </Card>
    );
  }

  if (!openPayments.length) {
    return (
      <Card className="open-payments-card">
        <h2 className="open-payments-title">
          <FormattedMessage
            id="revenues.openPayments.title"
            defaultMessage="Outstanding payments"
          />
        </h2>

        <div className="m-t-1">
          <FormattedMessage
            id="revenues.openPayments.noOpenPayments"
            defaultMessage="No pending payments for the selected time period."
          />
        </div>
      </Card>
    );
  }

  return (
    <Card className="open-payments-card">
      <h2 className="open-payments-title">
        <FormattedMessage
          id="revenues.openPayments.title"
          defaultMessage="Outstanding payments"
        />
      </h2>
      <table className="open-payments-table">
        <thead>
          <tr className="open-payments-table__header">
            <th />
            <th className="open-payment-details-header">
              <FormattedMessage
                id="revenues.openPayments.description"
                defaultMessage="Description"
              />
            </th>
            <th className="open-payment-amount-header">
              <FormattedMessage
                id="revenues.openPayments.amount"
                defaultMessage="Amount"
              />
            </th>
          </tr>
        </thead>
        <PaymentListAccordion payments={openPayments} />
      </table>
    </Card>
  );
}

OpenPayments.propTypes = {
  paymentsRequestState: PropTypes.shape({
    loading: PropTypes.bool,
    loaded: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.object),
    error: PropTypes.object,
  }).isRequired,
  openPayments: PropTypes.arrayOf(PropTypes.object),
};

OpenPayments.defaultProps = {
  openPayments: [],
};

export default OpenPayments;
