import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { withGatewayRoot } from './root';

function Success() {
  return (
    <div className="gateway__card text-center">
      <i className="material-icons material-icons--big material-icons--green">
        check
      </i>
      <h5 className="m-t-1">
        <FormattedMessage
          id="confirmEmail.completed.title"
          defaultMessage="Done!"
        />
      </h5>
      <p>
        <FormattedMessage
          id="confirmEmail.completed.description"
          defaultMessage="You have successfully confirmed your email."
        />
      </p>
      <Link to="/" className="btn btn-primary m-t-2">
        <FormattedMessage
          id="confirmEmail.completed.loginAgain"
          defaultMessage="Back to Login"
        />
      </Link>
    </div>
  );
}

function Failure() {
  return (
    <div className="gateway__card text-center">
      <i className="material-icons material-icons--big material-icons--red">
        error
      </i>
      <h5 className="m-t-1">
        <FormattedMessage
          id="confirmEmail.failure.title"
          defaultMessage="Something went wrong."
        />
      </h5>
      <p>
        <FormattedMessage
          id="confirmEmail.failure.description"
          defaultMessage="We were not able to confirm your email. Please try again later. You can go to Login in the meantime."
        />
      </p>
      <Link to="/signin" className="btn btn-primary m-t-2">
        <FormattedMessage
          id="confirmEmail.failure.loginAgain"
          defaultMessage="Go to Login"
        />
      </Link>
    </div>
  );
}

function ConfirmationResult({ stateLoaded, status }) {
  if (!stateLoaded) {
    // @sean TODO Should show a loading indicator
    return null;
  }

  return status === 'confirmed' ? <Success /> : <Failure />;
}

ConfirmationResult.propTypes = {
  stateLoaded: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

export default withGatewayRoot(ConfirmationResult);
