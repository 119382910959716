import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import cx from 'classnames';
import { FileInput, MediaGalleryItem } from 'source/components/common/form';
import {
  UploadErrors,
  NonAsciiRegExp,
} from 'source/components/common/form/fileUploadError';
import * as CustomPropTypes from 'source/utils/propTypes';
import { withInnerRef } from 'source/utils/dom';

class SingleImageUpload extends React.Component {
  state = {
    id: this.props.initialUrl,
    url: this.props.initialUrl,
    file: null,
    loaded: true,
    error: false,
  };

  setErrorState = () => {
    this.setState({
      error: true,
      url: null,
      file: null,
    });
  };

  handleFileInputChange = (filesToUpload) => {
    const file = filesToUpload[0];

    if (file.size > this.props.maxFileSize) {
      this.setErrorState();

      return this.props.onError({
        code: UploadErrors.MaxSize,
        error: new Error('File too large'),
        maxSize: this.props.maxFileSize,
      });
    }

    if (NonAsciiRegExp.test(file.name)) {
      this.setErrorState();

      return this.props.onError({
        code: UploadErrors.NotAllowedCharacter,
        error: new Error('Not Allowed Character in file name'),
      });
    }

    this.setState({
      id: new Date().getTime().toString(36),
      url: null,
      file,
      error: false,
      loaded: false,
    });

    // in case that the previous uploaded image had an error, remove the error message:
    this.props.onError(null);

    Promise.resolve(file)
      .then(this.props.onFileUpload)
      .then(this.handleFileUploadSuccess)
      .catch(this.handleFileUploadFailure);

    return undefined;
  };

  handleFileUploadSuccess = (url) => {
    this.setState({
      url,
      loaded: true,
    });
    this.props.onChange({ type: 'image', url });
  };

  handleFileUploadFailure = (res) => {
    this.setState({
      url: null,
      file: null,
      error: true,
      loaded: true,
    });
    this.props.onError({
      code: UploadErrors.ServerGeneric,
      error: res,
    });
  };

  render() {
    const { className, defaultUrl, defaultTitle, innerRef } = this.props;
    const { id, url, file, loaded, error } = this.state;
    const showImage = !!(url || file);
    const showDefaultImage = !showImage && defaultUrl && !error;

    return (
      <div className={cx('single-image-upload', className)}>
        {showImage && (
          <MediaGalleryItem
            id={id}
            file={file}
            url={url}
            loading={!loaded}
            removable={false}
            zoomable={false}
          />
        )}
        {showDefaultImage && (
          <div className="default">
            <img alt="default" className="default-image" src={defaultUrl} />
            <div className="default-title">
              <span>{defaultTitle}</span>
            </div>
          </div>
        )}
        {error && (
          <div className="error">
            <span aria-label="error" role="img">
              ⚠️
            </span>
          </div>
        )}
        <FileInput
          ref={innerRef}
          accept="image/*"
          showChooseFilePhrase={false}
          clearable={false}
          onChange={this.handleFileInputChange}
        />
      </div>
    );
  }
}

SingleImageUpload.propTypes = {
  className: PropTypes.string,
  initialUrl: PropTypes.string,
  defaultUrl: PropTypes.string,
  defaultTitle: PropTypes.string,
  onFileUpload: PropTypes.func.isRequired,
  onError: PropTypes.func,
  onChange: PropTypes.func,
  innerRef: CustomPropTypes.ref,
  maxFileSize: PropTypes.number,
};

SingleImageUpload.defaultProps = {
  className: '',
  onChange: noop,
  onError: noop,
  innerRef: {},
  maxFileSize: 1024 * 1024 * 22, // 22M
};

export default withInnerRef(SingleImageUpload);
