import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose, pure, withHandlers } from 'recompose';
import keyBy from 'lodash/keyBy';
import startCase from 'lodash/startCase';

import { FormError, NextPrevButtonList } from 'source/components/common';

const enhance = compose(
  injectIntl,
  pure,
  withHandlers({
    onNextStep: (props) => (e) => {
      const { form, onNextStep, onErrors, onValidateChannel } = props;

      e.preventDefault();
      const errors = onValidateChannel(form);
      return errors.length > 0 ? onErrors(errors) : onNextStep();
    },

    onChange: (props) => (e) => {
      const {
        target: { id: field, value },
      } = e;
      return props.onChange(field, value);
    },

    onSubmit: (props) => (e) => {
      const { form, onNextStep, onErrors, onValidateChannel } = props;

      e.preventDefault();
      const errors = onValidateChannel(form);
      return errors.length > 0 ? onErrors(errors) : onNextStep();
    },
  }),
);

function SocialNameStep(props) {
  const {
    intl,
    platform,
    form: { name, errors },
    onNextStep,
    onPrevStep,
    onChange,
    onSubmit,
  } = props;

  const errorsById = keyBy(errors, 'id');

  return (
    <div>
      <form onSubmit={onSubmit}>
        <fieldset className="form-group">
          <label htmlFor="name">
            <FormattedMessage
              id="channels.username"
              defaultMessage="Username"
            />
          </label>
          <input
            className="form-control label-s"
            id="name"
            placeholder={intl.formatMessage(
              {
                id: 'channels.enterUsername',
                defaultMessage: 'Enter your {platform} username',
              },
              { platform: startCase(platform) },
            )}
            autoComplete="off"
            onChange={onChange}
            value={name}
          />

          <div className="error-block">
            {errorsById.name ? <FormError error={errorsById.name} /> : null}
          </div>
        </fieldset>
      </form>
      <div className="modal-footer">
        <NextPrevButtonList onNextStep={onNextStep} onPrevStep={onPrevStep} />
      </div>
    </div>
  );
}

SocialNameStep.propTypes = {
  platform: PropTypes.string.isRequired,
  form: PropTypes.shape({
    name: PropTypes.string.isRequired,
    errors: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }),

  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onPrevStep: PropTypes.func.isRequired,
};

SocialNameStep.defaultProps = {
  form: {},
};

export default enhance(SocialNameStep);
