import { combineReducers } from 'redux';
import { compose } from 'recompose';
import { asyncStates, loadStateReducer } from '@blogfoster/redux-async-utils';

import { resetableReducer, withActions } from 'source/utils/redux';

import { actionTypes } from './actions';
import legacyReducer from './legacy/reducer';

const userDataReducer = (state = {}, action) => {
  if (action.type === actionTypes.USER_FETCH) {
    return loadStateReducer(actionTypes.USER_FETCH)(state, action);
  }

  // Also save updated data in the same place
  if (
    action.type === actionTypes.BILLING_INFORMATION_UPDATE &&
    action.async === asyncStates.success
  ) {
    const nextState = loadStateReducer(actionTypes.BILLING_INFORMATION_UPDATE)(
      state,
      action,
    );

    nextState.data = { ...state, billingInformation: nextState.data };

    return nextState;
  }

  return state;
};

// Only used to derive update status from
const billingInformationLoadStateReducer = resetableReducer(
  actionTypes.RESET_SCENE,
)(loadStateReducer(actionTypes.BILLING_INFORMATION_UPDATE));

/** resetable reduce +  */
const previousPath = compose(
  resetableReducer(actionTypes.RESET_SCENE),
  withActions([actionTypes.PREVIOUS_PATH], /** initialState */ null),
)((state, action) => action.payload);

export default combineReducers({
  legacy: legacyReducer,
  userData: userDataReducer,
  billingInformationState: billingInformationLoadStateReducer,
  previousPath,
});
