import get from 'lodash/get';
import omit from 'lodash/omit';
import { asyncActionCreator } from '@blogfoster/redux-async-utils';
import { actionAuthRequest } from 'source/utils/axios';
import {
  changeTabSelection,
  formErrors,
  formChange,
  clearMessage,
  toggleDeleteAccountConfirm,
  submitForm,
} from './legacy/actions';

export const actionTypes = {
  USER_FETCH: 'data/v2/users/FETCH',
  BILLING_INFORMATION_UPDATE: 'data/v2/users/billing-information/UPDATE',
  PREVIOUS_PATH: 'scenes/acount-settings/PREVIOUS_PATH',
  RESET_SCENE: 'scenes/acount-settings/RESET_SCENE',
};

const loadUser = asyncActionCreator(
  actionTypes.USER_FETCH,
  ({ fields = [] } = {}) =>
    (dispatch, getState) => {
      const params = {};

      if (fields && fields.length > 0) {
        params.fields = JSON.stringify(fields);
      }

      return actionAuthRequest(dispatch, getState, {
        dispatchErrors: false,
      }).get('/v2/users', { params });
    },
);

// NOTE: Submitting the same action for both POST and PATCH, using the same
// reducer and reusing the same state for the response simplifies the logic in
// other places.
const postUserBillingInformation = asyncActionCreator(
  actionTypes.BILLING_INFORMATION_UPDATE,
  (payload) => (dispatch, getState) =>
    actionAuthRequest(dispatch, getState, {
      dispatchErrors: false,
    }).post('/v2/users/billing-information', payload),
);

const patchUserBillingInformation = asyncActionCreator(
  actionTypes.BILLING_INFORMATION_UPDATE,
  (payload) => (dispatch, getState) => {
    let { tax } = payload;
    const country = get(payload, 'address.country');

    // remove taxId if country is not DE
    if (country !== 'DE') {
      tax = omit(payload.tax, ['taxId', 'taxIdCode']);
    }

    return actionAuthRequest(dispatch, getState, {
      dispatchErrors: false,
    }).patch('/v2/users/billing-information', { ...payload, tax });
  },
);

const resetScene = () => ({ type: actionTypes.RESET_SCENE });

// exported so the sponsored post workflow can fire it
export const setPreviousPath = ({ pathname, label }) => ({
  type: actionTypes.PREVIOUS_PATH,
  payload: { pathname, label },
});

export default {
  loadUser,
  onResetScene: resetScene,
  onTabSelection: changeTabSelection,
  onChange: formChange,
  onErrors: formErrors,
  onAlertClose: clearMessage,
  onToggleConfirm: toggleDeleteAccountConfirm,
  onSubmit: submitForm,
  onPostUserBillingInformation: postUserBillingInformation,
  onPatchUserBillingInformation: patchUserBillingInformation,
};
