import React from 'react';

import OpenPaymentsContainer from './OpenPayments/container';
import InvoicesContainer from './Invoices/container';
import YearPicker from './YearPicker';

class Revenues extends React.Component {
  state = {
    selectedYear: new Date().getFullYear(),
  };

  onYearChange = (year) => {
    this.setState({ selectedYear: year });
  };

  render() {
    return (
      <div className="revenues revenue-container">
        <YearPicker
          value={this.state.selectedYear}
          onChange={this.onYearChange}
          className="year-picker"
        />
        <OpenPaymentsContainer selectedYear={this.state.selectedYear} />
        <InvoicesContainer selectedYear={this.state.selectedYear} />
      </div>
    );
  }
}

export default Revenues;
