import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import { branch, withProps, renderComponent, compose } from 'recompose';
import get from 'lodash/get';

import Markdown from 'source/components/common/markdown';
import * as CustomPropTypes from 'source/utils/propTypes';
import Spinner from 'source/components/common/spinner';
import Feature from 'source/components/common/feature';
import Accordion, {
  Panel,
  PanelHeader,
  PanelContent,
} from 'source/components/common/accordion';

import SponsoredPostsCampaignBriefing from 'source/components/app/sponsoredPosts/briefing';

import CampaignMeta from '../CampaignMeta';
import MissionAccomplished from '../MissionAccomplished';
import { isCompletedWorkflow } from '../../helpers';

function Loading() {
  return <Spinner className="spinner-view" />;
}

function Error() {
  return (
    <div className="row">
      <div className="text-xs-center">
        <FormattedMessage
          id="errors.message.generic"
          defaultMessage="An unexpected problem occurred, so please try again."
        />
      </div>
    </div>
  );
}

function CampaignDetails({
  intl,
  campaignMatch: { campaign, matches },
  application,
  components: { Workflow },
  workflow: { data: workflow },
}) {
  const hasCounterOffer = Boolean(
    get(application, 'match.payment.counterOffer'),
  );

  const price = get(application, 'match.payment.totals.price');
  const currency = get(application, 'match.payment.currency');

  return (
    <div className="sponsored-posts-campaign">
      <h1 className="sponsored-posts-campaign-name">
        {campaign.details.headline}
      </h1>

      <CampaignMeta
        campaign={campaign}
        matches={matches}
        application={application}
      />

      <Accordion defaultPanelName={!application ? 'briefing' : 'pitch'}>
        <Panel name="briefing">
          <PanelHeader
            title={intl.formatMessage({
              id: 'sponsoredPosts.campaign.panels.briefing.heading',
              defaultMessage: 'Briefing',
            })}
          />

          <PanelContent>
            <SponsoredPostsCampaignBriefing campaign={campaign} />
          </PanelContent>
        </Panel>
        {application && (
          <Panel name="pitch">
            <PanelHeader
              title={intl.formatMessage({
                id: 'sponsoredPosts.campaign.panels.pitch.heading',
                defaultMessage: 'Your pitch',
              })}
            />

            <PanelContent>
              <Markdown markdown={application.pitch} />
              <Feature feature="counterOffer">
                {hasCounterOffer && (
                  <div className="sponsored-posts-campaign-counter-offer">
                    <FormattedMessage
                      id="sponsoredPosts.campaign.panels.pitch.counterOffer"
                      defaultMessage="Counter Offer: "
                    />
                    <FormattedNumber
                      value={price}
                      currency={currency}
                      // eslint-disable-next-line react/style-prop-object
                      style="currency"
                    />
                  </div>
                )}
              </Feature>
            </PanelContent>
          </Panel>
        )}

        <Panel name="application" collapsible={false}>
          <PanelHeader
            title={intl.formatMessage({
              id: 'sponsoredPosts.campaign.panels.application.heading',
              defaultMessage: 'Application',
            })}
          />

          <PanelContent>
            <Workflow
              campaign={campaign}
              matches={matches}
              application={application}
            />
          </PanelContent>
        </Panel>
      </Accordion>
      {isCompletedWorkflow(workflow) && <MissionAccomplished />}
    </div>
  );
}

CampaignDetails.propTypes = {
  campaignMatch: PropTypes.shape({
    campaign: PropTypes.object.isRequired,
    matches: PropTypes.array.isRequired,
  }).isRequired,
  application: PropTypes.shape({
    pitch: PropTypes.string.isRequired,
  }),

  components: PropTypes.shape({
    Workflow: PropTypes.any.isRequired,
  }).isRequired,
  workflow: CustomPropTypes.workflow,
};

CampaignDetails.defaultProps = {
  application: undefined,
  channels: [],
};

// NOTE Here we implement "polymorphic" behavior for the CampaignDetails
// view by conditionally setting load and error state, depending on whether
// we are rendering for a campaign or an applcation

// Guard against errors on both campaign and application request
const withErrorGuard = compose(
  branch(
    ({ application }) => application && application.error,
    renderComponent(Error),
  ),

  branch(
    ({ campaignMatch }) => campaignMatch && campaignMatch.error,
    renderComponent(Error),
  ),
);

// Guard to show loading indicator before both campaign and application are loaded
const withLoadingGuard = compose(
  branch(
    ({ application }) => application && !application.loaded,
    renderComponent(Loading),
  ),

  branch(
    ({ campaignMatch }) => campaignMatch && !campaignMatch.loaded,
    renderComponent(Loading),
  ),
);

// Once we know there are no errors and the data is loaded
// we can unwrap it from its async information
// We also normalize campaign data shape for the application detail
const withData = compose(
  branch(
    (props) => props.campaignMatch,
    withProps(({ campaignMatch: { data } }) => ({
      campaignMatch: data,
    })),
  ),

  branch(
    (props) => props.application,
    withProps(({ application: { data } }) => ({
      application: data,
      campaignMatch: {
        campaign: data.campaign,
        matches: [data.match],
      },
    })),
  ),
);

export default compose(
  injectIntl,
  withErrorGuard,
  withLoadingGuard,
  withData,
)(CampaignDetails);
