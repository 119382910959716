import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { injectIntl, FormattedMessage } from 'react-intl';
import SingleImageUpload from 'source/components/common/form/singleImageUpload';
import FileUploadError from 'source/components/common/form/fileUploadError';
import * as propTypes from 'source/utils/propTypes';
import ScreenshotStatus from './ScreenshotStatus';

class ScreenshotPanel extends React.Component {
  state = {
    uploadError: null,
    uploading: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const url = get(this.props.screenshot, 'url');
    const nextUrl = get(nextProps.screenshot, 'url');

    const isUploading = this.state.uploading !== nextState.uploading;
    const isErrorUpdated = this.state.uploadError !== nextState.uploadError;
    const isUrlUpdated = url !== nextUrl;

    return isUploading || isUrlUpdated || isErrorUpdated;
  }

  onFileUpload = (file) => {
    const { name, onSubmitInsights } = this.props;
    this.setState({ uploading: true });
    return onSubmitInsights({ file, insightsName: name });
  };

  onFileUploaded = () => {
    this.setState({ uploading: false });
  };

  imageUploadRef = React.createRef();

  triggerFileInput = () => {
    if (
      this.imageUploadRef &&
      this.imageUploadRef.current &&
      !this.state.uploading
    ) {
      this.imageUploadRef.current.click();
    }
  };

  handleUploadError = (uploadError) => {
    this.setState({ uploadError, uploading: false });
  };

  render() {
    const {
      intl: { formatMessage },
      screenshot,
      platform,
      name,
      defaultScreenshotUrl,
    } = this.props;
    const { uploadError, uploading } = this.state;
    const screenshotUrl = get(screenshot, 'url');
    const showStatus = !uploadError && !uploading;

    return (
      <div className="insights-upload-box" onClick={this.triggerFileInput}>
        <SingleImageUpload
          ref={this.imageUploadRef}
          className="screenshot"
          initialUrl={screenshotUrl}
          defaultUrl={defaultScreenshotUrl}
          defaultTitle={formatMessage({
            id: 'channels.insights.uploadModal.example',
            defaultMessage: 'Example',
          })}
          onFileUpload={this.onFileUpload}
          onChange={this.onFileUploaded}
          onError={this.handleUploadError}
        />

        <div className="screenshot-info">
          <div className="screenshot-name">
            <FormattedMessage
              id={`channels.${platform}.insights.uploadModal.screenshots.${name}`}
            />
          </div>
          <div className="screenshot-statuses m-b-1">
            {showStatus && (
              <div className="screenshot-status">
                <ScreenshotStatus platform={platform} screenshot={screenshot} />
              </div>
            )}

            <div className="screenshot-upload-status">
              {uploading && (
                <FormattedMessage
                  id="imageUpload.status.uploading"
                  defaultMessage="<b>Uploading...</b>"
                />
              )}

              {uploadError && (
                <>
                  <div className="m-b-1">
                    <FileUploadError error={uploadError} />
                  </div>
                  <FormattedMessage
                    id="imageUpload.status.tryAgain"
                    defaultMessage="<b>Try again?</b>"
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ScreenshotPanel.propTypes = {
  screenshot: propTypes.screenshot,
  defaultScreenshotUrl: PropTypes.string,
  name: propTypes.screenshotName.isRequired,
  platform: propTypes.platform.isRequired,
  onSubmitInsights: PropTypes.func.isRequired,
};

export default injectIntl(ScreenshotPanel);
