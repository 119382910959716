import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';

import { Logo } from 'source/components';

import { fetchedAccessToken } from 'source/actions/account';
import { auth } from 'source/utils/auth';

class Access extends Component {
  componentDidMount() {
    const { accessToken, dispatch } = this.props;

    if (accessToken) {
      auth.init({ accessToken, godMode: true }); // Configure authentication in god mode

      dispatch(fetchedAccessToken('signin', { accessToken }));
      return this._redirect('/');
    }

    return this._redirect('/signin');
  }

  _redirect(to) {
    const { dispatch } = this.props;

    setTimeout(() => dispatch(replace(to)), 500);
  }

  render() {
    return (
      <div className="account-container flex-center-vertical">
        <Logo />
      </div>
    );
  }
}

Access.propTypes = {
  accessToken: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  accessToken: ownProps.location.query.access_token,
});

export default connect(mapStateToProps)(Access);
