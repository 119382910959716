import qs from 'querystring';
import { push, replace } from 'react-router-redux';
import pick from 'lodash/pick';

import constants from 'source/constants';

import { actionRequest, actionAuthRequest } from 'source/utils/axios';
import { auth } from 'source/utils/auth';
import { facebook } from 'source/utils/facebook';

import { setLanguage } from './application';

export const resetForms = () => ({ type: constants.ACCOUNT_FORM_RESET });

export const formChange = (form, field, value) => ({
  type: constants.ACCOUNT_FORM_CHANGE,
  payload: { form, field, value },
});

export const formErrors = (form, errors) => ({
  type: constants.ACCOUNT_FORM_ERRORS,
  payload: { form, errors },
});

export const cleanupErrors = (form, field) => ({
  type: constants.ACCOUNT_FORM_ERRORS_CLEAN,
  payload: { form, field },
});

export const fetchingAccessToken = (form) => ({
  type: constants.ACCOUNT_ACCESS_TOKEN_FETCHING,
  payload: form,
});

export const fetchedAccessToken = (form, account) => ({
  type: constants.ACCOUNT_ACCESS_TOKEN_FETCHED,
  payload: { form, account },
});

export const accountCreated = () => ({
  type: constants.ACCOUNT_CREATED,
});

export const failedToFetchAccessToken = (form, error, response) => ({
  type: constants.ACCOUNT_ACCESS_TOKEN_FETCH_FAILED,
  payload: { form, error, response },
});

export const failedToRequestPasswordReset = (form, error, response) => ({
  type: constants.ACCOUNT_REQUEST_PASSWORD_RESET_FAILED,
  payload: { form, error, response },
});

export const cleanupServerErrors = (form) => ({
  type: constants.ACCOUNT_SERVER_ERRORS_CLEAN,
  payload: form,
});

export const resetStore = (source) => ({
  type: constants.GLOBAL_RESET_STORE,
  payload: { source },
});

export const accountEmailConfirmed = () => ({
  type: constants.ACCOUNT_EMAIL_CONFIRMED,
});

export const failedToConfirmAccountEmail = () => ({
  type: constants.ACCOUNT_EMAIL_CONFIRMATION_FAILED,
});

export const changeLanguage = (language) => (dispatch) =>
  dispatch(setLanguage(language));

export const createAccount =
  (account, redirectTo = '/') =>
  (dispatch, getState) => {
    const language = getState().application.locale;

    dispatch(fetchingAccessToken('signup'));

    const accountData = {
      firstname: account.firstname.trim(),
      email: account.email.trim(),
      password: account.password,
      country: account.country,
      language,
    };

    actionRequest(dispatch)
      .post('/v1/auth/signup', accountData)
      .then((res) => {
        auth.init({ accessToken: res.data.accessToken });
        facebook.track('CompleteRegistration', { registration_name: 'Signup' });

        dispatch(resetStore('account:createAccount'));
        dispatch(accountCreated());
        dispatch(fetchedAccessToken('signup', res.data, 'Signup'));
        dispatch(push(redirectTo));
      })
      .catch((res) => {
        dispatch(
          failedToFetchAccessToken(
            'signup',
            new Error('failed to create account'),
            res,
          ),
        );
      });
  };

export const redirectToSignIn =
  (status, { replaceRoute = false } = {}) =>
  (dispatch, getState) => {
    const {
      account: { redirectingToSignIn },
    } = getState();

    dispatch({
      type: constants.ACCOUNT_REDIRECT_TO_SIGNIN,
      payload: status,
    });

    // If we are done or are already redirecting, skip
    if (status === 'done' || redirectingToSignIn) {
      return;
    }

    const {
      location: { search, pathname },
    } = window;
    const redirectAction = replaceRoute ? replace : push;
    // Parse query string, removing ? prefix
    const queryObject = qs.parse(search.slice(1));
    queryObject.redirectTo = `${pathname}${search}`;
    const redirectQuery = `?${qs.stringify(queryObject)}`;
    // If path is root, don''t append redirect information
    const redirectTo = `/signin${pathname === '/' ? '' : redirectQuery}`;
    dispatch(redirectAction(redirectTo));
  };

export const loginToAccount =
  (account, redirectTo = '/') =>
  (dispatch) => {
    dispatch(fetchingAccessToken('signin'));

    actionRequest(dispatch, { handleUnauthorizedRequest: false })
      .post('/v1/auth/token', {
        username: account.username.trim(),
        password: account.password,
      })
      .then((res) => {
        auth.init({ accessToken: res.data.accessToken });

        dispatch(resetStore('account:loginToAccount'));
        dispatch(fetchedAccessToken('signin', res.data, 'Login'));
        dispatch(push(redirectTo));
      })
      .catch((res) => {
        dispatch(
          failedToFetchAccessToken(
            'signin',
            new Error('failed to login to account'),
            res,
          ),
        );
      });
  };

export const logout = () => (dispatch, getState) => {
  actionAuthRequest(dispatch, getState)
    .delete('/v1/auth/revoke')
    .then(() => {
      auth.reset();

      dispatch(resetStore('account:logout'));
    })
    .catch(() => {
      auth.reset();
      dispatch(resetStore('account:logout'));

      dispatch(push('/signin'));
    });
};

export const forgotPasswordRequest =
  (account, redirectTo = '/') =>
  (dispatch) => {
    actionRequest(dispatch)
      .post('/v1/users/reset-password/request', pick(account, ['email']))
      .then(() => {
        dispatch(push(redirectTo));
      })
      .catch((res) => {
        const error = new Error('failed to request password reset');
        dispatch(
          failedToRequestPasswordReset('forgotPassword', error, res.data),
        );
      });
  };

export const resetPasswordRequest =
  (account, redirectTo = '/') =>
  (dispatch) => {
    actionRequest(dispatch)
      .post('/v1/users/reset-password', pick(account, ['token', 'password']))
      .then(() => {
        dispatch(push(redirectTo));
      })
      .catch((res) => {
        dispatch(
          failedToRequestPasswordReset(
            'resetPassword',
            new Error('failed to password reset'),
            res.data,
          ),
        );
      });
  };

export const confirmEmail = (id, token) => (dispatch) => {
  actionRequest(dispatch)
    .post('/v1/users/confirm-email', { id, token })
    .then(() => {
      dispatch(accountEmailConfirmed());
    })
    .catch((res) => {
      dispatch(
        failedToConfirmAccountEmail(new Error('failed to confirm'), res.data),
      );
    });
};
