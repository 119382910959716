import { combineReducers } from 'redux';

import { resetableReducer } from 'source/utils/redux';

import detailsReducer from './components/CampaignDetails/reducer';
import workflowReducer from './components/Workflow/reducer';

import { actionTypes } from './actions';

export default resetableReducer(actionTypes.RESET_SCENE)(
  combineReducers({
    details: detailsReducer,
    workflow: workflowReducer,
  }),
);
