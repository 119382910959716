import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';

import { FormError, NextPrevButtonList, Icon } from 'source/components/common';

const getVerificationError = (verificationError, platform) => {
  const platformLabels = {
    instagram: 'Instagram',
    tiktok: 'TikTok',
  };

  const platformLabel = platformLabels[platform];

  if (verificationError.status === 503) {
    return {
      messageId: 'channels.verification.error',
      values: { platform: platformLabel },
    };
  }

  if (verificationError.status === 404) {
    return {
      messageId: 'channels.verification.notFound',
    };
  }

  return { messageId: 'channels.verification.failed' };
};

const verificationHelpLinks = {
  instagram: defineMessages({
    url: {
      id: 'channels.instagram.verificationHelpLink.url',
      defaultMessage:
        'https://eqolot.com/academy/tutorial-how-to-verify-your-instagram-profile',
    },
    label: {
      id: 'channels.instagram.verificationHelpLink.label',
      defaultMessage: 'this tutorial',
    },
  }),
  tiktok: defineMessages({
    url: {
      id: 'channels.tiktok.verificationHelpLink.url',
      defaultMessage: 'https://link.blogfoster.com/HyBMhHg7L',
    },
    label: {
      id: 'channels.tiktok.verificationHelpLink.label',
      defaultMessage: 'this tutorial',
    },
  }),
};

const channelLinks = {
  instagram: defineMessages({
    url: {
      id: 'channels.instagram.verificationStep.addCode.url',
      defaultMessage: 'https://www.instagram.com/accounts/edit/',
    },
    label: {
      id: 'channels.instagram.verificationStep.addCode.label',
      defaultMessage: 'this tutorial',
    },
  }),
  tiktok: defineMessages({
    url: {
      id: 'channels.tiktok.verificationStep.addCode.url',
      defaultMessage: 'https://www.tiktok.com/@{channelName}',
    },
    label: {
      id: 'channels.tiktok.verificationStep.addCode.label',
      defaultMessage: 'this tutorial',
    },
  }),
};

class SocialVerificationStep extends React.PureComponent {
  componentDidMount() {
    this.handleLoadVerificationCode();
  }

  handleLoadVerificationCode = () => {
    const {
      id,
      platform,
      form: { name },
      onLoadVerificationCode,
    } = this.props;

    return onLoadVerificationCode(id, platform, name);
  };

  handleVerify = (e) => {
    const {
      id,
      platform,
      onCreateChannel,
      form: { name },
      verification: { loaded, loading },
    } = this.props;

    e.preventDefault();

    if (!loaded && !loading) {
      onCreateChannel(id, platform, name);
    }
  };

  renderVerificationCode() {
    const {
      verificationCode: { loaded, error, data },
    } = this.props;

    if (error) {
      return (
        <div className="channel-verification-code">
          <div className="text-danger">
            <FormattedMessage id="channels.verification.codeError" />
            <button
              className="btn m-l-1"
              onClick={this.handleLoadVerificationCode}
            >
              <Icon className="small-icon" name="sync" />
            </button>
          </div>
        </div>
      );
    }

    return (
      <pre className="channel-verification-code">
        {loaded ? (
          data
        ) : (
          <span>
            <FormattedMessage id="common.loading" />
            ...
          </span>
        )}
      </pre>
    );
  }

  renderVerifyButton() {
    const {
      verification: { loading, loaded },
    } = this.props;
    let status;
    let statusClass;

    if (loading) {
      status = 'pending';
      statusClass = 'btn-saving';
    } else if (loaded) {
      status = 'verified';
      statusClass = 'btn-saved';
    } else {
      status = 'unverified';
      statusClass = '';
    }

    return (
      <div className="m-b-1">
        <button
          className={`btn btn-primary ${statusClass}`}
          onClick={this.handleVerify}
        >
          <FormattedMessage id={`channels.verificationBtn.${status}`} />
        </button>
      </div>
    );
  }

  renderAcademyLink() {
    const {
      platform,
      intl: { formatMessage },
    } = this.props;

    const helpLink = verificationHelpLinks[platform];

    return (
      <div className="m-b-1">
        <small>
          <FormattedMessage
            id={`channels.${platform}.verificationAcademy`}
            values={{
              verificationHelpLink: (
                <a
                  href={formatMessage(helpLink.url)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FormattedMessage {...helpLink.label} />
                </a>
              ),
            }}
          />
        </small>
      </div>
    );
  }

  renderSimpleVerification() {
    const { intl, form, platform, onPrevStep } = this.props;
    const { name: channelName } = form;
    const channelLink = channelLinks[platform];

    return (
      <div className="list m-t-1">
        <div className="container">
          <div className="list-title m-b-1">
            <FormattedMessage
              id={`channels.${platform}.verificationStep.addCode`}
              defaultMessage="Please copy the following code and paste it into {channelLink} "
              values={{
                channelLink: (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={intl.formatMessage(channelLink.url, { channelName })}
                  >
                    <FormattedMessage {...channelLink.label} />
                  </a>
                ),
              }}
            />
          </div>
          {this.renderVerificationCode()}
          {this.renderAcademyLink()}
          <div className="list-title m-b-1">
            <FormattedMessage
              id={`channels.${platform}.verificationStep.removeCode`}
            />
          </div>
        </div>
        <div className="row modal-footer m-r-0">
          <NextPrevButtonList
            onPrevStep={onPrevStep}
            onNextStep={this.handleVerify}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      intl,
      form,
      platform,
      onPrevStep,
      onNextStep,
      verification: { loaded, error: verificationError },
    } = this.props;

    const { name: channelName } = form;
    const channelLink = channelLinks[platform];

    if (['instagram', 'tiktok'].includes(platform)) {
      return this.renderSimpleVerification();
    }

    return (
      <div className="container m-t-1">
        <ol className="row list">
          <li className="list-item">
            <div className="list-title m-b-1">
              <FormattedMessage
                id={`channels.${platform}.verificationStep.addCode`}
                defaultMessage="Please copy the following code and paste it into {channelLink} "
                values={{
                  channelLink: (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={intl.formatMessage(channelLink.url, {
                        channelName,
                      })}
                    >
                      <FormattedMessage {...channelLink.label} />
                    </a>
                  ),
                }}
              />
            </div>
            {this.renderVerificationCode()}
            {this.renderAcademyLink()}
          </li>

          <li className="list-item">
            <div className="list-title m-b-1">
              <FormattedMessage
                id={`channels.${platform}.verificationStep.verify`}
              />
            </div>
            {this.renderVerifyButton()}
            {verificationError ? (
              <div className="m-b-1">
                <FormError
                  error={getVerificationError(verificationError, platform)}
                />
              </div>
            ) : null}
          </li>

          <li className="list-item">
            <div className="list-title m-b-1">
              <FormattedMessage
                id={`channels.${platform}.verificationStep.removeCode`}
              />
            </div>
          </li>
        </ol>

        <div className="row">
          <div className="modal-footer">
            <NextPrevButtonList
              onPrevStep={onPrevStep}
              disableNext={!loaded}
              onNextStep={onNextStep}
            />
          </div>
        </div>
      </div>
    );
  }
}

SocialVerificationStep.propTypes = {
  id: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
  form: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  verificationCode: PropTypes.object.isRequired,
  verification: PropTypes.object.isRequired,

  onLoadVerificationCode: PropTypes.func.isRequired,
  onCreateChannel: PropTypes.func.isRequired,
  onPrevStep: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
};

SocialVerificationStep.defaultProps = {
  form: {},
};

export default injectIntl(SocialVerificationStep);
