import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import { lifecycle, compose } from 'recompose';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import validator from 'validator';

import formController, { renderError } from 'source/utils/forms';

import { withGatewayRoot } from './root';

const withRedirectCompletion = lifecycle({
  componentDidMount() {
    this.props.onRedirectComplete();
  },
});

function SignIn({ id, intl, form, onChange, onSubmit }) {
  return (
    <div className="gateway__card">
      <h5>
        <FormattedMessage
          id="signup.caption.signinTitle"
          defaultMessage="Login to your account"
        />
      </h5>
      <form id={id} onSubmit={onSubmit} onChange={onChange} noValidate>
        <fieldset className="form-group">
          <label htmlFor="username">
            <FormattedMessage id="signup.email" defaultMessage="Email" />
          </label>
          <input
            className="form-control"
            id="username"
            type="email"
            placeholder={intl.formatMessage({
              id: 'signup.placeholder.email',
              defaultMessage: 'name@example.com',
            })}
            value={form.username}
            onChange={noop}
          />

          <div className="error-block">{renderError(form.errors.username)}</div>
        </fieldset>
        <fieldset className="form-group">
          <label htmlFor="password">
            <FormattedMessage id="signup.password" defaultMessage="Password" />:
          </label>
          <input
            className="form-control"
            id="password"
            type="password"
            placeholder={intl.formatMessage({
              id: 'signup.placeholder.password',
              defaultMessage: 'Password',
            })}
            value={form.password}
            onChange={noop}
          />

          <div className="error-block">{renderError(form.errors.password)}</div>
        </fieldset>
        <button
          className="m-t-2 btn btn-primary"
          type="submit"
          disabled={form.progress}
        >
          <FormattedMessage id="signin.loginButton" defaultMessage="Login" />
        </button>
        <div className="gateway__card__options m-t-1">
          <Link to="/signup">
            <FormattedMessage
              id="signin.dontHaveAccount"
              defaultMessage="No account yet?"
            />
          </Link>
          <Link to="/forgot-password">
            <FormattedMessage
              id="signin.forgotPassword"
              defaultMessage="Forgot password?"
            />
          </Link>
        </div>
      </form>
    </div>
  );
}

SignIn.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.shape({
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    progress: PropTypes.bool,
    errors: PropTypes.object,
  }).isRequired,

  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const withFormController = formController({
  validateForm(form) {
    const errors = {};

    if (isEmpty(form.username) || !validator.isEmail(form.username.trim())) {
      errors.username = 'signup.errors.emailFormat';
    }

    if (isEmpty(form.password)) {
      errors.password = 'signup.errors.missingPassword';
    }

    return errors;
  },
});

export default compose(
  injectIntl,
  withGatewayRoot,
  withRedirectCompletion,
  withFormController,
)(SignIn);
