import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

const alertTypes = {
  error: 'alert-danger',
  warning: 'alert-warning',
  success: 'alert-success',
};

function Alert({
  type,
  text,
  textId,
  values,
  noPrefix,
  onClose,
  style,
  children,
}) {
  const handleClose = (e) => {
    if (onClose) {
      onClose(e);
    }
  };

  const prefixes = {
    error: (
      <FormattedMessage
        id="alert.prefixes.error"
        defaultMessage="<strong>Oops!</strong>"
      />
    ),
    warning: (
      <FormattedMessage
        id="alert.prefixes.warning"
        defaultMessage="<strong>Don't worry!</strong>"
      />
    ),
    success: (
      <FormattedMessage
        id="alert.prefixes.success"
        defaultMessage="<strong>Congrats!</strong>"
      />
    ),
  };

  return (
    <div
      className={cx('alert alert-dismissible fade in', alertTypes[type])}
      role="alert"
      style={style}
    >
      {onClose && (
        <button type="button" className="close" aria-label="Close">
          <span aria-hidden="true" onClick={handleClose}>
            &times;
          </span>
        </button>
      )}

      <div className="flex-row">
        <div className="prefix">{!noPrefix && prefixes[type]}</div>
        <div>
          {text || <FormattedMessage id={textId} values={values} />}
          {children && <div className="children">{children}</div>}
        </div>
      </div>
    </div>
  );
}

Alert.propTypes = {
  type: PropTypes.string.isRequired,
  textId: PropTypes.string.isRequired,
  values: PropTypes.object,
  text: PropTypes.node,
  noPrefix: PropTypes.bool,
  onClose: PropTypes.func,
  style: PropTypes.object,
  children: PropTypes.node,
};

Alert.defaultProps = {
  noPrefix: false,
  values: {},
  style: {},
};

export default Alert;
