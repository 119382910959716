import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Modal from 'source/components/common/modal';
import BrandClubDetails from './BrandClubDetails';
import JoinBrandClub from './JoinBrandClub';

function ModalContent({
  brandClub,
  joinForm,
  onJoinFormChange,
  onJoinFormSubmit,
  hasEligibleChannels,
}) {
  const applicationStatus = get(brandClub, 'application.status');

  if (!brandClub) {
    return null;
  }
  if (applicationStatus === 'approved') {
    return <BrandClubDetails brandClub={brandClub} />;
  }
  return (
    <JoinBrandClub
      form={joinForm}
      brandClub={brandClub}
      onFormChange={onJoinFormChange}
      onFormSubmit={onJoinFormSubmit}
      hasEligibleChannels={hasEligibleChannels}
    />
  );
}

ModalContent.propTypes = {
  brandClub: PropTypes.shape({
    application: PropTypes.shape({
      status: PropTypes.oneOf(['new', 'review', 'sentToClient', 'approved']),
    }),
  }),
  hasEligibleChannels: PropTypes.bool,
  joinForm: PropTypes.object.isRequired,
  onJoinFormChange: PropTypes.func.isRequired,
  onJoinFormSubmit: PropTypes.func.isRequired,
};

function BrandClubsModal(props) {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={!!props.brandClub}
      onRequestClose={props.onClose}
      shouldCloseOnOverlayClick
      className="brand-clubs-modal-content"
      overlayClassName="brand-clubs-modal-overlay"
    >
      <>
        <ModalContent {...props} />
      </>
    </Modal>
  );
}

BrandClubsModal.propTypes = {
  brandClub: PropTypes.object,
  joinForm: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onJoinFormChange: PropTypes.func.isRequired,
  onJoinFormSubmit: PropTypes.func.isRequired,
  hasEligibleChannels: PropTypes.bool,
};

BrandClubsModal.defaultProps = {
  brandClub: null,
};

export default BrandClubsModal;
