import ibanUtil from 'iban';

export const isIbanValid = (iban) => ibanUtil.isValid(iban.replace(/ /g, ''));

export const isBicValid = (bic) =>
  /^[a-z]{6}[0-9a-z]{2}([0-9a-z]{3})?$/i.test(bic.replace(/ /g, ''));

export const isIbanMasked = (iban) => iban.indexOf('XXXX') > 0;

export const isBicMasked = (bic) => /^[a-z]{4}[X]{3}/i.test(bic);

export default {
  isIbanValid,
  isBicValid,
  isIbanMasked,
  isBicMasked,
};
