import React from 'react';
import PropTypes from 'prop-types';

import { BlogGaConnectStep as Connect } from './steps';
import {
  withChannelHandlers,
  channelHandlersShape,
} from './withChannelHandlers';
import ChannelPanel from './ChannelPanel';

function ConnectWebsiteWizard({ id, platform, onConnect }) {
  return (
    <div className="container">
      <Connect id={id} platform={platform} onConnect={onConnect} />
    </div>
  );
}

ConnectWebsiteWizard.propTypes = {
  id: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
  onConnect: PropTypes.func.isRequired,
};

function BlogChannel({
  id,
  status,
  form: { name },
  channelHandlers,
  closeButton,
  ...wizardProps
}) {
  return (
    <ChannelPanel
      id={id}
      icon="website"
      expanded
      disabled
      headline={name}
      className="channel channel-blog"
      bodyClassName="channel-body"
      status={status}
      platform={wizardProps.platform}
      newChannel={wizardProps.newChannel}
      closeButton={closeButton}
      showWarning
    >
      <ConnectWebsiteWizard id={id} {...wizardProps} />
    </ChannelPanel>
  );
}

BlogChannel.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  closeButton: PropTypes.node,
  // Provided by withChannelHandlers
  channelHandlers: channelHandlersShape.isRequired,
};

export default withChannelHandlers(BlogChannel);
