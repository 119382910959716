import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { lifecycle, compose } from 'recompose';

import Profile from './components/Profile';
import actions, { formsActions } from './actions';
import { getProfileState } from './selectors';

const withStateInit = lifecycle({
  componentDidMount() {
    const { router, location, query, onLoadState, onRedirectResponse } =
      this.props;

    if (query && query.oauthRedirect) {
      onRedirectResponse(query);
      // We've already parsed the OAuth query params so we can clear them now
      // This prevents users from refreshing the page and retaining them
      router.push(location.pathname);
    }

    const { channelId, uploadInsights } = location.query;

    const showInsightsUpload = uploadInsights === 'true';

    return onLoadState({ channelId, showInsightsUpload });
  },

  componentDidUpdate(prevProps) {
    const currentChannelId = this.props.selectedChannelId;
    const previousChannelId = prevProps.selectedChannelId;

    if (currentChannelId !== previousChannelId) {
      const { location, router } = this.props;

      const nextQuery = new URLSearchParams(location.search);

      if (currentChannelId) {
        nextQuery.set('channelId', currentChannelId || undefined);
      } else {
        nextQuery.delete('channelId');
      }
      router.push(`${location.pathname}?${nextQuery.toString()}`);
    }
  },

  componentWillUnmount() {
    const { redirectResponse, onRedirectResponseClose } = this.props;

    if (redirectResponse) {
      onRedirectResponseClose({ refresh: false });
    }
  },
});

const withSceneReset = lifecycle({
  componentWillUnmount() {
    this.props.onResetScene();
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(actions, dispatch),
  formsActions: formsActions(dispatch),
});

export default compose(
  connect(getProfileState, mapDispatchToProps),
  withRouter,
  withSceneReset,
  withStateInit,
)(Profile);
