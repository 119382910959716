/**
 * Wrapper for "gateway" components which the user arrives
 * at from outside of the app
 */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  LanguagePicker,
  SvgComponents,
  Notification,
} from 'source/components/common';
import Logo from 'source/components/logo';
import SupportButton from 'source/components/support/supportButton';

function GatewayRoot({
  children,
  language,
  languageOptions,
  serverError,
  onChangeLanguage,
  onErrorClose,
  compactLogo,
}) {
  return (
    <div className="gateway__container">
      <Notification error={serverError} onErrorClose={onErrorClose} />
      <header className="gateway__header">
        <Logo
          to="https://www.eqolot.com/"
          className={cx('gateway__header__logo', {
            'gateway__header__logo-compact': compactLogo,
          })}
          compact={compactLogo}
        />
        <LanguagePicker
          className="gateway__header__language-picker"
          value={language}
          options={languageOptions}
          onChange={onChangeLanguage}
        />
      </header>
      <main className="gateway__content">{children}</main>
      <footer className="gateway__footer" />
      <SvgComponents />
      <SupportButton />
    </div>
  );
}

GatewayRoot.propTypes = {
  children: PropTypes.any,
  language: PropTypes.string.isRequired,
  languageOptions: PropTypes.array.isRequired,
  compactLogo: PropTypes.bool,
  serverError: PropTypes.shape({
    translatedError: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),

  onChangeLanguage: PropTypes.func.isRequired,
  onErrorClose: PropTypes.func,
};

GatewayRoot.defaultProps = {
  compactLogo: false,
};

export default GatewayRoot;

export const withGatewayRoot = (Component) => {
  function WithGatewayRoot({ children, ...rest }) {
    return (
      <GatewayRoot {...rest}>
        <Component {...rest}>{children}</Component>
      </GatewayRoot>
    );
  }

  WithGatewayRoot.propTypes = GatewayRoot.propTypes;

  return WithGatewayRoot;
};
