import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';

import ProgressButton from './progressButton';

function CheckIntegrationButton({ intl, status, onClick }) {
  const savingClass = {
    default: '',
    checking: 'btn-saving',
    integrated: 'btn-saved',
    notIntegrated: '',
  };

  return (
    <ProgressButton
      caption={intl.formatMessage({ id: `checkIntegrationButton.${status}` })}
      progressClass={savingClass[status]}
      onClick={onClick}
    />
  );
}

CheckIntegrationButton.propTypes = {
  status: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default injectIntl(CheckIntegrationButton);
