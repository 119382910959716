import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Wizard from '../Wizard';

class StatefulWizard extends Component {
  static propTypes = {
    initialStep: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
  };

  state = {
    id: 'UNUSED_REQUIRED_ID',
    step: this.getStepNumber(this.props.initialStep),
  };

  getStepNumber(stepId) {
    return (
      React.Children.toArray(this.props.children).findIndex(
        (wizardStep) => wizardStep.props.stepId === stepId,
      ) || 0
    );
  }

  static Step = Wizard.Step;

  handleSetStep = ({ step }) => {
    this.setState({ step });
  };

  handleSuccess = (...args) => {
    this.props.onSuccess(...args);

    setTimeout(
      () => this.setState({ step: this.getStepNumber(this.props.initialStep) }),
      500,
    );
  };

  render() {
    return (
      <Wizard
        {...this.state}
        {...this.props}
        onSuccess={this.handleSuccess}
        onSetStep={this.handleSetStep}
      />
    );
  }
}

export default StatefulWizard;
