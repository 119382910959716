import React from 'react';
import PropTypes from 'prop-types';

import * as CustomPropTypes from 'source/utils/propTypes';

import {
  SocialNameStep,
  SocialVerificationStep,
  ChannelPreStep,
  CategoriesWizardStep,
  SubCategoriesWizardStep,
  CategoryWeightWizardStep,
} from './steps';
import {
  withChannelHandlers,
  channelHandlersShape,
} from './withChannelHandlers';
import ChannelPanel from './ChannelPanel';
import Wizard from '../Wizard';
import { hasAlmostOutdatedScreenshots } from '../../utils/channel';

function InstagramChannel({
  id,
  status,
  form,
  name,
  categories,
  platform,
  extension,
  showInsightsUpload,
  restrictAccess,
  wizard,
  onSubmitInsights,
  onDisconnect,
  onEnterDisconnect,
  verificationCode,
  verification,
  temporary,
  onLoadVerificationCode,
  onValidateChannel,
  onWizardSetStep,
  onCreateChannel,
  onSubmit,
  channelHandlers: {
    onChange,
    onErrors,
    onWizardSuccess,
    onInsightsUploadModalOpen,
    onInsightsUploadModalClose,
  },
  closeButton,
}) {
  const showWarning = !temporary
    ? hasAlmostOutdatedScreenshots({
        platform,
        screenshots: form.screenshots,
      })
    : false;

  return (
    <ChannelPanel
      id={id}
      platform={platform}
      expanded
      disabled
      icon="instagram"
      headline={name}
      className="channel channel-instagram"
      bodyClassName="channel-body"
      status={status}
      screenshots={form.screenshots}
      showWarning={showWarning}
      closeButton={closeButton}
    >
      <Wizard
        id={id}
        step={wizard.step}
        props={{ id, platform, form, onChange, onErrors }}
        onSetStep={onWizardSetStep}
        onSuccess={onWizardSuccess}
      >
        <ChannelPreStep
          channelName={name}
          extension={extension}
          showInsightsUpload={showInsightsUpload}
          restrictAccess={restrictAccess}
          status={status}
          form={form}
          id={id}
          onSubmitInsights={onSubmitInsights}
          onInsightsUploadModalOpen={onInsightsUploadModalOpen}
          onInsightsUploadModalClose={onInsightsUploadModalClose}
          onDisconnect={onDisconnect}
          onEnterDisconnect={onEnterDisconnect}
          nextOnConnect
        />
        <Wizard.Step disabled={!temporary}>
          <SocialNameStep onValidateChannel={onValidateChannel} />
        </Wizard.Step>
        <Wizard.Step disabled={!temporary}>
          <SocialVerificationStep
            verificationCode={verificationCode}
            verification={verification}
            onLoadVerificationCode={onLoadVerificationCode}
            onCreateChannel={onCreateChannel}
          />
        </Wizard.Step>
        <CategoriesWizardStep categories={categories} />
        <SubCategoriesWizardStep categories={categories} />
        <CategoryWeightWizardStep onSubmit={onSubmit} />
      </Wizard>
    </ChannelPanel>
  );
}

InstagramChannel.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  form: CustomPropTypes.form.isRequired,
  categories: PropTypes.any,
  platform: PropTypes.string.isRequired,
  extension: PropTypes.object,
  showInsightsUpload: PropTypes.bool,
  restrictAccess: PropTypes.bool,
  wizard: PropTypes.shape({
    step: PropTypes.any.isRequired,
  }),
  verificationCode: PropTypes.any,
  verification: PropTypes.any,
  temporary: PropTypes.bool,
  onLoadVerificationCode: PropTypes.func.isRequired,
  onValidateChannel: PropTypes.func.isRequired,
  onWizardSetStep: PropTypes.func.isRequired,
  onCreateChannel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitInsights: PropTypes.func.isRequired,
  onDisconnect: PropTypes.func.isRequired,
  onEnterDisconnect: PropTypes.func.isRequired,
  closeButton: PropTypes.node,
  // Provided by withChannelHandlers
  channelHandlers: channelHandlersShape.isRequired,
};

InstagramChannel.defaultProps = {
  showInsightsUpload: false,
  categories: null,
  restrictAccess: false,
  wizard: {},
  verificationCode: null,
  verification: null,
  temporary: false,
  closeButton: null,
};

export default withChannelHandlers(InstagramChannel);
