import { replace } from 'react-router-redux';

import constants from 'source/constants';
import { auth } from 'source/utils/auth';

import { redirectToSignIn } from './account';

export const handleGenericRequestError = (err) => ({
  type: constants.GLOBAL_GENERIC_REQUEST_ERROR,
  payload: err,
});

export const handleServerRequestError = (resp, message) => ({
  type: constants.GLOBAL_SERVER_REQUEST_ERROR,
  payload: { resp, message },
});

export const genericRequestErrorHappened = (err) => (dispatch) => {
  dispatch(handleGenericRequestError(err));
  dispatch(replace('/we-are-having-problems'));
};

export const unauthorizedRequestHappend = (resp) => (dispatch) => {
  auth.reset();
  dispatch(handleServerRequestError(resp.data, 'Request unauthorized'));
  dispatch(redirectToSignIn('pending'));
};

export const serverErrorRequestErrorHappened = (resp) => (dispatch) => {
  dispatch(handleServerRequestError(resp.data, 'Request server error'));
  dispatch(replace('/we-are-having-problems'));
};

export const resourceNotFound = (resp) => (dispatch) => {
  dispatch(handleServerRequestError(resp.data, 'Resource not found'));
  dispatch(replace('/not-found'));
};
