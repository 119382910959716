import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

const ShowForLocale = ({ only, otherwise = null, intl, children }) =>
  only.indexOf(intl.locale) !== -1 ? children : otherwise;

ShowForLocale.propTypes = {
  only: PropTypes.arrayOf(PropTypes.string).isRequired,
  otherwise: PropTypes.element,

  children: PropTypes.any,
};

export default injectIntl(ShowForLocale);
