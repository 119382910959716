import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

function Tooltip({
  children,
  className,
  placement,
  visible,
  style,
  x,
  y,
  ...props
}) {
  className = cx(
    'tooltip',
    {
      in: visible,
      [`tooltip-${placement}`]: placement != null,
    },
    className,
  );

  if (x != null) {
    style.left = x;
  }

  if (y != null) {
    style.top = y;
  }

  return (
    visible && (
      <div className={className} style={style} {...props}>
        <div className="tooltip-arrow" />
        <div className="tooltip-inner">{children}</div>
      </div>
    )
  );
}

Tooltip.propTypes = {
  visible: PropTypes.bool.isRequired,
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.any,
};

Tooltip.defaultProps = {
  visible: true,
  placement: 'bottom',
  x: undefined,
  y: undefined,
  className: undefined,
  style: undefined,
  children: undefined,
};

export default Tooltip;
