import React from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import first from 'lodash/first';
import config from 'config';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  compose,
  branch,
  withHandlers,
  withStateHandlers,
  renderComponent,
  defaultProps,
} from 'recompose';

import { links } from 'source/i18n';
import {
  ActivityStreamItem,
  ActivityStreamHeader,
  ActivityStreamBody,
} from 'source/components/common/activityStream';
import { SubmitButton } from 'source/components/common/button';
import Form, {
  FormGroup,
  FormActions,
  MediaGallery,
  MediaGalleryItem,
} from 'source/components/common/form';
import Icon from 'source/components/common/icon';
import MediaUpload from 'source/components/common/form/mediaUpload';
import { MessageCard } from 'source/components/common/message';
import { Alert } from 'source/components/common';
import { hasAttributes } from 'source/utils';
import Markdown from 'source/components/common/markdown';
import FileUploadError from 'source/components/common/form/fileUploadError';
import QRCode from 'source/components/common/qrCode';

// Helpers

/**
 * Wrap the given file upload handler to be compatible with our
 * image upload component API
 */
const withUploadHandler = withHandlers({
  onFileUpload:
    ({ onFileUpload }) =>
    ({ file }, onUploadProgress) =>
      onFileUpload(file, onUploadProgress).then((response) => {
        if (response.code === 'ECONNABORTED') {
          return Promise.reject(new Error('Timeout'));
        }
        if (response.status >= 400) {
          return Promise.reject(response.data);
        }
        return pick(first(response.data), ['url', 'filename', 'assetId']);
      }),
});

const withFileChangeHandler = withHandlers({
  onFileChange:
    ({ formName, onChange }) =>
    (urls) =>
      onChange(formName, 'screenshots', urls),
});

const withUploadError = withStateHandlers(() => ({ uploadError: null }), {
  setUploadError: () => (err) => ({ uploadError: err }),
});

function UploadsPreview({ afterPublicationUploads }) {
  return (
    <MediaGallery className="aurora-media-gallery">
      {afterPublicationUploads.screenshots.map((item) => (
        <MediaGalleryItem
          key={item.url}
          id={item.url}
          url={item.url}
          removable={false}
        />
      ))}
    </MediaGallery>
  );
}

UploadsPreview.propTypes = {
  afterPublicationUploads: PropTypes.shape({
    screenshots: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

// Components

/* eslint-disable react/prop-types */
function VerificationForm({
  form,
  formName,
  application,
  uploadError,
  setUploadError,
  onFileUpload,
  onFileChange,
  onChange,
  onSubmit,
  intl,
}) {
  const data = form[formName] || {};
  const notEnoughData = isEmpty(data.screenshots);
  const applicationUrl = `${config.app.url}/sponsored-posts/applications/${application.id}`;
  const { type: platform } = application;

  return (
    <Form id={formName} onChange={onChange} onSubmit={onSubmit}>
      {data.errors._error && <Alert type="error" textId={data.errors._error} />}
      <div className="sponsored-posts-insights">
        <div className="instructions">
          {platform === 'instagram' && (
            <Markdown
              markdown={intl.formatMessage({
                id: 'sponsoredPosts.campaigns.publication.insights.instagram.message_MD',
                defaultMessage:
                  'Take a screenshot of the publication insights in the instagram app and upload it here.\n- for posts, 7 days after publication\n- for stories, the day after publication',
              })}
            />
          )}
          {platform === 'tiktok' && (
            <Markdown
              markdown={intl.formatMessage({
                id: 'sponsoredPosts.campaigns.publication.insights.tiktok.message_MD',
                defaultMessage:
                  'Take a screenshot of the publication insights in the TikTok app and upload it here.',
              })}
            />
          )}
          <p className="relative p-l-2">
            <Icon name="info" className="message-icon" />
            <span className="strong">
              {platform === 'instagram' && (
                <a
                  href={intl.formatMessage(
                    links.captureInstagramInsightsHelpLink,
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  {platform === 'instagram' && (
                    <FormattedMessage
                      id="sponsoredPosts.campaigns.publication.insights.instagram.help"
                      defaultMessage="See here how to capture post and story Insights on Instagram"
                    />
                  )}
                </a>
              )}
              {platform === 'tiktok' && (
                <a
                  href={intl.formatMessage(links.captureTiktokInsightsHelpLink)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FormattedMessage
                    id="sponsoredPosts.campaigns.publication.insights.tiktok.help"
                    defaultMessage="See here how to capture post Insights on Tiktok"
                  />
                </a>
              )}
            </span>
          </p>
        </div>
        <div className="qr-code m-l-1">
          <QRCode className="qr-code-image " data={applicationUrl} />
          <div className="qr-code-hint p-x-1">
            <FormattedMessage
              id="sponsoredPosts.campaigns.publication.insights.qrCodeHint"
              defaultMessage="Scan this to upload from your phone"
            />
          </div>
        </div>
      </div>
      <FormGroup>
        <MediaUpload
          errors={data.errors}
          onFileUpload={onFileUpload}
          onError={setUploadError}
          onChange={onFileChange}
          initialValues={data.screenshots}
          allowedMIMETypes={['image/*']}
        />

        {uploadError ? (
          <MessageCard type="warning">
            <FileUploadError error={uploadError} />
          </MessageCard>
        ) : null}
      </FormGroup>
      <FormActions horizontal>
        <div>
          <SubmitButton
            disabled={
              hasAttributes(data.errors) || notEnoughData || data.submitting
            }
          >
            <FormattedMessage
              id="sponsoredPosts.campaigns.publication.insights.submit"
              defaultMessage="Send"
            />
          </SubmitButton>
        </div>
        <div>
          {platform === 'instagram' && (
            <FormattedMessage
              id="sponsoredPosts.campaigns.publication.insights.instagram.submitInfoText"
              defaultMessage="Please only send images after you added all post and story insights."
            />
          )}
          {platform === 'tiktok' && (
            <FormattedMessage
              id="sponsoredPosts.campaigns.publication.insights.tiktok.submitInfoText"
              defaultMessage="Please only send images after you added all post insights."
            />
          )}
        </div>
      </FormActions>
    </Form>
  );
}

function Inactive({ intl }) {
  return (
    <ActivityStreamItem iconName="graph">
      <ActivityStreamHeader
        title={intl.formatMessage({
          id: 'sponsoredPosts.campaigns.publication.insights',
          defaultMessage: 'Upload publication insights',
        })}
      />
    </ActivityStreamItem>
  );
}

function Active(props) {
  const { intl } = props;

  return (
    <ActivityStreamItem status="active" iconName="graph">
      <ActivityStreamHeader
        title={intl.formatMessage({
          id: 'sponsoredPosts.campaigns.publication.insights',
          defaultMessage: 'Upload publication insights',
        })}
      />

      <ActivityStreamBody>
        <VerificationForm {...props} />
      </ActivityStreamBody>
    </ActivityStreamItem>
  );
}

function Pending({
  intl,
  workflow: { lastUpdate },
  application: { afterPublicationUploads },
}) {
  return (
    <ActivityStreamItem status="active" iconName="graph" expandable>
      <ActivityStreamHeader
        title={intl.formatMessage({
          id: 'sponsoredPosts.campaigns.publication.insights',
          defaultMessage: 'Upload publication insights',
        })}
        date={lastUpdate}
      />

      <ActivityStreamBody>
        <MessageCard type="info">
          <strong>
            <FormattedMessage
              id="sponsoredPosts.campaigns.publication.insights.pending"
              defaultMessage="We have received your publication insights and we'll review them shortly."
            />
          </strong>
        </MessageCard>
        <UploadsPreview afterPublicationUploads={afterPublicationUploads} />
      </ActivityStreamBody>
    </ActivityStreamItem>
  );
}

function RejectedHint({ feedback }) {
  return (
    <MessageCard type="info">
      <strong>
        <FormattedMessage
          id="sponsoredPosts.campaigns.publication.insights.rejected.feedback"
          defaultMessage="We have some feedback for you."
        />
      </strong>
      <span>
        {feedback ? (
          <Markdown typeName="span" markdown={feedback} noParagraphs />
        ) : (
          <FormattedMessage
            id="sponsoredPosts.campaigns.publication.insights.rejected.message"
            defaultMessage="We have sent you an email with our feedback. Please check the points and send us an email back when you're ready or have any questions."
          />
        )}
      </span>
    </MessageCard>
  );
}

function Rejected(props) {
  const {
    intl,
    workflow: {
      lastUpdate,
      payload: { showHint, feedback },
    },
  } = props;

  return (
    <ActivityStreamItem status="failed" iconName="graph">
      <ActivityStreamHeader
        title={intl.formatMessage({
          id: 'sponsoredPosts.campaigns.publication.insights',
          defaultMessage: 'Upload publication insights',
        })}
        date={lastUpdate}
      />

      <ActivityStreamBody>
        {showHint ? <RejectedHint feedback={feedback} /> : null}
        <VerificationForm {...props} />
      </ActivityStreamBody>
    </ActivityStreamItem>
  );
}

function Done({
  intl,
  workflow: { lastUpdate },
  application: { afterPublicationUploads },
}) {
  return (
    <ActivityStreamItem
      status="done"
      iconName="graph"
      initialExpanded={false}
      expandable
    >
      <ActivityStreamHeader
        title={intl.formatMessage({
          id: 'sponsoredPosts.campaigns.publication.insights',
          defaultMessage: 'Upload publication insights',
        })}
        date={lastUpdate}
      />

      <UploadsPreview afterPublicationUploads={afterPublicationUploads} />
    </ActivityStreamItem>
  );
}

/* eslint-enable react/prop-types */

const statusToComponent = compose(
  branch(
    (props) => props.workflow.status === 'inactive',
    renderComponent(Inactive),
  ),

  branch(
    (props) => props.workflow.status === 'active',
    renderComponent(Active),
  ),

  branch(
    (props) => props.workflow.status === 'pending',
    renderComponent(Pending),
  ),

  branch(
    (props) => props.workflow.status === 'rejected',
    renderComponent(Rejected),
  ),

  branch((props) => props.workflow.status === 'done', renderComponent(Done)),
);

const DefaultComponent = Inactive;

DefaultComponent.propTypes = {
  formName: PropTypes.string.isRequired,
  workflow: PropTypes.shape({
    status: PropTypes.oneOf([
      'inactive',
      'active',
      'pending',
      'rejected',
      'done',
    ]).isRequired,
    payload: PropTypes.shape({
      showHint: PropTypes.bool,
      feedback: PropTypes.string,
    }),
  }).isRequired,
  form: PropTypes.object.isRequired,
  campaign: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onAction: PropTypes.any,
};

export default compose(
  defaultProps({
    formName: 'afterPublicationUploads',
  }),

  injectIntl,
  withFileChangeHandler,
  withUploadError,
  withUploadHandler,
  statusToComponent,
)(DefaultComponent);
