import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { injectIntl } from 'react-intl';

import {
  ChannelPreStep,
  CategoriesWizardStep,
  SubCategoriesWizardStep,
  CategoryWeightWizardStep,
  InstagramOAuthConnectStep,
} from './steps';
import {
  withChannelHandlers,
  channelHandlersShape,
} from './withChannelHandlers';

import Wizard from '../Wizard';
import ChannelPanel from './ChannelPanel';

function OAuthChannel({
  id,
  status,
  form,
  form: { name },
  categories,
  platform,
  temporary,
  restrictAccess,
  wizard,
  onConnect,
  onDisconnect,
  onEnterDisconnect,
  onWizardSetStep,
  onSubmit,
  intl,
  channelHandlers: { onChange, onErrors, onWizardSuccess },
  closeButton,
}) {
  const showInstagramOAuthConnect = platform === 'instagram' && temporary;

  const instagramOAuthHeadline =
    name ||
    intl.formatMessage({
      id: 'channels.instagram.connectOAuth.headline',
      defaultMessage: 'Connect to Instagram',
    });

  const headline = showInstagramOAuthConnect ? instagramOAuthHeadline : name;

  const icon = platform === 'pinterest' ? 'pinterest-white' : platform;

  return (
    <ChannelPanel
      id={id}
      icon={icon}
      expanded
      disabled
      headline={headline}
      platform={platform}
      className={cx('channel', `channel-${platform}`)}
      bodyClassName="channel-body"
      status={status}
      closeButton={closeButton}
    >
      <Wizard
        id={id}
        step={wizard.step}
        props={{ id, platform, form, onChange, onErrors }}
        onSetStep={onWizardSetStep}
        onSuccess={onWizardSuccess}
      >
        {showInstagramOAuthConnect ? (
          <InstagramOAuthConnectStep id={id} onConnect={onConnect} />
        ) : (
          <ChannelPreStep
            restrictAccess={restrictAccess}
            status={status}
            form={form}
            id={id}
            onConnect={onConnect}
            onDisconnect={onDisconnect}
            onEnterDisconnect={onEnterDisconnect}
          />
        )}
        <CategoriesWizardStep categories={categories} />
        <SubCategoriesWizardStep categories={categories} />
        <CategoryWeightWizardStep onSubmit={onSubmit} />
      </Wizard>
    </ChannelPanel>
  );
}

OAuthChannel.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  platform: PropTypes.string.isRequired,
  restrictAccess: PropTypes.bool,
  wizard: PropTypes.object.isRequired,
  onConnect: PropTypes.func.isRequired,
  onDisconnect: PropTypes.func.isRequired,
  onEnterDisconnect: PropTypes.func.isRequired,
  onWizardSetStep: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  temporary: PropTypes.bool,
  closeButton: PropTypes.node,
  // Provided by withChannelHandlers
  channelHandlers: channelHandlersShape.isRequired,
};

OAuthChannel.defaultProps = {
  restrictAccess: false,
  closeButton: null,
};

export default injectIntl(withChannelHandlers(OAuthChannel));
