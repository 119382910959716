import { withProps } from 'recompose';

const withTaxFields = withProps(() => ({
  taxFields: [
    'tax.hasVatId',
    'tax.vatNotRegistered',
    'tax.taxDeclarationConfirmation',
    'tax.vatId',
    'tax.taxId',
    'tax.taxIdCode',
  ],
}));

export default withTaxFields;
