import PropTypes from 'prop-types';
import React, { Children, cloneElement } from 'react';
import classNames from 'classnames';

import { Panel } from '../accordion';

function FormPanel({ className, children, ...props }) {
  className = classNames('form-panel', className);

  children = Children.map(children, (child, key) =>
    cloneElement(child, {
      ...child.props,
      toggleIcon: true,
      key,
    }),
  );

  return (
    <Panel className={className} {...props}>
      {children}
    </Panel>
  );
}

FormPanel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default FormPanel;
