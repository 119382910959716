import last from 'lodash/last';
import get from 'lodash/get';

export const isCompletedWorkflow = (workflow) => {
  const steps = get(workflow, 'steps');
  const lastStep = last(steps);

  if (!lastStep) {
    return false;
  }

  return lastStep.status === 'done';
};
