import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  genericError: {
    id: 'errors.message.generic',
    defaultMessage: 'An unexpected problem occurred, so please try again.',
  },
});

function ToastsBar({ error, onErrorClose }) {
  const renderError = () => {
    const message = error.translatedError || messages.genericError;

    return (
      <div className="oyl-notification oyl-notification--error oyl-notification--centered">
        <span>
          <FormattedMessage {...message} />
        </span>
        <a className="oyl-button" href="#" onClick={onErrorClose}>
          <FormattedMessage id="errors.controls.close" defaultMessage="Close" />
        </a>
      </div>
    );
  };

  return (
    <div className="toast-errors-container">{error ? renderError() : null}</div>
  );
}

ToastsBar.propTypes = {
  onErrorClose: PropTypes.func,
  error: PropTypes.object,
};

export default ToastsBar;
