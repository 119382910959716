import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose, pure, withHandlers } from 'recompose';
import cx from 'classnames';

const enhance = compose(
  pure,
  withHandlers({
    onClick:
      ({ disabled, onClick }) =>
      (e) =>
        disabled ? undefined : onClick(e),
  }),
);

function PrevStep({ className, disabled, onClick }) {
  return (
    <button
      className={cx('btn', 'btn-link', 'wizard-back-button', className, {
        disabled,
      })}
      onClick={onClick}
    >
      <svg className="icon">
        <use xlinkHref="#icon-arrow-back" />
      </svg>
      <FormattedMessage id="controls.captions.back" defaultMessage="Back" />
    </button>
  );
}

PrevStep.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

PrevStep.defaultProps = {
  className: '',
  disabled: false,
};

PrevStep = enhance(PrevStep);

function NextStep({ className, disabled, onClick, isLastStep }) {
  return (
    <button
      className={cx('btn', 'btn-primary', 'wizard-forward-button', className, {
        disabled,
      })}
      onClick={onClick}
    >
      {isLastStep ? (
        <FormattedMessage
          id="controls.captions.finishButton"
          defaultMessage="Finish"
        />
      ) : (
        <FormattedMessage
          id="controls.captions.nextButton"
          defaultMessage="Next"
        />
      )}
    </button>
  );
}

NextStep.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  isLastStep: PropTypes.bool,
};

NextStep.defaultProps = {
  className: '',
  disabled: false,
  isLastStep: false,
};

NextStep = enhance(NextStep);

function NextPreButtonList({
  disablePrev,
  disableNext,
  onPrevStep,
  onNextStep,
  classNamePrev,
  classNameNext,
  isLastStep,
}) {
  return (
    <div>
      {onPrevStep ? (
        <PrevStep
          disabled={disablePrev}
          onClick={onPrevStep}
          className={classNamePrev}
        />
      ) : null}
      {onNextStep ? (
        <NextStep
          disabled={disableNext}
          onClick={onNextStep}
          className={classNameNext}
          isLastStep={isLastStep}
        />
      ) : null}
    </div>
  );
}

NextPreButtonList.propTypes = {
  disablePrev: PropTypes.bool,
  disableNext: PropTypes.bool,
  onPrevStep: PropTypes.func,
  onNextStep: PropTypes.func,
  classNamePrev: PropTypes.string,
  classNameNext: PropTypes.string,
  isLastStep: PropTypes.bool,
};

NextPreButtonList.defaultProps = {
  disablePrev: false,
  disableNext: false,
  onPrevStep: null,
  onNextStep: null,
  isLastStep: false,
};

export default NextPreButtonList;
