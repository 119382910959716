import PropTypes from 'prop-types';
import React from 'react';

import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';

import { FormError } from 'source/components/common';

/* == form helpers == */

const normalizeForm = (form) => {
  const normalizedForm = Object.keys(form).reduce((memo, key) => {
    const value = form[key];

    if (typeof value === 'string') {
      memo[key] = value.trim();
    } else {
      memo[key] = value;
    }

    return memo;
  }, {});

  return normalizedForm;
};

const handleFormChange =
  ({ id, onChange, isCheckbox, isRadio }) =>
  (e) => {
    const formKey = isRadio ? e.target.name : e.target.id;
    const formKeyValue = isCheckbox ? e.target.checked : e.target.value;

    onChange(id, formKey, formKeyValue);
  };

const handleFormSubmit =
  ({ id, form, onSubmit = noop, onErrors = noop }, validate) =>
  (e) => {
    e.preventDefault();
    e.stopPropagation();

    /**
     * The validate function can either return a plain object for synchronous
     * validation, which will be used as an errors object or return a promise
     * for asynchronous validation, where the rejected value will be used as
     * an errors object.
     */
    const handleValidateResult = (validateResult) => {
      // wait for the promise and resolve with onSubmit and reject with onErrors
      if (typeof validateResult.then === 'function') {
        return validateResult
          .then(() => onSubmit(id, form))
          .catch((errors) => onErrors(id, errors));
      }

      // for sync response check
      if (!isEmpty(validateResult)) {
        return onErrors(id, validateResult);
      }

      return onSubmit(id, form);
    };

    const normalizedForm = normalizeForm(form);
    const validateResult = validate(normalizedForm);

    return handleValidateResult(validateResult);
  };

const forms =
  ({ validateForm }) =>
  (Component) => {
    class FormsComponent extends React.Component {
      handleChange = (e) => {
        const { id, onChange } = this.props;
        const isCheckbox = e.target.type === 'checkbox';
        const isRadio = e.target.type === 'radio';

        return handleFormChange({ id, onChange, isCheckbox, isRadio })(e);
      };

      handleSubmit = (e) => {
        const { id, form, onSubmit, onErrors } = this.props;

        return handleFormSubmit(
          { id, form, onSubmit, onErrors },
          validateForm,
        )(e);
      };

      render() {
        return (
          <Component
            {...this.props}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
          />
        );
      }
    }

    FormsComponent.propTypes = {
      id: PropTypes.string.isRequired,
      form: PropTypes.object.isRequired,
      onChange: PropTypes.func.isRequired,
      onSubmit: PropTypes.func.isRequired,
      onErrors: PropTypes.func.isRequired,
    };

    return FormsComponent;
  };

// Helper to render a form error (if it exists)
export const renderError = (messageId) =>
  messageId ? <FormError error={{ messageId }} /> : null;

export default forms;
