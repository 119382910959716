import { createStorage } from '.';

export const auth = {
  init({ accessToken, godMode = false }) {
    if (godMode) {
      createStorage({ session: true }).save('accessToken', accessToken);
    } else {
      createStorage().save('accessToken', accessToken);
    }
  },

  reset() {
    createStorage({ session: true }).remove('accessToken');
    createStorage().remove('accessToken');
  },

  isGodMode() {
    const accessToken = createStorage({ session: true }).read('accessToken');

    return Boolean(accessToken);
  },

  getAccessToken() {
    const accessTokenGodMode = createStorage({ session: true }).read(
      'accessToken',
    );

    if (accessTokenGodMode) {
      return accessTokenGodMode;
    }

    return createStorage().read('accessToken');
  },
};
