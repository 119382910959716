import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';

import PasswordStrength from 'source/components/common/passwordStrength';
import { Alert, FormError } from 'source/components/common';
import forms from 'source/utils/forms';
import { messageProp } from 'source/utils/propTypes';

const validateForm = (form) => {
  const errors = {};

  if (isEmpty(form.currentPassword)) {
    errors.currentPassword =
      'accountSettings.changePassword.errorMissingValueCurrentPassword';
  }

  if (isEmpty(form.newPassword)) {
    errors.newPassword =
      'accountSettings.changePassword.errorMissingValueNewPassword';
  }

  if (isEmpty(form.repeatPassword)) {
    errors.repeatPassword =
      'accountSettings.changePassword.errorMissingValueRepeatPassword';
  } else if (form.newPassword !== form.repeatPassword) {
    errors.repeatPassword =
      'accountSettings.changePassword.errorPasswordNotMatch';
  }

  return errors;
};

class ChangePasswordForm extends Component {
  handleAlertClose = () => {
    const { id, onAlertClose } = this.props;

    return onAlertClose(id);
  };

  renderError(name) {
    const {
      form: { errors },
    } = this.props;

    const error = errors[name];
    return error ? <FormError error={{ messageId: error }} /> : null;
  }

  renderAlert() {
    const {
      form: { message },
    } = this.props;

    if (!message) {
      return null;
    }

    return (
      <div className="alert-container">
        <Alert {...message} onClose={this.handleAlertClose} />
      </div>
    );
  }

  render() {
    const { id, intl, form, onChange, onSubmit } = this.props;

    return (
      <div>
        {this.renderAlert()}

        <div className="row">
          <div className="col-md-12">
            <form id={id} onSubmit={onSubmit} onChange={onChange}>
              <fieldset
                className={cx('form-group', {
                  'has-danger': !!form.errors.currentPassword,
                })}
              >
                <label htmlFor="currentPassword">
                  <FormattedMessage
                    id="accountSettings.changePassword.enterCurrentPassword"
                    defaultMessage="Enter current password"
                  />
                  :
                </label>
                <input
                  type="password"
                  className="form-control label-m"
                  id="currentPassword"
                  value={form.currentPassword}
                  onChange={noop}
                />

                <div className="error-block">
                  {this.renderError('currentPassword')}
                </div>
              </fieldset>
              <fieldset
                className={cx('form-group', {
                  'has-danger': !!form.errors.newPassword,
                })}
              >
                <label htmlFor="newPassword">
                  <FormattedMessage
                    id="accountSettings.changePassword.enterNewPassword"
                    defaultMessage="Enter new password"
                  />
                  :
                </label>
                <input
                  type="password"
                  className="form-control label-m"
                  id="newPassword"
                  value={form.newPassword}
                  onChange={noop}
                  placeholder={intl.formatMessage({
                    id: 'accountSettings.changePassword.enterNewPasswordHint',
                    defaultMessage:
                      'Tip: Make your password safer by using special characters.',
                  })}
                />

                <div className="error-block">
                  {this.renderError('newPassword')}
                </div>
                <PasswordStrength password={form.newPassword} />
              </fieldset>
              <fieldset
                className={cx('form-group', {
                  'has-danger': !!form.errors.repeatPassword,
                })}
              >
                <label htmlFor="repeatPassword">
                  <FormattedMessage
                    id="accountSettings.changePassword.repeatNewPassword"
                    defaultMessage="Repeat new password"
                  />
                  :
                </label>
                <input
                  type="password"
                  className="form-control label-m"
                  id="repeatPassword"
                  value={form.repeatPassword}
                  onChange={noop}
                />

                <div className="error-block">
                  {this.renderError('repeatPassword')}
                </div>
              </fieldset>
              <button type="submit" className="btn btn-secondary">
                <FormattedMessage
                  id="accountSettings.changePassword.changePasswordButton"
                  defaultMessage="Change Password"
                />
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

ChangePasswordForm.propTypes = {
  id: PropTypes.string.isRequired,

  form: PropTypes.shape({
    currentPassword: PropTypes.string.isRequired,
    newPassword: PropTypes.string.isRequired,
    repeatPassword: PropTypes.string.isRequired,
    message: messageProp,
    errors: PropTypes.object.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onAlertClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default injectIntl(forms({ validateForm })(ChangePasswordForm));
