import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';

import cx from 'classnames';
import * as propTypes from 'source/utils/propTypes';
import {
  isPending,
  hasOutdatedScreenshots,
} from 'source/scenes/profile/utils/channel';

const STATE_TYPES = {
  READY: 'READY',
  NOT_READY: 'NOT_READY',
  PENDING: 'PENDING',
};

const states = {
  [STATE_TYPES.READY]: {
    message: defineMessage({
      id: 'status.ready',
      defaultMessage: 'ready',
    }),
    color: 'green',
  },
  [STATE_TYPES.NOT_READY]: {
    message: defineMessage({
      id: 'status.notReady',
      defaultMessage: 'not ready',
    }),
    color: 'red',
  },
  [STATE_TYPES.PENDING]: {
    message: defineMessage({
      id: 'status.pending',
      defaultMessage: 'pending',
    }),
    color: 'orange',
  },
};

function ChannelStatus({
  className,
  ready,
  readyState,
  platform,
  screenshots,
}) {
  let state;

  if (!ready) {
    state = states.NOT_READY;
  } else if (
    isPending(readyState) ||
    hasOutdatedScreenshots({ platform, screenshots })
  ) {
    state = states.PENDING;
  } else {
    state = states.READY;
  }

  return (
    <div className={cx('status-container', className)}>
      <div className={cx('status-indicator', state.color)} />
      <div className="status-text text-truncate">
        <FormattedMessage {...state.message} />
      </div>
    </div>
  );
}

ChannelStatus.propTypes = {
  className: PropTypes.string,
  ready: PropTypes.bool.isRequired,
  readyState: PropTypes.shape({
    hasCachedData: PropTypes.bool,
  }),
  platform: propTypes.platform.isRequired,
  screenshots: propTypes.screenshots,
};

ChannelStatus.defaultProps = {
  className: '',
  readyState: {},
};

export default ChannelStatus;
