import React from 'react';
import LinearGradient from 'source/components/common/vis/linearGradient';

const pastelStops = {
  website: [
    { offset: 0, color: '#804248', opacity: 0.2 },
    { offset: 0.95, color: '#804248', opacity: 0 },
  ],
  twitter: [
    { offset: 0, color: '#0fa1ef', opacity: 0.2 },
    { offset: 0.95, color: '#0fa1ef', opacity: 0 },
  ],
  instagram: [
    { offset: 0, color: '#cc9900', opacity: 0.2 },
    { offset: 0.95, color: '#cc9900', opacity: 0 },
  ],
  pinterest: [
    { offset: 0, color: '#e60023', opacity: 0.2 },
    { offset: 0.95, color: '#e60023', opacity: 0 },
  ],
  youtube: [
    { offset: 0, color: '#ff0000', opacity: 0.2 },
    { offset: 0.95, color: '#ff0000', opacity: 0 },
  ],
  ga: [
    { offset: 0, color: '#804248', opacity: 0.2 },
    { offset: 0.95, color: '#804248', opacity: 0 },
  ],
  tiktok: [
    { offset: 0, color: '#000', opacity: 0.2 },
    { offset: 0.95, color: '#000', opacity: 0 },
  ],
};

function SvgGradients() {
  const gradients = Object.keys(pastelStops).map((platform) => {
    const stops = pastelStops[platform];
    return (
      <defs key={platform}>
        <LinearGradient
          x1="0"
          y1="0"
          x2="0"
          y2="1"
          id={`gradient-${platform}`}
          stops={stops}
        />
      </defs>
    );
  });
  return (
    <svg style={{ position: 'absolute', left: '-9999px', height: 0 }}>
      {gradients}
    </svg>
  );
}

export default SvgGradients;
