import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { FormattedMessage, injectIntl } from 'react-intl';
import { FormError } from 'source/components/common';

class JoinBrandClubForm extends React.PureComponent {
  handleChange = (e) => {
    e.preventDefault();
    const { form, onChange } = this.props;
    const {
      target: { name, value },
    } = e;

    return onChange(form, name, value);
  };

  handleSubmit = (e) => {
    e.preventDefault();

    return this.props.onSubmit();
  };

  render() {
    const {
      brandClub: { translationKey },
      form,
      intl,
      hasEligibleChannels,
    } = this.props;

    const isSendButtonDisabled =
      Object.keys(form.errors).length || form.submitting;

    return (
      <>
        <FormattedMessage id={`brandClubs.${translationKey}.joinRequest`} />
        <form onSubmit={this.handleSubmit}>
          {hasEligibleChannels && (
            <textarea
              className="pitch m-t-1 p-1"
              rows="6"
              name="pitch"
              value={form.pitch}
              onChange={this.handleChange}
              placeholder={intl.formatMessage({
                id: 'brandClubs.join.pitch.placeholder',
                defaultMessage: 'We should collaborate because...',
              })}
            />
          )}

          {form.errors.pitch && (
            <FormError error={{ messageId: form.errors.pitch.messageId }} />
          )}

          {!hasEligibleChannels && (
            <div className="no-channels-message">
              <FormattedMessage
                id="brandClubs.noChannels.info"
                defaultMessage="Please set up your channels to join a Private Network"
              />
              <Link to="/profile">
                <FormattedMessage
                  id="brandClubs.noChannels.profileLink"
                  defaultMessage="Go to Profile"
                />
              </Link>
            </div>
          )}

          {hasEligibleChannels && (
            <button
              className="send-btn m-y-1"
              type="submit"
              disabled={isSendButtonDisabled}
            >
              <FormattedMessage
                id="brandClubs.join.sendButton"
                defaultMessage="Send"
              />
            </button>
          )}
        </form>
      </>
    );
  }
}

JoinBrandClubForm.propTypes = {
  brandClub: PropTypes.shape({
    translationKey: PropTypes.string.isRequired,
  }),

  hasEligibleChannels: PropTypes.bool,

  form: PropTypes.shape({
    errors: PropTypes.object.isRequired,
    submitted: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    pitch: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default injectIntl(JoinBrandClubForm);
