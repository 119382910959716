import React from 'react';
import { injectIntl } from 'react-intl';

import {
  ActivityStreamItem,
  ActivityStreamHeader,
} from 'source/components/common/activityStream';

function VerificationInactive({ intl }) {
  return (
    <ActivityStreamItem iconName="check-document">
      <ActivityStreamHeader
        title={intl.formatMessage({
          id: 'sponsoredPosts.campaigns.verification',
          defaultMessage: 'Verification',
        })}
      />
    </ActivityStreamItem>
  );
}

VerificationInactive.propTypes = {};

export default injectIntl(VerificationInactive);
