import { defineMessages } from 'react-intl';

export const genderMessages = defineMessages({
  F: {
    id: 'genders.f',
    defaultMessage: 'Female',
  },
  M: {
    id: 'genders.m',
    defaultMessage: 'Male',
  },
  D: {
    id: 'genders.d',
    defaultMessage: 'Diverse',
  },
});
