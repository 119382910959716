// sponsored posts - scenes setup
import SponsoredPostsCampaigns from 'source/scenes/exploreCampaigns/containers/ExploreCampaigns';
import SponsoredPostsApplications from 'source/scenes/myCampaigns/containers/MyCampaigns';
import SponsoredPostsCampaignDetail from 'source/scenes/campaignDetails/containers/CampaignDetail';
import SponsoredPostsApplicationDetail from 'source/scenes/campaignDetails/containers/ApplicationDetail';

// sponsored posts - old setup
import SponsoredPostsRoot from './app/sponsoredPosts';
import SponsoredPostsNavigation from './app/sponsoredPosts/navigation';

// * import/exports
import * as GatewayImport from './gateway';

// root level routes
export { default as Root } from './root';

// gateway level routes
export const Gateway = GatewayImport;

// app level routes
export { default as App } from './app';

// brand clubs
export { default as BrandClubs } from 'source/scenes/brandClubs/container';

// profile
export { default as Profile } from 'source/scenes/profile/container';

export { default as AccountSettings } from 'source/scenes/accountSettings/container';
export { default as Revenues } from 'source/scenes/revenues/container';

// new sponsored posts
export const SponsoredPosts = {
  Root: SponsoredPostsRoot,
  Navigation: SponsoredPostsNavigation,
  Campaigns: SponsoredPostsCampaigns,
  Applications: SponsoredPostsApplications,
  CampaignDetail: SponsoredPostsCampaignDetail,
  ApplicationDetail: SponsoredPostsApplicationDetail,
};
