import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';

import { withGatewayRoot } from './root';

function Error({ hasAccessToken, route }) {
  let errorCode = null;
  const code = route.status;
  const error = {
    code,
    title: <FormattedMessage id={`errors.${code}.title`} />,
    message: <FormattedMessage id={`errors.${code}.message`} />,
  };

  if (error.code != null) {
    errorCode = <strong className="card-error-code">{error.code}</strong>;
  }

  return (
    <div className="gateway__card gateway__card--error text-center">
      <div className="card-block">
        {errorCode}
        <h4 className="card-title">{error.title}</h4>
        <p>{error.message}</p>
        {hasAccessToken ? (
          <Link to="/">
            <FormattedMessage
              id="errors.controls.backToDashboard"
              defaultMessage="Back to Dashboard"
            />
          </Link>
        ) : (
          <Link to="/signin">
            <FormattedMessage
              id="errors.controls.backToSignIn"
              defaultMessage="Back to Sign In"
            />
          </Link>
        )}
      </div>
    </div>
  );
}

Error.propTypes = {
  hasAccessToken: PropTypes.bool,
  route: PropTypes.object,
};

export default withGatewayRoot(Error);
