import PropTypes from 'prop-types';
import {
  branch,
  compose,
  lifecycle,
  setPropTypes,
  renderComponent,
} from 'recompose';
import { connect } from 'react-redux';

import { ProgressBar } from 'source/components/common';
import { AccountSettings } from './components';
import actions from './actions';
import selector from './selector';

const withAccountSettings = compose(
  setPropTypes({
    loadUser: PropTypes.func.isRequired,
  }),
  lifecycle({
    componentDidMount() {
      /** load billing information of the user once the container is mounted */
      this.props.loadUser({ fields: ['billingInformation'] });
    },
  }),
);

const withResetScene = compose(
  setPropTypes({
    onResetScene: PropTypes.func.isRequired,
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.onResetScene();
    },
  }),
);

/**
 * prevent the container form showing the form until the API data is acutally
 * loaded.
 *
 * NOTE @alexspri
 *    For better UI/UX we should actually only block the forms but not the
 *    entire account-settings view.
 */
const withLoadingGuard = branch(
  (props) => !props.stateLoaded,
  renderComponent(ProgressBar),
);

export default compose(
  connect(selector, actions),
  withResetScene,
  withAccountSettings,
  withLoadingGuard,
  /**
   * TODO @alexspri - we need an error guard here
   */
)(AccountSettings);
