import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import FormGroup from './group';

function FormActions({ className, row, horizontal, children, ...props }) {
  className = classNames(
    'form-actions',
    {
      'form-actions-horizontal': horizontal,
    },
    className,
  );

  if (row) {
    children = <div className="col-xs-push-2 col-xs-6">{children}</div>;
  }

  return (
    <FormGroup className={className} row={row} {...props}>
      {children}
    </FormGroup>
  );
}

FormActions.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  row: PropTypes.bool,
  horizontal: PropTypes.bool,
};

FormActions.defaultProps = {
  row: false,
  horizontal: false,
};

export default FormActions;
