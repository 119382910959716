import { connect } from 'react-redux';

import ResetPassword from 'source/components/gateway/resetPassword';

import {
  formChange,
  formErrors,
  cleanupErrors,
  cleanupServerErrors,
  resetPasswordRequest,
  changeLanguage,
} from 'source/actions/account';

const mapStateToProps = (state) => ({
  id: 'resetPassword',
  form: state.account.resetPassword,
  language: state.application.locale,
  languageOptions: state.account.languageOptions,
  serverError: state.account.resetPassword.serverError,
});

const mapDispatchToProps = (dispatch, props) => ({
  onErrorClose: () => dispatch(cleanupServerErrors('resetPassword')),
  onChange: (form, field, value) => {
    dispatch(formChange(form, field, value));
    dispatch(cleanupErrors(form, field));
  },
  onErrors: (form, errors) => dispatch(formErrors(form, errors)),
  onSubmit: (id, form) =>
    dispatch(
      resetPasswordRequest(
        { ...form, token: props.params.resetToken },
        '/reset-password-completed',
      ),
    ),
  onChangeLanguage: (language) => dispatch(changeLanguage(language)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
