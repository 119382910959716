import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import passwordScorer from 'source/utils/passwordScorer';

const messageIds = {
  weak: 'passwordStrength.weak',
  moderate: 'passwordStrength.moderate',
  strong: 'passwordStrength.strong',
};

function PasswordStrength({ intl, password }) {
  const { score, strength } = passwordScorer(password);

  const containerStyle = {
    width: '100%',
    overflow: 'hidden',
  };

  const strengthLangStyle = {
    fontSize: 11,
    position: 'relative',
    color: '#999',
    top: 2,
    textTransform: 'capitalize',
  };

  const statusColors = {
    weak: '#F7A700',
    moderate: '#E9DE33',
    strong: '#70B518',
  };

  const getMeterStyle = () => {
    const width = Math.min(score + password.length, 100);

    return {
      width: `${width}%`,
      maxWidth: '90%',
      opacity: width * 0.01 + 0.5,
      background: statusColors[strength],
      height: 4,
      transition: 'all 400ms linear',
      display: 'inline-block',
      marginRight: '1%',
    };
  };

  return (
    <div className="password-strength-indicator" style={containerStyle}>
      <span style={getMeterStyle()} className="passwordField__meter" />
      <span style={strengthLangStyle} className="passwordField__strength">
        {password.length > 0
          ? intl.formatMessage({ id: messageIds[strength] })
          : null}
      </span>
    </div>
  );
}

PasswordStrength.propTypes = {
  password: PropTypes.string.isRequired,
};

export default injectIntl(PasswordStrength);
