import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import * as CustomPropTypes from 'source/utils/propTypes';
import { withInnerRef } from 'source/utils/dom';

export class MediaGalleryFileInput extends React.PureComponent {
  handleChange = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // `e.target.value` is not an array but a `FileList` object and with
    // `[ ...e.target.value ]` we are converting it to an array.
    const files = [...e.target.files];
    this.props.onChange(files);

    // input elements with type "file" are read-only and not controlled. We
    // decided to clear it's internal state after every change and store it's
    // state somewhere else. Use the `onChange` handler to keep track of what
    // files were selected.
    if (this.props.innerRef?.current) {
      this.props.innerRef.current.value = '';
    }
  };

  render() {
    return (
      <label
        className={cx('file-input', this.props.className)}
        onClick={(e) => e.stopPropagation()}
      >
        <input
          type="file"
          accept="image/*, video/*"
          onChange={this.handleChange}
          className="file-input"
          style={{ display: 'none' }}
          ref={this.props.innerRef}
          multiple
          hidden
        />
        {this.props.title}
      </label>
    );
  }
}

MediaGalleryFileInput.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  innerRef: CustomPropTypes.ref,
};

MediaGalleryFileInput.defaultProps = {
  title: '',
  className: '',
  innerRef: {},
};

export default withInnerRef(MediaGalleryFileInput);
