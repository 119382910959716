import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import validator from 'validator';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';

import { PasswordStrength, CountrySelection } from 'source/components/common';
import FormattedLink from 'source/components/common/formattedLink';
import formController, { renderError } from 'source/utils/forms';

import { withGatewayRoot } from './root';

function SignUp({ id, intl, form, referrer, onChange, onSubmit }) {
  const referrerId = {
    gofeminin: 'signup.teaserImage.gofeminin',
  }[referrer];

  const cardClass = cx([
    'gateway__card',
    'gateway__card--signup',
    { 'has-referrer': Boolean(referrerId) },
  ]);

  const termsLink = (
    <FormattedLink
      messageId="signup.termsAndConditions"
      hrefMessageId="signup.termsUrl"
    />
  );

  const privacyLink = (
    <FormattedLink
      messageId="signup.privacyPolicy"
      hrefMessageId="signup.dataPrivacyUrl"
    />
  );

  return (
    <div className={cardClass}>
      {referrerId ? (
        <div className="gateway__card__signup-referrer">
          <img
            src={intl.formatMessage({ id: referrerId })}
            alt="EQOLOT signup"
          />
        </div>
      ) : null}
      <div className="gateway__card__form-container">
        <h5>
          <FormattedMessage
            id="signup.signupTitle"
            defaultMessage="Your account details"
          />
        </h5>
        <form id={id} onSubmit={onSubmit} onChange={onChange} noValidate>
          <fieldset className="form-group">
            <fieldset className="form-group">
              <label htmlFor="firstname">
                <FormattedMessage
                  id="signup.placeholder.firstName"
                  defaultMessage="First name"
                />
                :
              </label>
              <input
                className="form-control"
                id="firstname"
                type="text"
                placeholder={intl.formatMessage({
                  id: 'signup.placeholder.firstName',
                  defaultMessage: 'First name',
                })}
                value={form.firstname}
                onChange={noop}
              />

              <div className="error-block">
                {renderError(form.errors.firstname)}
              </div>
            </fieldset>
            <fieldset className="form-group">
              <label htmlFor="email">
                <FormattedMessage id="signup.email" defaultMessage="Email" />:
              </label>
              <input
                className="form-control"
                id="email"
                type="email"
                placeholder={intl.formatMessage({
                  id: 'signup.placeholder.email',
                  defaultMessage: 'name@example.com',
                })}
                value={form.email}
                onChange={noop}
              />

              <div className="error-block">
                {renderError(form.errors.email)}
              </div>
            </fieldset>
            <fieldset className="form-group">
              <label htmlFor="password">
                <FormattedMessage
                  id="signup.password"
                  defaultMessage="Password"
                />
                :
              </label>
              <input
                className="form-control"
                id="password"
                type="password"
                placeholder={intl.formatMessage({
                  id: 'signup.placeholder.password',
                  defaultMessage: 'Password',
                })}
                value={form.password}
                onChange={noop}
              />

              <PasswordStrength password={form.password} />
              <div className="error-block">
                {renderError(form.errors.password)}
              </div>
            </fieldset>
            <fieldset className="form-group">
              <label htmlFor="country">
                <FormattedMessage
                  id="signup.country"
                  defaultMessage="Country"
                />
                :
              </label>
              <CountrySelection
                id="country"
                value={form.country}
                onChange={noop}
              />

              <div className="error-block">
                {renderError(form.errors.country)}
              </div>
            </fieldset>
          </fieldset>
          <fieldset className="form-group">
            <input type="checkbox" id="acceptedTerms" />
            <label htmlFor="acceptedTerms">
              <FormattedMessage
                id="signup.acceptTerms"
                defaultMessage="I accept the {termsLink} and have read the {privacyLink}."
                values={{ termsLink, privacyLink }}
              />
            </label>
          </fieldset>
          <button
            className="btn btn-primary m-t-2"
            disabled={!form.acceptedTerms}
          >
            <FormattedMessage
              id="signup.createAccountButton"
              defaultMessage="Create my account"
            />
          </button>
          <div className="gateway__card__options m-t-1">
            <Link className="center-block" to="/signin">
              <FormattedMessage
                id="signup.alreadyHaveAccount"
                defaultMessage="I already have an account"
              />
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

SignUp.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.shape({
    firstname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    acceptedTerms: PropTypes.bool.isRequired,
    country: PropTypes.string.isRequired,
    errors: PropTypes.object.isRequired,
  }).isRequired,
  referrer: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const withFormController = formController({
  validateForm(form) {
    const errors = {};

    if (isEmpty(form.firstname)) {
      errors.firstname = 'signup.errors.missingFirstName';
    }

    if (isEmpty(form.email) || !validator.isEmail(form.email.trim())) {
      errors.email = 'signup.errors.emailFormat';
    }

    if (isEmpty(form.password)) {
      errors.password = 'signup.errors.missingPassword';
    }

    if (isEmpty(form.country)) {
      errors.country = 'signup.errors.missingCountry';
    }

    return errors;
  },
});

export default injectIntl(withGatewayRoot(withFormController(SignUp)));
