import pick from 'lodash/pick';
import size from 'lodash/size';
import get from 'lodash/get';
import set from 'lodash/set';
import transform from 'lodash/transform';
import omitBy from 'lodash/omitBy';

export { createReducer } from './redux';

export const formatReadingTime = (readingTime) => {
  const readingTimeSec = Math.round(readingTime * 60);

  const minutes = Math.floor(readingTimeSec / 60);
  const seconds = readingTimeSec % 60;

  if (seconds) {
    const formattedSeconds = seconds > 9 ? seconds : `0${seconds}`;
    return `${minutes}:${formattedSeconds}`;
  }

  return minutes;
};

export const createStorage = ({ session } = {}) => {
  const store = session ? sessionStorage : localStorage;

  return {
    save(key, value) {
      store.setItem(key, JSON.stringify(value));
    },

    read(key) {
      return JSON.parse(store.getItem(key));
    },

    remove(key) {
      store.removeItem(key);
    },
  };
};

export const createConstants = (...constants) =>
  constants.reduce((acc, constant) => {
    acc[constant] = constant; // eslint-disable-line no-param-reassign
    return acc;
  }, {});

export const checkHttpStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;

  throw error;
};

export const parseJSON = (response) => response.json();

export const composeComponents = (component, wrappers = []) =>
  wrappers.reduce((c, wrapper) => wrapper(c), component);

export const nullIfEmpty = (value) => (value === '' ? null : value);

export const percentToInteger = (value) => parseInt(value * 100, 10);

export const integerToPercent = (value) => value / 100;

export const mapObject = (mapping, input, defaults = {}) =>
  transform(
    mapping,
    (output, toProp, fromProp) => {
      const value = get(input, fromProp) || get(defaults, toProp);
      if (value) {
        set(output, toProp, value);
      }
    },
    {},
  );

export const hasAttributes = (obj, attributes) => {
  if (attributes && attributes.length > 0) {
    obj = pick(obj, attributes);
  }

  return size(obj) > 0;
};

/**
 * Creates a function that takes the given arguments and an event.
 *
 * @param {Function} func - handler that will be called once an event is fired
 * @param {...Any} args - args passed to function once event is fired
 */
export const createEventListener =
  (func, ...args) =>
  (e) =>
    func(...args, e);

export { default as account } from './account';

// This re-export is breaking storybook,
// let's keep it disabled here for now, since
// it's only imported in one place
// export { default as experiments } from './experiments';

/* list operations */

// Pop the last item of "src" and push it the the beginning of "dst".
// This function is modifying "src" and "dst"!
export const rpoplpush = (src, dst) => {
  if (src.length <= 0) {
    return;
  }

  const last = src.pop();
  dst.unshift(last);
};

/* == form helpers == */
export const trimValues = (obj, props) =>
  props.reduce(
    (memo, prop) => {
      const val = get(obj, prop);
      if (val) {
        set(memo, prop, val.trim());
      }

      return memo;
    },
    { ...obj },
  );

export const upperCaseFirstLetter = (text) =>
  `${text[0].toUpperCase()}${text.slice(1)}`;

export const compactObject = (obj) => omitBy(obj, (value) => !value);

/**
 * trims new-line characters from multiline string
 *
 * @param {String} str - string to trim
 * @param {Boolean} options.compact - if set new lines will not be replaced to one space but no space
 */
export const trim = (str, { compact = false } = {}) =>
  str.replace(/\n\s*/g, compact ? '' : ' ');

/**
 * tracing function to understand function args and results
 */
export const trace =
  (tag) =>
  (fn) =>
  (...args) => {
    // eslint-disable-next-line no-console
    console.log(`${tag}: args`, ...args);
    try {
      const res = fn(...args);

      // if `res` is thenable we wait for it
      if (typeof res.then === 'function') {
        return res
          .then((thenResult) => {
            // eslint-disable-next-line no-console
            console.log(`${tag}: res`, thenResult);
            return thenResult;
          })
          .catch((thenError) => {
            // eslint-disable-next-line no-console
            console.log(`${tag}: err`, thenError);
            throw thenError;
          });
      }

      // otherwise we log the sync result

      // eslint-disable-next-line no-console
      console.log(`${tag}: res`, res);
      return res;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(`${tag}: err`, err);
      throw err;
    }
  };
