import React from 'react';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { withGatewayRoot } from './root';

function ResetPasswordRequested() {
  return (
    <div className="gateway__card gateway__card--reset-request text-center">
      <h5>
        <FormattedMessage
          id="passwordReset.requested.title"
          defaultMessage="Check your email"
        />
      </h5>
      <p>
        <FormattedMessage
          id="passwordReset.requested.description"
          defaultMessage="We have sent you an email with instructions for resetting your password."
        />
      </p>
      <p>
        <i className="notification-icon">1</i>
        <i className="material-icons material-icons--big material-icons--grey">
          mail
        </i>
      </p>
      <div className="gateway__card__options m-t-1">
        <Link className="center-block" to="/signin">
          <FormattedMessage
            id="passwordReset.requested.backToSignIn"
            defaultMessage="Back to Sign In"
          />
        </Link>
      </div>
    </div>
  );
}

export default withGatewayRoot(ResetPasswordRequested);
