import React from 'react';
import PropTypes from 'prop-types';
import { compose, setPropTypes, lifecycle } from 'recompose';
import { FormattedMessage } from 'react-intl';
import findKey from 'lodash/findKey';
import { withRouter, locationShape, routerShape } from 'react-router';

import {
  Accordion,
  ProfileForm,
  NotificationsForm,
  ChangePasswordForm,
  DeleteAccountForm,
} from '../legacy/components';

import PaymentForm from './forms/paymentForm';

const tabsMappings = {
  profile: {
    index: 0,
    url: 'profile',
  },
  notifications: {
    index: 1,
    url: 'notifications',
  },
  payment: {
    index: 2,
    url: 'payment',
  },
  changePassword: {
    index: 3,
    url: 'changePassword',
  },
  deleteAccount: {
    index: 4,
    url: 'deleteAccount',
  },
};

const withOpenTabOnMount = compose(
  setPropTypes({
    onTabSelection: PropTypes.func.isRequired,
    params: PropTypes.shape({
      tab: PropTypes.string,
    }),
  }),

  lifecycle({
    componentDidMount() {
      const { params = {}, onTabSelection } = this.props;

      /** check if the route has "params" set and if so, change the open tab */
      const { tab: tabParamName } = params;
      const tab = tabParamName ? tabsMappings[tabParamName] : undefined;

      if (tab && tab.index !== undefined) {
        onTabSelection(tab.index);
      }
    },
  }),
);

function AccountSettings({
  profile,
  notifications,
  userData,
  changePassword,
  deleteAccount,
  currentTab,
  onTabSelection,
  onToggleConfirm,
  router,
  ...props
}) {
  const tabs = [
    {
      name: (
        <FormattedMessage
          id="accountSettings.profile.title"
          defaultMessage="Profile"
        />
      ),
      component: <ProfileForm id="profile" form={profile} {...props} />,
    },

    {
      name: (
        <FormattedMessage
          id="accountSettings.notifications.title"
          defaultMessage="Notifications"
        />
      ),
      component: (
        <NotificationsForm id="notifications" form={notifications} {...props} />
      ),
    },

    {
      name: (
        <FormattedMessage
          id="accountSettings.paymentData.title"
          defaultMessage="Accounting"
        />
      ),
      component: (
        <PaymentForm
          id="payment"
          userData={userData}
          onPostUserBillingInformation={props.onPostUserBillingInformation}
          onPatchUserBillingInformation={props.onPatchUserBillingInformation}
          billingInformationSaveStatus={props.billingInformationSaveStatus}
        />
      ),
    },

    {
      name: (
        <FormattedMessage
          id="accountSettings.changePassword.title"
          defaultMessage="Change Password"
        />
      ),
      component: (
        <ChangePasswordForm
          id="changePassword"
          form={changePassword}
          {...props}
        />
      ),
    },

    {
      name: (
        <FormattedMessage
          id="accountSettings.deleteAccount.title"
          defaultMessage="Delete Account"
        />
      ),
      component: (
        <DeleteAccountForm
          id="deleteAccount"
          form={deleteAccount}
          onShowConfirm={onToggleConfirm}
          onCancel={onToggleConfirm}
          {...props}
        />
      ),
    },
  ];

  const handleSelectTab = (tabIndex) => {
    if (tabIndex === -1) {
      router.replace('/account-settings');
    } else {
      const tabKey = findKey(tabsMappings, { index: tabIndex });

      if (tabKey) {
        const tab = tabsMappings[tabKey];

        if (tab && tab.url) {
          router.replace(`/account-settings/${tab.url}`);
        }
      }
    }

    onTabSelection(tabIndex);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="card">
          <div className="card-block">
            <Accordion
              tabs={tabs}
              currentTab={currentTab}
              onTabSelection={handleSelectTab}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

AccountSettings.propTypes = {
  stateLoaded: PropTypes.bool.isRequired,
  profile: PropTypes.object,
  notifications: PropTypes.object,
  changePassword: PropTypes.object,
  deleteAccount: PropTypes.object,
  currentTab: PropTypes.any,

  // new
  userData: PropTypes.object.isRequired,
  billingInformationSaveStatus: PropTypes.string.isRequired,

  // dispatchers
  onTabSelection: PropTypes.func.isRequired,
  onToggleConfirm: PropTypes.func.isRequired,

  // passthroug props
  onPostUserBillingInformation: PropTypes.any,
  onPatchUserBillingInformation: PropTypes.any,

  // Provided by withRouter
  location: locationShape.isRequired,
  router: routerShape.isRequired,
};

AccountSettings.defaultProps = {
  profile: undefined,
  notifications: undefined,
  changePassword: undefined,
  deleteAccount: undefined,
  currentTab: undefined,
  onPostUserBillingInformation: undefined,
  onPatchUserBillingInformation: undefined,
};

export default compose(withRouter, withOpenTabOnMount)(AccountSettings);
