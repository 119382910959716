import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';
import debounce from 'lodash/debounce';

class Form extends Component {
  static propTypes = {
    id: PropTypes.any.isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    onChange: noop,
    onSubmit: noop,
    className: '',
  };

  handleChange = (e) => {
    const { id, onChange } = this.props;

    let value;
    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    return onChange(id, e.target.id, value);
  };

  handleSubmit = (e) => {
    e.preventDefault();

    return this.submitForm();
  };

  submitForm = debounce(() => {
    const { id, onSubmit } = this.props;

    return onSubmit(id);
  }, 1000);

  render() {
    const { className, ...props } = this.props;

    const formClassName = classNames('form', className);

    return (
      <form
        {...props}
        className={formClassName}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export {
  default as FormControl,
  Textarea,
  Input,
  FileInput,
  PasswordInput,
  EmailInput,
  NumberInput,
  NumberFormatInput,
  Checkbox,
  Select,
  ButtonSwitchGroup,
} from './control';

export { default as Label } from './label';
export { default as FormattedError } from './formattedError';
export { default as FormGroup } from './group';
export { default as FormActions } from './actions';
export { default as FormControlFeedback } from './control-feedback';
export { default as FormPanel } from './panel';
export { default as FormSummary } from './summary';
export { default as MediaGalleryFileInput } from './mediaGalleryFileInput';
export { default as MediaGallery, MediaGalleryItem } from './mediaGallery';
export { default as MediaUpload } from './mediaUpload';

export default Form;
