import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withProps, branch, renderComponent, compose } from 'recompose';
import { FormattedMessage, FormattedNumber, FormattedDate } from 'react-intl';
import {
  ToolTipController,
  Select as ToolTipWrapper,
} from 'react-tooltip-controller';

import Feature from 'source/components/common/feature';
import Icon from 'source/components/common/icon';
import Tooltip from 'source/components/common/tooltip';

// @sean TODO Don't reach into other scene's folder
import CampaignImage from 'source/scenes/exploreCampaigns/components/CampaignImage';

// Helpers

const getMaxPayment = (payments) =>
  payments.reduce(
    (acc, payment) => {
      if (payment.totals.price >= acc.totals.price) {
        return payment;
      }

      return acc;
    },
    { totals: { price: 0 } },
  );

const renderPrice = (value, currency, showAsterisk = false) => (
  <span>
    {/* eslint-disable-next-line react/style-prop-object */}
    <FormattedNumber value={value} currency={currency} style="currency" />
    {showAsterisk && '*'}
  </span>
);

const renderCounterOfferPrice = (
  originalValue,
  counterOfferValue,
  currency,
) => (
  <div className="sponsored-posts-campaign-counter-offer-price">
    <span>{renderPrice(originalValue, currency)}</span>
    <span>
      <FormattedNumber
        value={counterOfferValue}
        currency={currency}
        // eslint-disable-next-line react/style-prop-object
        style="currency"
      />
      *
    </span>
  </div>
);

const renderDate = (date) => (
  <span>
    <FormattedDate value={moment(date, 'YYYY-MM-DD').toDate()} />
  </span>
);

const isOnMission = ({ application }) =>
  application &&
  application.mission.status === 'confirmed' &&
  application.mission.publishDate;

const paymentProps = {
  price: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  totals: PropTypes.shape({
    price: PropTypes.number.isRequired,
  }).isRequired,
};

// Components

function Price({ hasMultiplePayments, payment }) {
  const {
    currency,
    totals: { price, priceOriginal },
    counterOffer: hasCounterOffer,
  } = payment;

  const renderedPrice = hasCounterOffer ? (
    renderCounterOfferPrice(priceOriginal, price, currency)
  ) : (
    <Feature
      feature="counterOffer"
      otherwise={() => renderPrice(price, currency)}
    >
      {renderPrice(price, currency, true)}
    </Feature>
  );

  if (hasMultiplePayments) {
    return (
      <FormattedMessage
        id="sponsoredPosts.campaigns.maxPayment"
        defaultMessage="up to {maxPayment}"
        values={{ maxPayment: renderedPrice }}
      />
    );
  }

  return renderedPrice;
}

Price.propTypes = {
  payment: PropTypes.shape(paymentProps),
  hasMultiplePayments: PropTypes.bool,
};

function PriceDescription({
  currency,
  price,
  totals: { price: counterOfferPrice },
  bonuses: { total: totalBonus },
  counterOffer: hasCounterOffer,
}) {
  return (
    <div className="sponsored-posts-campaign-price-infos-list">
      {totalBonus && !hasCounterOffer ? (
        <FormattedMessage
          id="sponsoredPosts.campaigns.paymentWithBonus"
          defaultMessage="One-time payment {price} + {bonus} bonus"
          values={{
            price: renderPrice(
              hasCounterOffer ? counterOfferPrice : price,
              currency,
            ),

            bonus: renderPrice(totalBonus, currency),
          }}
        />
      ) : (
        <FormattedMessage
          id="sponsoredPosts.campaigns.payment"
          defaultMessage="One-time payment"
        />
      )}

      <span className="sponsored-posts-campaign-price-info">
        <span className="price-info">
          <FormattedMessage id="netPrice" defaultMessage="Net Price" />
        </span>{' '}
        <ToolTipController detect="hover" offsetX={-116}>
          <ToolTipWrapper>
            <span className="icon-wrapper">
              <Icon name="info" />
            </span>
          </ToolTipWrapper>
          <Tooltip className="tooltip-net-price">
            <FormattedMessage
              id="netPrice.tooltip"
              defaultMessage="If you represent a VAT-paying company, the tax will be added on top of the credit note you receive for participating in the campaign at the end of the collaboration."
            />
          </Tooltip>
        </ToolTipController>
      </span>
      <Feature feature="counterOffer">
        <span className="sponsored-posts-campaign-price-info">
          <span className="price-info">
            <FormattedMessage
              id="counterOfferPrice"
              defaultMessage="* Counteroffer"
            />
          </span>{' '}
          <ToolTipController detect="hover" offsetX={-116}>
            <ToolTipWrapper>
              <span className="icon-wrapper">
                <Icon name="info" />
              </span>
            </ToolTipWrapper>
            <Tooltip className="tooltip-counter-offer-price">
              <FormattedMessage
                id="counterOfferPrice.tooltip"
                defaultMessage="You can offer a different payment for this campaign: propose a smaller amount to be more competitive, or a larger one if the collaboration requires extra effort."
              />
            </Tooltip>
          </ToolTipController>
        </span>
      </Feature>
    </div>
  );
}

PriceDescription.propTypes = {
  price: PropTypes.number.isRequired,
  totals: PropTypes.shape({
    price: PropTypes.number.isRequired,
  }),

  counterOffer: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  bonuses: PropTypes.shape({
    total: PropTypes.number,
  }).isRequired,
};

function CampaignPublishDate({ campaign }) {
  const publishStart = moment(campaign.timeline.publishStart, 'YYYY-MM-DD');
  const publishEnd = moment(campaign.timeline.publishEnd, 'YYYY-MM-DD');
  const isPublishRange =
    campaign.timeline.publishStart && !publishStart.isSame(publishEnd, 'day');

  return (
    <span>
      {isPublishRange ? (
        <FormattedDate value={publishStart} day="numeric" month="numeric" />
      ) : (
        ''
      )}

      {isPublishRange && ' - '}
      <FormattedDate value={publishEnd} />
    </span>
  );
}

CampaignPublishDate.propTypes = {
  campaign: PropTypes.object.isRequired,
};

const PublishDate = branch(
  isOnMission,
  renderComponent(({ application }) =>
    renderDate(application.mission.publishDate),
  ),

  renderComponent(CampaignPublishDate),
)(null);

PublishDate.propTypes = {
  campaign: PropTypes.shape({
    timeline: PropTypes.shape({
      publishEnd: PropTypes.string.isRequired,
      publishStart: PropTypes.string,
    }).isRequired,
  }).isRequired,
  application: PropTypes.shape({
    mission: PropTypes.shape({
      publishDate: PropTypes.string,
    }),
  }),
};

function PublishingDates({ application, campaign }) {
  return (
    <span>
      <strong className="icon-list-title h1">
        <Icon name="publish" />
        <PublishDate campaign={campaign} application={application} />
      </strong>
      <br />
      <FormattedMessage
        id="sponsoredPosts.campaigns.publicationDates"
        defaultMessage="Publication dates"
      />
    </span>
  );
}

PublishingDates.propTypes = {
  campaign: PropTypes.object.isRequired,
  application: PropTypes.object,
};

function PreviewDate({ application }) {
  return (
    <span>
      <strong className="icon-list-title h1">
        {renderDate(application.mission.previewDate)}
      </strong>
      <br />
      <FormattedMessage
        id="sponsoredPosts.campaigns.previewDates"
        defaultMessage="Preview due dates"
      />
    </span>
  );
}

PreviewDate.propTypes = {
  application: PropTypes.shape({
    mission: PropTypes.shape({
      previewDate: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

function CampaignMeta({ campaign, matches, application, maxPayment }) {
  return (
    <div className="sponsored-posts-campaign-meta row">
      <div className="col-md-4">
        <ul className="icon-list">
          <li className="icon-list-item sponsored-posts-campaign-price">
            <strong className="icon-list-title h1">
              <Icon name="revenues-new" />{' '}
              <Price
                hasMultiplePayments={matches.length > 1}
                payment={maxPayment}
              />
            </strong>
            <br />
            <PriceDescription {...maxPayment} />
          </li>
          <li className="icon-list-item sponsored-posts-campaign-due-date">
            <PublishingDates campaign={campaign} application={application} />
          </li>
          <li className="icon-list-item sponsored-posts-campaign-due-date">
            {application && isOnMission({ application }) ? (
              <PreviewDate application={application} />
            ) : null}
          </li>
        </ul>
      </div>
      <div className="col-md-4">
        <CampaignImage campaign={campaign} />
      </div>
    </div>
  );
}

CampaignMeta.propTypes = {
  campaign: PropTypes.object.isRequired,
  application: PropTypes.object,
  matches: PropTypes.array.isRequired,
  maxPayment: PropTypes.shape(paymentProps),
};

const withMaxPayment = withProps(({ matches }) => ({
  maxPayment: getMaxPayment(matches.map((match) => match.payment)),
}));

export default compose(withMaxPayment)(CampaignMeta);
