import find from 'lodash/find';
import reject from 'lodash/reject';

export const onCategoryChange =
  (props) =>
  ({ code, checked }) => {
    const { categories, selectedCategories, onChange } = props;

    const nextSelectedCategories = checked
      ? [...selectedCategories, find(categories, { code })] // add category
      : reject(selectedCategories, { code }); // remove category

    return onChange(nextSelectedCategories);
  };
