import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';

import { genderMessages } from 'source/utils/user';

function FormattedValue({ value }) {
  return value || <>&mdash;</>;
}

FormattedValue.propTypes = {
  value: PropTypes.any,
};

function Address({ address }) {
  if (!Object.values(address).every(Boolean)) {
    return <FormattedValue />;
  }

  const { streetName, streetNumber, postalCode, city } = address;

  return (
    <>
      {[streetName, streetNumber].filter(Boolean).join(' ')}
      <br />
      {[postalCode, city].filter(Boolean).join(' ')}
    </>
  );
}

Address.propTypes = {
  address: PropTypes.shape({
    streetName: PropTypes.string,
    streetNumber: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
  }),
};

Address.defaultProps = {
  address: {},
};

function UserProfile({ user, intl }) {
  return (
    <div className="card card-block user-profile">
      <div className="container profile">
        <div className="container">
          <div className="row flex">
            <div className="disclaimer-container">
              <div className="disclaimer">
                <FormattedMessage
                  id="channels.userProfile.title"
                  defaultMessage="Your profile information is used to match you with the most relevant campaigns. Make sure it is correct and up to date."
                />
              </div>
              <div className="gap" />
              <div className="btn-group">
                <Link to="/account-settings/profile">
                  <button className="btn btn-primary">
                    <FormattedMessage
                      id="channels.userProfile.editProfile.btn"
                      defaultMessage="Edit profile"
                    />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="flex row col-lg-6 m-t-1">
              <div className="flex-1">
                <div className="text-muted">
                  <FormattedMessage
                    id="channels.userProfile.firstName"
                    defaultMessage="First name"
                  />
                </div>
                <div className="text-eqolot">
                  <FormattedValue value={user.firstname} />
                </div>
              </div>
              <div className="flex-1">
                <div className="text-muted">
                  <FormattedMessage
                    id="channels.userProfile.lastName"
                    defaultMessage="Last name"
                  />
                </div>
                <div className="text-eqolot">
                  <FormattedValue value={user.lastname} />
                </div>
              </div>
            </div>
            <div className="flex row col-lg-6 m-t-1">
              <div className="flex-1">
                <div className="text-muted">
                  <FormattedMessage
                    id="channels.userProfile.yearOfBirth"
                    defaultMessage="Year of birth"
                  />
                </div>
                <div className="text-eqolot">
                  <FormattedValue value={user.yearOfBirth} />
                </div>
              </div>
              <div className="flex-1">
                <div className="text-muted">
                  <FormattedMessage
                    id="channels.userProfile.gender"
                    defaultMessage="Gender"
                  />
                </div>
                <div className="text-eqolot">
                  {user.salutation ? (
                    <FormattedValue
                      value={intl.formatMessage(
                        genderMessages[user.salutation.toUpperCase()],
                      )}
                    />
                  ) : (
                    <FormattedValue />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="flex row col-lg-6 m-t-1">
              <div className="flex-1">
                <div className="text-muted">
                  <FormattedMessage
                    id="channels.userProfile.address"
                    defaultMessage="Address"
                  />
                </div>
                <div className="text-eqolot">
                  <Address address={user.address} />
                </div>
              </div>
            </div>
            <div className="flex row col-lg-6 m-t-1">
              <div className="flex-1">
                <div className="text-muted">
                  <FormattedMessage
                    id="channels.userProfile.country"
                    defaultMessage="Country"
                  />
                </div>
                <div className="text-eqolot">{user.country}</div>
              </div>
              <div className="flex-1">
                <div className="text-muted">
                  <FormattedMessage
                    id="channels.userProfile.language"
                    defaultMessage="Language"
                  />
                </div>
                <div className="text-eqolot">{user.language}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

UserProfile.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    salutation: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    yearOfBirth: PropTypes.number,
    address: PropTypes.object,
    language: PropTypes.string,
    country: PropTypes.string,
  }),
};

UserProfile.defaultProps = {
  user: {},
};

export default injectIntl(UserProfile);
