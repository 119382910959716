import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import noop from 'lodash/noop';

import * as CustomPropTypes from 'source/utils/propTypes';
import { withInnerRef } from 'source/utils/dom';

const killEventPropagation = (e) => {
  e.stopPropagation();
};

class FileInput extends React.Component {
  handleChange = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // `e.target.value` is not an array but a `FileList` object and with
    // `[ ...e.target.value ]` we are converting it to an array.
    const files = [...e.target.files];
    this.props.onChange(files);

    // input elements with type "file" are read-only and not controlled. We
    // decided to clear it's internal state after every change and store it's
    // state somewhere else. Use the `onChange` handler to keep track of what
    // files were selected.
    if (this.props.innerRef && this.props.innerRef.current) {
      this.props.innerRef.current.value = '';
    }
  };

  handleClearClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const files = [];
    this.props.onChange(files);
  };

  // TODO: Trigger `onClick` when buttons are focused and spacebar/enter is
  // pressed for better a11y.
  render() {
    return (
      <label
        className={cx('file-input', this.props.className)}
        onClick={killEventPropagation}
      >
        <input
          type="file"
          accept={this.props.accept}
          onChange={this.handleChange}
          className="file-input__control"
          id={this.props.id}
          multiple={this.props.multiple}
          style={{ display: 'none' }}
          ref={this.props.innerRef}
          hidden
        />
        {this.props.showInput && (
          <span className="file-input__value">
            {this.props.placeholderPhrase}
          </span>
        )}
        {this.props.showChooseFilePhrase && (
          <span
            role="button"
            tabIndex="0"
            className="file-input__button file-input__button--choose"
          >
            {this.props.chooseFilePhrase}
          </span>
        )}
        {this.props.clearable && (
          <span
            role="button"
            tabIndex="0"
            onClick={this.handleClearClick}
            className="file-input__button file-input__button--clear"
          >
            {this.props.clearPhrase}
          </span>
        )}
      </label>
    );
  }
}

FileInput.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  placeholderPhrase: PropTypes.string,
  chooseFilePhrase: PropTypes.string,
  clearPhrase: PropTypes.string,
  clearable: PropTypes.bool,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  showInput: PropTypes.bool,
  showChooseFilePhrase: PropTypes.bool,
  onChange: PropTypes.func,
  innerRef: CustomPropTypes.ref,
};

FileInput.defaultProps = {
  className: '',
  id: '',
  accept: '',
  placeholderPhrase: 'No file chosen',
  chooseFilePhrase: 'Choose File',
  clearPhrase: 'Clear',
  clearable: false,
  multiple: false,
  showInput: false,
  showChooseFilePhrase: true,
  onChange: noop,
  innerRef: {},
};

export default withInnerRef(FileInput);
