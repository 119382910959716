import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { routerShape } from 'react-router';

import {
  branch,
  renderComponent,
  lifecycle,
  withProps,
  compose,
  getContext,
} from 'recompose';

import Spinner from 'source/components/common/spinner';
import BrandClubs from './components/BrandClubs';
import actions from './actions';
import { getBrandClubsState } from './selectors';

function Loading() {
  return <Spinner className="spinner-view" />;
}

function UnconfirmedUserError() {
  return (
    <div className="row">
      <div className="text-xs-center">
        <FormattedMessage
          id="sponsoredPosts.explore.unconfirmedUser"
          defaultMessage="Please confirm your email to gain access to campaigns."
        />
      </div>
    </div>
  );
}

const withRouter = getContext({
  router: routerShape.isRequired,
});

const withSceneReset = lifecycle({
  componentWillUnmount() {
    this.props.onResetScene();
  },
});

const withBrandClubsFetch = lifecycle({
  componentDidMount() {
    const {
      location: { pathname },
    } = this.props;
    const [, , clubId] = pathname.split('/');
    this.props
      .onLoadBrandClubs()
      .then(() => this.props.onOpenBrandModal(clubId));
  },
});

const withChannelsFetch = lifecycle({
  componentDidMount() {
    this.props.onLoadChannels();
  },
});

const withLoadingGuard = branch(
  ({ brandClubs: { loaded } }) => !loaded,
  renderComponent(Loading),
);

const withUnconfirmedUserGuard = branch(
  ({ userConfirmed }) => !userConfirmed,
  renderComponent(UnconfirmedUserError),
);

// Takes async applications data and yields just the data portion
const withBrandClubs = withProps(({ brandClubs }) => ({
  brandClubs: brandClubs.data,
}));

export default compose(
  connect(getBrandClubsState, actions),
  withSceneReset,
  withChannelsFetch,
  withBrandClubsFetch,
  withUnconfirmedUserGuard,
  withLoadingGuard,
  withBrandClubs,
  withRouter,
)(BrandClubs);
