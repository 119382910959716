import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';

import scenes from 'source/scenes/reducer';
import account, { getInitialState as accountInitialState } from './account';
import application, {
  getInitialState as applicationInitialState,
} from './application';
import experiments, {
  getInitialState as experimentsInitialState,
} from './experiments';
import sponsoredPosts, {
  getInitialState as sponsoredPostsInitialState,
} from './sponsoredPosts';
import acceptTerms, {
  getInitialState as acceptTermsInitialState,
} from './acceptTerms';

const reducers = combineReducers({
  // external
  routing: routerReducer,
  form: formReducer,

  // internal
  account,
  application,
  experiments,
  sponsoredPosts,
  acceptTerms,
  scenes,
});

export const getInitialState = (accessToken, locale, env) => ({
  account: { ...accountInitialState(accessToken) },
  application: { ...applicationInitialState(locale, env) },
  experiments: { ...experimentsInitialState() },
  sponsoredPosts: { ...sponsoredPostsInitialState() },
  acceptTerms: { ...acceptTermsInitialState() },
});

export default reducers;
