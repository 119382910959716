import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

function FormControlFeedback({ className, message }) {
  if (!message) {
    return null;
  }

  className = classNames('form-control-feedback', className);

  return <div className={className}>{message}</div>;
}

FormControlFeedback.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
};

export default FormControlFeedback;
