import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

class Modal extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { isOpen } = this.props;
    return isOpen || isOpen !== nextProps.isOpen;
  }

  componentDidUpdate(prevProps) {
    if (this.modalRef && this.modalRef.current) {
      const { isOpen } = this.props;
      if (isOpen !== prevProps.isOpen) {
        if (isOpen) {
          disableBodyScroll(this.modalRef.current, {
            reserveScrollBarGap: true,
            allowTouchMove: true,
          });
        } else {
          enableBodyScroll(this.modalRef.current);
        }
      }
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  modalRef = React.createRef();

  render() {
    return <ReactModal {...this.props} ref={this.modalRef} />;
  }
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
};

Modal.defaultProps = {
  isOpen: false,
};

export default Modal;
