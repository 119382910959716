import humanFormat from 'human-format';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// eslint-disable-next-line no-control-regex
export const NonAsciiRegExp = /[^\x00-\x7F]/g;
export const AllowedCharacters =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz !“#$%&‘()*+,-./:;<=>?@[]^_`{|}~';

export const UploadErrors = {
  ServerGeneric: 'unknown-server-error',
  MaxSize: 'file-exceeds-max-size',
  NotAllowedCharacter: 'not-allowed-character',
  NotAllowedCharacterWithExample: 'notAllowedCharacterWithExample',
};

function FileUploadError({ error }) {
  const { allowedCharacters, maxSize } = error;

  const getMessageProps = ({ code } = {}) => {
    const errorBuilders = {
      [UploadErrors.MaxSize]: () => ({
        id: 'fileUpload.errors.uploadTooLarge',
        values: {
          size: humanFormat(maxSize, {
            scale: 'binary',
            unit: 'B',
          }),
        },
      }),
      [UploadErrors.ServerGeneric]: () => ({
        id: 'fileUpload.errors.uploadError',
      }),
      [UploadErrors.NotAllowedCharacter]: () => ({
        id: 'fileUpload.errors.notAllowedCharacter',
        values: {
          allowedCharacters,
        },
      }),
      [UploadErrors.NotAllowedCharacterWithExample]: () => ({
        id: 'fileUpload.errors.notAllowedCharacterWithExample',
        values: {
          allowedCharacters,
        },
      }),
    };

    const errorBuilder = errorBuilders[code];
    return errorBuilder ? errorBuilder(error) : {};
  };

  return <FormattedMessage {...getMessageProps(error)} />;
}

FileUploadError.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.shape({
      maxSize: PropTypes.number,
      code: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      allowedCharacters: PropTypes.string,
      code: PropTypes.string.isRequired,
    }),
  ]).isRequired,
};

export default FileUploadError;
