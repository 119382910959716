import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import validator from 'validator';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withCookies, Cookies } from 'react-cookie';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter, locationShape } from 'react-router';
import { createStructuredSelector } from 'reselect';

import Markdown from 'source/components/common/markdown';
import Icon from 'source/components/common/icon';

const POPUP_ID_DEFAULT = 'POPUP_ID_DEFAULT';

class SupportButton extends React.PureComponent {
  constructor(props) {
    super(props);

    const { intl } = props;

    this.popupId = intl.formatMessage({
      id: 'popup.id',
      // eslint-disable-next-line
      defaultMessage: POPUP_ID_DEFAULT,
    });
    this.disablePopup = !this.popupId || this.popupId === POPUP_ID_DEFAULT;
    this.popupCookieId = `announcement_prompt_dismissed_${this.popupId}`;

    this.state = {
      open: this.showPopup(),
    };
  }

  componentWillMount() {
    const check = this.getCheckForCurrentPopup();

    const shouldLoad =
      !this.disablePopup &&
      !this.popupHasBeenSeen() &&
      this.props.user &&
      check.action;

    if (shouldLoad) {
      check.action();
    }
  }

  componentDidMount() {
    if (!this.popupHasBeenSeen()) {
      window.addEventListener('beforeunload', this.setPopupHasBeenSeen);
    }
  }

  componentDidUpdate(prevProps) {
    const prevCheck = get(prevProps.checks, this.popupId);
    const currentCheck = this.getCheckForCurrentPopup();

    if (!prevCheck || isEmpty(currentCheck)) {
      return;
    }

    if (prevCheck.loading !== currentCheck.loading && !currentCheck.loading) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        open: this.showPopup(),
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.setPopupHasBeenSeen);
  }

  getPopupContent() {
    const { intl } = this.props;

    // eslint-disable-next-line
    const popupContent = intl.formatMessage({
      id: 'popup.content',
    });

    if (!popupContent) {
      return <div />;
    }

    if (validator.isURL(popupContent)) {
      return (
        <iframe
          title="Content"
          src={popupContent}
          frameBorder="0"
          width="100%"
          height="100%"
        />
      );
    }

    return <Markdown markdown={popupContent} />;
  }

  getCheckForCurrentPopup() {
    return get(this.props.checks, this.popupId, {});
  }

  setPopupHasBeenSeen = () => {
    if (this.props.user) {
      this.props.cookies.set(this.popupCookieId, true);
    }
  };

  popupHasBeenSeen = () => this.props.cookies.get(this.popupCookieId);

  toggleOpen = () => {
    const { open } = this.state;

    if (open && !this.popupHasBeenSeen()) {
      this.setPopupHasBeenSeen();
    }

    this.setState({ open: !open });
  };

  showPopup = () => {
    const shouldShow =
      !this.disablePopup && this.props.user && !this.popupHasBeenSeen();
    const currentCheck = this.getCheckForCurrentPopup();

    if (!isEmpty(currentCheck)) {
      return shouldShow && !currentCheck.loading && currentCheck.result;
    }

    return shouldShow;
  };

  createUserMailtoLink = (email) => {
    const {
      user,
      campaign,
      application,
      location: { pathname },
    } = this.props;

    const onCampaignOrApplicationPage = pathname.includes('sponsored-posts');
    const campaignName = get(campaign, 'details.headline', '');
    const applicationId = get(application, 'id');
    const params = [];

    if (user) {
      params.push(`UserId: ${user.id}`);
    }
    if (onCampaignOrApplicationPage && campaignName) {
      campaignName && params.push(`Campaign: ${campaignName}`);
      applicationId && params.push(`Application: ${applicationId}`);
    }

    const subject = encodeURIComponent(params.join('; '));

    return `mailto:${email}?subject=${subject}`;
  };

  render() {
    const { open } = this.state;

    const currentCheck = this.getCheckForCurrentPopup();
    if (currentCheck.loading) {
      return null;
    }

    return (
      <div className="support-button">
        <div
          className={cx('support-button-trigger', 'icon-button', {
            active: open,
          })}
          onClick={this.toggleOpen}
        >
          <span className={cx({ hidden: open })}>?</span>
          <Icon name="cross" />
        </div>
        <div className={cx('support-button-popup', { hidden: !open })}>
          {this.showPopup() && (
            <div className="announcement">
              <div className="content">{this.getPopupContent()}</div>
            </div>
          )}

          {!this.showPopup() && (
            <>
              <div>
                <h4 className="m-b-1">
                  <FormattedMessage
                    id="support.button.title"
                    defaultMessage="How can we help you?"
                  />
                </h4>
                <FormattedMessage
                  id="support.button.subTitle"
                  defaultMessage="We are here to answer your questions:"
                />
              </div>
              <div>
                <a
                  href={this.createUserMailtoLink('support@eqolot.com')}
                  className="btn btn-link"
                >
                  <Icon name="mail" />
                  <FormattedMessage
                    id="support.button.generalQuestions"
                    defaultMessage="General questions"
                  />
                </a>
              </div>
              <div>
                <a
                  href={this.createUserMailtoLink('influencerteam@eqolot.com')}
                  className="btn btn-link"
                >
                  <Icon name="mail" />
                  <FormattedMessage
                    id="support.button.campaignQuestions"
                    defaultMessage="Campaigns"
                  />
                </a>
              </div>
              <div>
                <a
                  href={this.createUserMailtoLink('privacy@eqolot.com')}
                  className="btn btn-link"
                >
                  <Icon name="mail" />
                  <FormattedMessage
                    id="support.button.privacyQuestions"
                    defaultMessage="Privacy"
                  />
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

SupportButton.propTypes = {
  location: locationShape,
  user: PropTypes.object,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  application: PropTypes.shape({ id: PropTypes.string }),
  campaign: PropTypes.shape({
    details: PropTypes.shape({ headline: PropTypes.string }),
  }),
  checks: PropTypes.object,
};

// Popup ID: 20211101-1238_Pinterest
// Only show the popup survery to users without a connected
// Pinterest account
const pinterestSurveryCheck = (state) => {
  const channelsLoading = get(state, 'insights.channels.loading');
  const channelsLoaded = get(state, 'insights.channels.loaded');
  const channels = get(state, 'insights.channels.data', []) || [];

  let isLoading = true;

  if (channelsLoaded && !channelsLoading) {
    isLoading = false;
  }

  return {
    // action to be executed on mount
    action: undefined,
    // whether the check is still loading
    loading: isLoading,
    // if true, the popup is appropriate to show to the user
    result: channels.every((channel) => channel.platform !== 'pinterest'),
  };
};

const getUser = (state) => state.application.user;

const getChecks = (state, props) => ({
  '20211101-1238_Pinterest': pinterestSurveryCheck(state, props),
});

const mapStateToProps = createStructuredSelector({
  checks: getChecks,
  user: getUser,
});

export default compose(
  withCookies,
  withRouter,
  injectIntl,
  connect(mapStateToProps),
)(SupportButton);
