import { connect } from 'react-redux';

import { changeLanguage } from 'source/actions/account';

import Error from 'source/components/gateway/error';

const mapStateToProps = (state) => ({
  hasAccessToken: !!state.account.accessToken,
  language: state.application.locale,
  languageOptions: state.account.languageOptions,
});

const mapDispatchToProps = {
  onChangeLanguage: changeLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Error);
