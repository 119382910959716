import React, { Component } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'recompose';
import validator from 'validator';
import cx from 'classnames';

import { genderMessages } from 'source/utils/user';
import forms from 'source/utils/forms';
import { messageProp } from 'source/utils/propTypes';
import { languageOptions } from 'source/utils/languages';
import { supportedLocales } from 'source/i18n';

import {
  Alert,
  FormError,
  SaveProgressButton,
  CountrySelection,
  LanguageSelection,
} from 'source/components/common';

const allowedLanguages = supportedLocales;

const genders = [
  {
    message: genderMessages.F,
    value: 'F',
  },
  {
    message: genderMessages.M,
    value: 'M',
  },
  {
    message: genderMessages.D,
    value: 'D',
  },
];

const currentYear = new Date().getFullYear();

const validateForm = (form) => {
  const errors = {};

  if (allowedLanguages.indexOf(form.language) === -1) {
    errors.language = 'accountSettings.profile.errors.wrongLanguage';
  }

  if (!form.firstname) {
    errors.firstname = 'accountSettings.profile.errors.missingFirstname';
  }

  if (
    form.yearOfBirth &&
    !validator.isInt(String(form.yearOfBirth), { min: 1900, max: currentYear })
  ) {
    errors.yearOfBirth = 'accountSettings.profile.errors.wrongYearOfBirth';
  }

  return errors;
};

class ProfileForm extends Component {
  // using onClick instead of onChange
  // in order to handle click on selected radio button
  onRadioClick = (e) => {
    const { name, value, checked } = e.target;
    const formValue = this.props.form[name];

    if (value === formValue && checked) {
      e.target.value = null;
    }
    this.props.onChange(e);
  };

  handleAlertClose = () => {
    const { id, onAlertClose } = this.props;

    return onAlertClose(id);
  };

  renderAlert() {
    const {
      form: { message },
    } = this.props;

    if (!message) {
      return null;
    }

    return (
      <div className="alert-container">
        <Alert {...message} onClose={this.handleAlertClose} />
      </div>
    );
  }

  renderError = (name) => {
    const {
      form: { errors },
    } = this.props;

    const error = errors[name];
    return error ? <FormError error={{ messageId: error }} /> : null;
  };

  render() {
    const {
      id,
      form,
      form: { saveStatus },
      intl: { formatMessage },
      onChange,
      onSubmit,
    } = this.props;

    return (
      <div className="profile-form">
        <p>
          <FormattedMessage
            id="accountSettings.profile.text"
            defaultMessage="Your profile information is used to match you with the most relevant campaigns.<br></br>Make sure it is correct and up to date."
          />
        </p>
        {this.renderAlert()}

        <div className="row">
          <div className="col-md-12">
            <form id={id}>
              {/* email setting */}
              <fieldset className="form-group">
                <label htmlFor="email" className="required">
                  <FormattedMessage
                    id="accountSettings.profile.email"
                    defaultMessage="Email"
                  />
                  <span>:</span>
                </label>
                <input
                  type="email"
                  className="form-control label-default"
                  id="email"
                  value={form.email}
                  readOnly
                />
              </fieldset>

              {/* phone setting */}
              <fieldset className="form-group">
                <label htmlFor="phone">
                  <FormattedMessage
                    id="accountSettings.profile.mobilePhone"
                    defaultMessage="Mobile phone"
                  />
                  <span>:</span>
                </label>
                <input
                  type="tel"
                  className="form-control label-default"
                  id="phone"
                  value={form.phone}
                  onChange={onChange}
                />
              </fieldset>

              {/* language setting */}
              <fieldset className="form-group underline">
                <label htmlFor="language" className="required">
                  <FormattedMessage
                    id="accountSettings.profile.language"
                    defaultMessage="Language"
                  />
                  <span>:</span>
                </label>
                <div className="row">
                  <div className="col-lg-12">
                    <LanguageSelection
                      id="language"
                      languages={languageOptions}
                      onChange={onChange}
                      value={form.language}
                    />

                    <div className="error-block">
                      {this.renderError('language')}
                    </div>
                  </div>
                </div>
              </fieldset>

              {/* year of birth setting */}
              <fieldset className="form-group">
                <label htmlFor="yearOfBirth">
                  <FormattedMessage
                    id="accountSettings.profile.yearOfBirth"
                    defaultMessage="Year of birth"
                  />
                  <span>:</span>
                </label>
                <input
                  type="number"
                  className="form-control label-default"
                  id="yearOfBirth"
                  value={form.yearOfBirth || ''}
                  onChange={onChange}
                />

                <div className="error-block">
                  {this.renderError('yearOfBirth')}
                </div>
              </fieldset>

              {/* salutation setting (shown as gender in the UI) */}
              <fieldset className="form-group">
                <label htmlFor="salutation">
                  <FormattedMessage
                    id="accountSettings.profile.gender"
                    defaultMessage="Gender"
                  />
                  <span>:</span>
                </label>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="btn-group choices" data-toggle="button">
                      {genders.map(({ message, value }) => (
                        <label
                          key={`choice-${value}`}
                          className={cx('btn btn-secondary', {
                            active: form.salutation === value,
                          })}
                        >
                          <input
                            type="radio"
                            value={value}
                            name="salutation"
                            onClick={this.onRadioClick}
                            onChange={noop}
                            checked={form.salutation === value}
                          />
                          <FormattedMessage {...message} />
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </fieldset>

              {/* name setting */}
              <fieldset className="form-group">
                <label htmlFor="name" className="required">
                  <FormattedMessage
                    id="accountSettings.profile.name"
                    defaultMessage="Name"
                  />
                  <span>:</span>
                </label>
                <div className="row">
                  <div className="col-lg-6">
                    <input
                      type="text"
                      className="form-control static"
                      id="firstname"
                      value={form.firstname}
                      onChange={onChange}
                      placeholder={formatMessage({
                        id: 'accountSettings.profile.firstname',
                        defaultMessage: 'First name',
                      })}
                    />

                    <div className="error-block">
                      {this.renderError('firstname')}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="text"
                      className="form-control static"
                      id="lastname"
                      value={form.lastname}
                      onChange={onChange}
                      placeholder={formatMessage({
                        id: 'accountSettings.profile.lastname',
                        defaultMessage: 'Last name',
                      })}
                    />
                  </div>
                </div>
              </fieldset>

              {/* address setting */}
              <fieldset className="form-group">
                <label htmlFor="address">
                  <FormattedMessage
                    id="accountSettings.profile.address"
                    defaultMessage="Address"
                  />
                  <span>:</span>
                </label>
                <div className="row">
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control static"
                      id="streetName"
                      onChange={onChange}
                      value={form.streetName}
                      placeholder={formatMessage({
                        id: 'accountSettings.profile.street',
                        defaultMessage: 'Street',
                      })}
                    />
                  </div>
                  <div className="col-lg-4">
                    <input
                      type="text"
                      className="form-control static"
                      id="streetNumber"
                      onChange={onChange}
                      value={form.streetNumber}
                      placeholder={formatMessage({
                        id: 'accountSettings.profile.streetNumber',
                        defaultMessage: 'Street number',
                      })}
                    />
                  </div>
                </div>
                <div className="row m-t-1">
                  <div className="col-lg-4">
                    <input
                      type="text"
                      className="form-control static"
                      id="postalCode"
                      onChange={onChange}
                      value={form.postalCode}
                      placeholder={formatMessage({
                        id: 'accountSettings.profile.postalCode',
                        defaultMessage: 'Postal code',
                      })}
                    />
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      className="form-control static"
                      id="city"
                      onChange={onChange}
                      value={form.city}
                      placeholder={formatMessage({
                        id: 'accountSettings.profile.city',
                        defaultMessage: 'City',
                      })}
                    />
                  </div>
                </div>
                <div className="row m-t-1">
                  <div className="col-lg-12">
                    <div className="underline">
                      <CountrySelection
                        id="country"
                        onChange={onChange}
                        value={form.country}
                      />
                    </div>
                  </div>
                </div>
              </fieldset>

              <SaveProgressButton onClick={onSubmit} status={saveStatus} />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

ProfileForm.propTypes = {
  id: PropTypes.string.isRequired,

  form: PropTypes.shape({
    email: PropTypes.string.isRequired,
    phone: PropTypes.string,
    language: PropTypes.string.isRequired,
    yearOfBirth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    salutation: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    streetName: PropTypes.string,
    streetNumber: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string.isRequired,
    message: messageProp,
    saveStatus: PropTypes.string.isRequired,
    errors: PropTypes.object.isRequired,
  }).isRequired,

  onAlertClose: PropTypes.func.isRequired,

  // props from forms HOC
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default compose(forms({ validateForm }), injectIntl)(ProfileForm);
