import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import omit from 'lodash/omit';
import values from 'lodash/values';
import get from 'lodash/get';
import moment from 'moment';

import config from 'config';

import instagramPostsImpressionsUrl from 'source/images/insights/instagram-posts-impressions.png';
import instagramStoriesImpressionsUrl from 'source/images/insights/instagram-stories-impressions.png';
import instagramCountriesUrl from 'source/images/insights/instagram-countries.png';
import instagramAgeAndGenderUrl from 'source/images/insights/instagram-age-and-gender.png';
import tiktokOverviewUrl from 'source/images/insights/tiktok-overview.png';
import tiktokContentUrl from 'source/images/insights/tiktok-content.png';
import tiktokFollowersUrl from 'source/images/insights/tiktok-followers.png';

import { getKPISeries } from './kpi';

export const screenshotNames = {
  instagram: [
    'countries',
    'ageAndGender',
    'storiesImpressions',
    'postsImpressions',
  ],
  tiktok: ['overview', 'content', 'followers'],
};

export const screenshotDefaults = {
  instagram: {
    storiesImpressions: instagramStoriesImpressionsUrl,
    postsImpressions: instagramPostsImpressionsUrl,
    countries: instagramCountriesUrl,
    ageAndGender: instagramAgeAndGenderUrl,
  },
  tiktok: {
    overview: tiktokOverviewUrl,
    content: tiktokContentUrl,
    followers: tiktokFollowersUrl,
  },
};

export const getOldestScreenshotAge = ({ screenshots, platform }) => {
  const oldestUpdatedAt = get(screenshots, 'oldestUpdatedAt');

  const isMissing =
    isEmpty(screenshots) ||
    screenshotNames[platform].some(
      (prop) => !screenshots[prop] || !screenshots[prop].url,
    );

  if (!oldestUpdatedAt || isMissing) {
    return null;
  }

  const today = moment().startOf('day');
  const updatedAtDate = moment(oldestUpdatedAt).startOf('day');
  return moment(today).diff(updatedAtDate, 'days');
};

export const hasAlmostOutdatedScreenshots = ({ platform, screenshots }) => {
  const platformInsights = config.insights[platform];
  if (!platformInsights) {
    return false;
  }

  const oldestScreenshotAge = getOldestScreenshotAge({ screenshots, platform });
  const { insightsIntermediateTtl } = config.insights[platform];

  return (
    oldestScreenshotAge === null ||
    oldestScreenshotAge > insightsIntermediateTtl
  );
};

export const hasOutdatedScreenshots = ({ platform, screenshots }) => {
  const platformInsights = config.insights[platform];
  if (!platformInsights) {
    return false;
  }

  const oldestScreenshotAge = getOldestScreenshotAge({ screenshots, platform });
  const { insightsTtl } = config.insights[platform];

  return oldestScreenshotAge === null || oldestScreenshotAge >= insightsTtl;
};

// we consider channel is pending if it's either:
//   - in quality review state (qualityReview === true)
//   - or it does not have cached data
//   - the rest of properties must be true
export const isPendingReadyState = (readyState = {}) =>
  readyState.qualityReview === true ||
  (readyState.hasCachedData === false &&
    values(omit(readyState, ['hasCachedData'])).every((v) => v));

export const platformLabels = defineMessages({
  ga: {
    id: 'channels.platforms.ga',
    defaultMessage: 'Blog',
  },
  twitter: {
    id: 'channels.platforms.twitter',
    defaultMessage: 'Twitter',
  },
  facebook: {
    id: 'channels.platforms.facebook',
    defaultMessage: 'Facebook',
  },
  instagram: {
    id: 'channels.platforms.instagram',
    defaultMessage: 'Instagram',
  },
  pinterest: {
    id: 'channels.platforms.pinterest',
    defaultMessage: 'Pinterest',
  },
  youtube: {
    id: 'channels.platforms.youtube',
    defaultMessage: 'YouTube',
  },
  tiktok: {
    id: 'channels.platforms.tiktok',
    defaultMessage: 'TikTok',
  },
});

export const getChannelName = (channel) => {
  if (channel.url || channel.data.url) {
    return channel.name;
  }

  if (channel.referenceChannel && channel.referenceChannel.name) {
    return channel.referenceChannel.name;
  }

  return (
    <FormattedMessage
      id="insights.v2.overview.error.notReady.ga.title"
      defaultMessage="New Channel"
    />
  );
};

export const isPending = (channel) =>
  channel.platform === 'ga' && isPendingReadyState(channel.readyState);

export const hasEnoughData = (channel) => {
  const { platform } = channel;

  if (platform === 'ga') {
    return !isPending(channel);
  }

  if (platform === 'pinterest') {
    return !isUndefined(get(channel, 'keyMetrics.impressionMedian'));
  }

  return !isEmpty(getKPISeries(channel));
};
