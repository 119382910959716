import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { FormattedMessage, injectIntl } from 'react-intl';

import Markdown from 'source/components/common/markdown';
import { links } from 'source/i18n';

import ConnectButton from '../common/connectButton';

const InstagramOAuthConnectStep = injectIntl(({ id, onConnect, intl }) => (
  <div className="channel-form">
    <ConnectButton
      id={id}
      platform="instagram"
      onClick={onConnect}
      messageId="channels.instagram.connectOAuth.button"
    />
    <div className="m-y-2">
      <Markdown
        markdown={intl.formatMessage({
          id: 'channels.instagram.connectOAuth.description_MD',
          defaultMessage:
            '– EQOLOT will never update your profile or post on your Instagram\n– you can revoke the connection anytime from your Facebook settings',
        })}
      />
    </div>
    <p className="p-b-1">
      <a
        href={intl.formatMessage(links.connectOAuthHowItWorksLink)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <b>
          <FormattedMessage
            id="channels.instagram.connectOAuth.howItWorks"
            defaultMessage="How it works"
          />
        </b>
      </a>
    </p>
  </div>
));

InstagramOAuthConnectStep.propTypes = {
  id: PropTypes.string,
  onConnect: PropTypes.func,
};

InstagramOAuthConnectStep.defaultProps = {
  onPrevStep: noop,
  onConnect: noop,
};

export default InstagramOAuthConnectStep;
