import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';

import { isSmallWindow } from 'source/utils/window';
import { withInnerRef } from 'source/utils/dom';
import Icon from 'source/components/common/icon';
import Logo from 'source/components/logo';
import { themeProps } from 'source/utils/theme';

const SidebarLink = injectIntl(
  ({ className, icon, label, title, intl, onClick, ...otherProps }) => {
    const classNames = cx('sidebar-link', 'text-truncate', className);
    return (
      <li
        className={classNames}
        title={title || intl.formatMessage(label)}
        onClick={onClick}
      >
        <Link {...otherProps}>
          <Icon name={icon} />
          <FormattedMessage {...label} />
        </Link>
      </li>
    );
  },
);

SidebarLink.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  title: PropTypes.string,
};

SidebarLink.defaultProps = {
  activeClassName: 'active',
  title: '',
};

const labels = defineMessages({
  brandClubs: {
    id: 'navigation.links.brandClubs',
    defaultMessage: 'Brand Clubs',
  },
  sponsoredPosts: {
    id: 'navigation.links.sponsoredPosts',
    defaultMessage: 'New Campaigns',
  },
  activeSponsoredPosts: {
    id: 'navigation.links.activeSponsoredPosts',
    defaultMessage: 'My Campaigns',
  },
  insights: {
    id: 'navigation.links.insights',
    defaultMessage: 'Insights',
  },
  profile: {
    id: 'navigation.links.profile',
    defaultMessage: 'Profile',
  },
  revenues: {
    id: 'navigation.links.revenues',
    defaultMessage: 'Revenues',
  },
  settings: {
    id: 'navigation.links.settings',
    defaultMessage: 'Account Settings',
  },
});

export const SidebarTrigger = withInnerRef(
  ({ onClick, isActive, innerRef }) => (
    <button
      ref={innerRef}
      className="sidebar-trigger-container"
      onClick={onClick}
    >
      <div
        className={cx('sidebar-trigger', 'icon-button', {
          'sidebar-trigger-active': isActive,
        })}
      >
        <Icon name="hamburger-menu" />
      </div>
    </button>
  ),
);

SidebarTrigger.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

SidebarTrigger.defaultProps = {
  isActive: false,
};

function Sidebar({ theme: { themeClass }, collapsed, toggle, innerRef }) {
  return (
    <div className={themeClass} ref={innerRef}>
      <div className={cx('sidebar', { 'sidebar-collapsed': collapsed })}>
        {isSmallWindow() ? (
          <Logo className="sidebar-logo" onClick={toggle} />
        ) : (
          <Logo className="sidebar-logo" to="/" />
        )}
        <ul className="sidebar-nav">
          <SidebarLink
            icon="gift"
            to="/brand-clubs"
            label={labels.brandClubs}
            onClick={toggle}
          />
          <SidebarLink
            icon="package"
            to="/sponsored-posts/campaigns"
            label={labels.sponsoredPosts}
            onClick={toggle}
          />
          <div className="sidebar-separator" />
          <SidebarLink
            icon="clipboard"
            to="/sponsored-posts/applications"
            label={labels.activeSponsoredPosts}
            onClick={toggle}
          />
          <SidebarLink
            icon="profile"
            to="/profile"
            label={labels.profile}
            onClick={toggle}
          />
          <SidebarLink
            icon="credit-card"
            to="/revenues"
            label={labels.revenues}
            onClick={toggle}
          />
          <SidebarLink
            icon="gear"
            to="/account-settings"
            label={labels.settings}
            onClick={toggle}
          />
        </ul>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  ...themeProps,
  collapsed: PropTypes.bool.isRequired,
};

export default withInnerRef(Sidebar);
