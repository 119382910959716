import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { MessageCard } from 'source/components/common/message';
import { ActivityStreamBody } from 'source/components/common/activityStream';

function DoneHint() {
  return (
    <ActivityStreamBody>
      <MessageCard type="info">
        <strong>
          <FormattedMessage
            id="sponsoredPosts.campaigns.verification.accepted.feedback"
            defaultMessage="Congrats!"
          />
        </strong>
        <span>
          <FormattedMessage
            id="sponsoredPosts.campaigns.verification.accepted.message"
            defaultMessage="Your post has been approved."
          />
        </span>
      </MessageCard>
    </ActivityStreamBody>
  );
}

export default injectIntl(DoneHint);
