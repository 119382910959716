import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { resetStore, redirectToSignIn } from 'source/actions/account';

export default function requireAuthentication(Component) {
  class AuthenticatedComponent extends React.Component {
    static propTypes = {
      token: PropTypes.string,
      dispatch: PropTypes.func.isRequired,
      query: PropTypes.string,
      path: PropTypes.string,
    };

    componentDidMount() {
      this._checkAndRedirect();
    }

    componentDidUpdate() {
      this._checkAndRedirect();
    }

    _checkAndRedirect() {
      const { dispatch, token } = this.props;

      if (!token) {
        dispatch(resetStore('requireAuthentication:_checkAndRedirect'));
        dispatch(redirectToSignIn('pending', { replaceRoute: true }));
      }
    }

    render() {
      return (
        <div className="fullscreen authenticated">
          {this.props.token ? <Component {...this.props} /> : null}
        </div>
      );
    }
  }

  const mapStateToProps = (state, props) => ({
    token: state.account.accessToken,
    path: props.location.pathname,
    query: props.location.search,
  });

  return connect(mapStateToProps)(AuthenticatedComponent);
}
