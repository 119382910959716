import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';

export const hasParent = (child, parent) => {
  while (child.parentNode != null) {
    if (parent === child.parentNode) {
      return true;
    }

    child = child.parentNode;
  }

  return false;
};

export const withInnerRef = (Component) =>
  React.forwardRef((props, ref) => <Component innerRef={ref} {...props} />);

export const withOutsideClickHandler = (Component) => {
  class Wrapper extends React.Component {
    componentDidMount() {
      document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }

    wrapperRef = React.createRef();

    handleClickOutside = (event) => {
      if (
        this.wrapperRef &&
        this.wrapperRef.current &&
        !this.wrapperRef.current !== event.target &&
        !this.wrapperRef.current.contains(event.target)
      ) {
        if (isFunction(this.props.onClickOutside)) {
          this.props.onClickOutside(event);
        }
      }
    };

    render() {
      return (
        <div ref={this.wrapperRef}>
          <Component {...this.props} />
        </div>
      );
    }
  }

  Wrapper.propTypes = {
    onClickOutside: PropTypes.func,
  };

  return Wrapper;
};
