import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';

import config from 'config';
import get from 'lodash/get';

import { actionAuthRequest, requestFlow } from 'source/utils/axios';
import { connectSocialPlatform } from 'source/actions/social';
import { loadedApplicationState } from 'source/actions/application';

import wizardRedux from './components/Wizard/redux';

const channelFields = JSON.stringify([
  'id',
  'name',
  'platform',
  'data',
  'details',
  'categories',
  'ready',
  'readyState',
  'referenceChannel',
  'qualityReview',
  'screenshots',
  'pricePerPost',
  'pricePerPostV2',
  'metrics',
  'extension',
  'keyMetrics',
  'avatarUrl',
]);

const namespace = 'scenes/profile';

export const actionTypes = {
  STATE_LOADING: `${namespace}/STATE_LOADING`,
  STATE_LOADED: `${namespace}/STATE_LOADED`,
  STATE_LOADING_FAILED: `${namespace}/STATE_LOADING_FAILED`,

  ADD_CHANNEL: `${namespace}/ADD_CHANNEL`,

  SELECT_CHANNEL: `${namespace}/SELECT_CHANNEL`,
  UNSELECT_CHANNEL: `${namespace}/UNSELECT_CHANNEL`,

  FORM_CHANGE: `${namespace}/FORM_CHANGE`,
  FORM_ERRORS: `${namespace}/FORM_ERRORS`,
  FORM_RESET: `${namespace}/FORM_RESET`,
  FORM_REMOVE: `${namespace}/FORM_REMOVE`,

  CHECKING_INTEGRATION_STATUS: `${namespace}/CHECKING_INTEGRATION_STATUS`,
  CHECKED_INTEGRATION_STATUS: `${namespace}/CHECKED_INTEGRATION_STATUS`,
  CHECK_INTEGRATION_STATUS_FAILED: `${namespace}/CHECK_INTEGRATION_STATUS_FAILED`,

  UPLOAD_INSIGHTS_MODAL_OPEN: `${namespace}/UPLOAD_INSIGHTS_MODAL_OPEN`,
  UPLOAD_INSIGHTS_MODAL_CLOSE: `${namespace}/UPLOAD_INSIGHTS_MODAL_CLOSE`,

  REDIRECT_RESPONSE_OCCURRED: `${namespace}/REDIRECT_RESPONSE_OCCURRED`,
  REDIRECT_RESPONSE_CLOSED: `${namespace}/REDIRECT_RESPONSE_CLOSED`,

  VERIFICATION_CODE_LOADING: `${namespace}/VERIFICATION_CODE_LOADING`,
  VERIFICATION_CODE_LOADING_FAILED: `${namespace}/VERIFICATION_CODE_LOADING_FAILED`,
  VERIFICATION_CODE_LOADED: `${namespace}/VERIFICATION_CODE_LOADED`,

  CREATE_PENDING: `${namespace}/CREATE_PENDING`,
  CREATE_FAILED: `${namespace}/CREATE_FAILED`,
  CREATE_SUCCESS: `${namespace}/CREATE_SUCCESS`,
  PATCH: `${namespace}/PATCH`,

  LOADING_ACCOUNT_SUMMARIES: `${namespace}/LOADING_ACCOUNT_SUMMARIES`,
  LOADED_ACCOUNT_SUMMARIES: `${namespace}/LOADED_ACCOUNT_SUMMARIES`,
  LOADING_ACCOUNT_SUMMARIES_FAILED: `${namespace}/LOADING_ACCOUNT_SUMMARIES_FAILED`,
  UPDATED_ACCOUNT_SUMMARIES: `${namespace}/UPDATED_ACCOUNT_SUMMARIES`,

  DISCONNECT: `${namespace}/DISCONNECT`,
  ENTER_DISCONNECT: `${namespace}/ENTER_DISCONNECT`,

  RESET_SCENE: `${namespace}/RESET_SCENE`,
};

const resetScene = () => ({ type: actionTypes.RESET_SCENE });

const loadChannelsState = (preSelections) => (dispatch, getState) => {
  const onBefore = () =>
    dispatch({
      type: actionTypes.STATE_LOADING,
    });

  const onSuccess = (payload) =>
    dispatch({
      type: actionTypes.STATE_LOADED,
      payload: { ...payload, preSelections },
    });

  const onError = (err) =>
    dispatch({
      type: actionTypes.STATE_LOADING_FAILED,
      payload: err,
    });

  const request = () => {
    const fetchCategories = actionAuthRequest(dispatch, getState).get(
      '/v1/categories',
    );

    const fetchChannels = actionAuthRequest(dispatch, getState).get(
      `/v2/channels`,
      {
        params: {
          fields: channelFields,
        },
      },
    );

    return Promise.all([fetchCategories, fetchChannels]).then(
      ([categories, channels]) => ({
        categories: categories.data,
        channels: channels.data,
      }),
    );
  };

  return requestFlow(request, { onBefore, onSuccess, onError });
};

const wizardSetStep = wizardRedux.actions.setStep('channels');

const redirectToDashboard = () => (dispatch) => {
  dispatch(push('/'));
};

export const addChannel = (type, options) => ({
  type: actionTypes.ADD_CHANNEL,
  payload: { type, options },
});

export const selectChannel = ({ id }) => ({
  type: actionTypes.SELECT_CHANNEL,
  payload: { id },
});

export const unselectChannel = () => ({
  type: actionTypes.UNSELECT_CHANNEL,
  payload: {},
});

export const redirectResponse = (query) => ({
  type: actionTypes.REDIRECT_RESPONSE_OCCURRED,
  payload: query,
});

export const redirectResponseClose =
  ({ refresh = true }) =>
  (dispatch) => {
    dispatch({ type: actionTypes.REDIRECT_RESPONSE_CLOSED });
    if (refresh) {
      dispatch(push('/profile'));
    }
  };

export const loadChannelAccountSummaries = (id) => (dispatch, getState) => {
  const onBefore = () =>
    dispatch({
      type: actionTypes.LOADING_ACCOUNT_SUMMARIES,
      payload: { id },
    });

  const onSuccess = (resp) =>
    dispatch({
      type: actionTypes.LOADED_ACCOUNT_SUMMARIES,
      payload: { id, accountSummaries: resp.data.accountSummaries },
    });

  const onError = (error) =>
    dispatch({
      type: actionTypes.LOADING_ACCOUNT_SUMMARIES_FAILED,
      payload: { id, error },
    });

  const fields = JSON.stringify(['accountSummaries']);
  const params = { fields };

  const request = () =>
    actionAuthRequest(dispatch, getState).get(`/v2/channels/${id}`, { params });

  return requestFlow(request, { onBefore, onSuccess, onError });
};

const loadVerificationCode =
  (channelId, platform, name) => (dispatch, getState) => {
    const onBefore = () =>
      dispatch({
        type: actionTypes.VERIFICATION_CODE_LOADING,
        payload: { channelId },
      });

    const onSuccess = (response) =>
      dispatch({
        type: actionTypes.VERIFICATION_CODE_LOADED,
        payload: {
          channelId,
          data: response.data,
        },
      });

    const onError = (err) =>
      dispatch({
        type: actionTypes.VERIFICATION_CODE_LOADING_FAILED,
        error: true,
        payload: {
          channelId,
          data: err,
        },
      });

    const request = () =>
      actionAuthRequest(dispatch, getState, {
        dispatchErrors: false,
      }).post('/v1/channels/code', { platform, name });

    return requestFlow(request, { onBefore, onSuccess, onError });
  };

// NOTE only used for instagram and tiktok right now
const createChannel = (channelId, platform, name) => (dispatch, getState) => {
  const onBefore = () =>
    dispatch({
      type: actionTypes.CREATE_PENDING,
      payload: { channelId },
    });

  const onSuccess = ({ data }) => {
    dispatch({
      type: actionTypes.CREATE_SUCCESS,
      payload: { channelId, data },
    });

    // update 'userHasChannels' in application state
    const channels = get(getState(), 'scenes.profile.channels');
    dispatch(loadedApplicationState({}, channels));
  };

  const onError = (err) =>
    dispatch({
      type: actionTypes.CREATE_FAILED,
      error: true,
      payload: {
        channelId,
        data: err,
      },
    });

  const request = () => {
    const params = { fields: channelFields };

    const postChannel = () =>
      actionAuthRequest(dispatch, getState, {
        dispatchErrors: false,
        dispatch503: false,
        options: {
          timeout: 60000,
        },
      }).post('/v2/channels', { platform, name });

    const fetchChannel = ({ data }) =>
      actionAuthRequest(dispatch, getState, {
        dispatchErrors: false,
      }).get(`/v2/channels/${data.id}`, { params });

    // NOTE: @alexbeletky, POST responds returns minimal set of fields,
    // in simplest case just `id` of newly created resource, we need to fetch
    // channel to get all fields to correctly render the channel on UI
    return Promise.resolve().then(postChannel).then(fetchChannel);
  };

  return requestFlow(request, { onBefore, onSuccess, onError });
};

const formChange = (id, field, value) => ({
  type: actionTypes.FORM_CHANGE,
  payload: { id, field, value },
});

const formReset = (id) => ({
  type: actionTypes.FORM_RESET,
  payload: { id },
});

const formErrors = (id, errors) => ({
  type: actionTypes.FORM_ERRORS,
  payload: { id, errors },
});

const syncInsightsUploadModalWithLocation = ({ isOpen, location, router }) => {
  if (location && router) {
    const nextQuery = new URLSearchParams(location.search);

    if (isOpen) {
      nextQuery.set('uploadInsights', 'true');
    } else {
      nextQuery.delete('uploadInsights');
    }

    router.push(`${location.pathname}?${nextQuery.toString()}`);
  }
};

const insightsUploadModalOpen = (id, location, router) => {
  syncInsightsUploadModalWithLocation({ isOpen: true, location, router });

  return {
    type: actionTypes.UPLOAD_INSIGHTS_MODAL_OPEN,
    payload: { id },
  };
};

const insightsUploadModalClose = (id, location, router) => {
  syncInsightsUploadModalWithLocation({ isOpen: false, location, router });

  return {
    type: actionTypes.UPLOAD_INSIGHTS_MODAL_CLOSE,
    payload: { id },
  };
};

const formRemove = (id) => ({
  type: actionTypes.FORM_REMOVE,
  payload: { id },
});

const submitChannelCategories =
  ({ id, form }) =>
  (dispatch, getState) => {
    const { weightDistribution = {} } = form;

    const categories = Object.keys(weightDistribution)
      .map((code) => ({ code, weight: weightDistribution[code] }))
      .filter((cat) => cat.weight > 0);

    const onBefore = () =>
      dispatch({
        type: actionTypes.PATCH,
        payload: { status: 'submitting', id },
      });

    const onSuccess = (resp) =>
      dispatch({
        type: actionTypes.PATCH,
        payload: {
          status: 'success',
          id,
          data: resp.data,
        },
      });

    const onError = (resp) =>
      dispatch({
        type: actionTypes.PATCH,
        payload: { status: 'failed', id, data: resp },
      });

    const request = () => {
      const params = { fields: channelFields };

      const patchChannel = () =>
        actionAuthRequest(dispatch, getState).patch(`/v1/channels/${id}`, {
          categories,
        });

      const fetchChannel = ({ data }) =>
        actionAuthRequest(dispatch, getState, {
          dispatchErrors: false,
        }).get(`/v2/channels/${data.id}`, { params });

      // NOTE: @pascalhelbig, PATCH responds for GA does not returns ready state.
      // We need to fetch channel to get all fields to correctly render the channel on UI
      return Promise.resolve().then(patchChannel).then(fetchChannel);
    };

    return requestFlow(request, { onBefore, onSuccess, onError });
  };

const submitChannel =
  ({ id, platform, form }) =>
  (dispatch) => {
    const handlers = {
      instagram: submitChannelCategories,
      pinterest: submitChannelCategories,
      facebook: submitChannelCategories,
      twitter: submitChannelCategories,
      youtube: submitChannelCategories,
      ga: submitChannelCategories,
      tiktok: submitChannelCategories,
    };

    const handler = handlers[platform];
    if (!handler) {
      console.warn(`no handler defined to submit: ${platform}`); // eslint-disable-line no-console
      return null;
    }

    return dispatch(handler({ id, platform, form }));
  };

const patchChannel =
  ({ id, payload }) =>
  (dispatch, getState) => {
    const onSuccess = (resp) =>
      dispatch({
        type: actionTypes.UPDATED_ACCOUNT_SUMMARIES,
        payload: { id, data: resp.data },
      });
    // We'll catch the errors in the components where this function is called to
    // show server-side validation errors.
    const onError = (err) => Promise.reject(err);

    const request = () =>
      actionAuthRequest(dispatch, getState).patch(
        `/v1/channels/${id}`,
        payload,
      );

    return requestFlow(request, { onError, onSuccess });
  };

export const connectChannel =
  ({ id, platform }) =>
  (dispatch) =>
    dispatch(
      connectSocialPlatform({
        platform,
        redirectUri: `${config.app.url}/channels?oauthRedirect=true&platform=${platform}`,
        id,
      }),
    );

const submitInsights =
  ({ file, channelId, insightsName }) =>
  (dispatch, getState) => {
    const onSuccess = (channel) => {
      dispatch({
        type: actionTypes.PATCH,
        payload: {
          status: 'success',
          id: channelId,
          data: channel,
          showInsightsUpload: true,
        },
      });

      return channel.screenshots[insightsName].url;
    };

    // This handler rejects Promise and returns it all the way back to upload component
    const onError = (resp) => Promise.reject(resp);

    const request = () => {
      const uploadFile = () => {
        const payload = new FormData();
        payload.append('file', file);

        return actionAuthRequest(dispatch, getState, {
          dispatchErrors: false,
          options: {
            timeout: 0, // set unlimited timeout
          },
        })
          .post('/v1/uploads', payload)
          .then((resp) => resp.data);
      };

      const patchChannel = (data) => {
        const [fileData] = data;
        return actionAuthRequest(dispatch, getState, {
          dispatchErrors: false,
        })
          .patch(`/v1/channels/${channelId}`, {
            screenshots: {
              [insightsName]: {
                url: fileData.url,
                filename: fileData.filename,
              },
            },
          })
          .then((channel) => channel.data);
      };

      return Promise.resolve().then(uploadFile).then(patchChannel);
    };

    return requestFlow(request, { onSuccess, onError });
  };

export const disconnectChannel =
  ({ id, revertMigration, referenceChannelId }) =>
  (dispatch, getState) => {
    const onBefore = () =>
      dispatch({
        type: actionTypes.DISCONNECT,
        payload: { id, status: 'submitting' },
      });

    const onSuccess = () => {
      if (revertMigration) {
        return dispatch(loadChannelsState(referenceChannelId));
      }

      dispatch(formRemove(id));

      // update 'userHasChannels' in application state
      const channels = get(getState(), 'scenes.profile.channels');
      return dispatch(loadedApplicationState({}, channels));
    };

    const onError = (err) =>
      dispatch({
        type: actionTypes.DISCONNECT,
        payload: { id, err, status: 'failed' },
      });

    const request = () => {
      const params = {};

      if (revertMigration) {
        params.revertMigration = true;
      }

      return actionAuthRequest(dispatch, getState).delete(
        `/v2/channels/${id}`,
        {
          params,
        },
      );
    };

    return requestFlow(request, { onBefore, onSuccess, onError });
  };

export const beforeDisconnectChannel = (id, confirmation) => (dispatch) => {
  dispatch({
    type: actionTypes.ENTER_DISCONNECT,
    payload: { id, confirmation },
  });
};

const validateChannel =
  ({ name, platform }) =>
  (dispatch, getState) => {
    const INSTAGRAM_VALIDATION_EXPRESSION = /^[a-zA-Z0-9._]{1,30}$/;

    const { channels } = getState().scenes.profile;

    const matchingChannel = channels.find(
      (channel) => channel.name === name && channel.platform === platform,
    );

    if (matchingChannel) {
      return [
        {
          id: 'name',
          messageId: 'channels.errors.usernameAlreadyExists',
        },
      ];
    }

    return !name || !name.trim() || !INSTAGRAM_VALIDATION_EXPRESSION.test(name)
      ? [{ id: 'name', messageId: 'channels.errors.invalidUsername' }]
      : [];
  };

export const formsActions = (dispatch) => {
  const formsActions = {
    // legacy platform
    // only migration to google analytics is allowed
    website: {
      onConnect: connectChannel,
      onFormReset: formReset,
    },

    ga: {
      onChange: formChange,
      onErrors: formErrors,
      onConnect: connectChannel,
      onDisconnect: disconnectChannel,
      onEnterDisconnect: beforeDisconnectChannel,
      onSubmit: submitChannel,
      onSubmitAccountDetails: patchChannel,
      onFormReset: formReset,
      onRedirectToDashboard: redirectToDashboard,
      onWizardSetStep: wizardSetStep,
      onAccountSummaries: loadChannelAccountSummaries,
    },

    twitter: {
      onChange: formChange,
      onErrors: formErrors,
      onConnect: connectChannel,
      onDisconnect: disconnectChannel,
      onEnterDisconnect: beforeDisconnectChannel,
      onFormReset: formReset,
      onWizardSetStep: wizardSetStep,
      onSubmit: submitChannel,
    },

    facebook: {
      onChange: formChange,
      onErrors: formErrors,
      onConnect: connectChannel,
      onDisconnect: disconnectChannel,
      onEnterDisconnect: beforeDisconnectChannel,
      onFormReset: formReset,
      onWizardSetStep: wizardSetStep,
      onSubmit: submitChannel,
    },

    instagram: {
      onChange: formChange,
      onErrors: formErrors,
      onLoadVerificationCode: loadVerificationCode,
      onCreateChannel: createChannel,
      onConnect: connectChannel,
      onDisconnect: disconnectChannel,
      onEnterDisconnect: beforeDisconnectChannel,
      onFormReset: formReset,
      onWizardSetStep: wizardSetStep,
      onSubmit: submitChannel,
      onSubmitInsights: submitInsights,
      onInsightsUploadModalOpen: insightsUploadModalOpen,
      onInsightsUploadModalClose: insightsUploadModalClose,
      onValidateChannel: validateChannel,
    },

    tiktok: {
      onChange: formChange,
      onErrors: formErrors,
      onLoadVerificationCode: loadVerificationCode,
      onCreateChannel: createChannel,
      onDisconnect: disconnectChannel,
      onEnterDisconnect: beforeDisconnectChannel,
      onFormReset: formReset,
      onWizardSetStep: wizardSetStep,
      onSubmit: submitChannel,
      onSubmitInsights: submitInsights,
      onInsightsUploadModalOpen: insightsUploadModalOpen,
      onInsightsUploadModalClose: insightsUploadModalClose,
      onValidateChannel: validateChannel,
    },

    pinterest: {
      onChange: formChange,
      onErrors: formErrors,
      onConnect: connectChannel,
      onDisconnect: disconnectChannel,
      onEnterDisconnect: beforeDisconnectChannel,
      onFormReset: formReset,
      onWizardSetStep: wizardSetStep,
      onSubmit: submitChannel,
    },

    youtube: {
      onChange: formChange,
      onErrors: formErrors,
      onConnect: connectChannel,
      onDisconnect: disconnectChannel,
      onEnterDisconnect: beforeDisconnectChannel,
      onFormReset: formReset,
      onWizardSetStep: wizardSetStep,
      onSubmit: submitChannel,
    },
  };

  return Object.keys(formsActions).reduce((actions, type) => {
    actions[type] = bindActionCreators(formsActions[type], dispatch);

    return actions;
  }, {});
};

export default {
  onLoadState: loadChannelsState,
  onAddProfile: addChannel,
  onSelectChannel: selectChannel,
  onUnselectChannel: unselectChannel,
  onRedirectResponse: redirectResponse,
  onRedirectResponseClose: redirectResponseClose,
  onResetScene: resetScene,
};
