import React from 'react';
import { FormattedMessage } from 'react-intl';

import { withGatewayRoot } from './root';

function AccountDeleted() {
  return (
    <div className="card gateway__card gateway__card--account-deleted text-center">
      <div className="card-block">
        <h4 className="card-title">
          <FormattedMessage
            id="accountDeleted.goodbye.text"
            defaultMessage="Goodbye."
          />
        </h4>
        <h6 className="card-subtitle text-muted">
          <FormattedMessage
            id="accountDeleted.heading"
            defaultMessage="Your account has been deleted."
          />
        </h6>
      </div>
      <img
        className="card-account-deleted-img"
        src="images/adbot-account-deleted.png"
        alt="adbot-account-deleted"
      />

      <div className="card-block">
        <p>
          <FormattedMessage
            id="accountDeleted.text"
            defaultMessage="We are sorry to see you leave. If you deleted your account by accident please contact our support team."
          />
        </p>
        <a href="mailto:support@eqolot.com" className="btn btn-secondary m-t-2">
          <FormattedMessage
            id="accountDeleted.contactSupport"
            defaultMessage="Contact Support"
          />
        </a>
      </div>
    </div>
  );
}

export default withGatewayRoot(AccountDeleted);
