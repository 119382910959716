import get from 'lodash/get';
import omit from 'lodash/omit';
import { withPropsOnChange } from 'recompose';

const withInitialValues = withPropsOnChange(['userData'], (props) => {
  const defaultBillingInfo = {
    account: {},
    address: {},
    name: {},
    tax: {},
  };

  const billingInfo =
    get(props.userData, 'billingInformation') || defaultBillingInfo;

  const vatRegistered = get(billingInfo, 'tax.vatRegistered');

  const vatNotRegistered =
    vatRegistered !== undefined && vatRegistered ? 'no' : 'yes';

  const taxDeclarationConfirmation = Boolean(
    get(billingInfo, 'tax.taxDeclarationConfirmation'),
  );

  const hasVatId = get(billingInfo, 'tax.vatId') ? 'yes' : 'no';

  return {
    initialValues: {
      ...billingInfo,
      tax: {
        ...omit(billingInfo.tax, ['vatRegistered']),
        vatNotRegistered,
        taxDeclarationConfirmation,
        hasVatId,
      },
    },
  };
});

export default withInitialValues;
