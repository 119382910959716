import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import moment from 'moment';

import { setLanguage } from 'source/actions/application';

import '../scss/screen.scss';
import * as i18n from '../i18n';

export const defaultRichTextElements = {
  br: () => <br />,
  b: (chunks) => <b>{chunks}</b>,
  strong: (chunks) => <strong>{chunks}</strong>,
  p: (chunks) => <p>{chunks}</p>,
  i: (chunks) => <i>{chunks}</i>,
  h5: (chunks) => <h5>{chunks}</h5>,
  ul: (chunks) => <ul>{chunks}</ul>,
  ol: (chunks) => <ol>{chunks}</ol>,
  li: (chunks) => <li>{chunks}</li>,
  small: (chunks) => <small>{chunks}</small>,
  blockquote: (chunks) => <blockquote>{chunks}</blockquote>,
};

class Root extends Component {
  componentDidMount() {
    // set language using a query parameter if given
    const { query, dispatch } = this.props;

    if (query.lang) {
      dispatch(setLanguage(query.lang));
    }
  }

  render() {
    const { locale, children } = this.props;

    moment.locale(locale);

    return (
      <IntlProvider
        locale={locale}
        messages={i18n[locale]}
        defaultLocale={i18n.defaultLocale}
        // https://formatjs.io/docs/react-intl/upgrade-guide-3x#breaking-api-changes
        textComponent="span"
        defaultRichTextElements={defaultRichTextElements}
      >
        {children}
      </IntlProvider>
    );
  }
}

Root.propTypes = {
  children: PropTypes.any,
  locale: PropTypes.string.isRequired,
  query: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

Root.defaultProps = {
  children: undefined,
};

const mapStateToProps = (state, ownProps) => ({
  locale: state.application.locale,
  query: ownProps.location.query,
});

export default connect(mapStateToProps)(Root);
