const development = require('./development');
const stage = require('./stage');
const production = require('./production');

const env = process.env.TARGET_ENV || 'development';

const configs = {
  development,
  stage,
  production,
};

module.exports = configs[env];
