import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import cx from 'classnames';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import {
  ToolTipController,
  Select as ToolTipWrapper,
} from 'react-tooltip-controller';

import { links } from 'source/i18n';
import Card, { CardBlock, CardTitle } from 'source/components/common/card';
import Icon from 'source/components/common/icon';
import Tooltip from 'source/components/common/tooltip';
import Markdown from 'source/components/common/markdown';
import { trim } from 'source/utils';

import {
  publishDateMessageProps,
  findMaxPayment,
  campaignShape,
  matchesShape,
  disableClick,
  stopPropagation,
} from './campaignCardHelpers';
import CampaignImage from './CampaignImage';
import CampaignCardLabel from './CampaignCardLabel';
import CampaignTypeLabel from './CampaignTypeLabel';

function CampaignCardHeader({
  matches,
  campaign: {
    details: { headline, mission },
  },
}) {
  return (
    <div className="sponsored-posts-campaign-header-container">
      {!matches.length ? (
        <Icon className="sponsored-posts-campaign-name-icon" name="lock" />
      ) : null}
      <div className="sponsored-posts-campaign-header">
        <CardTitle className="sponsored-posts-campaign-name">
          {headline}
        </CardTitle>
        <div className="sponsored-posts-campaign-description">
          <Markdown markdown={trim(mission)} typeName="span" noParagraphs />
        </div>
      </div>
    </div>
  );
}

CampaignCardHeader.propTypes = {
  campaign: campaignShape.isRequired,
  matches: matchesShape.isRequired,
};

CampaignCardHeader.defaultProps = {
  available: true,
};

const UnavailableBody = injectIntl(({ intl }) => (
  <div className="sponsored-posts-campaign-card-meta centered">
    <div
      className="sponsored-posts-campaign-card-meta-info"
      onClick={
        /**
         * NOTE @alexspri
         *    We need to stop the propagation here as the link doesn't
         *    work otherwise. Because the `CampaignCard` prevents the default
         *    otherwise.
         */
        stopPropagation
      }
    >
      <a
        target="_blank"
        rel="noreferrer"
        href={intl.formatMessage(links.faqLink)}
      >
        <FormattedMessage
          id="sponsoredPosts.campaigns.requirements.generic"
          defaultMessage="Criteria not met. See our FAQs to find out more."
        />
      </a>
    </div>
  </div>
));

function DetailsBody({
  campaign: { timeline },
  match: {
    payment: {
      totals: { price },
      currency,
    },
  },
}) {
  const hasAvailablePrice = price > 0;

  return (
    <div className="sponsored-posts-campaign-card-meta">
      <div className="sponsored-posts-campaign-publish-date">
        <FormattedMessage {...publishDateMessageProps(timeline)} />
      </div>

      {hasAvailablePrice && (
        <div className="sponsored-posts-campaign-net-price-box">
          <div className="sponsored-posts-campaign-price-info">
            <span className="price-info">
              <FormattedMessage id="netPrice" defaultMessage="Net Price" />
            </span>
            &nbsp;
            <ToolTipController detect="hover" offsetX={-198}>
              <ToolTipWrapper>
                <span className="icon-wrapper">
                  <Icon name="info" />
                </span>
              </ToolTipWrapper>
              <Tooltip className="tooltip-net-price">
                <FormattedMessage
                  id="netPrice.tooltip"
                  defaultMessage="If you represent a VAT-paying company, the tax will be added on top of the credit note you receive for participating in the campaign at the end of the collaboration."
                />
              </Tooltip>
            </ToolTipController>
          </div>
          <div className="sponsored-posts-campaign-price sponsored-posts-campaign-price-card">
            <strong className="h1">
              <FormattedNumber
                value={price}
                currency={currency}
                // eslint-disable-next-line react/style-prop-object
                style="currency"
              />
            </strong>
          </div>
        </div>
      )}
    </div>
  );
}

DetailsBody.propTypes = {
  campaign: PropTypes.shape({
    timeline: PropTypes.object.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    payment: PropTypes.object.isRequired,
  }).isRequired,
};

const handleClubId = (clubId) => (clubId ? `?clubId=${clubId}` : '');

function CampaignCard({ campaign, matches, label, clubId, link }) {
  return (
    <Link
      className="sponsored-posts-campaign-card-container"
      to={
        link ||
        `/sponsored-posts/campaigns/${campaign.id}${handleClubId(clubId)}`
      }
      onClick={!matches.length ? disableClick : undefined}
    >
      <Card
        className={cx('sponsored-posts-campaign-card', {
          'sponsored-posts-campaign-card--unavailable': !matches.length,
        })}
      >
        <CampaignTypeLabel type={campaign.type} />
        {label ? <CampaignCardLabel label={label} /> : null}
        <CardBlock element={<CampaignImage campaign={campaign} />} />
        <CardBlock>
          <CampaignCardHeader campaign={campaign} matches={matches} />
          {!matches.length ? (
            <UnavailableBody />
          ) : (
            <DetailsBody campaign={campaign} match={findMaxPayment(matches)} />
          )}
        </CardBlock>
      </Card>
    </Link>
  );
}

CampaignCard.propTypes = {
  campaign: campaignShape,
  matches: matchesShape,
  label: PropTypes.string,
  link: PropTypes.string,
  clubId: PropTypes.string,
};

CampaignCard.defaultProps = {
  label: '',
};

export default CampaignCard;
