import { asyncActionCreator } from '@blogfoster/redux-async-utils';

import { actionAuthRequest } from 'source/utils/axios';

export const actionTypes = {
  INVOICES_FETCH: 'data/v2/invoices/FETCH',
};

export const getInvoices = asyncActionCreator(
  actionTypes.INVOICES_FETCH,
  ({ fields = [], filters = [] } = {}) =>
    (dispatch, getState) => {
      const params = {};

      if (fields && fields.length > 0) {
        params.fields = JSON.stringify(fields);
      }

      if (filters && filters.length > 0) {
        params.filters = JSON.stringify(filters);
      }

      return actionAuthRequest(dispatch, getState, {
        dispatchErrors: false,
      }).get('/v2/invoices', { params });
    },
);
