import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import signupCountries from 'source/i18n/signupCountries.json';

const sortBy = (key) => (a, b) => {
  const valueA = get(a, key, '');
  const valueB = get(b, key, '');

  return valueA.localeCompare(valueB);
};

// EU + Schengen + Brexit
const EU_SCH_BRE = ['EU', 'SCH', 'BRE'];

// EU + Schengen + Brexit countries
const euSchBreCountries = signupCountries.filter(
  (country) => !isEmpty(intersection(EU_SCH_BRE, country.groups)),
);

// Others
const otherCountries = signupCountries.filter((c) => isEmpty(c.groups));

const sortSignupCountries = (locale = 'de') => {
  const compareFn = sortBy(`name.${locale.toLowerCase()}`);

  return [euSchBreCountries, otherCountries].reduce(
    (allCountries, countriesGroup) => [
      ...allCountries,
      ...countriesGroup.sort(compareFn),
    ],
    [],
  );
};

const signupCountriesMap = {
  de: sortSignupCountries('de'),
  en: sortSignupCountries('en'),
};

export const getSignupCountries = (locale = 'de') =>
  get(signupCountriesMap, locale.toLowerCase(), []);

export const getCountry = (code) =>
  signupCountries.find(
    (country) => country.code.toLowerCase() === code.toLowerCase(),
  );
