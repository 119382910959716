import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import InvoiceLabel from './InvoiceLabel';
import InvoiceTableAccordion from './InvoiceTableAccordion';

function InvoiceItem({
  invoice,
  invoices,
  invoice: { type, id, legacyId, isLegacyInvoice, pdfUrl, total, currency },
}) {
  return (
    <li className="invoice-item">
      <div className="invoice-header">
        <h4 className="invoice-title">
          <FormattedMessage id={`revenues.invoice.${type}`} /> #
          {isLegacyInvoice ? legacyId : id}
        </h4>
        <InvoiceLabel invoice={invoice} invoices={invoices} />
        <a
          className="invoice-download"
          href={pdfUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="btn btn-primary" type="button">
            <FormattedMessage
              id="revenues.invoices.downloadPdf"
              defaultMessage="Download PDF"
            />
          </button>
        </a>
      </div>
      <InvoiceTableAccordion invoice={invoice} />
      <div className="invoice-totals">
        <span className="invoice-totals-label">
          <FormattedMessage
            id="revenues.invoices.totals"
            defaultMessage="Total:"
          />
        </span>
        <span className="invoice-totals-value">
          <FormattedNumber
            value={total / 100}
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            currency={currency}
          />
        </span>
      </div>
    </li>
  );
}

InvoiceItem.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.object),
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    legacyId: PropTypes.string.isRequired,
    isLegacyInvoice: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(['creditNote', 'invoice']).isRequired,
    currency: PropTypes.string.isRequired,
    lines: PropTypes.arrayOf(PropTypes.object).isRequired,
    total: PropTypes.number.isRequired,
    pdfUrl: PropTypes.string.isRequired,
  }).isRequired,
};

export default InvoiceItem;
