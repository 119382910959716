import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import noop from 'lodash/noop';

const categoryIcons = {
  IAB1: 'icon-category-arts-entertainment',
  IAB2: 'icon-category-automotive',
  IAB3: 'icon-category-business',
  IAB4: 'icon-category-careers',
  IAB5: 'icon-education',
  IAB6: 'icon-category-family-parenting',
  IAB7: 'icon-category-health-fitness',
  IAB8: 'icon-category-food-drink',
  IAB9: 'icon-category-hobbies-interests',
  IAB10: 'icon-category-home-garden',
  IAB11: 'icon-law',
  IAB12: 'icon-category-news',
  IAB13: 'icon-category-personal-finance',
  IAB14: 'icon-category-society',
  IAB16: 'icon-category-pets',
  IAB17: 'icon-category-sports',
  IAB18: 'icon-category-style-fashion',
  IAB19: 'icon-category-technology-computing',
  IAB20: 'icon-category-travel',
  IAB21: 'icon-real-estate',
  IAB22: 'icon-shopping',
};

class Category extends React.PureComponent {
  handleClick = (e) => {
    const { code, checked, disabled, onChange } = this.props;

    e.preventDefault();
    e.stopPropagation();

    if (disabled) {
      return null;
    }

    return onChange({ code, checked: !checked });
  };

  render() {
    const { code, checked, disabled } = this.props;

    const icon = categoryIcons[code];

    return (
      <div
        role="button"
        className="category-chip"
        onClick={this.handleClick}
        tabIndex={0}
      >
        <input
          type="checkbox"
          id={code}
          checked={checked}
          disabled={disabled}
          onChange={noop}
        />
        <label
          htmlFor={code}
          className={cx('category-chip__label', { disabled })}
        >
          {icon ? (
            <svg className="icon">
              <use xlinkHref={`#${icon}`} />
            </svg>
          ) : null}
          <FormattedMessage
            className="category-name"
            id={`preferences.categories.${code}`}
          />
        </label>
      </div>
    );
  }
}

Category.propTypes = {
  code: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

Category.defaultProps = {
  checked: false,
  disabled: false,
  onChange: noop,
};

export default Category;
