import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import deepTrim from 'deep-trim';

import { isIbanValid, isBicValid } from 'source/utils/account';

import {
  isValidVatId,
  isValidGermanTaxId,
  isValidGermanTaxIdCode,
} from '../../../../../utils/validators';

// prettier-ignore
const euCountries = new Set([
  'AT', 'IT', 'BE', 'LV', 'BG', 'LT', 'HR', 'LU', 'CY', 'MT', 'CZ', 'NL', 'DK',
  'PL', 'EE', 'PT', 'FI', 'RO', 'FR', 'SK', 'DE', 'SI', 'GR', 'ES', 'HU', 'SE',
  'IE', 'GB', 'CH', 'NO', 'IS',
]);

export const isEuropeanCountry = (country) => euCountries.has(country);

const doesNotExist = (value) => value === '' || value == null;

export const validateValues = (values, initialValues) => {
  const errors = {
    name: {},
    address: {},
    account: {},
    tax: {},
  };

  // trim all string values
  values = deepTrim(values);

  // tax values
  const tax = get(values, 'tax');
  const vatNotRegistered = get(values, 'tax.vatNotRegistered');
  const hasVatId = get(values, 'tax.hasVatId');
  const taxDeclarationConfirmation = get(
    values,
    'tax.taxDeclarationConfirmation',
  );
  const vatId = get(values, 'tax.vatId');
  const taxId = get(values, 'tax.taxId');
  const taxIdCode = get(values, 'tax.taxIdCode');

  // name values
  const name = get(values, 'name');
  const firstname = get(values, 'name.firstname');
  const lastname = get(values, 'name.lastname');

  // address values
  const address = get(values, 'address');
  const company = get(values, 'address.company');
  const street = get(values, 'address.street');
  const number = get(values, 'address.number');
  const postalCode = get(values, 'address.postalCode');
  const city = get(values, 'address.city');
  const country = get(values, 'address.country');

  // bank account values
  const account = get(values, 'account');
  const owner = get(values, 'account.owner');
  const iban = get(values, 'account.iban');
  const bic = get(values, 'account.bic');
  const initialIban = get(initialValues, 'account.iban');
  const initialBic = get(initialValues, 'account.bic');

  if (country === 'DE' && !tax) {
    // if the `tax` form wasn't initialized only require "Are you a
    // Kleinunternehmen?" selection
    errors.tax.vatNotRegistered =
      'accountSettings.payment.missingVatNotRegistered';
  }

  if (country === 'DE' && tax) {
    // always require the "Are you a Kleinunternehmen?" selection
    if (doesNotExist(vatNotRegistered)) {
      errors.tax.vatNotRegistered =
        'accountSettings.payment.missingVatNotRegistered';
    }

    if (vatNotRegistered === 'yes') {
      // validations for small German companies ("Kleinunternehmen")

      if (!taxDeclarationConfirmation) {
        errors.tax.taxDeclarationConfirmation =
          'accountSettings.payment.missingTaxDeclarationConfirmation';
      }

      if (doesNotExist(taxId) && doesNotExist(taxIdCode)) {
        errors.tax.taxId = 'accountSettings.payment.missingTaxId';
        errors.tax.taxIdCode = 'accountSettings.payment.missingTaxIdCode';
      }

      // if `taxId` is given, make sure it's valid
      if (taxId && !isValidGermanTaxId(taxId)) {
        errors.tax.taxId = 'accountSettings.payment.taxIdInvalid';
      }

      if (taxIdCode && !isValidGermanTaxIdCode(taxIdCode)) {
        errors.tax.taxIdCode = 'accountSettings.payment.taxIdCodeInvalid';
      }
    }

    if (vatNotRegistered === 'no') {
      // validations for normal German companies

      if (
        doesNotExist(vatId) &&
        doesNotExist(taxId) &&
        doesNotExist(taxIdCode)
      ) {
        errors.tax.vatId = 'accountSettings.payment.missingVatId';
        errors.tax.taxId = 'accountSettings.payment.missingTaxId';
        errors.tax.taxIdCode = 'accountSettings.payment.missingTaxIdCode';
      }

      if (vatId && !isValidVatId(vatId, country)) {
        errors.tax.vatId = 'accountSettings.payment.vatIdInvalid';
      }

      if (taxId && !isValidGermanTaxId(taxId)) {
        errors.tax.taxId = 'accountSettings.payment.taxIdInvalid';
      }

      if (taxIdCode && !isValidGermanTaxIdCode(taxIdCode)) {
        errors.tax.taxIdCode = 'accountSettings.payment.taxIdCodeInvalid';
      }
    }
  }

  if (isEuropeanCountry(country) && country !== 'DE' && !tax) {
    // For European countries, we make them answer the question
    // "Do you have a VAT ID?" first before anything else.
    errors.tax.hasVatId = 'accountSettings.payment.missingHasVatId';
  }

  if (isEuropeanCountry(country) && country !== 'DE' && tax) {
    // an answer for the "Do you have a VAT ID?" question is required
    if (!hasVatId) {
      errors.tax.hasVatId = 'accountSettings.payment.missingHasVatId';
    }

    if (hasVatId === 'yes' && !vatId) {
      // if the user says he does have a VAT ID, `vatId` is required
      errors.tax.vatId = 'accountSettings.payment.missingVat';
    }

    // if a `vatId` is given, make sure it's valid
    if (vatId && !isValidVatId(vatId, country)) {
      errors.tax.vatId = 'accountSettings.payment.vatIdInvalid';
    }

    if (hasVatId === 'no' && !taxDeclarationConfirmation) {
      // if the user says he doesn't have a VAT ID, `taxDeclarationConfirmation`
      // has to be checked
      errors.tax.taxDeclarationConfirmation =
        'accountSettings.payment.missingTaxDeclarationConfirmation';
    }
  }

  if (!name) {
    errors.name.firstname = 'accountSettings.payment.missingFirstName';
    errors.name.lastname = 'accountSettings.payment.missingLastName';
  } else {
    if (!firstname) {
      errors.name.firstname = 'accountSettings.payment.missingFirstName';
    }

    if (!lastname) {
      errors.name.lastname = 'accountSettings.payment.missingLastName';
    }
  }

  if (!address) {
    errors.address.company = 'accountSettings.payment.missingCompanyName';
    errors.address.street = 'accountSettings.payment.missingStreet';
    errors.address.number = 'accountSettings.payment.missingNumber';
    errors.address.postalCode = 'accountSettings.payment.missingPostalCode';
    errors.address.city = 'accountSettings.payment.missingCity';
    errors.address.country = 'accountSettings.payment.missingCompanyCountry';
  } else {
    if (!company) {
      errors.address.company = 'accountSettings.payment.missingCompanyName';
    }

    if (!street) {
      errors.address.street = 'accountSettings.payment.missingStreet';
    }

    if (!number) {
      errors.address.number = 'accountSettings.payment.missingNumber';
    }

    if (!postalCode) {
      errors.address.postalCode = 'accountSettings.payment.missingPostalCode';
    }

    if (!city) {
      errors.address.city = 'accountSettings.payment.missingCity';
    }

    if (!country) {
      errors.address.country = 'accountSettings.payment.missingCountry';
    }
  }

  if (!account) {
    errors.account.owner = 'accountSettings.payment.missingOwner';
    errors.account.iban = 'accountSettings.payment.missingIban';
    errors.account.bic = 'accountSettings.payment.missingBic';
  } else {
    if (!owner) {
      errors.account.owner = 'accountSettings.payment.missingOwner';
    }

    if (!iban) {
      errors.account.iban = 'accountSettings.payment.missingIban';
    }

    if (iban && iban !== initialIban && !isIbanValid(iban)) {
      errors.account.iban = 'accountSettings.payment.ibanInvalid';
    }

    if (!bic) {
      errors.account.bic = 'accountSettings.payment.missingBic';
    }

    if (bic && bic !== initialBic && !isBicValid(bic)) {
      errors.account.bic = 'accountSettings.payment.bicInvalid';
    }
  }

  const hasErrors =
    !isEmpty(errors.account) ||
    !isEmpty(errors.address) ||
    !isEmpty(errors.name) ||
    !isEmpty(errors.tax);

  return { hasErrors, errors };
};
