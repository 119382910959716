import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { compose } from 'recompose';
import get from 'lodash/get';

import { SubmitButton } from 'source/components/common/button';
import Form, {
  FormGroup,
  FormActions,
  Textarea,
  Input,
  FormControlFeedback,
} from 'source/components/common/form';
import { ActivityStreamBody } from 'source/components/common/activityStream';
import { MessageCard } from 'source/components/common/message';
import { hasAttributes } from 'source/utils';

import {
  VerificationInactive,
  VerificationActive,
  VerificationPending,
  VerificationRejected,
  VerificationDone,
  VerificationExpired,
} from './verificationSteps';

// Helpers

const createPreviewHelpLink = defineMessages({
  url: {
    id: 'createPreviewHelp.blog.url',
    defaultMessage:
      'https://eqolot.com/en/academy/so-erstellst-du-eine-blogpost-vorschau-fuer-die-blogfoster-ueberpruefung',
  },

  label: {
    id: 'createPreviewHelp.blog.label',
    defaultMessage: 'this tutorial',
  },
});

// Components

/* eslint-disable react/prop-types */

function VerificationForm({ intl, form, formName, onChange, onSubmit }) {
  const data = form[formName] || {};

  return (
    <Form id={formName} onChange={onChange} onSubmit={onSubmit}>
      <FormGroup hasDanger={hasAttributes(data.errors, ['title'])}>
        <Input
          id="title"
          placeholder={intl.formatMessage({
            id: 'sponsoredPosts.campaigns.verification.submit.titlePlaceholder',
            defaultMessage: 'Title of your article',
          })}
          value={data.title || ''}
          danger={!!get(data, 'errors.title')}
        />

        <FormControlFeedback
          message={
            get(data, 'errors.title')
              ? intl.formatMessage({ id: get(data, 'errors.title') })
              : null
          }
        />
      </FormGroup>
      <FormGroup hasDanger={hasAttributes(data.errors, ['article'])}>
        <Textarea
          id="article"
          placeholder={intl.formatMessage({
            id: 'sponsoredPosts.campaigns.verification.submit.placeholder',
            defaultMessage: 'Article in HTML format …',
          })}
          rows="5"
          value={data.article || ''}
          danger={!!get(data, 'errors.article')}
        />

        <FormControlFeedback
          message={
            get(data, 'errors.article')
              ? intl.formatMessage({ id: get(data, 'errors.article') })
              : null
          }
        />
      </FormGroup>
      <FormActions>
        <SubmitButton disabled={hasAttributes(data.errors) || data.submitting}>
          <FormattedMessage
            id="sponsoredPosts.campaigns.verification.submit"
            defaultMessage="Send"
          />
        </SubmitButton>
      </FormActions>
    </Form>
  );
}

function Active(props) {
  return (
    <VerificationActive>
      <ActivityStreamBody>
        <MessageCard type="ask">
          <strong>
            <FormattedMessage
              id="sponsoredPosts.campaigns.verification.help"
              defaultMessage="Not sure what to do?"
            />
          </strong>
          <span>
            <FormattedMessage
              id="sponsoredPosts.campaigns.verification.help.message"
              defaultMessage="Check out {createPreviewHelpLink} in our Academy."
              values={{
                createPreviewHelpLink: (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={props.intl.formatMessage(createPreviewHelpLink.url)}
                  >
                    <FormattedMessage {...createPreviewHelpLink.label} />
                  </a>
                ),
              }}
            />
          </span>
        </MessageCard>
        <VerificationForm {...props} />
      </ActivityStreamBody>
    </VerificationActive>
  );
}

function Pending(props) {
  return (
    <VerificationPending {...props}>
      <MessageCard type="info">
        <strong>
          <FormattedMessage
            id="sponsoredPosts.campaigns.verification.pending"
            defaultMessage="We have received your article."
          />
        </strong>
        <span>
          <FormattedMessage
            id="sponsoredPosts.campaigns.verification.pending.message"
            defaultMessage="We will contact you once we complete verification."
          />
        </span>
      </MessageCard>
    </VerificationPending>
  );
}

function Rejected(props) {
  return (
    <VerificationRejected {...props}>
      <VerificationForm {...props} />
    </VerificationRejected>
  );
}

/* eslint-enable react/prop-types */

function BlogVerification(props) {
  const {
    workflow: { status },
  } = props;

  const stepsByStatus = {
    inactive: VerificationInactive,
    active: Active,
    pending: Pending,
    rejected: Rejected,
    done: VerificationDone,
    expired: VerificationExpired,
  };

  const Component = stepsByStatus[status] || VerificationInactive;

  return <Component {...props} />;
}

BlogVerification.propTypes = {
  formName: PropTypes.string,
  workflow: PropTypes.shape({
    status: PropTypes.oneOf([
      'inactive',
      'active',
      'pending',
      'rejected',
      'done',
      'expired',
    ]).isRequired,
    payload: PropTypes.shape({
      showHint: PropTypes.bool,
      note: PropTypes.string,
    }),

    lastUpdate: PropTypes.string,
  }).isRequired,
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

BlogVerification.defaultProps = {
  formName: 'blogVerification',
};

export default compose(injectIntl)(BlogVerification);
