import React from 'react';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';

import ChannelCard from './ChannelCard';

function ChannelsList({ channels, onSelectChannel }) {
  const cards = channels.map((channel) => (
    <ChannelCard
      key={channel.id}
      channel={channel}
      onSelectChannel={onSelectChannel}
    />
  ));

  const cardPairs = chunk(cards, 2).map(([card1, card2], index) => (
    <div className="row" key={channels[index].id}>
      <div className="col-sm-6 thrush-column">{card1}</div>
      <div className="col-sm-6 thrush-column">{card2}</div>
    </div>
  ));

  return <div>{cardPairs}</div>;
}

ChannelsList.propTypes = {
  channels: PropTypes.arrayOf(PropTypes.object),
  onSelectChannel: PropTypes.func.isRequired,
};

ChannelsList.defaultProps = {
  channels: [],
};

export default ChannelsList;
