import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router';

import { Modal } from 'source/components/common';

import { withGatewayRoot } from './root';

function AcceptTermsModal({ intl, onAgree }) {
  // Get the url of the centralized terms and conditions page for the currently
  // selected language. We're going to include that page with an `iframe` here.
  const termsUrl = intl.formatMessage({
    id: 'acceptTerms.termsUrl',
    defaultMessage:
      'https://s3-eu-west-1.amazonaws.com/assets.blogfoster.com/terms-and-conditions/v5_en.html',
  });

  return (
    <Modal
      isOpen
      className="accept_terms_modal gateway__card gateway__card--tos container"
    >
      <h4>
        <FormattedMessage
          id="signup.termsAndConditions"
          defaultMessage="Terms & Conditions"
        />
      </h4>
      <p className="m-t-1">
        <FormattedMessage
          id="acceptTerms.description"
          defaultMessage="In order to improve the EQOLOT experience, we have updated our Terms and Conditions."
        />
      </p>
      <div>
        <iframe title="terms" src={termsUrl} />
      </div>
      <div className="gateway__card__options m-t-2">
        <Link to="/signout" className="btn btn-secondary">
          <FormattedMessage
            id="userSettings.logout"
            defaultMessage="Sign out"
          />
        </Link>
        <button onClick={onAgree} className="btn btn-primary">
          <FormattedMessage
            id="acceptTerms.accept"
            defaultMessage="Accept and go to Application"
          />
        </button>
      </div>
    </Modal>
  );
}

AcceptTermsModal.propTypes = {
  onAgree: PropTypes.func.isRequired,
};

export default injectIntl(withGatewayRoot(AcceptTermsModal));
