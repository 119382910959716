import React from 'react';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { withGatewayRoot } from './root';

function ResetPasswordCompleted() {
  return (
    <div className="gateway__card gateway__card--password-reset text-center">
      <i className="material-icons material-icons--big material-icons--green">
        check
      </i>
      <h5>
        <FormattedMessage
          id="passwordReset.completed.title"
          defaultMessage="Done!"
        />
      </h5>
      <p>
        <FormattedMessage
          id="passwordReset.completed.description"
          defaultMessage="Your password has been changed."
        />
      </p>
      <Link to="/signin" className="btn btn-primary m-t-2">
        <FormattedMessage
          id="passwordReset.completed.loginAgain"
          defaultMessage="Login again"
        />
      </Link>
    </div>
  );
}

export default withGatewayRoot(ResetPasswordCompleted);
