import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import noop from 'lodash/noop';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import find from 'lodash/find';
import values from 'lodash/values';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import {
  UploadErrors,
  NonAsciiRegExp,
  AllowedCharacters,
} from 'source/components/common/form/fileUploadError';

import {
  MediaGalleryFileInput,
  FormControlFeedback,
  MediaGallery,
  MediaGalleryItem,
} from 'source/components/common/form';

import {
  getMediaTypeFromFile,
  isVideoMIMEType,
  isImageMIMEType,
} from 'source/utils/fileUpload';

const SortableItem = SortableElement(({ upload, onDelete, badgeIndex }) => (
  <MediaGalleryItem
    badgeIndex={badgeIndex + 1}
    id={upload.id}
    file={upload.file}
    url={upload.url}
    type={upload.type}
    assetId={upload.assetId}
    loading={!upload.loaded}
    removable={!upload.loading}
    draggable={!upload.loading}
    onDelete={onDelete}
    percentCompleted={upload.percentCompleted}
    showProgress
  />
));

const SortableList = SortableContainer(
  ({ uploads, onDelete, onFileInputChange, fileInputTitle }) => (
    <MediaGallery className="aurora-media-gallery">
      {uploads.map((upload, index) => (
        <SortableItem
          key={upload.id}
          index={index}
          badgeIndex={index}
          upload={upload}
          onDelete={onDelete}
        />
      ))}
      <MediaGalleryFileInput
        onChange={onFileInputChange}
        title={fileInputTitle}
        className="media-gallery-item"
      />
    </MediaGallery>
  ),
);

class MediaUpload extends React.Component {
  constructor(props) {
    super(props);

    // Assume URL's are unique and use them as id's
    const transformUrls = (files) =>
      files.map((file) => ({
        ...file,
        id: file.url,
        loading: false,
        loaded: true,
      }));

    this.state = {
      uploads: keyBy(transformUrls(props.initialValues), 'id'),
    };
  }

  getChooseFilePhrase = () => {
    const supportsVideos = find(this.props.allowedMIMETypes, isVideoMIMEType);
    const supportsImages = find(this.props.allowedMIMETypes, isImageMIMEType);

    if (supportsVideos && supportsImages) {
      return this.props.intl.formatMessage({
        id: 'mediaUpload.chooseFile.imagesOrVideos',
      });
    }

    if (supportsVideos) {
      return this.props.intl.formatMessage({
        id: 'mediaUpload.chooseFile.videos',
      });
    }

    return this.props.intl.formatMessage({
      id: 'mediaUpload.chooseFile.images',
    });
  };

  getHelperContainer = () =>
    document.querySelector('.aurora-media-gallery') || document.body;

  handleFileInputChange = (filesToUpload) => {
    if (filesToUpload.some((file) => file.size > this.props.maxFileSize)) {
      return this.props.onError({
        code: UploadErrors.MaxSize,
        error: new Error('File too large'),
        maxSize: this.props.maxFileSize,
      });
    }

    if (filesToUpload.some((file) => NonAsciiRegExp.test(file.name))) {
      return this.props.onError({
        code: UploadErrors.NotAllowedCharacterWithExample,
        error: new Error('Not Allowed Character in file name'),
        allowedCharacters: AllowedCharacters,
      });
    }

    // Generate a unique id for each file
    const files = filesToUpload.map((file) => ({
      id: `${file.name}-${new Date().getTime().toString(36)}`,
      file,
    }));

    this.setState((prevState) => {
      const transformFiles = (files) =>
        files.map(({ id, file }) => ({
          id,
          file,
          url: null,
          loading: true,
          loaded: false,
        }));

      return {
        ...prevState,
        uploads: {
          ...prevState.uploads,
          ...keyBy(transformFiles(files), 'id'),
        },
      };
    });

    // in case that the previous uploaded image had an error, remove the error message:
    this.props.onError(null);

    // TODO: For better UX we should validate the file sizes and display a nice
    // error message before actually uploading.
    files.forEach((file, i) => {
      setTimeout(() => {
        this.props
          .onFileUpload(file, (progressEvent) =>
            this.handleFileUploadProgress(file, progressEvent),
          )
          .then(this.handleFileUploadSuccess(file))
          .catch(this.handleFileUploadFailure(file));
      }, 100 * i);
    });

    return undefined;
  };

  handleFileUploadProgress = (file, progressEvent) => {
    const percentCompleted =
      Math.round((progressEvent.loaded / progressEvent.total) * 100) / 100;

    this.setState((prevState) => {
      const uploads = {
        ...prevState.uploads,
        [file.id]: {
          ...prevState.uploads[file.id],
          percentCompleted,
        },
      };

      return { uploads };
    });
  };

  handleFileUploadSuccess =
    ({ id, file }) =>
    ({ url, filename, assetId }) => {
      const nextState = {
        ...this.state,
        uploads: {
          ...this.state.uploads,
          [id]: {
            ...this.state.uploads[id],
            url,
            filename,
            assetId,
            type: getMediaTypeFromFile(file),
            loading: false,
            loaded: true,
          },
        },
      };

      // Mark upload as completed in internal state and trigger the `onChange`
      // callback with all completed uploads.
      this.setState(() => nextState);
      this.props.onChange(
        values(nextState.uploads)
          .filter((upload) => Boolean(upload.url))
          .map(({ type, url, filename, assetId }) => ({
            type,
            url,
            filename,
            assetId,
          })),
      );
    };

  handleFileUploadFailure =
    ({ id }) =>
    (res) => {
      this.setState(() => ({
        ...this.state,
        uploads: omit(this.state.uploads, id),
      }));
      this.props.onError({
        code: UploadErrors.ServerGeneric,
        error: res,
      });
    };

  handleDelete = (id) => {
    // Remove upload object from internal state and trigger the `onChange`
    // callback with all completed uploads.
    const nextState = { ...this.state, uploads: omit(this.state.uploads, id) };

    this.setState(() => nextState);
    this.props.onChange(
      values(nextState.uploads)
        .filter((upload) => Boolean(upload.url))
        .map(({ type, url, filename, assetId }) => ({
          type,
          url,
          filename,
          assetId,
        })),
    );
  };

  handleShouldCancelStart = (e) => {
    const elementClasses = e.target.classList || [];
    const parentClasses = e.target.parentElement.classList || [];
    const classes = [...elementClasses, ...parentClasses];

    return !classes.includes('icon-reorder');
  };

  handleSortEnd = ({ oldIndex, newIndex }) => {
    const setState = (prevState) => {
      const uploads = arrayMove(values(prevState.uploads), oldIndex, newIndex);

      return {
        uploads: keyBy(uploads, 'id'),
      };
    };

    const onComplete = () => {
      this.props.onChange(
        values(this.state.uploads)
          .filter((upload) => Boolean(upload.url))
          .map(({ type, url, filename, assetId }) => ({
            type,
            url,
            filename,
            assetId,
          })),
      );
    };

    this.setState(setState, onComplete);
  };

  render() {
    return (
      <div>
        <FormControlFeedback
          message={
            this.props.errors.media
              ? this.props.intl.formatMessage({ id: this.props.errors.media })
              : null
          }
        />
        <SortableList
          axis="xy"
          helperClass="active"
          helperContainer={this.getHelperContainer}
          uploads={values(this.state.uploads)}
          onDelete={this.handleDelete}
          shouldCancelStart={this.handleShouldCancelStart}
          onSortEnd={this.handleSortEnd}
          onFileInputChange={this.handleFileInputChange}
          fileInputTitle={this.getChooseFilePhrase()}
        />
      </div>
    );
  }
}

MediaUpload.propTypes = {
  initialValues: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
  allowedMIMETypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFileUpload: PropTypes.func.isRequired,
  onError: PropTypes.func,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  maxFileSize: PropTypes.number,
};

MediaUpload.defaultProps = {
  initialValues: [],
  errors: {},
  onChange: noop,
  onError: noop,
  maxFileSize: 1024 * 1024 * 22, // 22M
};

export default injectIntl(MediaUpload);
