import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import {
  ActivityStreamItem,
  ActivityStreamHeader,
} from 'source/components/common/activityStream';

function VerificationActive({ children, intl }) {
  return (
    <ActivityStreamItem status="active" iconName="check-document">
      <ActivityStreamHeader
        title={intl.formatMessage({
          id: 'sponsoredPosts.campaigns.verification',
          defaultMessage: 'Verification',
        })}
      />
      {children}
    </ActivityStreamItem>
  );
}

VerificationActive.propTypes = {
  children: PropTypes.node,
};

export default injectIntl(VerificationActive);
