import PropTypes from 'prop-types';
import moment from 'moment';
import { defaultMemoize as memoize } from 'reselect';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';

const hasPublishRange = (timeline) => {
  if (!timeline.publishStart) {
    return false;
  }

  const publishStart = moment(timeline.publishStart, 'YYYY-MM-DD');
  const publishEnd = moment(timeline.publishEnd, 'YYYY-MM-DD');

  return !publishStart.isSame(publishEnd, 'day');
};

const inRange = (start, end, ts) => {
  ts = moment(ts, 'YYYY-MM-DD');

  return ts.isSameOrAfter(start, 'day') && ts.isSameOrBefore(end, 'day');
};

export const publishDateMessageProps = (timeline) => {
  const id = hasPublishRange(timeline)
    ? 'sponsoredPosts.campaigns.publishStartEnd'
    : 'sponsoredPosts.campaigns.publishEnd';

  const values = {
    publishEnd: new Date(timeline.publishEnd),
  };

  if (timeline.publishStart) {
    values.publishStart = new Date(timeline.publishStart);
  }

  return { id, values };
};

export const findMaxPayment = memoize((matches) =>
  last(
    sortBy(
      matches,
      ({
        payment: {
          totals: { price },
        },
      }) => price,
    ),
  ),
);

export const getLabelType = ({
  timeline: { applicationStart, applicationEnd },
}) => {
  const now = moment();
  const inApplicationRange = inRange(applicationStart, applicationEnd, now);
  const daysTillExpires = moment(applicationEnd, 'YYYY-MM-DD').diff(
    now,
    'days',
  );

  const daysTillApplicationStart = moment(applicationStart, 'YYYY-MM-DD').diff(
    now,
    'days',
  );

  const expires = inApplicationRange && daysTillExpires <= 5;
  const newCampaign = inApplicationRange && daysTillApplicationStart >= -3;
  const comingSoon =
    !inApplicationRange &&
    daysTillApplicationStart >= 0 &&
    daysTillApplicationStart <= 3;

  if (expires) {
    return 'expires';
  }
  if (newCampaign) {
    return 'new';
  }
  if (comingSoon) {
    return 'comingSoon';
  }

  return null;
};

export const campaignShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  timeline: PropTypes.shape({
    publishStart: PropTypes.string,
    publishEnd: PropTypes.string.isRequired,
  }),

  details: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    mission: PropTypes.string.isRequired,
  }).isRequired,
});

export const matchesShape = PropTypes.arrayOf(
  PropTypes.shape({
    channel: PropTypes.object.isRequired,
    payment: PropTypes.shape({
      price: PropTypes.number.isRequired,
      totals: PropTypes.shape({
        price: PropTypes.number.isRequired,
      }).isRequired,
      currency: PropTypes.string.isRequired,
    }).isRequired,
  }),
);

export const disableClick = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

export const stopPropagation = (e) => {
  e.stopPropagation();
};

export const campaignCardLabelClasses = {
  new: 'label-success',
  comingSoon: 'label-primary',
  unavailable: 'label-warning',
};

export const typesToLabel = {
  website: 'Blog',
  instagram: 'Instagram',
  tiktok: 'TikTok',
  twitter: 'Twitter',
  pinterest: 'Pinterest',
  facebook: 'Facebook',
  youtube: 'YouTube',
};
