import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { withProps, branch, renderComponent, compose } from 'recompose';

import { getLabelType, campaignCardLabelClasses } from './campaignCardHelpers';

const withLabel = withProps(({ campaign }) => ({
  label: getLabelType(campaign),
}));

const CampaignCardLabel = compose(
  branch(({ campaign }) => !campaign.timeline, renderComponent(null)),
  withLabel,
)(({ label }) => {
  const labelClass = campaignCardLabelClasses[label];
  const className = cx(
    'label',
    'sponsored-posts-campaign-card-label',
    labelClass,
  );

  const id = `sponsoredPosts.campaigns.labels.${label}`;

  return (
    <div className={className}>
      <FormattedMessage id={id} />
    </div>
  );
});

CampaignCardLabel.propTypes = {
  label: PropTypes.string.isRequired,
};

export default CampaignCardLabel;
