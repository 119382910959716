import merge from 'lodash/merge';
import get from 'lodash/get';

const redux = {
  actionTypes: {
    SET_STEP: 'wizard/SET_STEP',
  },

  actions: {
    setStep:
      (name) =>
      ({ id, step }) => ({
        type: redux.actionTypes.SET_STEP,
        payload: { id, step },
        name,
      }),
  },

  initialState: {},

  reducers: {
    handleSteps:
      (name) =>
      (state = redux.initialState, action) => {
        if (
          action.name !== name ||
          action.type !== redux.actionTypes.SET_STEP
        ) {
          return state;
        }

        return merge({}, state, {
          [action.payload.id]: {
            step: action.payload.step,
          },
        });
      },
  },

  selectors: {
    getStep: (state, { id }) => get(state, [id, 'step'], 0),
  },
};

export default redux;
