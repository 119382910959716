import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router';
import get from 'lodash/get';
import find from 'lodash/find';
import noop from 'lodash/noop';
import moment from 'moment';
import cx from 'classnames';
import {
  ToolTipController,
  Select as ToolTipWrapper,
} from 'react-tooltip-controller';
import getSymbolFromCurrency from 'currency-symbol-map';

import {
  ActivityStreamItem,
  ActivityStreamHeader,
  ActivityStreamBody,
} from 'source/components/common/activityStream';
import { SubmitButton } from 'source/components/common/button';
import Feature from 'source/components/common/feature';

import Form, {
  FormGroup,
  FormActions,
  Textarea,
  Select,
  FormControlFeedback,
  NumberFormatInput,
} from 'source/components/common/form';
import { hasAttributes } from 'source/utils';
import * as propTypes from 'source/utils/propTypes';
import { MessageCard } from 'source/components/common/message';
import { hasOutdatedScreenshots } from 'source/scenes/profile/utils/channel';

import Icon from 'source/components/common/icon';
import Tooltip from 'source/components/common/tooltip';

const platformsWithManualInsightsUpload = ['instagram', 'tiktok'];

// Helpers

const getSelectedChannelName = (matches, selectedChannel) =>
  get(
    find(matches, (match) => match.channel.id === selectedChannel),
    'channel.name',
  );

const getTotal = ({ totals: { price } }) => price;
const getBasePrice = ({ price }) => price;

const getChannelOptionTextId = (channel) => {
  const defaultId = 'sponsoredPosts.campaigns.channelOptions.label';

  if (channel.platform === 'website') {
    return `${defaultId}.websiteWarning`;
  }

  if (
    platformsWithManualInsightsUpload.includes(channel.platform) &&
    hasOutdatedScreenshots({ ...channel })
  ) {
    return `${defaultId}.insightsWarning`;
  }

  // @alexbeletsky: 22082022 disabling the warning for Pinterest..
  // if (channel.platform === 'pinterest' && getBasePrice(payment) === 0) {
  //   return `${defaultId}.lowPinterestReachWarning`;
  // }

  return defaultId;
};

function ChannelsLink({ id, textId }) {
  return (
    <Link to={`/profile?channelId=${id}`}>
      <FormattedMessage id={textId} />
    </Link>
  );
}

ChannelsLink.propTypes = {
  id: PropTypes.string.isRequired,
  textId: PropTypes.string.isRequired,
};

function GaUpgradePrompt({ id, platform }) {
  if (platform !== 'website') {
    return null;
  }
  return (
    <span className="ga-upgrade-prompt">
      <FormattedMessage
        id="sponsoredPosts.campaigns.promptToUpgrade"
        defaultMessage="Go to {link} to upgrade"
        values={{
          link: (
            <ChannelsLink
              id={id}
              textId="sponsoredPosts.campaigns.promptToUpgrade.linkText"
            />
          ),
        }}
      />
    </span>
  );
}

GaUpgradePrompt.propTypes = {
  id: PropTypes.string.isRequired,
  platform: propTypes.platform.isRequired,
};

function UploadInsightsPrompt({ id, platform, screenshots }) {
  if (!hasOutdatedScreenshots({ platform, screenshots })) {
    return null;
  }
  return (
    <span>
      <ChannelsLink
        id={id}
        textId="sponsoredPosts.campaigns.promptToUploadInsights.linkText"
      />{' '}
      ⚠️
    </span>
  );
}

UploadInsightsPrompt.propTypes = {
  id: PropTypes.string.isRequired,
  platform: propTypes.platform.isRequired,
  screenshots: propTypes.screenshots,
};

function LowPinterestReachPrompt({ channel, matches }) {
  if (channel.platform !== 'pinterest') {
    return null;
  }

  const channelMatch = matches.find((match) => match.channel.id === channel.id);
  if (!channelMatch) {
    return null;
  }

  const price = getBasePrice(channelMatch.payment);
  if (price > 0) {
    return null;
  }

  return (
    <span>
      <FormattedMessage
        id="sponsoredPosts.campaigns.promptToIncreasePinterestReach.text"
        defaultMessage="This channel doesn't have recent idea pins with significant traffic"
      />{' '}
      ⚠️
    </span>
  );
}

LowPinterestReachPrompt.propTypes = {
  channel: PropTypes.shape({
    id: PropTypes.string.isRequired,
    platform: propTypes.platform.isRequired,
  }).isRequired,
  matches: PropTypes.arrayOf(
    PropTypes.shape({
      channel: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
      payment: PropTypes.shape({
        price: PropTypes.number.isRequired,
      }).isRequired,
    }),
  ),
};

LowPinterestReachPrompt.defaultProps = {
  matches: [],
};

// Components

class Application extends Component {
  constructor(props) {
    super(props);

    this.statusToRender = {
      inactive: this.renderInactive,
      active: this.renderActive,
      pending: this.renderPending,
      rejected: this.renderRejected,
      done: this.renderDone,
      expired: this.renderExpired,
    };
  }

  toggleCounterOffer = (e) => {
    e.preventDefault();

    const { form, formName } = this.props;

    const { counterOfferActive } = form[formName] || {};

    this.props.onChange(
      'application',
      'counterOfferActive',
      !counterOfferActive,
    );
  };

  renderInactive() {
    const { intl } = this.props;

    return (
      <ActivityStreamItem iconName="mail">
        <ActivityStreamHeader
          title={intl.formatMessage({
            id: 'sponsoredPosts.campaigns.application',
            defaultMessage: 'Application',
          })}
        />
      </ActivityStreamItem>
    );
  }

  renderDisabledApplicationHint() {
    const {
      workflow: { payload },
    } = this.props;

    // only show hint if application is disabled and a hint should activly be show
    if (!payload || !payload.disableApplication || !payload.showHint) {
      return null;
    }

    return (
      <ActivityStreamBody>
        <MessageCard type="info">
          <span>
            <FormattedMessage
              id="sponsoredPosts.campaigns.application.blocked"
              defaultMessage="Currently we are not taking any applications for this campaign. Please come back later."
            />
          </span>
        </MessageCard>
      </ActivityStreamBody>
    );
  }

  renderActiveApplication() {
    const {
      intl,
      formName,
      workflow: { payload },
      form,
      matches,
      onChange,
      onSubmit,
    } = this.props;

    const data = form[formName] || {};
    const disableApplication = get(payload, 'disableApplication', false);
    const { selectedChannelId, counterOfferActive } = data;
    const selectedMatch = matches.find(
      (match) => match.channel.id === selectedChannelId,
    );

    const { channel: selectedChannel } = selectedMatch;

    // we stop support application with Piwik channels since 01-Nov-2019
    const forbidPiwikWebsites =
      selectedChannel.platform === 'website' && moment().isAfter('2019-11-01');

    // disable application submit for manually added instagram channels (or tiktok)
    // with outdated insights
    const hasOutdatedInsights = hasOutdatedScreenshots(selectedChannel);

    const forbidChannelWithOutdatedInsights =
      platformsWithManualInsightsUpload.includes(selectedChannel.platform) &&
      hasOutdatedInsights;

    // don't show input form if application is disabled and a hint should be shown
    if (disableApplication && payload.showHint) {
      return null;
    }

    const options = matches.map(({ channel, payment }) => (
      <option value={channel.id} key={channel.id} onChange={noop}>
        {intl.formatMessage(
          { id: getChannelOptionTextId(channel, payment) },
          {
            channelName: channel.name,
            price:
              getTotal(payment) > 0
                ? intl.formatNumber(getTotal(payment), {
                    style: 'currency',
                    currency: payment.currency,
                  })
                : '',
          },
        )}
      </option>
    ));

    return (
      <ActivityStreamBody>
        {this.renderDisabledApplicationHint()}
        <p>
          <FormattedMessage
            id="sponsoredPosts.campaigns.applicationPitch.1"
            defaultMessage="Is this campaign a good match for your channel? Write us an interesting pitch and we will recommend you to our clients."
          />
        </p>
        <p>
          <FormattedMessage
            id="sponsoredPosts.campaigns.applicationPitch.2"
            defaultMessage="What sort of post would it be? How would you promote it? A short text will improve your chances of getting the job."
          />
        </p>
        <Form id={formName} onChange={onChange} onSubmit={onSubmit}>
          <FormGroup hasDanger={hasAttributes(data.errors, ['pitch'])}>
            <Textarea
              id="pitch"
              placeholder={intl.formatMessage({
                id: 'sponsoredPosts.campaigns.applicationPitch.example',
                defaultMessage:
                  'Hello, I would like to participate in this campaign . My channel is good for this because...',
              })}
              rows="8"
              value={data.pitch || ''}
              danger={!!get(data, 'errors.pitch')}
              onChange={noop}
            />

            <FormControlFeedback
              message={
                get(data, 'errors.pitch')
                  ? intl.formatMessage({ id: get(data, 'errors.pitch') })
                  : null
              }
            />
          </FormGroup>
          <FormActions>
            <div className="channel-selection">
              <p className="channel-selection__instruction">
                <FormattedMessage
                  id="sponsoredPosts.campaigns.selectChannel"
                  defaultMessage="Select the channel with which to apply"
                />
              </p>
              <div className="channel-selection__select">
                <Select
                  id="selectedChannelId"
                  value={selectedChannelId}
                  onChange={noop}
                >
                  {options}
                </Select>
                <Feature feature="counterOffer">
                  <div
                    className={cx('channel-selection__counter-offer', {
                      'channel-selection__counter-offer-active':
                        counterOfferActive,
                    })}
                  >
                    {counterOfferActive ? (
                      <>
                        <div className="form-group-horizontal">
                          <fieldset className="form-group-counter-offer">
                            <label htmlFor="counterOfferPrice">
                              {intl.formatMessage(
                                {
                                  id: 'sponsoredPosts.campaigns.selectChannel.counterOffer.input.label',
                                  defaultMessage: 'Your price {currency}:',
                                },

                                {
                                  currency: getSymbolFromCurrency(
                                    selectedMatch.payment.currency,
                                  ),
                                },
                              )}
                            </label>
                            <NumberFormatInput
                              id="counterOfferPrice"
                              value={data.counterOfferPrice || ''}
                              danger={!!get(data, 'errors.counterOfferPrice')}
                              allowNegative={false}
                            />
                          </fieldset>
                          <FormControlFeedback
                            message={
                              get(data, 'errors.counterOfferPrice')
                                ? intl.formatMessage({
                                    id: get(data, 'errors.counterOfferPrice'),
                                  })
                                : null
                            }
                          />
                        </div>
                        <a
                          className="toggle-control"
                          href="#"
                          onClick={this.toggleCounterOffer}
                        >
                          {intl.formatMessage({
                            id: 'sponsoredPosts.campaigns.selectChannel.revertCounterOffer',
                            defaultMessage: 'Revert to standard',
                          })}
                        </a>
                      </>
                    ) : (
                      <>
                        <a
                          className="font-weight-bold"
                          href="#"
                          onClick={this.toggleCounterOffer}
                        >
                          {intl.formatMessage({
                            id: 'sponsoredPosts.campaigns.selectChannel.counterOffer',
                            defaultMessage: 'Make a counteroffer',
                          })}
                        </a>
                        <ToolTipController detect="hover" offsetX={-111}>
                          <ToolTipWrapper>
                            <span className="icon-wrapper">
                              <Icon name="info" />
                            </span>
                          </ToolTipWrapper>
                          <Tooltip className="tooltip-counter-offer-price">
                            <FormattedMessage
                              id="counterOfferPrice.tooltip"
                              defaultMessage="You can offer a different payment for this campaign: propose a smaller amount to be more competitive, or a larger one if the collaboration requires extra effort."
                            />
                          </Tooltip>
                        </ToolTipController>
                      </>
                    )}
                  </div>
                </Feature>
                <div className="m-l-1 warning-box">
                  <GaUpgradePrompt {...selectedChannel} />
                  <UploadInsightsPrompt {...selectedChannel} />
                  <LowPinterestReachPrompt
                    channel={selectedChannel}
                    matches={matches}
                  />
                </div>
              </div>
            </div>
            <SubmitButton
              disabled={
                disableApplication ||
                hasAttributes(data.errors) ||
                forbidPiwikWebsites ||
                forbidChannelWithOutdatedInsights ||
                data.submitting
              }
            >
              <FormattedMessage
                id="sponsoredPosts.campaigns.apply"
                defaultMessage="Apply with {channel}"
                values={{
                  channel: getSelectedChannelName(matches, selectedChannelId),
                }}
              />
            </SubmitButton>
          </FormActions>
        </Form>
      </ActivityStreamBody>
    );
  }

  renderActive() {
    const { intl } = this.props;

    return (
      <ActivityStreamItem status="active" iconName="mail">
        <ActivityStreamHeader
          title={intl.formatMessage({
            id: 'sponsoredPosts.campaigns.application',
            defaultMessage: 'Application',
          })}
        />

        {this.renderDisabledApplicationHint()}
        {this.renderActiveApplication()}
      </ActivityStreamItem>
    );
  }

  renderPending() {
    const {
      intl,
      campaign,
      application,
      workflow: { lastUpdate },
    } = this.props;

    const campaignName = campaign.details.headline;
    const applicationId = application.id;
    const mailSubject = `Campaign: ${campaignName}; Application: ${applicationId}`;
    const mailtoLink = `mailto:influencerteam@eqolot.com?subject=${encodeURIComponent(
      mailSubject,
    )}`;

    return (
      <ActivityStreamItem status="active" iconName="mail">
        <ActivityStreamHeader
          title={intl.formatMessage({
            id: 'sponsoredPosts.campaigns.application.pending.title',
            defaultMessage: 'Applied',
          })}
          date={lastUpdate}
        />

        <ActivityStreamBody>
          <MessageCard type="info">
            <b>
              <FormattedMessage
                id="sponsoredPosts.campaigns.application.pending"
                defaultMessage="Thanks for your application."
              />
            </b>
            <span>
              <FormattedMessage
                id="sponsoredPosts.campaigns.application.pending.message"
                defaultMessage="You will receive an email from us, as soon as we have verified your information and forwarded it to the client."
              />
            </span>
            <div className="m-t-1">
              <FormattedMessage
                id="sponsoredPosts.campaigns.application.pending.ps"
                defaultMessage="PS: If you must change this engagement, {mailtoLink}"
                values={{
                  mailtoLink: (
                    <a target="_blank" rel="noreferrer" href={mailtoLink}>
                      <FormattedMessage
                        id="sponsoredPosts.campaigns.application.pending.ps.mailToLink.label"
                        // eslint-disable-next-line formatjs/enforce-placeholders
                        defaultMessage="<b>please let us know.</b>"
                      />
                    </a>
                  ),
                }}
              />
            </div>
          </MessageCard>
        </ActivityStreamBody>
      </ActivityStreamItem>
    );
  }

  renderRejected() {
    const {
      intl,
      workflow: { lastUpdate },
    } = this.props;

    const link = (
      <Link to="sponsored-posts">
        <FormattedMessage
          id="sponsoredPosts.campaigns.here"
          defaultMessage="here"
        />
      </Link>
    );

    return (
      <ActivityStreamItem status="failed" iconName="mail">
        <ActivityStreamHeader
          title={intl.formatMessage({
            id: 'sponsoredPosts.campaigns.application.rejected.title',
            defaultMessage: 'Application rejected',
          })}
          date={lastUpdate}
        />

        <ActivityStreamBody>
          <MessageCard type="info">
            <strong>
              <FormattedMessage
                id="sponsoredPosts.campaigns.application.rejected"
                defaultMessage="We are sorry!"
              />
            </strong>
            <span>
              <FormattedMessage
                id="sponsoredPosts.campaigns.application.rejected.message"
                defaultMessage="Your application has been rejected. Please look for available campaigns here: {link}."
                values={{ link }}
              />
            </span>
          </MessageCard>
        </ActivityStreamBody>
      </ActivityStreamItem>
    );
  }

  renderHint() {
    const {
      workflow: { payload },
    } = this.props;

    if (!payload || !payload.showHint) {
      return null;
    }

    return (
      <ActivityStreamBody>
        <MessageCard type="info">
          <strong>
            <FormattedMessage
              id="sponsoredPosts.campaigns.application.done"
              defaultMessage="Congrats!"
            />
          </strong>
          <span>
            <FormattedMessage
              id="sponsoredPosts.campaigns.application.done.message"
              defaultMessage="You have successfully applied."
            />
          </span>
        </MessageCard>
      </ActivityStreamBody>
    );
  }

  renderHistoryButton() {
    const { options } = this.props;

    if (!options || !options.historyButton) {
      return null;
    }

    return options.historyButton;
  }

  renderDone() {
    const {
      intl,
      workflow: { lastUpdate },
    } = this.props;

    return (
      <ActivityStreamItem status="done" iconName="mail">
        <ActivityStreamHeader
          title={intl.formatMessage({
            id: 'sponsoredPosts.campaigns.application.applied',
            defaultMessage: 'Successfully applied',
          })}
          date={lastUpdate}
        >
          {this.renderHistoryButton()}
        </ActivityStreamHeader>
        {this.renderHint()}
      </ActivityStreamItem>
    );
  }

  renderExpired() {
    const { intl } = this.props;

    return (
      <ActivityStreamItem status="failed" iconName="mail">
        <ActivityStreamHeader
          title={intl.formatMessage({
            id: 'sponsoredPosts.campaigns.application.expired.title',
            defaultMessage: 'Application expired',
          })}
        />

        <ActivityStreamBody>
          <MessageCard type="info">
            <strong>
              <FormattedMessage
                id="sponsoredPosts.campaigns.application.expired"
                defaultMessage="What a pity!"
              />
            </strong>
            <span>
              <FormattedMessage
                id="sponsoredPosts.campaigns.application.expired.message"
                defaultMessage="The deadline for applications has expired, unfortunately."
              />
            </span>
          </MessageCard>
        </ActivityStreamBody>
      </ActivityStreamItem>
    );
  }

  render() {
    const {
      workflow: { status },
    } = this.props;

    const renderFn = this.statusToRender[status];
    if (!renderFn) {
      return this.renderInactive();
    }

    return renderFn.call(this);
  }
}

Application.propTypes = {
  formName: PropTypes.string,
  application: PropTypes.shape({ id: PropTypes.string }),
  campaign: PropTypes.shape({
    details: PropTypes.shape({ headline: PropTypes.string }),
  }),

  workflow: PropTypes.shape({
    status: PropTypes.oneOf([
      'inactive',
      'active',
      'pending',
      'rejected',
      'done',
      'expired',
    ]).isRequired,
    payload: PropTypes.shape({
      disableApplication: PropTypes.bool,
      showHint: PropTypes.bool,
    }),

    lastUpdate: PropTypes.string,
  }).isRequired,
  matches: PropTypes.arrayOf(
    PropTypes.shape({
      channel: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      payment: PropTypes.shape({
        price: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
        totals: PropTypes.shape({
          price: PropTypes.number.isRequired,
        }),
      }).isRequired,
    }),
  ).isRequired,
  user: PropTypes.object,
  options: PropTypes.shape({
    historyButton: PropTypes.element,
  }),

  form: PropTypes.shape({
    application: PropTypes.shape({
      submitting: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

Application.defaultProps = {
  formName: 'application',
};

export default injectIntl(Application);
