import PropTypes from 'prop-types';
import React from 'react';
import capitalize from 'lodash/capitalize';

import * as shape from 'd3-shape';
import * as scale from 'd3-scale';

export const d3LineGraphProps = {
  scaleX: PropTypes.func.isRequired,
  scaleY: PropTypes.func.isRequired,
  curve: PropTypes.func.isRequired,
  area: PropTypes.func.isRequired,
  domainX: PropTypes.array.isRequired,
  domainY: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  rangeX: PropTypes.array,
  rangeY: PropTypes.array,
};

/**
 * Compatibility layer between our React component API and the corresponding
 * D3 functions
 */
export const injectD3LineGraph = (Wrapped) => {
  function LineGraph({ scaleX, scaleY, curve, ...rest }) {
    const scaleXFn = scale[`scale${capitalize(scaleX)}`];
    const scaleYFn = scale[`scale${capitalize(scaleY)}`];
    const curveFn = shape[`curve${capitalize(curve)}`];
    return (
      <Wrapped
        {...rest}
        scaleX={scaleXFn}
        scaleY={scaleYFn}
        curve={curveFn}
        area={shape.area}
      />
    );
  }

  LineGraph.propTypes = {
    scaleX: PropTypes.string,
    scaleY: PropTypes.string,
    curve: PropTypes.string,
  };

  LineGraph.defaultProps = {
    scaleX: 'linear',
    scaleY: 'linear',
    curve: 'basis',
  };

  return LineGraph;
};
