import { connect } from 'react-redux';

import SignIn from 'source/components/gateway/signin';

import {
  formChange,
  formErrors,
  cleanupErrors,
  loginToAccount,
  cleanupServerErrors,
  changeLanguage,
  redirectToSignIn,
} from 'source/actions/account';

const mapStateToProps = (state, props) => ({
  id: 'signin',
  form: state.account.signin,
  language: state.application.locale,
  languageOptions: state.account.languageOptions,
  serverError: state.account.signin.serverError,
  referrer: props.location.query.ref,
});

const mapDispatchToProps = (dispatch, props) => {
  const { redirectTo } = props.location.query;

  return {
    onErrorClose: () => dispatch(cleanupServerErrors('signin')),
    onChange: (form, field, value) => {
      dispatch(formChange(form, field, value));
      dispatch(cleanupErrors(form, field));
    },
    onErrors: (form, errors) => dispatch(formErrors(form, errors)),
    onSubmit: (id, form) => dispatch(loginToAccount(form, redirectTo)),
    onChangeLanguage: (language) => dispatch(changeLanguage(language)),
    onRedirectComplete: () => dispatch(redirectToSignIn('done')),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
