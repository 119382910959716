import React from 'react';
import PropTypes from 'prop-types';
import partition from 'lodash/partition';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withProps } from 'recompose';
import get from 'lodash/get';

import { asyncProps } from '@blogfoster/redux-async-utils';

import { rpoplpush } from 'source/utils';

import ActivityStream, {
  ActivityStreamHistory,
} from 'source/components/common/activityStream';
import Button from 'source/components/common/button';
import {
  Application,
  Accept,
  Tasks,
  Publication,
  ProductShipment,
  SocialMediaSharing,
  BlogVerification,
  InstagramVerification,
  TiktokVerification,
  PinterestVerification,
  YouTubeVerification,
  AfterPublicationUploads,
} from 'source/components/app/sponsoredPosts/activities';

const workflowsByType = {
  website: {
    application: Application,
    accept: Accept,
    productShipment: ProductShipment,
    tasks: Tasks,
    contentReview: BlogVerification,
    publication: withProps({ formName: 'websitePublication' })(Publication),
    socialMediaSharing: SocialMediaSharing,
  },

  instagram: {
    application: Application,
    accept: Accept,
    productShipment: ProductShipment,
    tasks: Tasks,
    contentReview: InstagramVerification,
    publication: withProps({ formName: 'instagramPublication' })(Publication),
    afterPublicationUploads: AfterPublicationUploads,
  },

  tiktok: {
    application: Application,
    accept: Accept,
    productShipment: ProductShipment,
    tasks: Tasks,
    contentReview: TiktokVerification,
    publication: withProps({ formName: 'tiktokPublication' })(Publication),
    afterPublicationUploads: AfterPublicationUploads,
  },

  pinterest: {
    application: Application,
    accept: Accept,
    productShipment: ProductShipment,
    tasks: Tasks,
    contentReview: PinterestVerification,
    publication: withProps({ formName: 'pinterestPublication' })(Publication),
  },

  youtube: {
    application: Application,
    accept: Accept,
    productShipment: ProductShipment,
    tasks: Tasks,
    contentReview: YouTubeVerification,
    publication: withProps({ formName: 'youtubePublication' })(Publication),
  },
};

const workflowStepRenderer = (type) => {
  const activities = workflowsByType[type] || {};

  return function (step, props) {
    const Activity = activities[step.name];

    if (!Activity) {
      return null;
    }

    return (
      <Activity
        key={step.name + get(step, 'payload.list', '')}
        workflow={step}
        {...props}
      />
    );
  };
};

/**
 * Partition the workflow items into the ones that are done and the rest.
 * To avoid changes in order we should partition items into done until the first
 * item is reached that is not in "done" status anymore. Then the rest is the
 * other partition.
 */
const partitionWorkflowItems = (workflow) => {
  let addToDone = true;
  return partition(workflow, (workflowItem) => {
    if (addToDone && workflowItem.status === 'done') {
      return true;
    }

    addToDone = false;
    return false;
  });
};

class SponsoredPostsCampaignApplication extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showHistory: false,
    };
  }

  toggleHistory = () => {
    this.setState({ showHistory: !this.state.showHistory });
  };

  renderHistory(doneWorkflow, props, renderStep) {
    if (!doneWorkflow || doneWorkflow.length <= 0) {
      return null;
    }

    const {
      intl: { formatMessage },
    } = this.props;
    const [first, ...rest] = doneWorkflow;

    // add a button to toggle the history only if there is at least 1 item in the rest list
    const options = {};
    if (rest.length >= 1) {
      options.historyButton = (
        <Button onClick={this.toggleHistory}>
          {this.state.showHistory
            ? formatMessage({
                id: 'sponsoredPosts.campaigns.hideHistory',
                defaultMessage: 'Hide History',
              })
            : formatMessage({
                id: 'sponsoredPosts.campaigns.showHistory',
                defaultMessage: 'Show History',
              })}
        </Button>
      );
    }

    const firstActivity = renderStep(first, { ...props, options });
    const restActivities = rest.map((step) => renderStep(step, props));

    return (
      <ActivityStreamHistory collapsed={!this.state.showHistory}>
        {firstActivity}
        {restActivities}
      </ActivityStreamHistory>
    );
  }

  render() {
    const { workflow: workflowAsync, toastError, ...props } = this.props;

    // We only care about the initial loading state. If we have data, we
    // know it's been loaded at least once
    if (!workflowAsync.data) {
      return null;
    }

    const { type: workflowType, steps: workflowSteps } = workflowAsync.data;

    const renderStep = workflowStepRenderer(workflowType);
    const errorId = get(toastError, 'messageId');
    const errorToast = errorId && (
      <div className="alert alert-danger" role="alert">
        <FormattedMessage id={errorId} />
      </div>
    );

    const [doneWorkflow, leftWorkflow] = partitionWorkflowItems(workflowSteps);

    // We want to put the last element of done to the start of left so that
    // the latest "done" will always be shown.
    rpoplpush(doneWorkflow, leftWorkflow);

    const leftActivities = leftWorkflow.map((step) => renderStep(step, props));

    return (
      <div className="sponsored-posts-application">
        {errorToast}
        <ActivityStream>
          {
            // @sean TODO Why are we passing mystery props down to children
            this.renderHistory(doneWorkflow, props, renderStep)
          }

          {leftActivities}
        </ActivityStream>
      </div>
    );
  }
}

SponsoredPostsCampaignApplication.propTypes = {
  workflow: PropTypes.shape({
    ...asyncProps,
    data: PropTypes.shape({
      type: PropTypes.string.isRequired,
      steps: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
      ),
    }),
  }),

  toastError: PropTypes.shape({
    messageId: PropTypes.string.isRequired,
  }),
};

export default injectIntl(SponsoredPostsCampaignApplication);
