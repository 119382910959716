import React from 'react';
import PropTypes from 'prop-types';
import { SubmissionError } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import noop from 'lodash/noop';
import get from 'lodash/get';

import Failure from 'source/components/app/createWebsite/failure';

import {
  withChannelHandlers,
  channelHandlersShape,
} from './withChannelHandlers';

import {
  BlogGaConnectStep as Connect,
  BlogGaSelectionStep as AccountDetailsSelection,
  ChannelPreStep,
  CategoriesWizardStep,
  SubCategoriesWizardStep,
  CategoryWeightWizardStep,
} from './steps';
import Wizard from './StatefulWizard';
import ChannelPanel from './ChannelPanel';

class AccountDetailsSelectionStep extends React.PureComponent {
  componentDidMount() {
    const {
      id,
      form: { accountSummaries },
    } = this.props;

    if (!accountSummaries) {
      this.props.onAccountSummaries(id);
    }
  }

  handleSubmit = (account) => {
    const { id } = this.props;

    const payload = {
      name: account.url,
      data: {
        ...account,
      },
    };

    return this.props
      .onSubmit({ id, payload })
      .then(this.props.onNextStep)
      .catch((err) => {
        if (err.status === 409) {
          throw new SubmissionError({
            _error: 'channels.ga.websiteAlreadyExists',
          });
        }

        throw new SubmissionError({
          _error: 'channels.ga.creationFailed',
        });
      });
  };

  render() {
    const {
      id,
      form: { accountSummaries, selectedAccount },
      onCancel,
      referenceChannel,
    } = this.props;

    if (!accountSummaries || accountSummaries.loading) {
      return (
        <div className="text-center">
          <FormattedMessage
            id="channels.ga.loadingAccountSummaries"
            defaultMessage="Loading your Google Analytics accounts..."
          />
        </div>
      );
    }

    if (accountSummaries.error) {
      return (
        <div className="text-center">
          <FormattedMessage
            id="channels.ga.loadingAccountSummariesFailed"
            defaultMessage="Failed to load your Google Analytics accounts. Please try again later."
          />
        </div>
      );
    }

    return (
      <AccountDetailsSelection
        id={id}
        form={id}
        referenceChannel={referenceChannel}
        accountSummaries={accountSummaries.data}
        selectedAccount={selectedAccount}
        onSubmit={this.handleSubmit}
        onCancel={onCancel}
      />
    );
  }
}

AccountDetailsSelectionStep.propTypes = {
  id: PropTypes.string,
  form: PropTypes.object,
  referenceChannel: PropTypes.object,
  onAccountSummaries: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onNextStep: PropTypes.func,
};

AccountDetailsSelectionStep.defaultProps = {
  onNextStep: noop,
};

class ConnectGoogleAnalyticsWizard extends React.PureComponent {
  handleCancelInAccountSelectionStep = ({ id }) => {
    const { onDisconnect, referenceChannel } = this.props;
    const params = { id };

    if (referenceChannel) {
      params.referenceChannelId = referenceChannel.id;
      params.revertMigration = true;
    }

    onDisconnect(params);
  };

  renderWizard() {
    const {
      id,
      status,
      form,
      newChannel,
      referenceChannel,
      platform,
      categories,
      accountSummaries,
      onSubmit,
      onSubmitAccountDetails,
      onConnect,
      onDisconnect,
      onEnterDisconnect,
      onAccountSummaries,
      channelHandlers: { onChange, onErrors, onWizardSuccess },
    } = this.props;

    const STEP_IDS = {
      INITIAL_STEP: 'INITIAL_STEP',
      CONNECT_STEP: 'CONNECT_STEP',
      SELECTION_STEP: 'SELECTION_STEP',
      CATEGORIES_SELECTION_STEP: 'CATEGORIES_SELECTION_STEP',
      SUB_CATEGORIES_SELECTION_STEP: 'SUB_CATEGORIES_SELECTION_STEP',
      CATEGORIES_WEIGHT_SELECTION_STEP: 'CATEGORIES_WEIGHT_SELECTION_STEP',
    };

    let initialStep = STEP_IDS.INITIAL_STEP;

    if (
      !status &&
      form.readyState.accountSettingsAvailable &&
      !form.errorStateId
    ) {
      initialStep = STEP_IDS.CATEGORIES_SELECTION_STEP;
    }

    return (
      <Wizard
        initialStep={initialStep}
        props={{ id, platform, form, onChange, onErrors }}
        onSuccess={onWizardSuccess}
      >
        <Wizard.Step
          stepId={STEP_IDS.INITIAL_STEP}
          disabled={newChannel || (!status && !form.errorStateId)}
        >
          <ChannelPreStep
            id={id}
            status={status}
            form={form}
            newChannel={newChannel}
            onDisconnect={onDisconnect}
            onEnterDisconnect={onEnterDisconnect}
          />
        </Wizard.Step>
        <Wizard.Step stepId={STEP_IDS.CONNECT_STEP} disabled={!newChannel}>
          <Connect platform={platform} onConnect={onConnect} />
        </Wizard.Step>
        <Wizard.Step stepId={STEP_IDS.SELECTION_STEP} disabled={status}>
          <AccountDetailsSelectionStep
            id={id}
            accountSummaries={accountSummaries}
            referenceChannel={referenceChannel}
            onAccountSummaries={onAccountSummaries}
            onSubmit={onSubmitAccountDetails}
            onCancel={this.handleCancelInAccountSelectionStep}
          />
        </Wizard.Step>
        <CategoriesWizardStep
          stepId={STEP_IDS.CATEGORIES_SELECTION_STEP}
          categories={categories}
        />

        <SubCategoriesWizardStep
          categories={categories}
          stepId={STEP_IDS.SUB_CATEGORIES_SELECTION_STEP}
        />

        <CategoryWeightWizardStep
          onSubmit={onSubmit}
          stepId={STEP_IDS.CATEGORIES_WEIGHT_SELECTION_STEP}
        />
      </Wizard>
    );
  }

  renderSubmitFailure() {
    const { form, onRedirectToDashboard } = this.props;

    return (
      <Failure
        failureReason={form.failureReason}
        onRepeat={this.handleRepeat}
        onRedirect={onRedirectToDashboard}
      />
    );
  }

  render() {
    const { form } = this.props;

    if (form.submitFailed) {
      return <div className="container">{this.renderSubmitFailure()}</div>;
    }

    return this.renderWizard();
  }
}

ConnectGoogleAnalyticsWizard.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  newChannel: PropTypes.bool,
  referenceChannel: PropTypes.object,
  accountSummaries: PropTypes.array,
  categories: PropTypes.array.isRequired,
  platform: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitAccountDetails: PropTypes.func.isRequired,
  onConnect: PropTypes.func.isRequired,
  onDisconnect: PropTypes.func.isRequired,
  onEnterDisconnect: PropTypes.func.isRequired,
  onRedirectToDashboard: PropTypes.func.isRequired,
  onAccountSummaries: PropTypes.func.isRequired,
  channelHandlers: channelHandlersShape.isRequired,
};

ConnectGoogleAnalyticsWizard.defaultProps = {
  newChannel: false,
};

class GaChannel extends React.Component {
  renderWizard() {
    const {
      id,
      form,
      form: { name },
      intl: { formatMessage },
      status,
      platform,
      readyState,
      channelHandlers,
      closeButton,
      ...wizardProps
    } = this.props;

    const headline =
      name ||
      get(this.props, 'referenceChannel.name') ||
      formatMessage({
        id: 'channels.ga.newChannel',
        defaultMessage: 'New Channel - Finish the Setup',
      });

    return (
      <ChannelPanel
        id={id}
        expanded
        disabled
        icon="ga"
        headline={headline}
        className="channel channel-blog"
        bodyClassName="channel-body"
        status={status}
        platform={platform}
        readyState={readyState}
        closeButton={closeButton}
      >
        <ConnectGoogleAnalyticsWizard
          id={id}
          form={form}
          status={status}
          platform={platform}
          channelHandlers={channelHandlers}
          {...wizardProps}
        />
      </ChannelPanel>
    );
  }

  renderFailure() {
    const { form, onRedirectToDashboard } = this.props;

    return (
      <Failure
        failureReason={form.failureReason}
        onRepeat={this.handleRepeat}
        onRedirect={onRedirectToDashboard}
      />
    );
  }

  render() {
    const { form } = this.props;

    return form.submitFailed ? this.renderFailure() : this.renderWizard();
  }
}

GaChannel.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  readyState: PropTypes.object,
  form: PropTypes.object.isRequired,

  platform: PropTypes.string.isRequired,
  onRedirectToDashboard: PropTypes.func.isRequired,
  closeButton: PropTypes.node,
  // Provided by withChannelHandlers
  channelHandlers: channelHandlersShape.isRequired,
};

export default withChannelHandlers(injectIntl(GaChannel));
