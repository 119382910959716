import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose, withPropsOnChange } from 'recompose';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import mapValues from 'lodash/mapValues';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import { links } from 'source/i18n';
import { ProgressBar } from 'source/components/common';

import Card from '../Card';
import InvoicePanels from './InvoicePanels';

class Invoices extends React.Component {
  componentDidMount() {
    this.fetchInvoices();
  }

  fetchInvoices() {
    this.props.onGetInvoices({
      fields: [
        'id',
        'type',
        'pdfUrl',
        'period',
        'currency',
        'address',
        'lines.currency',
        'lines.price',
        'lines.description',
        'lines.payment.id',
        'lines.payment.references.application.id',
        'lines.payment.references.campaign.details.headline',
        'lines.payment.references.channel.name',
        'totals',
        'userId',
      ],
    });
  }

  render() {
    let content;
    if (this.props.invoices.error) {
      content = <FormattedMessage id="revenues.invoices.fetchError" />;
    } else if (this.props.invoices.loading || !this.props.invoices.loaded) {
      content = <ProgressBar />;
    } else if (this.props.invoices.data.length === 0) {
      content = <FormattedMessage id="revenues.invoices.noInvoices" />;
    } else {
      content = (
        <InvoicePanels
          selectedYear={this.props.selectedYear}
          invoicePanels={this.props.invoicePanels}
          invoices={this.props.invoices.data}
        />
      );
    }

    return (
      <Card className="invoices">
        <div className="invoices-header">
          <h2>
            <FormattedMessage id="revenues.invoices" />
          </h2>
          <div className="alert alert-info" role="alert">
            <FormattedMessage
              id="revenues.invoices.paymentExplanation"
              defaultMessage="Please find your credit notes below. We process the credit notes to your bank account latest within 45 days. Based on how frequently you work with EQOLOT, we transfer you the money faster. Please find the description of this process on {academyLink}."
              values={{
                academyLink: (
                  <a
                    href={this.props.intl.formatMessage(links.academyLink)}
                    className="standard"
                  >
                    <FormattedMessage
                      id="academyLink.label"
                      defaultMessage="Academy website"
                    />
                  </a>
                ),
              }}
            />
          </div>
        </div>
        <div className="invoices-content">{content}</div>
      </Card>
    );
  }
}

Invoices.propTypes = {
  invoices: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.object),
    error: PropTypes.object,
  }).isRequired,
  invoicePanels: PropTypes.arrayOf(
    PropTypes.shape({
      monthId: PropTypes.string.isRequired,
      invoices: PropTypes.arrayOf(
        PropTypes.shape({
          period: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  onGetInvoices: PropTypes.func.isRequired,
  selectedYear: PropTypes.number.isRequired,
};

const filterInvoicesWithPdf = withPropsOnChange(['invoices'], (props) => {
  const hasPdfUrl = (invoice) => Boolean(invoice.pdfUrl);

  return {
    invoices: {
      ...props.invoices,
      data: props.invoices.data && props.invoices.data.filter(hasPdfUrl),
    },
  };
});

const enrichWithLegacyInvoiceData = withPropsOnChange(['invoices'], (props) => {
  if (!props.invoices.data) {
    return props;
  }

  const enrichInvoice = (invoice) => {
    const isLegacyInvoice = invoice.lines && invoice.lines.length === 0;

    // We have some legacy invoices where our `id` property doesn't match the
    // the one on the generated PDF file. As a workaround, we calculate those id's
    // here on the client. In the future we should move this part to the API or
    // store the `legacyId` property in the database.
    const periodPrefix = moment(invoice.period, 'YYYY-MM')
      .add(1, 'month')
      .format('YYYYMM');

    const legacyId = `${periodPrefix}U${invoice.userId}`;

    return {
      ...invoice,
      legacyId,
      isLegacyInvoice,
    };
  };

  return {
    invoices: {
      ...props.invoices,
      data: props.invoices.data && props.invoices.data.map(enrichInvoice),
    },
  };
});

const calculateTotals = withPropsOnChange(['invoices'], (props) => {
  if (!props.invoices.data) {
    return props;
  }

  const calculateTotal = (invoice) => ({
    ...invoice,
    total: invoice.isLegacyInvoice
      ? invoice.totals.totalAmount
      : invoice.totals.netAmount,
  });

  return {
    invoices: {
      ...props.invoices,
      data: props.invoices.data && props.invoices.data.map(calculateTotal),
    },
  };
});

const withInvoicesPerYear = withPropsOnChange(
  function didInvoicesChange(props, nextProps) {
    return (
      props.invoices.data !== nextProps.invoices.data ||
      props.selectedYear !== nextProps.selectedYear
    );
  },
  function filterInvoices(props) {
    const invoicesPerYear = (props.invoices.data || []).filter((invoice) =>
      invoice.period.startsWith(props.selectedYear),
    );
    return { invoicesPerYear };
  },
);

const withInvoicePanels = withPropsOnChange(
  function didInvoicesChange(props, nextProps) {
    return props.invoicesPerYear !== nextProps.invoicesPerYear;
  },
  function createInvoicePanels(props) {
    // prettier-ignore
    const monthIds = [
      'months.0', 'months.1', 'months.2', 'months.3', 'months.4', 'months.5',
      'months.6', 'months.7', 'months.8', 'months.9', 'months.10', 'months.11',
    ];

    const invoicesByMonthId = groupBy(
      props.invoicesPerYear,
      function invoiceToMonthId({ period }) {
        const month = moment(period, 'YYYY-MM').month();

        return monthIds[month];
      },
    );

    const sortedInvoicesByMonthId = mapValues(invoicesByMonthId, (invoices) =>
      sortBy(invoices, 'currency'),
    );

    const invoicePanels = monthIds.map((monthId) => ({
      monthId,
      invoices: sortedInvoicesByMonthId[monthId],
    }));

    // We don't want to display empty months.
    const nonEmptyInvoicePanels = invoicePanels.filter(
      (tab) => !isEmpty(tab.invoices),
    );

    return { invoicePanels: nonEmptyInvoicePanels };
  },
);

export default compose(
  filterInvoicesWithPdf,
  enrichWithLegacyInvoiceData,
  calculateTotals,
  withInvoicesPerYear,
  withInvoicePanels,
  injectIntl,
)(Invoices);
