import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

/**
 * Similar to Nav, except page transitions are not driven by react-router / URL
 * and are internal to the component
 */
class TabsNav extends Component {
  onTabSelect = (id) => (e) => {
    e.preventDefault();
    return this.props.onSelectTab(id);
  };

  renderTab = ({ id: tabId, label }, index) => {
    const { currentTab } = this.props;
    const id = tabId || index;
    const active = id === currentTab;

    return (
      <li key={id} className="nav-item">
        <a
          className={cx('nav-link', { active })}
          href="#"
          onClick={this.onTabSelect(id)}
        >
          {typeof label !== 'string' ? label : <FormattedMessage id={label} />}
        </a>
      </li>
    );
  };

  render() {
    const { centered, tabs, ...rest } = this.props;
    const className = cx(
      'nav nav-tabs',
      { 'nav-tabs-centered': centered },
      rest.className,
    );

    return <ul className={className}>{tabs.map(this.renderTab)}</ul>;
  }
}

TabsNav.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
  centered: PropTypes.bool,
  currentTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onSelectTab: PropTypes.func.isRequired,
};

TabsNav.defaultProps = {
  centered: false,
};

export default TabsNav;
