import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import {
  ActivityStreamItem,
  ActivityStreamHeader,
} from 'source/components/common/activityStream';

import DoneHint from './doneHint';
import ContentPreview from './contentPreview';

const isBlog = (platform) => ['website', 'ga'].includes(platform);

function VerificationDone({
  intl,
  workflow,
  workflow: { lastUpdate },
  application: { contentPreview, type },
}) {
  return (
    <ActivityStreamItem
      status="done"
      iconName="check-document"
      initialExpanded={!isBlog(type)}
      expandable={!isBlog(type)}
    >
      <ActivityStreamHeader
        title={intl.formatMessage({
          id: 'sponsoredPosts.campaigns.verification.completed',
          defaultMessage: 'Verified successfully.',
        })}
        date={lastUpdate}
      />
      {workflow?.payload?.showHint && <DoneHint />}
      {['instagram', 'tiktok', 'pinterest'].includes(type) && (
        <ContentPreview contentPreview={contentPreview} platform={type} />
      )}
    </ActivityStreamItem>
  );
}

VerificationDone.propTypes = {
  workflow: PropTypes.shape({
    payload: PropTypes.shape({
      showHint: PropTypes.bool,
    }),
    lastUpdate: PropTypes.string,
  }).isRequired,
  application: PropTypes.shape({
    type: PropTypes.string.isRequired,
    contentPreview: PropTypes.object.isRequired,
  }).isRequired,
};

export default injectIntl(VerificationDone);
