import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { MediaGallery, MediaGalleryItem } from 'source/components/common/form';
import Markdown from 'source/components/common/markdown';

const labelsMap = {
  instagram: defineMessages({
    caption: {
      id: 'instagram.campaigns.verification.caption.name',
      defaultMessage: 'Post Caption:',
    },
    media: {
      id: 'instagram.campaigns.verification.media.name',
      defaultMessage: 'Post Images:',
    },
  }),
  tiktok: defineMessages({
    caption: {
      id: 'tiktok.campaigns.verification.caption.name',
      defaultMessage: 'Post Caption:',
    },
    media: {
      id: 'tiktok.campaigns.verification.media.name',
      defaultMessage: 'Post video:',
    },
  }),
  pinterest: defineMessages({
    caption: {
      id: 'pinterest.campaigns.verification.caption.name',
      defaultMessage: 'Post Caption:',
    },
    media: {
      id: 'pinterest.campaigns.verification.media.name',
      defaultMessage: 'Post video:',
    },
  }),
};

function ContentPreview({ contentPreview, platform }) {
  const labels = labelsMap[platform];

  return (
    <>
      <p className="m-t-1">
        <FormattedMessage {...labels.caption} />
      </p>
      <p>
        <Markdown
          className="muted"
          typeName="span"
          markdown={contentPreview.caption}
        />
      </p>
      <p>
        <FormattedMessage {...labels.media} />
      </p>
      <MediaGallery className="aurora-media-gallery">
        {contentPreview.media.map((item, index) => (
          <MediaGalleryItem
            key={item.url}
            id={item.url}
            url={item.url}
            filename={item.filename}
            assetId={item.assetId}
            type={item.type}
            removable={false}
            badgeIndex={index + 1}
          />
        ))}
      </MediaGallery>
    </>
  );
}

ContentPreview.propTypes = {
  platform: PropTypes.oneOf(['instagram', 'tiktok', 'pinterest']).isRequired,
  contentPreview: PropTypes.shape({
    caption: PropTypes.string.isRequired,
    media: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        assetId: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default injectIntl(ContentPreview);
