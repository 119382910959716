import React from 'react';
import { FormattedMessage } from 'react-intl';
import { branch, renderComponent, withProps, compose } from 'recompose';

import Spinner from 'source/components/common/spinner';

import ApplicationsTable from '../ApplicationsTable';

function Loading() {
  return <Spinner className="spinner-view" />;
}

function Error() {
  return (
    <div className="row">
      <div className="text-xs-center">
        <FormattedMessage
          id="errors.message.generic"
          defaultMessage="An unexpected problem occurred, so please try again."
        />
      </div>
    </div>
  );
}

const withLoadingGuard = branch(
  ({ applications: { loaded } }) => !loaded,
  renderComponent(Loading),
);

const withErrorGuard = branch(
  ({ applications: { error } }) => Boolean(error),
  renderComponent(Error),
);

// Takes async applications data and yields just the data portion
const withApplications = withProps(({ applications }) => ({
  applications: applications.data,
}));

export default compose(
  withErrorGuard,
  withLoadingGuard,
  withApplications,
)(ApplicationsTable);
