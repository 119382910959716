import React from 'react';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { withGatewayRoot } from './root';

function SignOut() {
  return (
    <div className="gateway__card gateway__card--nopad gateway__card--signout">
      <img
        className="card-img-top"
        src="/images/adbot-waving.gif"
        alt="adbot-waving"
      />

      <div className="card-block">
        <h4 className="card-title">
          <FormattedMessage
            id="signout.header.seeYou"
            defaultMessage="See you soon."
          />
        </h4>
        <h6 className="card-subtitle text-muted">
          <FormattedMessage
            id="signout.msg.loggedOut"
            defaultMessage="You are now logged out."
          />
          <br />
          <FormattedMessage
            id="signout.msg.areYouFollowing"
            defaultMessage="Are you already following EQOLOT?"
          />
        </h6>
        <ul>
          <li>
            <a href="https://twitter.com/eqolotcom">
              <svg className="icon">
                <use xlinkHref="#icon-twitter" />
              </svg>
              Twitter
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/eqolotcom/">
              <svg className="icon">
                <use xlinkHref="#icon-facebook" />
              </svg>
              Facebook
            </a>
          </li>
          <li>
            <a href="http://instagram.com/eqolotcom">
              <svg className="icon">
                <use xlinkHref="#icon-instagram" />
              </svg>
              Instagram
            </a>
          </li>
          <li>
            <a
              href="http://blogfoster.com/academy"
              rel="noopener noreferrer"
              target="_blank"
            >
              <svg className="icon icon--blog">
                <use xlinkHref="#icon-blog" />
              </svg>
              <FormattedMessage
                id="signout.msg.follow.ourBlog"
                defaultMessage="Our Blog"
              />
            </a>
          </li>
        </ul>
        <Link className="btn btn-primary btn-block" to="/signin">
          <FormattedMessage
            id="signout.link.signIn"
            defaultMessage="Sign in again"
          />
        </Link>
      </div>
    </div>
  );
}

export default withGatewayRoot(SignOut);
