import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectInvoices } from './selectors';
import { getInvoices } from './actions';

import Invoices from './index';

const InvoicesContainer = connect(
  createStructuredSelector({
    invoices: selectInvoices,
  }),
  { onGetInvoices: getInvoices },
)(Invoices);

export default InvoicesContainer;
