import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { selectUnit } from '@formatjs/intl-utils';
import get from 'lodash/get';
import noop from 'lodash/noop';
import moment from 'moment';
import { withProps, setPropTypes, compose } from 'recompose';

import {
  ActivityStreamItem,
  ActivityStreamHeader,
  ActivityStreamBody,
  ActivityStreamItemWithSubHeading,
} from 'source/components/common/activityStream';
import { SubmitButton } from 'source/components/common/button';
import Form, {
  FormGroup,
  Input,
  Label,
  FormControlFeedback,
} from 'source/components/common/form';
import { MessageCard } from 'source/components/common/message';
import { hasAttributes, upperCaseFirstLetter } from 'source/utils';
import Markdown from 'source/components/common/markdown';

// Helpers
const withShowHint = withProps(({ workflow: { payload } }) => ({
  showHint: payload && payload.showHint,
}));

const withUrgencyMessage = compose(
  setPropTypes({
    campaign: PropTypes.shape({
      timeline: PropTypes.shape({
        publicationStart: PropTypes.string,
        publicationEnd: PropTypes.string,
      }),
    }),

    application: PropTypes.shape({
      mission: PropTypes.shape({
        publishDate: PropTypes.string,
      }),
    }),
  }),

  withProps(({ intl, campaign, workflow: { payload } }) => {
    // @sean TODO Only use publishDate. There are a few old applications still without
    //       a publishDate, though (05.07.2017)
    const publishDate = get(payload, 'publishDate');
    const normalizedPublishDate =
      publishDate && moment(publishDate, 'YYYY-MM-DD');

    const normalizedPublishStart = moment(
      campaign.timeline.publishStart,
      'YYYY-MM-DD',
    );

    const normalizedPublishEnd = moment(
      campaign.timeline.publishEnd,
      'YYYY-MM-DD',
    );

    let titleId = 'sponsoredPosts.campaigns.publication.due';
    let messageId = 'sponsoredPosts.campaigns.publication.due.message';
    const publishStartRelative = selectUnit(
      normalizedPublishDate || normalizedPublishStart,
    );

    let due = upperCaseFirstLetter(
      intl.formatRelativeTime(
        publishStartRelative.value,
        publishStartRelative.unit,
        {
          numeric: 'auto',
        },
      ),
    );

    const { over } = payload;

    if (over) {
      titleId = 'sponsoredPosts.campaigns.publication.over';
      messageId = 'sponsoredPosts.campaigns.publication.over.message';
      const publishEndRelative = selectUnit(
        normalizedPublishDate || normalizedPublishEnd,
      );

      due = intl.formatRelativeTime(
        publishEndRelative.value,
        publishEndRelative.unit,
        {
          numeric: 'auto',
        },
      );
    }

    return {
      urgencyMessage: {
        titleId,
        messageId,
        due,
      },
    };
  }),
);

const withFormData = compose(
  setPropTypes({
    form: PropTypes.object.isRequired,
    formName: PropTypes.string.isRequired,
  }),

  withProps(({ form, formName }) => ({
    formData: form[formName],
  })),
);

const getErrorMessage = (formData, field) => {
  const messageId = get(formData, ['errors', field]);
  if (!messageId) {
    return null;
  }
  return <FormattedMessage id={messageId} />;
};

// Components

const UrgencyHint = withUrgencyMessage(
  ({ urgencyMessage: { titleId, messageId, due } }) => (
    <MessageCard type="info">
      <strong>
        <FormattedMessage id={titleId} />
      </strong>
      <span>
        <FormattedMessage id={messageId} values={{ due }} />
      </span>
    </MessageCard>
  ),
);

function RejectedHint({
  workflow: {
    payload: { feedback },
  },
}) {
  return (
    <MessageCard type="info">
      <strong>
        <FormattedMessage
          id="sponsoredPosts.campaigns.publication.rejected.feedback"
          defaultMessage="We have some feedback for you."
        />
      </strong>
      <span>
        {feedback ? (
          <Markdown typeName="span" markdown={feedback} noParagraphs />
        ) : (
          <FormattedMessage
            id="sponsoredPosts.campaigns.publication.rejected.message"
            defaultMessage="We have sent you an email with our feedback. Please check the points and send us an email back when you're ready or have any questions."
          />
        )}
      </span>
    </MessageCard>
  );
}

RejectedHint.propTypes = {
  workflow: PropTypes.shape({
    payload: PropTypes.shape({
      feedback: PropTypes.string,
    }),
  }).isRequired,
};

function InstagramPublicationInstructions({ intl }) {
  const exampleUrl = intl.formatMessage({
    id: 'sponsoredPosts.campaigns.publication.publish.instagram.example.url',
    defaultMessage:
      'https://www.instagram.com/p/XYZ12xyzXYZ/?taken-by=yourchannelname',
  });

  return (
    <div className="activity-stream-publication-instructions">
      <FormattedMessage
        id="sponsoredPosts.campaigns.publication.publish.instagram.hint"
        defaultMessage="Please do not send us the link to your Instagram-Channel, but the URL to your Posting."
      >
        {(text) => <span className="muted">{text}</span>}
      </FormattedMessage>
      <FormattedMessage
        id="sponsoredPosts.campaigns.publication.publish.example"
        defaultMessage="Example: "
      >
        {(text) => (
          <div>
            <span className="muted">{text}</span>
            <a className="text-break" href={exampleUrl}>
              {exampleUrl}
            </a>
          </div>
        )}
      </FormattedMessage>
    </div>
  );
}

InstagramPublicationInstructions.propTypes = {};

function TiktokPublicationInstructions({ intl }) {
  const exampleUrl = intl.formatMessage({
    id: 'sponsoredPosts.campaigns.publication.publish.tiktok.example.url',
    defaultMessage: 'https://www.tiktok.com/@username/video/1234567890',
  });

  return (
    <div className="activity-stream-publication-instructions">
      <FormattedMessage
        id="sponsoredPosts.campaigns.publication.publish.tiktok.hint"
        defaultMessage="Please do not send us the link to your TikTok-Channel, but the URL to your Posting."
      >
        {(text) => <span className="muted">{text}</span>}
      </FormattedMessage>
      <FormattedMessage
        id="sponsoredPosts.campaigns.publication.publish.example"
        defaultMessage="Example: "
      >
        {(text) => (
          <div>
            <span className="muted">{text}</span>
            <a className="text-break" href={exampleUrl}>
              {exampleUrl}
            </a>
          </div>
        )}
      </FormattedMessage>
    </div>
  );
}

TiktokPublicationInstructions.propTypes = {};

function PinterestPublicationInstructions({ intl }) {
  const exampleUrl = intl.formatMessage({
    id: 'sponsoredPosts.campaigns.publication.publish.pinterest.example.url',
    defaultMessage: 'https://www.pinterest.com/pin/1234567890/',
  });

  return (
    <div className="activity-stream-publication-instructions">
      <FormattedMessage
        id="sponsoredPosts.campaigns.publication.publish.pinterest.hint"
        defaultMessage="Please do not send us the link to your Pinterest-Channel, but the URL to your Posting."
      >
        {(text) => <span className="muted">{text}</span>}
      </FormattedMessage>
      <FormattedMessage
        id="sponsoredPosts.campaigns.publication.publish.example"
        defaultMessage="Example: "
      >
        {(text) => (
          <div>
            <span className="muted">{text}</span>
            <a className="text-break" href={exampleUrl}>
              {exampleUrl}
            </a>
          </div>
        )}
      </FormattedMessage>
    </div>
  );
}

PinterestPublicationInstructions.propTypes = {};

function PublicationForm({
  disabled,
  formName,
  formData,
  onChange,
  onSubmit,
  platform,
  intl,
}) {
  return (
    <Form id={formName} onChange={onChange} onSubmit={onSubmit}>
      <div className="activity-stream-publication-form-body">
        <fieldset className="form-group" disabled={disabled}>
          <FormGroup hasDanger={hasAttributes(formData.errors, ['url'])}>
            <Label htmlFor="url">
              <FormattedMessage
                id="sponsoredPosts.campaigns.publication.publish.url"
                defaultMessage="Please upload the post URL to the eqolot system immediately after the publication"
              />
            </Label>
            <Input
              id="url"
              value={formData.url || ''}
              danger={!!get(formData, 'errors.url')}
            />

            <FormControlFeedback message={getErrorMessage(formData, 'url')} />
          </FormGroup>
        </fieldset>
        {platform === 'instagram' && (
          <InstagramPublicationInstructions intl={intl} />
        )}
        {platform === 'tiktok' && <TiktokPublicationInstructions intl={intl} />}
        {platform === 'pinterest' && (
          <PinterestPublicationInstructions intl={intl} />
        )}
      </div>
      <SubmitButton
        disabled={
          disabled || hasAttributes(formData.errors) || formData.submitting
        }
      >
        <FormattedMessage
          id="sponsoredPosts.campaigns.publication.publish.continue"
          defaultMessage="Continue"
        />
      </SubmitButton>
    </Form>
  );
}

PublicationForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
  formName: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  platform: PropTypes.string.isRequired,
};

function Inactive({ intl }) {
  return (
    <ActivityStreamItem iconName="publish">
      <ActivityStreamHeader
        title={intl.formatMessage({
          id: 'sponsoredPosts.campaigns.publication',
          defaultMessage: 'Publication',
        })}
      />
    </ActivityStreamItem>
  );
}

Inactive.propTypes = {};

function Active({
  intl,
  formName,
  formData,
  workflow,
  campaign,
  showHint,
  workflow: { payload },
  application,
  onChange,
  onSubmit,
  onAction,
}) {
  return (
    <ActivityStreamItem status="active" iconName="publish">
      <ActivityStreamHeader
        title={intl.formatMessage({
          id: 'sponsoredPosts.campaigns.publication',
          defaultMessage: 'Publication',
        })}
      />

      <ActivityStreamBody>
        {showHint ? (
          <UrgencyHint intl={intl} workflow={workflow} campaign={campaign} />
        ) : null}

        <PublicationForm
          disabled={Boolean(payload && payload.disablePublishing)}
          showCheckbox={application.type === 'website'}
          formName={formName}
          formData={formData}
          onChange={onChange}
          onSubmit={onSubmit}
          onAction={onAction}
          platform={campaign.type}
          intl={intl}
        />
      </ActivityStreamBody>
    </ActivityStreamItem>
  );
}

Active.propTypes = {
  formName: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  showHint: PropTypes.bool.isRequired,
  workflow: PropTypes.shape({
    payload: PropTypes.shape({
      disablePublishing: PropTypes.bool,
    }),
  }).isRequired,
  application: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }),

  campaign: PropTypes.shape({
    timeline: PropTypes.object,
    type: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

function Pending({ intl, workflow: { lastUpdate } }) {
  return (
    <ActivityStreamItemWithSubHeading
      intl={intl}
      status="active"
      iconName="publish"
      headingId="sponsoredPosts.campaigns.publication"
      headingDate={lastUpdate}
      subHeadingId="sponsoredPosts.campaigns.publication.publish.pending"
      subHeadingContentId="sponsoredPosts.campaigns.publication.publish.pending.message"
    />
  );
}

Pending.propTypes = {
  workflow: PropTypes.shape({
    lastUpdate: PropTypes.string.isRequired,
  }).isRequired,
};

function Rejected({
  intl,
  formName,
  formData,
  showHint,
  workflow,
  workflow: { lastUpdate, payload },
  campaign,
  application,
  onChange,
  onSubmit,
  onAction,
}) {
  return (
    <ActivityStreamItem status="failed" iconName="publish">
      <ActivityStreamHeader
        title={intl.formatMessage({
          id: 'sponsoredPosts.campaigns.publication.rejected',
          defaultMessage: 'Publication failed.',
        })}
        date={lastUpdate}
      />

      <ActivityStreamBody>
        {showHint ? <RejectedHint workflow={workflow} /> : null}

        <PublicationForm
          disabled={Boolean(payload && payload.disablePublishing)}
          showCheckbox={application.type === 'website'}
          formName={formName}
          formData={formData}
          onChange={onChange}
          onSubmit={onSubmit}
          onAction={onAction}
          platform={campaign.type}
          intl={intl}
        />
      </ActivityStreamBody>
    </ActivityStreamItem>
  );
}

Rejected.propTypes = {
  formName: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  showHint: PropTypes.bool.isRequired,
  workflow: PropTypes.shape({
    lastUpdate: PropTypes.string.isRequired,
    payload: PropTypes.shape({
      disablePublishing: PropTypes.bool,
    }),
  }).isRequired,
  application: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }),

  campaign: PropTypes.shape({
    timeline: PropTypes.object,
    type: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

function Done({ intl, workflow: { lastUpdate } }) {
  return (
    <ActivityStreamItem status="done" iconName="publish">
      <ActivityStreamHeader
        title={intl.formatMessage({
          id: 'sponsoredPosts.campaigns.publication.accepted',
          defaultMessage: 'Successfully published.',
        })}
        date={lastUpdate}
      />
    </ActivityStreamItem>
  );
}

Done.propTypes = {
  workflow: PropTypes.shape({
    lastUpdate: PropTypes.string.isRequired,
  }).isRequired,
};

function Expired({ intl, workflow: { lastUpdate } }) {
  return (
    <ActivityStreamItem status="failed" iconName="publish">
      <ActivityStreamHeader
        title={intl.formatMessage({
          id: 'sponsoredPosts.campaigns.publication.publish',
          defaultMessage: 'Publish',
        })}
        date={lastUpdate}
      />

      <ActivityStreamBody>
        <MessageCard type="info">
          <strong>
            <FormattedMessage
              id="sponsoredPosts.campaigns.accept.expired.title"
              defaultMessage="What a pity!"
            />
          </strong>
          <span>
            <FormattedMessage
              id="sponsoredPosts.campaigns.accept.expired.message"
              defaultMessage="The deadline for application has expired, unfortunately."
            />
          </span>
        </MessageCard>
      </ActivityStreamBody>
    </ActivityStreamItem>
  );
}

Expired.propTypes = {
  workflow: PropTypes.shape({
    lastUpdate: PropTypes.string.isRequired,
  }).isRequired,
};

function Publication(props) {
  const Component =
    {
      inactive: Inactive,
      active: Active,
      pending: Pending,
      rejected: Rejected,
      done: Done,
      expired: Expired,
    }[props.workflow.status] || noop;

  return <Component {...props} />;
}

Publication.propTypes = {
  workflow: PropTypes.shape({
    status: PropTypes.oneOf([
      'inactive',
      'active',
      'pending',
      'rejected',
      'done',
      'expired',
    ]).isRequired,
    lastUpdate: PropTypes.string,
  }).isRequired,
};

export default compose(injectIntl, withShowHint, withFormData)(Publication);
