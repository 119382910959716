import React from 'react';
import PropTypes from 'prop-types';

function ChannelAvatar({ url }) {
  if (!url) {
    return null;
  }

  return <img className="channel-avatar" src={url} alt="Channel Avatar" />;
}

ChannelAvatar.propTypes = {
  url: PropTypes.string,
};
ChannelAvatar.defaultProps = {
  url: '',
};

export default ChannelAvatar;
