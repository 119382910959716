import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import get from 'lodash/get';
import { branch, renderComponent, mapProps, compose } from 'recompose';

import { asyncProps } from '@blogfoster/redux-async-utils';

import Icon from 'source/components/common/icon';
import Spinner from 'source/components/common/spinner';
import Tabs from 'source/components/common/tabsNav';

import CampaignCard from '../CampaignCard';
import CampaignCardTeaser from '../CampaignCardTeaser';

const campaignTypes = [
  'all',
  'website',
  'instagram',
  'tiktok',
  'pinterest',
  'youtube',
];

function SubscribeButton({ isSubscribed, onSubscribe }) {
  const icon = isSubscribed ? <Icon name="checkmark" /> : <Icon name="mail" />;

  if (isSubscribed) {
    return (
      <Link
        className="btn btn-primary subscription-btn"
        to="/account-settings/notifications"
      >
        {icon}
        <FormattedMessage
          id="sponsoredPosts.explore.noContent.newsletter.enable.label.subscribed"
          defaultMessage="Subscribed"
        />
      </Link>
    );
  }

  return (
    <button className="btn btn-primary subscription-btn" onClick={onSubscribe}>
      {icon}
      <FormattedMessage
        id="sponsoredPosts.explore.noContent.newsletter.enable.label"
        defaultMessage="Subscribe"
      />
    </button>
  );
}

SubscribeButton.propTypes = {
  isSubscribed: PropTypes.bool.isRequired,
  onSubscribe: PropTypes.func.isRequired,
};

// Components
function NoContent({ userSettings, onEnableCampaignEmails }) {
  return (
    <div className="row">
      <div className="text-xs-center sponsored-posts-overview-explore--no-content">
        <h1 className="title">
          <FormattedMessage
            id="sponsoredPosts.explore.noContent.title"
            defaultMessage="No new campaigns at the moment"
          />
        </h1>
        <FormattedMessage
          id="sponsoredPosts.explore.noContent.message"
          defaultMessage="Sign up below to get an email notification when new campaigns become available for you."
        />
      </div>
      <div className="card card-block text-xs-center sponsored-posts-overview-explore--no-content-subscription-card">
        <div className="text-container">
          <h1 className="title">
            <FormattedMessage
              id="sponsoredPosts.explore.noContent.newsletter.title"
              defaultMessage="Get new campaigns by email"
            />
          </h1>
          <FormattedMessage
            id="sponsoredPosts.explore.noContent.newsletter.message"
            defaultMessage="Weekly emails with new campaigns that are relevant to you."
          />
        </div>
        <SubscribeButton
          isSubscribed={userSettings.recommendedCampaignEmails}
          onSubscribe={onEnableCampaignEmails}
        />
      </div>
    </div>
  );
}

NoContent.propTypes = {
  userSettings: PropTypes.shape({
    promotionalEmails: PropTypes.bool,
    recommendedCampaignEmails: PropTypes.bool,
  }).isRequired,
  onEnableCampaignEmails: PropTypes.func.isRequired,
};

function Loading() {
  return <Spinner className="spinner-view" />;
}

function Error() {
  return (
    <div className="row">
      <div className="text-xs-center">
        <FormattedMessage
          id="errors.message.generic"
          defaultMessage="An unexpected problem occurred, so please try again."
        />
      </div>
    </div>
  );
}

function CampaignsList({ showTeaserCampaigns, campaigns }) {
  if (showTeaserCampaigns) {
    return (
      <div>
        {campaigns.map((campaign) => (
          <div className="col-md-4" key={campaign.id}>
            <CampaignCardTeaser campaign={campaign} />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      {campaigns.map(({ campaign, matches }) => (
        <div className="col-md-4" key={campaign.id}>
          <CampaignCard campaign={campaign} matches={matches} />
        </div>
      ))}
    </div>
  );
}

CampaignsList.propTypes = {
  showTeaserCampaigns: PropTypes.bool.isRequired,
  campaigns: PropTypes.oneOfType(
    PropTypes.arrayOf(
      PropTypes.shape({
        campaign: PropTypes.object.isRequired,
        matches: PropTypes.array.isRequired,
      }),
    ),
    PropTypes.arrayOf(PropTypes.object),
  ).isRequired,
  userSettings: PropTypes.shape({
    promotionalEmails: PropTypes.bool,
    recommendedCampaignEmails: PropTypes.bool,
  }),
};

const withErrorGuard = branch(
  ({ campaignData }) => Boolean(campaignData.error),
  renderComponent(Error),
);

const withLoadingGuard = branch(
  ({ campaignData }) => !campaignData.loaded,
  renderComponent(Loading),
);

// Takes async campaignMatchesById and a platform filter and yields
// the corresponding campaign matches list. Meant to be called after load
const withCampaignMatches = mapProps(
  ({ showTeaserCampaigns, campaignData, campaignTypeFilter, ...props }) => ({
    ...props,
    showTeaserCampaigns,
    campaigns: campaignData.data[campaignTypeFilter] || [],
  }),
);

const withNoContentMessage = branch(
  ({ campaigns }) => !campaigns.length,
  renderComponent(NoContent),
);

const CampaignsListManaged = compose(
  withErrorGuard,
  withLoadingGuard,
  withCampaignMatches,
  withNoContentMessage,
)(CampaignsList);

export function CampaignTypeTabs({
  loaded,
  data: campaignMatchesByType,
  selectedTab,
  onSelectTab,
}) {
  const campaignCountByType = campaignTypes.reduce((acc, type) => {
    if (loaded) {
      acc[type] = get(campaignMatchesByType, [type, 'length']) || 0;
    } else {
      acc[type] = 0;
    }
    return acc;
  }, {});
  const tabs = campaignTypes.map((type) => ({
    id: type,
    label: (
      <span>
        <FormattedMessage id={`sponsoredPosts.campaigns.filter.${type}`} />
        &nbsp; ({campaignCountByType[type]})
      </span>
    ),
  }));

  return (
    <Tabs onSelectTab={onSelectTab} currentTab={selectedTab} tabs={tabs} />
  );
}

CampaignTypeTabs.propTypes = {
  loaded: PropTypes.bool.isRequired,
  data: PropTypes.objectOf(PropTypes.array),
  selectedTab: PropTypes.string.isRequired,
  onSelectTab: PropTypes.func.isRequired,
};

function ExploreCampaigns({
  campaignMatchesByType,
  allCampaignsByType,
  campaignTypeFilter,
  onSelectCampaignTypeFilter,
  onEnableCampaignEmails,
  userSettings,
  userConfirmed,
  userHasChannels,
}) {
  const showTeaserCampaigns = !userConfirmed || !userHasChannels;
  const campaignData = showTeaserCampaigns
    ? allCampaignsByType
    : campaignMatchesByType;

  return (
    <div className="sponsored-posts-overview-explore">
      <div className="sponsored-posts-overview-tab-container">
        <CampaignTypeTabs
          {...campaignData}
          selectedTab={campaignTypeFilter}
          onSelectTab={onSelectCampaignTypeFilter}
        />
      </div>
      <div className="row card-grid">
        <CampaignsListManaged
          campaignData={campaignData}
          showTeaserCampaigns={showTeaserCampaigns}
          userSettings={userSettings}
          onEnableCampaignEmails={onEnableCampaignEmails}
          campaignTypeFilter={campaignTypeFilter}
        />
      </div>
    </div>
  );
}

ExploreCampaigns.propTypes = {
  campaignMatchesByType: PropTypes.shape(asyncProps).isRequired,
  allCampaignsByType: PropTypes.shape(asyncProps).isRequired,
  campaignTypeFilter: PropTypes.string.isRequired,
  onSelectCampaignTypeFilter: PropTypes.func.isRequired,
  userSettings: PropTypes.shape({
    promotionalEmails: PropTypes.bool,
    recommendedCampaignEmails: PropTypes.bool,
  }),
  userConfirmed: PropTypes.bool.isRequired,
  userHasChannels: PropTypes.bool.isRequired,
  onEnableCampaignEmails: PropTypes.func.isRequired,
};

export default ExploreCampaigns;
