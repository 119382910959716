import { combineReducers } from 'redux';

import exploreCampaigns from './exploreCampaigns/reducer';
import myCampaigns from './myCampaigns/reducer';
import campaignDetails from './campaignDetails/reducer';
import accountSettings from './accountSettings/reducer';
import revenues from './revenues/reducer';
import brandClubs from './brandClubs/reducer';
import profile from './profile/reducer';

export default combineReducers({
  exploreCampaigns,
  myCampaigns,
  campaignDetails,
  accountSettings,
  revenues,
  brandClubs,
  profile,
});
