import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

function SoftwareButton({ software, active, onClick }) {
  const handleClick = (e) => {
    e.preventDefault();

    onClick('software', software);
  };

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  return (
    <label
      htmlFor={software}
      className={cx('btn', 'btn-secondary', { active })}
      onClick={handleClick}
    >
      <input id={software} type="radio" name="options" value={software} />
      <FormattedMessage
        id={`blogProfile.blog.software.names.${software}`}
        defaultMessage={software}
      />
    </label>
  );
  /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
}

SoftwareButton.propTypes = {
  software: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SoftwareButton;
