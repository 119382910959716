import { combineReducers } from 'redux';
import merge from 'lodash/merge';

import {
  loadStateReducer,
  asyncActionReducer,
  asyncStates,
} from '@blogfoster/redux-async-utils';

import { combineReducersFlat } from 'source/utils/redux';

import { actionTypes } from './actions';

const applicationFetchReducer = loadStateReducer(actionTypes.APPLICATION_FETCH);

const applicationUpdateReducer = asyncActionReducer(
  actionTypes.APPLICATION_UPDATE,
  {
    [asyncStates.success]: (state, { payload: response }) => ({
      ...state,
      // We merge instead of replace because a PATCH never returns
      // resolved fields, e.g. application.campaign
      // @sean TODO We may want to consider normalization of nested resources
      //       in the client
      data: merge(state.data, response.data),
    }),
  },
);

// NOTE Currently we don't listen to APPLICATION_CREATE because it results
// in a redirect anyway

const applicationReducer = combineReducersFlat([
  applicationFetchReducer,
  applicationUpdateReducer,
]);

const campaignMatchReducer = loadStateReducer(actionTypes.CAMPAIGN_MATCH_FETCH);

export default combineReducers({
  application: applicationReducer,
  campaignMatch: campaignMatchReducer,
});
