import React from 'react';
import PropTypes from 'prop-types';
import {
  injectIntl,
  FormattedMessage,
  FormattedNumber,
  FormattedDate,
  defineMessages,
} from 'react-intl';
import isBoolean from 'lodash/isBoolean';
import cx from 'classnames';
import moment from 'moment';
import {
  ToolTipController,
  Select as ToolTipWrapper,
} from 'react-tooltip-controller';
import { compose } from 'recompose';

import { Link } from 'react-router';
import includes from 'lodash/includes';
import last from 'lodash/last';
import isEmpty from 'lodash/isEmpty';

import Tooltip from 'source/components/common/tooltip';
import links from 'source/i18n/links';
import Icon from 'source/components/common/icon';
import Spinner from 'source/components/common/spinner';

import { getKPISeries, getKPI, getKPILabelsByPlatform } from '../utils/kpi';
import {
  getChannelName,
  hasEnoughData,
  hasAlmostOutdatedScreenshots,
} from '../utils/channel';
import GrowthChart from './GrowthChart';
import ChannelStatus from './ChannelStatus';
import ChannelAvatar from './ChannelAvatar';

const pricingTooltips = defineMessages({
  instagram: {
    id: 'basePayment.tooltip.instagram',
    defaultMessage:
      'This base price per post is calculated by EQOLOT and not provided nor modified by the Instagram service. The EQOLOT base price is only applicable for EQOLOT campaigns on the EQOLOT platform.',
  },

  website: {
    id: 'basePayment.tooltip.website',
    defaultMessage:
      'The base price per post is calculated by EQOLOT and is only applicable for EQOLOT campaigns on the EQOLOT platform.',
  },

  ga: {
    id: 'basePayment.tooltip.ga',
    defaultMessage:
      'The base price per post is calculated by EQOLOT and is only applicable for EQOLOT campaigns on the EQOLOT platform.',
  },

  youtube: {
    id: 'basePayment.tooltip.youtube',
    defaultMessage:
      'The base payment per post is calculated by EQOLOT and not provided nor modified by the YouTube service. The EQOLOT base payment is only applicable for EQOLOT campaigns on the EQOLOT platform.',
  },

  tiktok: {
    id: 'basePayment.tooltip.tiktok',
    defaultMessage:
      'This base price per post is calculated by EQOLOT and not provided nor modified by the TikTok service. The EQOLOT base price is only applicable for EQOLOT campaigns on the EQOLOT platform.',
  },

  pinterest: {
    id: 'basePayment.tooltip.pinterest',
    defaultMessage:
      'This base price per post is calculated by EQOLOT and not provided nor modified by the Pinterest service. The EQOLOT base price is only applicable for EQOLOT campaigns on the EQOLOT platform.',
  },
});

const PricePerPost = injectIntl(({ intl, platform, value }) => {
  const supportedPlatforms = [
    'website',
    'instagram',
    'youtube',
    'ga',
    'tiktok',
    'pinterest',
  ];

  if (value === undefined || !includes(supportedPlatforms, platform)) {
    return null;
  }

  const isBlog = ['website', 'ga'].includes(platform);

  const priceLabel = (
    <FormattedMessage
      id="insights.v2.overview.kpi.price.label"
      defaultMessage="EQOLOT base payment"
    />
  );

  if (platform === 'pinterest') {
    return null;
  }

  // @alexbeletsky: 05/09/2022 - should be re-enabled after Pinterest
  // campaigns pricing is fixed..

  // if (platform === 'pinterest' && value === 0) {
  //   return (
  //     <div>
  //       <div className="insights-card__kpi-value f2 fw7 lh-solid">
  //         <FormattedMessage
  //           id="insights.v2.overview.kpi.price.empty.value"
  //           defaultMessage="— €"
  //         />
  //       </div>
  //       <div className="insights-card__kpi-label f5">
  //         <FormattedMessage
  //           id="insights.v2.overview.kpi.price.insufficientData.label"
  //           defaultMessage="Insufficient data"
  //         />
  //         <ToolTipController detect="hover" offsetX={-111}>
  //           <ToolTipWrapper>
  //             <span className="icon-wrapper">
  //               <Icon name="help" />
  //             </span>
  //           </ToolTipWrapper>
  //           <Tooltip className="tooltip-base-payment">
  //             <FormattedMessage
  //               id="basePayment.tooltip.pinterest.insufficientData"
  //               defaultMessage="This channel doesn't have recent idea pins with significant traffic."
  //             />
  //           </Tooltip>
  //         </ToolTipController>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div>
      <div className="insights-card__kpi-value f2 fw7 lh-solid">
        <FormattedMessage
          id="insights.v2.overview.kpi.price.value"
          defaultMessage="{price, number} €"
          values={{ price: value }}
        />
      </div>
      <div className="insights-card__kpi-label f5">
        {isBlog ? (
          <a
            target="_blank"
            rel="noreferrer"
            href={intl.formatMessage(links.basePaymentPricingLink)}
          >
            {priceLabel}
          </a>
        ) : (
          priceLabel
        )}

        <ToolTipController detect="hover" offsetX={-111}>
          <ToolTipWrapper>
            <span className="icon-wrapper">
              <Icon name="help" />
            </span>
          </ToolTipWrapper>
          <Tooltip className="tooltip-base-payment">
            <FormattedMessage {...pricingTooltips[platform]} />
          </Tooltip>
        </ToolTipController>
      </div>
    </div>
  );
});

PricePerPost.propTypes = {
  platform: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

function CardBody({ channel }) {
  const { metrics, platform, pricePerPost, pricePerPostV2 } = channel;

  const metricsByDate = metrics.byDate;

  const isGAEstimation =
    platform === 'ga' && metricsByDate?.totals?.extrapolatedPageviews;
  const kpiLabel = getKPILabelsByPlatform(channel);
  const kpi = getKPI(channel);
  const price = platform === 'website' ? pricePerPostV2 : pricePerPost;

  return (
    <div className="thrush-card__body insights-card__body">
      <div className="row insights-card__data">
        <div className="col-lg-6 insights-card__data-block">
          {isGAEstimation && (
            <div className="insights-card__kpi-label f5">
              <FormattedMessage
                id="insights.v2.overview.estimated.label"
                defaultMessage="Estimated"
              />
            </div>
          )}
          <div className="insights-card__kpi-value f2 fw7 lh-solid">
            <FormattedNumber value={kpi} />
          </div>
          <div className="insights-card__kpi-label f5">
            <FormattedMessage {...kpiLabel} />
          </div>
          {!isEmpty(metricsByDate) && (
            <div>
              <small>
                <FormattedMessage
                  id="insights.v2.overview.updated.label"
                  defaultMessage="Updated"
                />{' '}
                <FormattedDate
                  value={moment(last(metricsByDate).date).toDate()}
                />
              </small>
            </div>
          )}
        </div>
        <div className="col-lg-6 insights-card__data-block">
          <PricePerPost platform={platform} value={price} />
        </div>
      </div>
    </div>
  );
}

CardBody.propTypes = {
  channel: PropTypes.object,
};

function CardErrorBody({ title, message, iconName }) {
  return (
    <div className="thrush-card__body insights-card__body">
      <div className="insights-card__error">
        <div className="insights-card__error-message">
          {iconName && <Icon name={iconName} />}
          {title && <h1 className="f5 fw7">{title}</h1>}
          {message && <p className="f5 fw7">{message}</p>}
        </div>
      </div>
    </div>
  );
}

CardErrorBody.propTypes = {
  title: PropTypes.node,
  message: PropTypes.node,
  iconName: PropTypes.string,
};
CardErrorBody.defaultProps = {
  title: '',
  message: '',
  iconName: '',
};

function CardNotEnoughDataBody({ channel }) {
  let title = (
    <FormattedMessage
      id="insights.v2.overview.error.notEnoughData.heading"
      defaultMessage="Not enough data collected."
    />
  );
  let message = (
    <FormattedMessage
      id="insights.v2.overview.error.notEnoughData.social.message"
      defaultMessage="Please check again later."
    />
  );

  if (channel.platform === 'ga') {
    title = null;
    message = (
      <FormattedMessage
        id="insights.v2.overview.error.notEnoughData.ga.message"
        defaultMessage="Your new channel is being created, it will be ready in a few minutes ⏱"
      />
    );
  }

  if (channel.platform === 'website') {
    message = (
      <FormattedMessage
        id="insights.v2.overview.error.notEnoughData.website.message"
        defaultMessage="Please check again in 24 hours."
      />
    );
  }

  return <CardErrorBody title={title} message={message} iconName="spinner" />;
}

CardNotEnoughDataBody.propTypes = {
  channel: PropTypes.object.isRequired,
};

function CardUpgradeWebsitePrompt({ channel }) {
  return (
    <CardErrorBody
      title={
        <FormattedMessage
          id="insights.v2.overview.warning.promptToUpgrade.website.heading"
          defaultMessage="Upgrade to Google Analytics!"
        />
      }
      message={
        <FormattedMessage
          id="sponsoredPosts.campaigns.promptToUpgrade"
          defaultMessage="Go to {link} to upgrade"
          values={{
            link: (
              <Link to={`/profile?channelId=${channel.id}`}>
                <FormattedMessage
                  id="sponsoredPosts.campaigns.channelsLink"
                  defaultMessage="Channels"
                />
              </Link>
            ),
          }}
        />
      }
      iconName="warning"
    />
  );
}

CardUpgradeWebsitePrompt.propTypes = {
  channel: PropTypes.object.isRequired,
};

function ChannelCard({ channel, onSelectChannel, loaded }) {
  const chartData = channel.ready ? getKPISeries(channel) : null;
  const notEnoughData = !hasEnoughData(channel);

  const handleClick = (e) => {
    e.preventDefault();

    onSelectChannel({ id: channel.id });
  };

  const showInsightsWarning = !channel.temporary
    ? hasAlmostOutdatedScreenshots({
        platform: channel.platform,
        screenshots: channel.screenshots,
      })
    : false;

  const rootClassName = cx(
    'thrush-card',
    'insights-card',
    'insights-card--overview',
    `insights-card--${channel.platform}`,
    {
      'card-error': !channel.ready || (loaded && notEnoughData),
      'card-loading tc': !loaded,
    },
  );

  if (channel.platform === 'website') {
    return (
      <div className={rootClassName} onClick={handleClick}>
        {!loaded && <Spinner />}
        {loaded && <CardUpgradeWebsitePrompt channel={channel} />}
      </div>
    );
  }

  return (
    <div className={rootClassName} onClick={handleClick}>
      {!loaded && <Spinner />}
      {loaded && (
        <>
          {chartData && (
            <GrowthChart
              className={cx(
                'insights-card__chart',
                `insights-card__chart--${channel.platform}`,
              )}
              data={chartData}
            />
          )}
          <div className="thrush-card__header insights-card__header">
            <ChannelAvatar url={channel.avatarUrl} />
            <Icon name={channel.platform} />
            <h1 className="f5 fw7">{getChannelName(channel)}</h1>
            <div className="card-status-container">
              {showInsightsWarning && (
                <div className="alert-icon">
                  <FormattedMessage
                    id="channels.header.alert"
                    defaultMessage="⚠️"
                  />
                </div>
              )}
              {isBoolean(channel.ready) ? (
                <ChannelStatus
                  className="status-badge"
                  ready={channel.ready}
                  readyState={channel.readyState}
                  platform={channel.platform}
                  screenshots={channel.screenshots}
                />
              ) : null}
            </div>
          </div>
          {channel.ready && !notEnoughData && <CardBody channel={channel} />}
          {!channel.ready && (
            <CardErrorBody
              title={
                <FormattedMessage
                  id="insights.v2.overview.error.notReady.heading"
                  defaultMessage="Channel not ready"
                />
              }
              message={
                <FormattedMessage
                  id="insights.v2.overview.error.notReady.message"
                  defaultMessage="You have not completed the setup for this channel."
                />
              }
              iconName="lock"
            />
          )}
          {notEnoughData && <CardNotEnoughDataBody channel={channel} />}
        </>
      )}
    </div>
  );
}

ChannelCard.propTypes = {
  channel: PropTypes.object.isRequired,
  onSelectChannel: PropTypes.func.isRequired,
  loaded: PropTypes.bool,
};
ChannelCard.defaultProps = {
  loaded: true,
};

export default compose(injectIntl)(ChannelCard);
