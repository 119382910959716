import PropTypes from 'prop-types';
import {
  fieldInputPropTypes as reduxFormFieldInputPropTypes,
  fieldMetaPropTypes as reduxFormFieldMetaPropTypes,
} from 'redux-form';

/**
 * The default `fieldInputPropTypes` mark some function as required but they are
 * not supplied when using the `Fields` component. For more details about these
 * props, checkout the redux-form docs:
 *
 * https://redux-form.com/7.1.2/docs/api/fields.md/#props
 */
export const fieldInputPropTypes = {
  ...reduxFormFieldInputPropTypes,
  onDrop: PropTypes.func,
  onDragStart: PropTypes.func,
};

export const fieldMetaPropTypes = reduxFormFieldMetaPropTypes;

export const fieldPropTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
};
