import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';

function DisconnectError({ id, userId, onExit, failureReason }) {
  const titleId = get(
    failureReason,
    'titleId',
    'channels.disconnect.error.default.title',
  );

  const bodyId = get(
    failureReason,
    'bodyId',
    'channels.disconnect.error.default.body',
  );

  const mailToHref = `mailto:support@eqolot.com?subject=User:${userId}&body=Channel:${id}`;

  return (
    <div className="container">
      <h5 className="m-b-1">
        <FormattedMessage id={titleId} />
      </h5>
      <p>
        <FormattedMessage
          id={bodyId}
          values={{
            supportLink: (
              <a href={mailToHref}>
                <FormattedMessage
                  id="channels.disconnect.error.default.body.supportLink.label"
                  defaultMessage="contact support."
                />
              </a>
            ),
          }}
        />
      </p>
      <div className="m-t-2" style={{ float: 'right' }}>
        <button
          className="btn btn-primary"
          style={{ width: 55 }}
          onClick={onExit}
        >
          <FormattedMessage
            id="channels.disconnect.error.ok"
            defaultMessage="OK"
          />
        </button>
      </div>
    </div>
  );
}

DisconnectError.propTypes = {
  id: PropTypes.string,
  userId: PropTypes.number,
  onExit: PropTypes.func.isRequired,
  failureReason: PropTypes.object,
};

export default DisconnectError;
