import { selectors as fromView } from './components/MyCampaigns/reducer';

export const getAsyncApplications = (state) =>
  fromView.getAsyncApplicationsLimited(state.scenes.myCampaigns);

export const getApplicationStatusFilter = (state) =>
  fromView.getApplicationStatusFilter(state.scenes.myCampaigns);

export const isShowMoreButtonVisible = (state) =>
  fromView.isShowMoreButtonVisible(state.scenes.myCampaigns);
