import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, FormSection } from 'redux-form';

import { Input } from './reduxFormComponents';

function NameFields() {
  return (
    <div style={{ marginTop: '40px' }}>
      <h4 className="headline-form">
        <FormattedMessage
          id="accountSettings.payment.name"
          defaultMessage="Name"
        />
      </h4>

      <FormSection name="name">
        <Field
          name="firstname"
          component={Input}
          labelId="accountSettings.payment.firstname"
        />

        <Field
          name="lastname"
          component={Input}
          labelId="accountSettings.payment.lastname"
        />
      </FormSection>
    </div>
  );
}

export default NameFields;
