import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import noop from 'lodash/noop';
import ConnectButton from '../common/connectButton';

function BlogGaConnectStep({
  id,
  platform: platformProp,
  onConnect,
  intl: { formatMessage },
}) {
  const academyArticle = formatMessage({
    id: 'channels.ga.connect.how-to.url',
    defaultMessage: 'https://support.google.com/analytics/answer/1008015?hl=en',
  });

  const platform = platformProp === 'website' ? 'ga' : platformProp;
  const referenceChannelId = platformProp === 'website' ? id : undefined;

  return (
    <>
      <h5>
        <FormattedMessage id={`channels.${platform}.connect.headline`} />
      </h5>
      <p>
        <FormattedMessage id={`channels.${platform}.connect.text`} />
      </p>
      <br />
      <h5>
        <FormattedMessage id={`channels.${platform}.connect.how-to.headline`} />
      </h5>
      <p>
        <a href={academyArticle} target="_blank" rel="noreferrer noopener">
          <FormattedMessage id={`channels.${platform}.connect.how-to.link`} />
        </a>
      </p>
      <p>
        <FormattedMessage
          id={`channels.${platform}.connect.ga4warning`}
          values={{ br: <br /> }}
        />
      </p>
      <div className="pull-xs-right">
        <ConnectButton
          id={referenceChannelId}
          platform={platform}
          onClick={onConnect}
        />
      </div>
    </>
  );
}

BlogGaConnectStep.propTypes = {
  id: PropTypes.string,
  platform: PropTypes.oneOf(['ga', 'website']).isRequired,
  onConnect: PropTypes.func,
};

BlogGaConnectStep.defaultProps = {
  onConnect: noop,
};

export default injectIntl(BlogGaConnectStep);
