import React from 'react';
import PropTypes from 'prop-types';
import config from 'config';
import * as propTypes from 'source/utils/propTypes';
import { getOldestScreenshotAge } from 'source/scenes/profile/utils/channel';
import {
  StatusMessageHtml,
  StatusMessageCombined,
  StatusMessageDaysCombined,
} from './statusMessages';

function InsightsStatus({ platform, screenshots }) {
  const uploadDaysAfter = getOldestScreenshotAge({ screenshots, platform });

  if (uploadDaysAfter === null) {
    return <StatusMessageHtml messageHtml="missingMessage" />;
  }

  const { insightsTtl, insightsIntermediateTtl } = config.insights[platform];

  if (uploadDaysAfter >= insightsTtl) {
    return <StatusMessageHtml messageHtml="expiredMessage" />;
  }

  if (uploadDaysAfter === insightsTtl - 1) {
    return (
      <StatusMessageCombined
        messageHtml="staleMessage"
        message="staleMessage.1day"
      />
    );
  }

  if (
    uploadDaysAfter > insightsIntermediateTtl &&
    uploadDaysAfter <= insightsTtl - 2
  ) {
    return (
      <StatusMessageDaysCombined
        message="staleMessage"
        messageDays="staleAgeHint"
        ageInDays={insightsTtl - uploadDaysAfter}
      />
    );
  }

  if (uploadDaysAfter <= insightsIntermediateTtl) {
    return (
      <StatusMessageDaysCombined
        message="freshMessage"
        messageDays="freshDateMessage"
        ageInDays={insightsTtl - uploadDaysAfter}
      />
    );
  }

  return null;
}

InsightsStatus.propTypes = {
  screenshots: propTypes.screenshots,
  platform: PropTypes.string.isRequired,
};

export default InsightsStatus;
