import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

// @alexbeletsky: [https://app.shortcut.com/eqolot/story/11961/make-countdown-on-payments-to-45days-always]
// This logic is temporary disabled (till Feb 2023),
// so we always have 45 days till payment..
/*
const getPaymentPeriod = (invoice, invoices) => {
  const invoicePeriod = moment(invoice.period, 'YYYY-MM');

  const oneMonthOldInvoicePeriod = invoicePeriod
    .clone()
    .subtract(1, 'month')
    .format('YYYY-MM');

  const oneMonthOldInvoice = invoices.find(
    (invoice) => invoice.period === oneMonthOldInvoicePeriod,
  );

  const twoMonthsOldInvoicePeriod = invoicePeriod
    .clone()
    .subtract(2, 'month')
    .format('YYYY-MM');

  const twoMonthsOldInvoice = invoices.find(
    (invoice) => invoice.period === twoMonthsOldInvoicePeriod,
  );

  const isFirstInvoice = invoices.length === 1;

  // this is the first invoice for the user
  if (isFirstInvoice) {
    return 31;
  }

  // has no invoices in the previous two months
  if (!oneMonthOldInvoice && !twoMonthsOldInvoice) {
    return 45;
  }

  // has at least one invoice in each of the previous two months
  if (oneMonthOldInvoice && twoMonthsOldInvoice) {
    return 15;
  }

  // has at least one invoice for one of the previous two months
  if (oneMonthOldInvoice || twoMonthsOldInvoice) {
    return 31;
  }

  return 0;
};
*/

const getPaymentPeriod = (/* invoice, invoices */) => 45;

function InvoiceLabel(props) {
  const { invoices, invoice } = props;

  if (invoice.type !== 'creditNote') {
    return null;
  }

  const now = moment();
  const invoicePeriod = moment(invoice.period, 'YYYY-MM');
  const paymentPeriod = getPaymentPeriod(invoice, invoices);

  const fortyFiveDaysPaymentPeriodStart = moment('2019-07', 'YYYY-MM');
  const latestDateOfPayment = invoicePeriod
    .clone()
    .add(1, 'month')
    .startOf('month')
    .add(paymentPeriod, 'days');

  const daysUntilPayment = latestDateOfPayment.diff(now, 'days');
  const isPaid =
    invoicePeriod.isBefore(fortyFiveDaysPaymentPeriodStart) ||
    latestDateOfPayment.isBefore(now);

  return (
    <div className="invoice-label">
      <div
        className={cx('circle', {
          warning: !isPaid,
          success: isPaid,
        })}
      />

      <div className="invoice-label__text">
        {isPaid && (
          <FormattedMessage id="revenues.invoices.paid" defaultMessage="paid" />
        )}

        {!isPaid && (
          <FormattedMessage
            id="revenues.invoices.paidInXDays"
            defaultMessage="payment due in {days} days"
            values={{ days: daysUntilPayment }}
          />
        )}
      </div>
    </div>
  );
}

InvoiceLabel.propTypes = {
  invoice: PropTypes.shape({
    period: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      period: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
};

export default InvoiceLabel;
