import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import { injectD3LineGraph, d3LineGraphProps } from './d3Utils';

function D3AreaChart({
  className,
  scaleX,
  scaleY,
  domainX,
  domainY,
  rangeX,
  rangeY,
  width,
  height,
  curve,
  data,
  area,
  ...rest
}) {
  rangeY = rangeY || [height, 0];
  rangeX = rangeX || [0, width];
  const getX = scaleX().domain(domainX).range(rangeX);
  const getY = scaleY().domain(domainY).range(rangeY);
  const calculatePath = area()
    .x(([x, y]) => getX(x)) // eslint-disable-line no-unused-vars
    .y0(() => height)
    .y1(([x, y]) => getY(y)) // eslint-disable-line no-unused-vars
    .curve(curve);

  return (
    <g className={cx('area-chart', className)} {...rest}>
      <path className="area-chart__area" d={calculatePath(data)} />
    </g>
  );
}

D3AreaChart.propTypes = {
  ...d3LineGraphProps,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default injectD3LineGraph(D3AreaChart);
