import React from 'react';
import PropTypes from 'prop-types';

class ArrowButton extends React.PureComponent {
  onClick = (e) => {
    e.preventDefault();
    const { direction } = this.props;

    this.props.onClick(direction);
  };

  render() {
    const { icon, className } = this.props;
    return (
      <a href="#" onClick={this.onClick} className={className}>
        <svg className="icon">
          <use xlinkHref={`#icon-${icon}`} />
        </svg>
      </a>
    );
  }
}

ArrowButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ArrowButton;
