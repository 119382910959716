import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

/**
 * Renders a link, where it's content is a i18n `FormattedMessage`, as well as
 * it's `href` property can be managed via i18n
 */
function FormattedLink({
  messageId,
  href,
  hrefMessageId,
  rel,
  target,
  intl,
  className,
}) {
  const ref = hrefMessageId ? intl.formatMessage({ id: hrefMessageId }) : href;

  return (
    <a href={ref} rel={rel} target={target} className={className}>
      <FormattedMessage id={messageId} />
    </a>
  );
}

FormattedLink.propTypes = {
  messageId: PropTypes.string.isRequired,

  href: PropTypes.string,
  hrefMessageId: PropTypes.string,
  rel: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
};

FormattedLink.defaultProps = {
  href: undefined,
  hrefMessageId: undefined,
  className: undefined,
  rel: 'noopener noreferrer',
  target: '_blank',
};

export default injectIntl(FormattedLink);
