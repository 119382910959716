import React from 'react';
import { FormattedMessage } from 'react-intl';

function BrandClubsNoContent() {
  return (
    <div className="text-center m-t-3">
      <h5>
        <FormattedMessage
          id="brandClubs.noContent"
          defaultMessage="There are no Brand Clubs available right now."
        />
      </h5>
    </div>
  );
}

export default BrandClubsNoContent;
