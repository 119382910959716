
import { defineMessages } from 'react-intl';

export default defineMessages({
  faqLink: {
    id: 'faqLink',
    defaultMessage: 'https://eqolot.com/en/support/faq/',
  },
  termsAndConditionsLink: {
    id: 'termsAndConditionsLink',
    defaultMessage: 'https://eqolot.com/en/agb/blogger-influencer/'
  },
  captureInstagramInsightsHelpLink: {
    id: 'captureInstagramInsightsHelpLink',
    defaultMessage: 'https://link.blogfoster.com/r11nZz1fL',
  },
  captureTiktokInsightsHelpLink: {
    id: 'captureTiktokInsightsHelpLink',
    defaultMessage: 'https://link.blogfoster.com/r11nZz1fL',
  },
  connectOAuthHowItWorksLink: {
    id: 'connectOAuth.howItWorksLink',
    defaultMessage: 'https://link.eqolot.com/eUJyA7Ec3',
  },
  academyLink: {
    id: 'academyLink',
    defaultMessage: 'https://link.blogfoster.com/Hk9pzWvMr'
  },
  basePaymentPricingLink: {
    id: 'basePaymentPricingLink',
    defaultMessage: 'https://eqolot.com/en/academy/pricing-blogfoster-wie-bewerten-wir-die-performance-der-influencer'
  },
});
