import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { withRouter, routerShape } from 'react-router';

class ErrorBoundary extends Component {
  state = {};

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      this.props.router.push('/not-found');

      // Dummy return to not break rendering while redirecting
      return <div />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  router: routerShape,
};

export default withRouter(ErrorBoundary);
