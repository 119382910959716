import PropTypes from 'prop-types';
import React from 'react';
import range from 'lodash/range';
import cx from 'classnames';

function Spinner({ className, ...props }) {
  const numberOfCircles = 3;
  const circles = range(numberOfCircles).map((i) => (
    <circle cx="50%" cy="50%" r="50%" key={i} />
  ));

  return (
    <svg className={cx('spinner', className)} {...props}>
      {circles}
    </svg>
  );
}

Spinner.propTypes = {
  className: PropTypes.string,
};

export default Spinner;
