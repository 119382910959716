import get from 'lodash/get';

/**
 * Similar to combine reducers, except reducer slices are not nested under
 * their own keys, but are merged into a top-level state
 */
export const combineReducersFlat =
  (reducers, initialState = {}) =>
  (state = initialState, action) =>
    reducers.reduce((state, reduce) => reduce(state, action), state);

export const createReducer =
  (initialState, reducerMap) =>
  (state = initialState, action) => {
    const reducer = reducerMap[action.type];

    return reducer ? reducer(state, action.payload) : state;
  };

export const resetableReducer = (resetAction) => (reducer) => (state, action) =>
  action.type === resetAction
    ? reducer(undefined, action)
    : reducer(state, action);

export const withActions =
  (actions, initialState) => (reducer) => (state, action) => {
    // reset state to initial state
    if (state === undefined) {
      return initialState;
    }

    // if it's not the action we're listeing for, just return the state
    if (!actions.includes(action.type)) {
      return state;
    }

    // it's own action and not the initialiser action
    return reducer(state, action);
  };

/**
 * HoF which takes an object path and a selector, and yields a new selector
 * which treats the state slice corresponding to that path as the state root
 */
export const forSlice = (slicePath, selector) => (state, props) => {
  const slice = get(state, slicePath);

  if (slice === undefined) {
    throw new Error(`forSlice: No state slice at ${slicePath}`);
  }

  return selector(slice, props);
};
