import normalizeUrl from 'normalize-url';
import UrlParser from 'url-parse';
import validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import set from 'lodash/set';
import some from 'lodash/some';
import identity from 'lodash/identity';
import compact from 'lodash/compact';
import { asyncActionCreator } from '@blogfoster/redux-async-utils';

import { actionAuthRequest } from 'source/utils/axios';
import { isValidSocialMediaUrl } from 'source/utils/validators';

import {
  createApplication,
  updateApplication,
  updateApplicationContentPreview,
} from 'source/scenes/campaignDetails/components/CampaignDetails/actions';

import { submitProfile } from 'source/scenes/accountSettings/legacy/actions';

// @sean TODO This import couples the workflow component to a certain state / scene.
//       Ideally, form reducers and actions are decoupled from view reducers and actions
import { getCampaignForm } from '../../selectors';

// Actions

export const namespace = 'campaignDetails/workflow';

export const actionTypes = {
  WORKFLOW_FETCH: `${namespace}/WORKFLOW_FETCH`,
  FILE_UPLOAD: `${namespace}/FILE_UPLOAD`,
  TOAST_ERROR: `${namespace}/TOAST_ERROR`,
  FORM_INIT: `${namespace}/FORM_INIT`,
  FORM_CHANGE: `${namespace}/FORM_CHANGE`,
  FORM_SUBMIT: `${namespace}/FORM_SUBMIT`,
  FORM_ERROR: `${namespace}/FORM_ERROR`,
};

// Helpers

const getForm = (state, formName) => getCampaignForm(state)[formName];

const tasksUpdatePayload = (state, tasks, payload) =>
  tasks.map((task, index) => {
    const formName = `tasks-${payload.list}`;
    const form = getForm(state, formName);
    let completed = get(form, index, task.completed || false);

    if (index === payload.taskIndex) {
      completed = payload.checked;
    }

    return {
      ...task,
      completed,
    };
  });

const isInstagramUrl = (url) =>
  validator.isURL(url, {
    protocols: ['http', 'https'],
    require_protocol: true,
    host_whitelist: [
      'instagram.com',
      'www.instagram.com',
      'instagr.am',
      'www.instagr.am',
    ],
  });

const isTiktokUrl = (url) =>
  validator.isURL(url, {
    protocols: ['http', 'https'],
    require_protocol: true,
    host_whitelist: ['tiktok.com', 'www.tiktok.com'],
  });

const isPinterestUrl = (url) =>
  validator.isURL(url, {
    protocols: ['http', 'https'],
    require_protocol: true,
    host_whitelist: [
      'pinterest.com',
      'www.pinterest.com',
      'pinterest.de',
      'www.pinterest.de',
    ],
  });

const isYouTubeUrl = (url) =>
  validator.isURL(url, {
    protocols: ['http', 'https'],
    require_protocol: true,
    host_whitelist: [
      'youtube.com',
      'www.youtube.com',
      'youtu.be',
      'www.youtu.be',
    ],
  });

// Action creators

/**
 * This can be called with either just a campaignId or both a
 * campaignId and applicationId
 */
export const loadWorkflow = asyncActionCreator(
  actionTypes.WORKFLOW_FETCH,
  (campaignId, applicationId) => (dispatch, getState) =>
    actionAuthRequest(dispatch, getState, {
      dispatchErrors: false,
    }).get('/v2/workflows', {
      params: {
        filters: JSON.stringify(
          compact([
            campaignId && { campaignId },
            applicationId && { applicationId },
          ]),
        ),
      },
    }),
);

export const uploadFile = asyncActionCreator(
  actionTypes.FILE_UPLOAD,
  (file, onUploadProgress) => (dispatch, getState) => {
    const payload = new FormData();
    payload.append('file', file);

    return actionAuthRequest(dispatch, getState, {
      dispatchErrors: false,
      options: {
        timeout: 0, // set unlimited timeout
        onUploadProgress,
      },
    }).post('/v1/uploads', payload);
  },
);

const tasksSubmitController = (tasksList) => ({
  submit:
    (formName, { application }) =>
    (dispatch) => {
      const payload = {
        finishedTaskLists: uniq([tasksList, ...application.finishedTaskLists]),
      };

      return dispatch(updateApplication(application.id, payload));
    },
  // Component prevents submit on invalid input
  validate: () => true,
});

const formControllers = {
  application: {
    validate: (form) => {
      const errors = {};

      if (!form.pitch || !form.pitch.trim()) {
        errors.pitch = 'sponsoredPosts.errors.fieldMissing';
      }

      if (form.counterOfferActive && !form.counterOfferPrice) {
        errors.counterOfferPrice = 'sponsoredPosts.errors.fieldMissing';
      }

      return errors;
    },

    submit:
      (formName, { campaign }) =>
      (dispatch, getState) => {
        const form = getForm(getState(), formName);

        const payload = {
          campaignId: campaign.id,
          channelId: form.selectedChannelId,
          pitch: form.pitch,
        };

        if (form.counterOfferActive) {
          payload.counterOfferPrice = Number(form.counterOfferPrice);
        }

        return dispatch(createApplication(payload));
      },
  },

  accept: {
    validate: () => true, // Component disallows submit when state invalid
    submit:
      (formName, { application, workflow }) =>
      (dispatch, getState) => {
        const form = getForm(getState(), formName);

        let mission = {
          status: form.accept ? 'confirmed' : 'refused',
        };

        if (mission.status === 'confirmed') {
          mission = {
            ...mission,
            ...pick(form, [
              'acceptedTerms',
              'acceptedPublishingDate',
              'acceptedPublishingDateId',
            ]),
          };

          const workflowSteps = get(workflow, 'data.steps', []);
          const step = workflowSteps.find((step) => step.name === formName);
          const stepPayload = get(step, 'payload', {});

          if (stepPayload.additionalTerms) {
            mission.acceptedAdditionalTerms = form.acceptedAdditionalTerms;
            mission.additionalTerms = stepPayload.additionalTerms;
          }
        }

        return dispatch(updateApplication(application.id, { mission }));
      },
  },

  productShipment: {
    validate: (form) => {
      const errors = [
        'firstname',
        'lastname',
        'street',
        'streetNumber',
        'postalCode',
        'city',
        'country',
      ].reduce((memo, field) => {
        if (!form[field]) {
          memo[field] = 'sponsoredPosts.errors.fieldMissing';
        }
        return memo;
      }, {});

      return errors;
    },
    submit:
      (formName, { application }) =>
      (dispatch, getState) => {
        const form = getForm(getState(), formName);
        const { saveAddress } = form;

        const address = pick(form, [
          'firstname',
          'lastname',
          'street',
          'streetNumber',
          'postalCode',
          'city',
          'country',
        ]);

        if (saveAddress) {
          form.streetName = form.street;
          dispatch(submitProfile('profile', form));
        }

        const productShipment = { address };

        if (form.product) {
          productShipment.product = form.product;
        }

        return dispatch(updateApplication(application.id, { productShipment }));
      },
  },

  'tasks-preTasks': tasksSubmitController('preTasks'),

  'tasks-tasks': tasksSubmitController('tasks'),

  blogVerification: {
    validate: (form) => {
      const errors = {};

      if (!form || !form.article) {
        errors.article = 'sponsoredPosts.errors.fieldMissing';
      }

      if (!form || !form.title) {
        errors.title = 'sponsoredPosts.errors.fieldMissing';
      }

      return errors;
    },
    submit:
      (formName, { application }) =>
      (dispatch, getState) => {
        const form = getForm(getState(), formName);
        const payload = pick(form, ['article', 'title']);

        return dispatch(
          updateApplicationContentPreview(application.id, payload),
        );
      },
  },

  instagramVerification: {
    // Component prevents submission on invalid input
    validate: () => true,
    submit:
      (formName, { application }) =>
      (dispatch, getState) => {
        const form = getForm(getState(), formName);
        const payload = pick(form, ['caption', 'media']);

        return dispatch(
          updateApplicationContentPreview(application.id, payload),
        );
      },
  },

  tiktokVerification: {
    // Component prevents submission on invalid input
    validate: () => true,
    submit:
      (formName, { application }) =>
      (dispatch, getState) => {
        const form = getForm(getState(), formName);
        const payload = pick(form, ['caption', 'media']);

        return dispatch(
          updateApplicationContentPreview(application.id, payload),
        );
      },
  },

  pinterestVerification: {
    // Component prevents submission on invalid input
    validate: () => true,
    submit:
      (formName, { application }) =>
      (dispatch, getState) => {
        const form = getForm(getState(), formName);
        const payload = pick(form, ['caption', 'media']);

        return dispatch(
          updateApplicationContentPreview(application.id, payload),
        );
      },
  },

  youtubeVerification: {
    // Component prevents submission on invalid input
    validate: () => true,
    submit:
      (formName, { application }) =>
      (dispatch, getState) => {
        const form = getForm(getState(), formName);
        const payload = {
          caption: form.caption,
          media: [
            {
              type: 'video',
              url: form.videoUrl,
            },
          ],
        };

        return dispatch(
          updateApplicationContentPreview(application.id, payload),
        );
      },
  },

  'tasks-postTasks': tasksSubmitController('postTasks'),

  websitePublication: {
    validate: (form, { application }) => {
      const errors = {};

      const channelUrl = normalizeUrl(get(application, 'channel.data.url'));
      const channelHost = new UrlParser(channelUrl).host;

      const validatorUrlOptions = {
        protocols: ['http', 'https'],
        require_protocol: true,
      };
      const validatorHostOptions = { host_whitelist: [channelHost] };

      if (!form.url || form.url.trim() === '') {
        errors.url = 'sponsoredPosts.errors.fieldMissing';
      } else if (!validator.isURL(form.url, validatorUrlOptions)) {
        errors.url = 'sponsoredPosts.errors.fieldUrlNotValid';
      } else {
        // Create normalized `url` only to make sure host matching works,
        // => removing unnecessary 'www' for example which isn't handled well by validator
        // NOTE: Don't try to normalize before because trying to normalize
        // empty strings and invalid url's makes normalizeUrl throw an error
        const normalizedArticleUrl = normalizeUrl(form.url);
        if (!validator.isURL(normalizedArticleUrl, validatorHostOptions)) {
          errors.url = 'sponsoredPosts.errors.fieldUrlOtherHost';
        }
      }

      return errors;
    },
    submit:
      (formName, { application }) =>
      (dispatch, getState) => {
        const form = getForm(getState(), formName);
        const urlFormData = pick(form, ['url']);
        // Some users submit URLs with escape sequences so we decode the URL once
        // by default. We know this might not be 100% safe for all cases but we'll
        // fix any issues manually if we discover problems later on.
        const decodedUrl = decodeURIComponent(urlFormData.url);
        const payload = {
          contentPublication: {
            ...urlFormData,
            url: decodedUrl,
          },
        };

        return dispatch(updateApplication(application.id, payload));
      },
  },

  instagramPublication: {
    validate: (form) => {
      const errors = {};

      if (!form.url || !form.url.trim()) {
        errors.url = 'sponsoredPosts.errors.fieldUrlNotValid';
      } else if (!isInstagramUrl(form.url)) {
        errors.url = 'sponsoredPosts.errors.instagram.url.invalid';
      }

      return errors;
    },
    submit:
      (formName, { application }) =>
      (dispatch, getState) => {
        const form = getForm(getState(), formName);
        const payload = {
          contentPublication: pick(form, ['url']),
        };

        return dispatch(updateApplication(application.id, payload));
      },
  },

  tiktokPublication: {
    validate: (form) => {
      const errors = {};

      if (!form.url || !form.url.trim()) {
        errors.url = 'sponsoredPosts.errors.fieldUrlNotValid';
      } else if (!isTiktokUrl(form.url)) {
        errors.url = 'sponsoredPosts.errors.tiktok.url.invalid';
      }

      return errors;
    },
    submit:
      (formName, { application }) =>
      (dispatch, getState) => {
        const form = getForm(getState(), formName);
        const payload = {
          contentPublication: pick(form, ['url']),
        };

        return dispatch(updateApplication(application.id, payload));
      },
  },

  pinterestPublication: {
    validate: (form) => {
      const errors = {};

      if (!form.url || !form.url.trim()) {
        errors.url = 'sponsoredPosts.errors.fieldUrlNotValid';
      } else if (!isPinterestUrl(form.url)) {
        errors.url = 'sponsoredPosts.errors.pinterest.url.invalid';
      }

      return errors;
    },
    submit:
      (formName, { application }) =>
      (dispatch, getState) => {
        const form = getForm(getState(), formName);
        const payload = {
          contentPublication: pick(form, ['url']),
        };

        return dispatch(updateApplication(application.id, payload));
      },
  },

  youtubePublication: {
    validate: (form) => {
      const errors = {};

      if (!form.url || !form.url.trim()) {
        errors.url = 'sponsoredPosts.errors.fieldUrlNotValid';
      } else if (!isYouTubeUrl(form.url)) {
        errors.url = 'sponsoredPosts.errors.youtube.url.invalid';
      }

      return errors;
    },
    submit:
      (formName, { application }) =>
      (dispatch, getState) => {
        const form = getForm(getState(), formName);
        const payload = {
          contentPublication: pick(form, ['url']),
        };

        return dispatch(updateApplication(application.id, payload));
      },
  },

  socialMediaSharing: {
    validate: ({ data }) => {
      let errors = { data: [] };

      data.forEach(({ type, url, notApplicable }, index) => {
        const hasUrl = url && url.trim();

        if (!notApplicable && !hasUrl) {
          errors = set(
            errors,
            `data.[${index}].url`,
            'sponsoredPosts.errors.fieldMissing',
          );
        }

        if (!notApplicable && hasUrl && !isValidSocialMediaUrl(type, url)) {
          errors = set(
            errors,
            `data.[${index}].url`,
            `sponsoredPosts.errors.${type}.url.invalid`,
          );
        }
      });

      const someExist = (array) => some(array, identity);

      // Return empty object if there are no errors.
      return someExist(errors.data) ? errors : {};
    },
    submit:
      (formName, { application }) =>
      (dispatch, getState) => {
        const form = getForm(getState(), formName);

        const sanitizeSharingItem = ({ type, notApplicable, url }) =>
          notApplicable ? { type, notApplicable } : { type, url: url.trim() };

        const payload = {
          socialMediaSharing: form.data.map(sanitizeSharingItem),
        };

        return dispatch(updateApplication(application.id, payload));
      },
  },

  afterPublicationUploads: {
    // Component prevents submission on invalid input
    validate: () => true,
    submit:
      (formName, { application }) =>
      (dispatch, getState) => {
        const form = getForm(getState(), formName);
        const payload = {
          afterPublicationUploads: pick(form, ['screenshots']),
        };

        return dispatch(updateApplication(application.id, payload));
      },
  },
};

const formActions = {
  tasks: {
    updateTask:
      (formName, task, { application }) =>
      (dispatch, getState) => {
        const payload = {
          tasks: tasksUpdatePayload(getState(), application.tasks, task),
        };

        return dispatch(updateApplication(application.id, payload));
      },
  },
};

export const formInit = ({ campaign, matches, application, user }) => ({
  type: actionTypes.FORM_INIT,
  payload: { campaign, matches, application, user },
});

export const formChange = (form, attr, value) => ({
  type: actionTypes.FORM_CHANGE,
  payload: { form, attr, value },
});

export const formError = (form, errors) => ({
  type: actionTypes.FORM_ERROR,
  payload: { form, errors },
});

export const formValidate =
  (formName, injections = {}) =>
  (dispatch, getState) => {
    const controller = formControllers[formName];

    if (!controller || !controller.validate) {
      throw new Error(`validation for form ${formName} is not implemented`);
    }

    const { validate } = controller;
    const campaignForm = getCampaignForm(getState());

    const errors = validate(campaignForm[formName], injections);

    if (!isEmpty(errors)) {
      return Promise.reject(errors);
    }
    return Promise.resolve();
  };

export const formSubmit =
  (formName, injections = {}) =>
  (dispatch) => {
    const controller = formControllers[formName];

    if (!controller || !controller.submit) {
      throw new Error(`submit for form ${formName} is not implemented`);
    }
    const { submit } = controller;

    return dispatch(submit(formName, injections));
  };

export const formAction = (formName, actionName, payload, injections = {}) => {
  const action = get(formActions, [formName, actionName]);

  if (!action) {
    throw new Error(
      `custom action ${actionName} for form ${formName} is not implemented`,
    );
  }

  return action(formName, payload, injections);
};

export const toastError = (toastErr) => ({
  type: actionTypes.TOAST_ERROR,
  payload: toastErr,
});
