import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { FormattedMessage, defineMessage } from 'react-intl';
import { Link } from 'react-router';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import OutsideClickHandler from 'react-outside-click-handler';

import { injectTheme, themeProps } from 'source/utils/theme';
import { Sidebar, SidebarTrigger } from 'source/components/app';
import Icon from 'source/components/common/icon';
import { SvgComponents, SvgGradients } from 'source/components/common';
import ProfileCheck from 'source/components/app/profileCheck';
import SupportButton from 'source/components/support/supportButton';
import { isSmallWindow } from 'source/utils/window';
import ErrorBoundary from 'source/utils/ErrorBoundary';

import {
  toggleNavigation,
  toggleUserSettings,
  loadApplicationState,
} from 'source/actions/application';

import { AppProvider } from './appContext';

const pages = {
  dashboard: {
    title: defineMessage({
      id: 'header.dashboard.topic',
      defaultMessage: 'Dashboard',
    }),
  },
  newWebsite: {
    title: defineMessage({
      id: 'header.newWebsite.topic',
      defaultMessage: 'Create New Website',
    }),
  },
  blogProfile: {
    title: defineMessage({
      id: 'header.blogProfile.topic',
      defaultMessage: 'Blog Profile',
    }),
  },
  insights: {
    title: defineMessage({
      id: 'header.insights.topic',
      defaultMessage: 'Insights',
    }),
  },
  brandClubs: {
    title: defineMessage({
      id: 'header.brandClubs.topic',
      defaultMessage: 'Brand Clubs',
    }),
  },
  sponsoredPosts: {
    title: defineMessage({
      id: 'header.sponsoredPosts.topic',
      defaultMessage: 'Campaigns',
    }),
  },
  revenues: {
    title: defineMessage({
      id: 'header.revenues.topic',
      defaultMessage: 'Revenues',
    }),
  },
  invoices: {
    title: defineMessage({
      id: 'header.invoices.topic',
      defaultMessage: 'Invoices',
    }),
  },
  tools: {
    title: defineMessage({
      id: 'header.tools.topic',
      defaultMessage: 'Tools',
    }),
  },
  accountSettings: {
    title: defineMessage({
      id: 'header.accountSettings.topic',
      defaultMessage: 'Account Settings',
    }),
    withLogout: true,
  },
  insightsV2: {
    title: defineMessage({
      id: 'header.insightsV2.topic',
      defaultMessage: 'Insights',
    }),
  },
  profile: {
    title: defineMessage({
      id: 'header.profile.topic',
      defaultMessage: 'Your Profile',
    }),
  },
};

class App extends Component {
  // eslint-disable-next-line react/sort-comp
  scrollIntoView = (element) => {
    element.scrollIntoView(true);
    // Scroll the content container up so that the element is visible right
    // underneath the navigation bar
    setTimeout(() => this.contentRef.current.scrollBy(0, -70), 0);
  };

  state = {
    appContext: { scrollIntoView: this.scrollIntoView },
  };

  componentDidMount() {
    this.props.loadApplicationState();
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  contentRef = React.createRef();

  sidebarRef = React.createRef();

  sidebarTriggerRef = React.createRef();

  handleClick = (e) => {
    const hasModifiers = e.metaKey || e.shiftKey || e.altKey || e.ctrlKey;

    if (hasModifiers || e.button !== 1) return;

    e.preventDefault();
  };

  handleToggleNavigation = (e) => {
    this.handleClick(e);

    this.dispatchToggleNavigation();
  };

  dispatchToggleNavigation() {
    const { toggleNavigation } = this.props;

    if (this.sidebarRef && this.sidebarRef.current) {
      const { state: appState } = this.props;
      const newSidebarCollapsed = !appState.sidebar.collapsed;
      if (newSidebarCollapsed) {
        enableBodyScroll(this.sidebarRef.current);
      } else {
        disableBodyScroll(this.sidebarRef.current);
      }
    }

    toggleNavigation();
  }

  _resolveRouteName(children) {
    let currentRoute = children.props.route;

    // Context is a high-level component that does set the current website and renders it's children
    // need to resolve it's siblings to get matched route value.
    if (currentRoute.name === 'context') {
      currentRoute = children.props.children.props.route;
    }

    return currentRoute.name;
  }

  handleSidebarClickOutside = (event) => {
    const {
      state: { sidebar },
    } = this.props;

    if (sidebar.collapsed) {
      return;
    }

    const exceptions = [this.sidebarTriggerRef];
    if (
      !_.some(
        exceptions,
        (exceptionRef) =>
          exceptionRef.current && exceptionRef.current.contains(event.target),
      )
    ) {
      this.handleToggleNavigation(event);
    }
  };

  renderSectionTitle() {
    const routeName = this._resolveRouteName(this.props.children);
    const page = pages[routeName];

    if (!page) {
      return <span />;
    }

    return (
      <div className="section-title">
        <FormattedMessage {...page.title}>
          {(txt) => <h1>{txt}</h1>}
        </FormattedMessage>
        {page.withLogout && (
          <Link to="/signout">
            <Icon name="logout" />
            <FormattedMessage id="logout" defaultMessage="Logout" />
          </Link>
        )}
      </div>
    );
  }

  renderContent() {
    const {
      children,
      theme: { themeClass, viewClass },
      state: { sidebar, user },
    } = this.props;
    const routeName = this._resolveRouteName(this.props.children);
    const className = cx('main', themeClass, viewClass, {
      'main-shifted': !sidebar.collapsed,
    });

    const showProfileCheck = !user.hasChannels || !user.confirmed;

    return (
      <AppProvider value={this.state.appContext}>
        <section className={className} ref={this.contentRef}>
          <div className="main-section">
            {this.renderSectionTitle()}
            {routeName !== 'profile' && showProfileCheck && (
              <ProfileCheck
                className="profile-check"
                userHasChannels={user.hasChannels}
                userIsConfirmed={user.confirmed}
              />
            )}
            {children}
          </div>
        </section>
      </AppProvider>
    );
  }

  renderApp() {
    const {
      state: { sidebar },
    } = this.props;

    return (
      <ErrorBoundary>
        <OutsideClickHandler
          onOutsideClick={this.handleSidebarClickOutside}
          disabled={sidebar.collapsed || !isSmallWindow()}
        >
          <Sidebar
            ref={this.sidebarRef}
            theme={{ themeClass: 'bf-plain' }}
            collapsed={sidebar.collapsed}
            toggle={this.handleToggleNavigation}
          />
        </OutsideClickHandler>

        {this.renderContent()}

        <div className="bf-plain">
          <SidebarTrigger
            ref={this.sidebarTriggerRef}
            onClick={this.handleToggleNavigation}
            isActive={!sidebar.collapsed}
          />
          <SupportButton />
          <SvgComponents />
          <SvgGradients />
        </div>
      </ErrorBoundary>
    );
  }

  render() {
    const {
      children,
      state: { stateLoaded },
    } = this.props;

    return stateLoaded && children ? this.renderApp() : null;
  }
}

App.contextTypes = {
  router: PropTypes.any,
};

App.propTypes = {
  ...themeProps,
  // eslint-disable-next-line react/no-unused-prop-types
  auroraView: PropTypes.string,
  state: PropTypes.shape({
    stateLoaded: PropTypes.bool,
    sidebar: PropTypes.shape({
      collapsed: PropTypes.bool.isRequired,
    }),
    usersettings: PropTypes.shape({
      visible: PropTypes.bool.isRequired,
    }),
    user: PropTypes.object,
  }).isRequired,
  children: PropTypes.any,
  toggleNavigation: PropTypes.func.isRequired,
  toggleUserSettings: PropTypes.func.isRequired,
  loadApplicationState: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  state: state.application,
  noPadding: false,
});

const mapDispatchToProps = {
  toggleNavigation,
  toggleUserSettings,
  loadApplicationState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectTheme(App));
