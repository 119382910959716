import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

function Icon({ name, className, onClick }) {
  className = classNames('icon', `icon-${name}`, className);

  return (
    <svg className={className} onClick={onClick}>
      <use xlinkHref={`#icon-${name}`} />
    </svg>
  );
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  className: '',
  onClick: () => {},
};

export default Icon;
