import omit from 'lodash/omit';
import pick from 'lodash/pick';

import { createReducer } from 'source/utils';
import constants from 'source/constants';

const legacyInitialState = {
  currentTab: -1,
  stateLoaded: false,
  data: null,

  profile: {
    errors: {},
    saveStatus: 'default',
    message: null,

    language: null,
    country: null,
  },
  changePassword: {
    errors: {},
    saveStatus: 'default',
    message: null,

    currentPassword: '',
    newPassword: '',
    repeatPassword: '',
  },
  deleteAccount: {
    errors: {},
    saveStatus: 'default',
    message: null,

    showConfirm: false,
    reason: '',
  },
  payment: {
    errors: {},
    saveStatus: 'default',
    message: null,

    paymentDataNotProvided: true,
    accountType: 'private',
    owner: '',
    iban: '',
    ibanFromServer: false,
    bic: '',
    bicFromServer: false,
    firstname: '',
    lastname: '',
    street: '',
    number: '',
    city: '',
    postalCode: '',
    country: 'DE',
    name: '',
    vat: '',
  },
  notifications: {
    errors: {},
    saveStatus: 'default',
    message: null,

    recommendedCampaignEmails: null,
    promotionalEmails: null,
  },
};

const mapPayloadToProfile = (form, id, payload) =>
  pick(payload, [
    'phone',
    'language',
    'yearOfBirth',
    'salutation',
    'firstname',
    'lastname',
    'streetName',
    'streetNumber',
    'postalCode',
    'city',
    'country',
  ]);

const mapPayloadToForm = (form, id, payload) => {
  const mappers = {
    profile: mapPayloadToProfile,
  };

  const mapFn = mappers[id];
  if (!mapFn) {
    return form;
  }

  return mapFn(form, id, payload);
};

const actionHandlers = {
  [constants.ACCOUNT_SETTINGS_TAB_CHANGED]: (state, index) => ({
    ...state,
    currentTab: index,
  }),

  [constants.ACCOUNT_SETTINGS_FORM_RESET]: (state) => {
    const initialState = legacyInitialState;

    return {
      ...state,
      changePassword: initialState.changePassword,
      deleteAccount: initialState.deleteAccount,
      payment: initialState.payment,
      notifications: initialState.notifications,
    };
  },

  [constants.ACCOUNT_SETTINGS_FORM_ERRORS]: (state, { form, errors }) => ({
    ...state,
    [form]: { ...state[form], errors },
  }),

  [constants.ACCOUNT_SETTINGS_FORM_CHANGE]: (state, { form, field, value }) => {
    const formOverrides = {};

    // in case of IBAN or BIC fields are changing, we reseting masked flags, so values
    // are re-validated and re-submitted to server..
    if (form === 'payment' && field === 'iban') {
      formOverrides.ibanFromServer = false;
    }

    if (form === 'payment' && field === 'bic') {
      formOverrides.bicFromServer = false;
    }

    return {
      ...state,
      [form]: {
        ...state[form],
        ...formOverrides,
        [field]: value,
        errors: omit(state[form].errors, [field]),
        saveStatus: 'default',
      },
    };
  },

  [constants.ACCOUNT_SETTINGS_ALERT_CLEAR]: (state, form) => ({
    ...state,
    [form]: { ...state[form], message: null },
  }),

  [constants.ACCOUNT_SETTINGS_PASSWORD_CHANGE_APPLIED_SUCCESSFULLY]: (
    state,
  ) => {
    const message = {
      type: 'success',
      textId: 'accountSettings.changePassword.successPasswordChanged',
    };

    return { ...state, changePassword: { ...state.changePassword, message } };
  },

  [constants.ACCOUNT_SETTINGS_PASSWORD_CHANGE_FAILED]: (
    state,
    { error, response },
  ) => {
    let errorTextId =
      'accountSettings.changePassword.errorGenericChangePassword';

    const messages = {
      'password-match-failed':
        'accountSettings.changePassword.errorPasswordMatchFailed',
    };

    if (response && response.code && messages[response.code]) {
      errorTextId = messages[response.code];
    }

    const changePassword = {
      ...state.changePassword,
      message: { type: 'error', textId: errorTextId },
      error,
    };

    return { ...state, changePassword };
  },

  [constants.ACCOUNT_SETTINGS_DELETE_ACCOUNT_FAILED]: (
    state,
    { error, response },
  ) => {
    let textId = 'accountSettings.deleteAccount.error.generic';

    if (response.statusCode === 409) {
      textId = 'accountSettings.deleteAccount.error.permissionDenied';
    }

    const message = {
      type: 'error',
      textId,
    };

    return {
      ...state,
      deleteAccount: { ...state.deleteAccount, message, error },
    };
  },

  [constants.ACCOUNT_SETTINGS_DELETE_ACCOUNT_TOGGLE_CONFIRM]: (state) => {
    const deleteAccount = {
      ...state.deleteAccount,
      showConfirm: !state.deleteAccount.showConfirm,
    };

    return { ...state, deleteAccount };
  },

  [constants.ACCOUNT_SETTINGS_PAYMENT_DATA_SAVING]: (state) => ({
    ...state,
    payment: { ...state.payment, saveStatus: 'saving' },
  }),

  [constants.ACCOUNT_SETTINGS_PAYMENT_DATA_SAVED]: (state) => ({
    ...state,
    payment: { ...state.payment, saveStatus: 'saved' },
  }),

  [constants.ACCOUNT_SETTINGS_PAYMENT_DATA_SAVE_FAILED]: (state, { error }) => {
    const message = {
      type: 'error',
      textId: 'errors.message.generic',
    };

    return {
      ...state,
      payment: { ...state.payment, saveStatus: 'default', message, error },
    };
  },

  [constants.ACCOUNT_SETTINGS_NOTIFICATIONS_UPDATE_FAILED]: (
    state,
    { error },
  ) => {
    const message = {
      type: 'error',
      textId: 'errors.message.generic',
    };

    return {
      ...state,
      notifications: {
        ...state.notifications,
        saveStatus: 'default',
        message,
        error,
      },
    };
  },

  [constants.ACCOUNT_SETTINGS_FORM_SUBMITTING]: (state, { id }) => ({
    ...state,
    [id]: {
      ...state[id],
      saveStatus: 'saving',
    },
  }),

  [constants.ACCOUNT_SETTINGS_FORM_SUBMITTED]: (state, { id, payload }) => ({
    ...state,
    [id]: {
      ...state[id],
      ...mapPayloadToForm(state[id], id, payload),
      saveStatus: 'saved',
    },
  }),

  [constants.ACCOUNT_SETTINGS_FORM_SUBMITTING_FAILED]: (
    state,
    { id, error },
  ) => {
    const message = {
      type: 'error',
      textId: 'errors.message.generic',
    };

    return {
      ...state,
      [id]: {
        ...state[id],
        saveStatus: 'default',
        message,
        error,
      },
    };
  },
};

const legacyReducer = createReducer(legacyInitialState, actionHandlers);

export default legacyReducer;
