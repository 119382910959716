import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

const genericError = 'errors.message.generic';

function Notification({ className, error, onErrorClose }) {
  if (!error) {
    return null;
  }

  const { translatedError } = error;

  return (
    <div className={cx('notification-container', className)}>
      <div className="notification">
        <FormattedMessage
          id={translatedError ? translatedError.id : genericError}
        />

        <button onClick={onErrorClose}>
          <FormattedMessage id="errors.controls.close" defaultMessage="Close" />
        </button>
      </div>
    </div>
  );
}

Notification.propTypes = {
  className: PropTypes.string,
  error: PropTypes.object,
  onErrorClose: PropTypes.func,
};

export default Notification;
