import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { messageProp } from 'source/utils/propTypes';
import forms from 'source/utils/forms';
import { SaveProgressButton } from 'source/components/common';

// No need to validate checkboxes
const validateForm = () => ({});

class NotificationsForm extends Component {
  render() {
    const {
      id,
      form: { saveStatus, recommendedCampaignEmails, promotionalEmails },
      onChange,
      onSubmit,
    } = this.props;

    return (
      <div>
        <form id={id}>
          <h4 className="headline-form">
            <FormattedMessage
              id="accountSettings.notifications.email"
              defaultMessage="Email Notifications"
            />
          </h4>

          <fieldset className="form-group">
            <label htmlFor="recommendedCampaignEmails">
              <div>
                <input
                  type="checkbox"
                  id="recommendedCampaignEmails"
                  checked={recommendedCampaignEmails}
                  onChange={onChange}
                />

                <FormattedMessage
                  id="accountSettings.notifications.recommendedCampaigns"
                  defaultMessage="Campaign recommendations and user assistance"
                />
              </div>
              <em>
                <FormattedMessage
                  id="accountSettings.notifications.recommendedCampaignsDescription"
                  defaultMessage="e.g. new or recommended campaigns that may interest you, information about connecting your channels"
                />
              </em>
            </label>

            <label htmlFor="promotionalEmails">
              <div>
                <input
                  type="checkbox"
                  id="promotionalEmails"
                  checked={promotionalEmails}
                  onChange={onChange}
                />

                <FormattedMessage
                  id="accountSettings.notifications.promotionalEmails"
                  defaultMessage="E-mails about updates, news, & events"
                />
              </div>
              <em>
                <FormattedMessage
                  id="accountSettings.notifications.promotionalEmailsDescription"
                  defaultMessage="e.g. platform updates, changes in pricing, events and invitations, surveys"
                />
              </em>
            </label>
          </fieldset>

          <SaveProgressButton onClick={onSubmit} status={saveStatus} />
        </form>
      </div>
    );
  }
}

NotificationsForm.propTypes = {
  id: PropTypes.string.isRequired,

  form: PropTypes.shape({
    recommendedCampaignEmails: PropTypes.bool.isRequired,
    promotionalEmails: PropTypes.bool.isRequired,
    message: messageProp,
    saveStatus: PropTypes.string.isRequired,
  }).isRequired,

  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default forms({ validateForm })(NotificationsForm);
