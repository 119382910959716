import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Link } from 'react-router';

function BrandClubsCard({
  brandClub: {
    id,
    isNew,
    translationKey,
    theme: { className, logoUrl, previewUrl } = {},
  },
}) {
  return (
    <Link to={`/brand-clubs/${id}`}>
      <div className={cx('brand-card row', className)}>
        <div className="col-lg-4">
          <div className="brand-card-splash">
            <img src={previewUrl} alt="" />
          </div>
        </div>
        <div className="col-lg-4">
          <div className="brand-card-details">
            <img className="club-logo" src={logoUrl} alt="" />
            {isNew && (
              <h5>
                <FormattedMessage
                  id="brandClubs.card.new"
                  defaultMessage="NEW!"
                />
              </h5>
            )}
            <h5>
              <FormattedMessage id={`brandClubs.${translationKey}.name`} />
            </h5>
            <div className="description">
              <FormattedMessage
                id={`brandClubs.${translationKey}.description`}
              />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

BrandClubsCard.propTypes = {
  brandClub: PropTypes.shape({
    id: PropTypes.string.isRequired,
    translationKey: PropTypes.string.isRequired,
    theme: PropTypes.shape({
      className: PropTypes.string.isRequired,
      logoUrl: PropTypes.string.isRequired,
      previewUrl: PropTypes.string.isRequired,
    }).isRequired,
    isNew: PropTypes.bool,
  }).isRequired,
};

export default BrandClubsCard;
