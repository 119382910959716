import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

function FormSummary({ className, data, ...props }) {
  className = classNames('form-summary', className);

  const items = data.map((item) => (
    <li className="form-summary-item" key={item.name}>
      <strong className="form-summary-title">{item.name}</strong>
      <span className="form-summary-content">{item.value}</span>
    </li>
  ));

  return (
    <ul className={className} {...props}>
      {items}
    </ul>
  );
}

FormSummary.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};

export default FormSummary;
