import PropTypes from 'prop-types';
import React, { cloneElement } from 'react';
import cx from 'classnames';

const dataType = PropTypes.arrayOf(PropTypes.object);
const columnsType = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    field: PropTypes.string,
    bodyElement: PropTypes.element,
    headerElement: PropTypes.element,
  }),
);

function Table({ columns, data, className, headless, ...props }) {
  className = cx('table', className);

  return (
    <table className={className} {...props}>
      {headless ? null : <TableHeader columns={columns} />}
      <TableBody columns={columns} data={data} />
    </table>
  );
}

Table.propTypes = {
  columns: columnsType,
  data: dataType,
  className: PropTypes.string,
  headless: PropTypes.bool,
};

Table.defaultProps = {
  columns: [],
  data: [],
  headless: false,
};

function TableHeader({ columns }) {
  const cells = columns.map(({ name, headerElement }, key) => {
    if (headerElement == null) {
      headerElement = <TableHeaderCell />;
    }

    return cloneElement(headerElement, {
      data: name,
      key,
    });
  });

  return (
    <thead>
      <TableRow>{cells}</TableRow>
    </thead>
  );
}

TableHeader.propTypes = {
  columns: columnsType,
};

TableHeader.defaultProps = {
  columns: [],
};

function TableBody({ columns, data }) {
  const rows = data.map((rowData, rowIndex) => {
    const cells = columns.map(({ field, bodyElement }, key) => {
      // Look for function returning a cell data vs a direct object field.
      const cellData = field != null ? rowData[field] : rowData;

      if (bodyElement == null) {
        bodyElement = <TableCell />;
      }

      return cloneElement(bodyElement, {
        data: cellData,
        key,
      });
    });

    // The table rows are not going to get reordered, removed, or filtered.
    // eslint-disable-next-line react/no-array-index-key
    return <TableRow key={rowIndex}>{cells}</TableRow>;
  });

  return <tbody>{rows}</tbody>;
}

TableBody.propTypes = {
  columns: columnsType,
  data: dataType,
};

TableBody.defaultProps = {
  columns: [],
  data: [],
};

function TableRow({ children }) {
  return <tr>{children}</tr>;
}

TableRow.propTypes = {
  children: PropTypes.any,
};

export const TableCell = ({ data, element, ...props }) =>
  cloneElement(element, {
    children: data,
    ...props,
  });

TableCell.propTypes = {
  element: PropTypes.element.isRequired,
  data: PropTypes.any,
};

TableCell.defaultProps = {
  element: <td />,
};

export function TableHeaderCell(props) {
  return <TableCell element={<th />} {...props} />;
}

export default Table;
