import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';

import { FormError, Icon, Tooltip } from 'source/components/common';

import { fieldInputPropTypes, fieldMetaPropTypes } from './propTypes';

class TooltipToggle extends React.Component {
  state = {
    showTooltip: false,
  };

  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState(({ showTooltip }) => ({ showTooltip: !showTooltip }));
  };

  render() {
    return (
      <span className="bf-tooltip-toggle">
        <button type="button" className="btn-icon" onClick={this.handleClick}>
          <Icon name="ask" />
        </button>
        <Tooltip visible={this.state.showTooltip}>
          <FormattedMessage id={this.props.messageId} />
        </Tooltip>
      </span>
    );
  }
}

TooltipToggle.propTypes = {
  messageId: PropTypes.string.isRequired,
};

TooltipToggle.defaultProps = {};

function Input(props) {
  const label = props.tooltipId ? (
    <label
      className="payment-form-label"
      htmlFor={`paymentForm-${props.input.name}`}
    >
      <FormattedMessage id={props.labelId} />{' '}
      <TooltipToggle messageId={props.tooltipId} />:
    </label>
  ) : (
    <label
      className="payment-form-label"
      htmlFor={`paymentForm-${props.input.name}`}
    >
      <FormattedMessage id={props.labelId} />:
    </label>
  );

  const placeholder = props.placeholderId
    ? props.intl.formatMessage({ id: props.placeholderId })
    : undefined;

  return (
    <fieldset className="form-group">
      <input
        {...props.input}
        id={`paymentForm-${props.input.name}`}
        className="form-control"
        disabled={props.disabled}
        placeholder={placeholder}
      />
      {label}
      <div className="error-block">
        {props.meta.error && (
          <FormError error={{ messageId: props.meta.error }} />
        )}
      </div>
    </fieldset>
  );
}

Input.propTypes = {
  labelId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  tooltipId: PropTypes.string,
  placeholderId: PropTypes.string,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
};

Input.defaultProps = {
  disabled: false,
  tooltipId: undefined,
  placeholderId: undefined,
};

const InputEnhanced = injectIntl(Input);

function Select(props) {
  return (
    <fieldset className="form-group payment-form-select">
      <div className="select-group">
        <label htmlFor={`paymentForm-${props.input.name}`}>
          <FormattedMessage id={props.labelId} />:
        </label>
        <select
          {...props.input}
          id={`paymentForm-${props.input.name}`}
          className="form-control"
        >
          {props.children}
        </select>
      </div>
      {props.meta.error && (
        <div className="error-block">
          <FormError error={{ messageId: props.meta.error }} />
        </div>
      )}
    </fieldset>
  );
}

Select.propTypes = {
  labelId: PropTypes.string.isRequired,
  children: PropTypes.any,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
};

Select.defaultProps = {
  children: null,
};

function Checkbox(props) {
  return (
    <fieldset className="form-group payment-form-checkboxes">
      <div className="checkbox-group">
        <input
          {...props.input}
          type="checkbox"
          checked={Boolean(props.input.value)}
          id={`paymentForm-${props.input.name}`}
        />
        <label
          htmlFor={`paymentForm-${props.input.name}`}
          className="confirmation"
        >
          <FormattedMessage id={props.labelId} />
        </label>
      </div>
      {props.meta.error && (
        <div className="error-block">
          <FormError error={{ messageId: props.meta.error }} />
        </div>
      )}
    </fieldset>
  );
}

Checkbox.propTypes = {
  labelId: PropTypes.string.isRequired,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
};

/* eslint-disable jsx-a11y/label-has-for */
function Choices(props) {
  return (
    <fieldset className="form-group payment-form-choices">
      <div className="choices-group">
        <label>
          <FormattedMessage id={props.labelId} />
        </label>
        <div className="btn-group choices" data-toggle="button">
          {props.choices.map(({ nameId, value }) => (
            <label
              key={`choice-${value}`}
              className={cx('btn btn-secondary', {
                active: props.input.value === value,
              })}
            >
              <input
                {...props.input}
                type="radio"
                value={value}
                checked={props.input.value === value}
              />
              <FormattedMessage id={nameId} />
            </label>
          ))}
        </div>
      </div>
      {props.meta.error && (
        <div className="error-block">
          <FormError error={{ messageId: props.meta.error }} />
        </div>
      )}
    </fieldset>
  );
}
/* eslint-enable jsx-a11y/label-has-for */

Choices.propTypes = {
  labelId: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      nameId: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
};

export { InputEnhanced as Input, Select, Checkbox, Choices };
