import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import lowercase from 'lodash/lowerCase';
import uppercase from 'lodash/upperCase';
import noop from 'lodash/noop';

import { getSignupCountries } from 'source/i18n/signupCountries.js';

const casingToTransform = {
  lowercase,
  uppercase,
};

function CountrySelection({
  intl,
  id,
  value,
  emptyOption = false,
  casing = 'lowercase',
  onChange = noop,
  className,
}) {
  const transformCase = casingToTransform[casing];
  const countries = getSignupCountries(intl.locale);

  return (
    <select id={id} className={className} value={value} onChange={onChange}>
      {emptyOption && (
        <option key="empty" value="-">
          {intl.formatMessage({
            id: 'options.empty.text',
            defaultMessage: 'Please select',
          })}
        </option>
      )}

      {countries.map(({ code, name }) => (
        <option key={code} value={transformCase(code)}>
          {name[intl.locale]}
        </option>
      ))}
    </select>
  );
}

CountrySelection.defaultProps = {
  emptyOption: false,
};

CountrySelection.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  casing: PropTypes.oneOf(['lowercase', 'uppercase']),
  emptyOption: PropTypes.bool,
  onChange: PropTypes.func,
};

CountrySelection.defaultProps = {
  className: '',
};

export default injectIntl(CountrySelection);
