import PropTypes from 'prop-types';
import React, { Component, Children, cloneElement } from 'react';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import noop from 'lodash/noop';
import NumberFormat from 'react-number-format';

import { numberFormats } from 'source/i18n';

import Icon from '../icon';
import customFileInput from './fileInput';

const idType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

function FormColumn({ column, children }) {
  const child = Children.only(children);

  if (column) {
    return <div className="col-xs-6">{child}</div>;
  }

  return child;
}

FormColumn.propTypes = {
  column: PropTypes.bool.isRequired,
  children: PropTypes.element,
};

FormColumn.defaultProps = {
  column: true,
  children: null,
};

// Looks strange, but its basically a super component for all form controls.
// So we need an element property instead a set of children.
function FormControl({ element, className, code, danger, column, ...props }) {
  className = cx(
    'form-control',
    {
      'form-control-code': code,
      'form-control-danger': danger,
    },
    element.props.className,
    className,
  );

  const control = cloneElement(element, {
    className,
    ...props,
  });

  return <FormColumn column={column}>{control}</FormColumn>;
}

FormControl.propTypes = {
  id: idType.isRequired,
  code: PropTypes.bool,
  danger: PropTypes.bool,
  className: PropTypes.string,
  element: PropTypes.element.isRequired,
  column: PropTypes.bool,
  onChange: PropTypes.func,
};

FormControl.defaultProps = {
  column: false,
  code: false,
  danger: false,
  className: '',
  onChange: noop,
};

const injectFormControl = (element) =>
  function (props) {
    return <FormControl element={element} {...props} />;
  };

export const Textarea = injectFormControl(<textarea />);
export const Input = injectFormControl(<input />);
export const PasswordInput = injectFormControl(<input type="password" />);
export const EmailInput = injectFormControl(<input type="email" />);
export const NumberInput = injectFormControl(<input type="number" />);
export const FileInput = customFileInput;
export const Select = injectFormControl(<select />);

export const NumberFormatInput = injectIntl(({ intl, id, value, ...props }) => {
  const inputRef = React.useRef();

  const onValueChange = React.useCallback(
    ({ value }) => {
      if (inputRef.current) {
        const currentValue = inputRef.current.value;
        inputRef.current.value = value;

        const event = new Event('change', { bubbles: true });
        const tracker = inputRef.current._valueTracker;

        if (tracker) {
          tracker.setValue(currentValue);
        }

        inputRef.current.dispatchEvent(event);
      }
    },
    [inputRef],
  );

  return (
    <>
      <FormControl
        id={`NumberFormat_${id}`}
        element={
          <NumberFormat
            {...numberFormats[intl.locale]}
            isNumericString
            decimalScale={2}
            value={value}
            onValueChange={onValueChange}
          />
        }
        {...props}
      />
      <input id={id} ref={inputRef} hidden />
    </>
  );
});

export function Checkbox({
  className,
  children,
  column,
  hasDanger,
  disabled,
  ...props
}) {
  className = cx('checkbox', { 'has-danger': hasDanger, disabled }, className);

  return (
    <FormColumn column={column}>
      <div className={className}>
        <label htmlFor={props.id}>
          <input type="checkbox" disabled={disabled} {...props} />
          <Icon name="aurora-check" className={cx({ disabled })} />
          {children}
        </label>
      </div>
    </FormColumn>
  );
}

Checkbox.propTypes = {
  id: idType.isRequired,
  className: PropTypes.string,
  children: PropTypes.any,
  column: PropTypes.bool.isRequired,
  hasDanger: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  column: false,
  hasDanger: false,
  disabled: false,
  onChange: noop,
};

export class ButtonSwitchGroup extends Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    children: PropTypes.any,
    column: PropTypes.bool.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    onChange: noop,
    column: false,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      value: props.defaultValue,
    };
  }

  handleClick = (value) => {
    this.props.onChange(value);
    this.setState({ value });
  };

  render() {
    const { children, column, className, ...props } = this.props;
    const { value } = this.state;

    const buttons = Children.map(children, (child) => {
      const { value: buttonValue, ...props } = child.props;

      const handleClick = (event) => {
        event.preventDefault();

        this.handleClick(buttonValue);
      };

      return cloneElement(child, {
        ...props,
        active: value === buttonValue,
        secondary: value !== buttonValue,
        onClick: handleClick,
      });
    });

    return (
      <FormColumn column={column}>
        <div className={cx('btn-group', className)} {...props}>
          {buttons}
        </div>
      </FormColumn>
    );
  }
}

export default FormControl;
