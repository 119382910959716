import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';

import formController, { renderError } from 'source/utils/forms';

import { withGatewayRoot } from './root';

function ForgotPassword({ id, intl, form, onChange, onSubmit }) {
  return (
    <div className="gateway__card">
      <h5>
        <FormattedMessage
          id="signup.caption.resetPasswordTitle"
          defaultMessage="Password reset"
        />
      </h5>
      <form id={id} onSubmit={onSubmit} onChange={onChange} noValidate>
        <fieldset className="form-group">
          <label htmlFor="email">
            <FormattedMessage id="signup.email" defaultMessage="Email" />:
          </label>
          <input
            className="form-control"
            id="email"
            type="email"
            placeholder={intl.formatMessage({
              id: 'signup.placeholder.email',
              defaultMessage: 'name@example.com',
            })}
            value={form.email}
            onChange={noop}
          />

          <div className="error-block">{renderError(form.errors.email)}</div>
        </fieldset>
        <button className="btn btn-primary m-t-2" type="submit">
          <FormattedMessage
            id="signin.resetPassword"
            defaultMessage="Reset my password"
          />
        </button>
        <div className="gateway__card__options m-t-1">
          <Link to="/signup">
            <FormattedMessage
              id="signin.dontHaveAccount"
              defaultMessage="No account yet?"
            />
          </Link>
          <Link to="/signin">
            <FormattedMessage
              id="signin.haveAccount"
              defaultMessage="Sign in to account"
            />
          </Link>
        </div>
      </form>
    </div>
  );
}

ForgotPassword.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.shape({
    email: PropTypes.string.isRequired,
    errors: PropTypes.object.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const withFormController = formController({
  validateForm(form) {
    const errors = {};

    if (isEmpty(form.email) || !validator.isEmail(form.email)) {
      errors.email = 'signup.errors.emailFormat';
    }

    return errors;
  },
});

export default injectIntl(withGatewayRoot(withFormController(ForgotPassword)));
