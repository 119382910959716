import groupBy from 'lodash/groupBy';

import { createAsyncSelector } from '@blogfoster/redux-async-utils';

import { selectors as fromExplore } from './components/ExploreCampaigns/reducer';

const getCampaignMatches = (state) =>
  fromExplore.getAsyncCampaignMatches(state.scenes.exploreCampaigns);

const getAllCampaigns = (state) =>
  fromExplore.getAsyncAllCampaigns(state.scenes.exploreCampaigns);

export const getCampaignTypeFilter = (state) =>
  fromExplore.getCampaignTypeFilter(state.scenes.exploreCampaigns);

const createCampaignMatchesByTypeSelector = (campaignMatchesSelector) =>
  createAsyncSelector(
    { campaignMatches: campaignMatchesSelector },
    ({ campaignMatches }) => ({
      ...groupBy(campaignMatches, 'campaign.type'),
      // Also include "all platforms" for convenience
      all: campaignMatches,
    }),
  );

const createAllCampaignsByTypeSelector = (allCampaignsSelector) =>
  createAsyncSelector(
    { allCampaigns: allCampaignsSelector },
    ({ allCampaigns }) => ({
      ...groupBy(allCampaigns, 'type'),
      // Also include "all platforms" for convenience
      all: allCampaigns,
    }),
  );

export const campaignMatchesByTypeSelectorCreator = (campaignMatchesSelector) =>
  createCampaignMatchesByTypeSelector(campaignMatchesSelector);

export const getCampaignMatchesByType =
  campaignMatchesByTypeSelectorCreator(getCampaignMatches);

export const getAllCampaignsByType =
  createAllCampaignsByTypeSelector(getAllCampaigns);
