import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { compose, withHandlers, pure } from 'recompose';
import noop from 'lodash/noop';
import find from 'lodash/find';

import * as CustomPropTypes from 'source/utils/propTypes';
import Category from './category';
import { onCategoryChange } from './utils';

const enhance = compose(
  pure,
  withHandlers({
    onChange: onCategoryChange,
  }),
);

function Categories(props) {
  const {
    className,
    categories,
    selectedCategories,
    maxCategories: maxCategoriesRaw,
    onChange,
  } = props;

  const maxCategories = parseInt(maxCategoriesRaw, 10);

  return (
    <div
      className={cx(
        'categories-list',
        'col-md-10',
        'col-md-offset-1',
        className,
      )}
    >
      {categories.map(({ code }) => {
        const selected = find(selectedCategories, { code });
        const checked = Boolean(selected);
        const disabled =
          maxCategories > 0 &&
          selectedCategories.length >= maxCategories &&
          !checked;

        return (
          <Category
            key={code}
            code={code}
            checked={checked}
            disabled={disabled}
            onChange={onChange}
          />
        );
      })}
    </div>
  );
}

Categories.propTypes = {
  className: PropTypes.string,
  categories: PropTypes.arrayOf(CustomPropTypes.category),
  selectedCategories: PropTypes.arrayOf(CustomPropTypes.category),
  maxCategories: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
};

Categories.defaultProps = {
  className: '',
  categories: [],
  selectedCategories: [],
  maxCategories: null,
  onChange: noop,
};

export default enhance(Categories);
