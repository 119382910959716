import React from 'react';
import { Route, Redirect, IndexRedirect } from 'react-router';

import * as Containers from 'source/containers';

import { composeComponents } from 'source/utils';
import { themeProvider, ThemeWrapper } from 'source/utils/theme';
import { isSmallWindow } from 'source/utils/window';

import {
  requireAuthentication,
  redirectNotAcceptedTerms,
  redirectAcceptedTerms,
  redirectNotCompleteUser,
  redirectCompleteUser,
} from 'source/utils/routing';

const { Gateway } = Containers;

const App = composeComponents(Containers.App, [
  requireAuthentication,
  redirectNotAcceptedTerms,
  redirectNotCompleteUser,
]);

const AcceptTerms = composeComponents(Gateway.AcceptTerms, [
  requireAuthentication,
  redirectAcceptedTerms,
]);

const CheckProfile = composeComponents(Gateway.CheckProfile, [
  requireAuthentication,
  redirectCompleteUser,
]);

// Create context provider which maps routes to themes
// @sean TODO The viewClass should be handled by components really. Remove
const provideTheme = themeProvider((path) => {
  let theme;

  if (
    path.includes('sponsored-posts/campaigns') ||
    path.includes('sponsored-posts/applications')
  ) {
    theme = { themeClass: 'aurora', viewClass: 'sponsored-posts' };
  } else if (path.includes('brand-clubs')) {
    theme = { themeClass: 'aurora bf-plain' };
  } else if (path.includes('profile')) {
    theme = { themeClass: 'thrush bf-plain' };
  } else {
    theme = { themeClass: 'bf-plain' };
  }

  return theme;
});

// Helper to wrap a components with a theme class
// eslint-disable-next-line react/prop-types
const renderThemed = ({ children }) => (
  <ThemeWrapper className="fullscreen">{children}</ThemeWrapper>
);

const routes = (
  <Route component={provideTheme(Containers.Root)}>
    <Route path="/" component={App}>
      <IndexRedirect to="sponsored-posts" />

      <Redirect from="insights" to="profile" />
      <Redirect from="insights/*" to="profile" />

      <Route
        name="brandClubs"
        path="brand-clubs/:brand"
        component={Containers.BrandClubs}
      />
      <Route
        name="brandClubs"
        path="brand-clubs"
        component={Containers.BrandClubs}
      />

      <Route name="revenues" path="revenues" component={Containers.Revenues} />

      <Route
        name="sponsoredPosts"
        path="sponsored-posts"
        component={Containers.SponsoredPosts.Root}
      >
        <IndexRedirect to="campaigns" />
        <Route component={Containers.SponsoredPosts.Navigation}>
          <Route
            path="campaigns"
            component={Containers.SponsoredPosts.Campaigns}
          />
          <Route
            path="applications"
            component={Containers.SponsoredPosts.Applications}
          />
        </Route>

        <Route
          path="campaigns/:campaignId"
          component={Containers.SponsoredPosts.CampaignDetail}
        />
        <Route
          path="applications/:applicationId"
          component={Containers.SponsoredPosts.ApplicationDetail}
        />
      </Route>

      {/* channels renamed to profile, redirect should handle old navigation from whatever sources */}
      <Redirect from="channels" to="profile" />
      <Route name="profile" path="profile" component={Containers.Profile} />

      <Route
        name="accountSettings"
        path="account-settings"
        component={Containers.AccountSettings}
      >
        <Route
          name="accountSettings"
          path="(:tab)"
          component={Containers.AccountSettings}
        />
      </Route>
      <Route name="revenues" path="revenues" component={Containers.Revenues} />
    </Route>

    <Route component={renderThemed}>
      <Route path="/accept-terms" component={AcceptTerms} />
      <Route path="/check-profile" component={CheckProfile} />
      <Route path="/signup" component={Gateway.SignUp} />
      <Route path="/signin" component={Gateway.SignIn} />
      <Route path="/forgot-password" component={Gateway.ForgotPassword} />
      <Route path="/sorry-see-you-go" component={Gateway.AccountDeleted} />
      <Route
        path="/reset-password-requested"
        component={Gateway.ResetPasswordRequested}
      />
      <Route
        path="/reset-password/:resetToken"
        component={Gateway.ResetPassword}
      />
      <Route
        path="/reset-password-completed"
        component={Gateway.ResetPasswordCompleted}
      />
      <Route
        path="/signout"
        component={(props) => (
          <Gateway.SignOut {...props} compactLogo={isSmallWindow()} />
        )}
      />
      <Route
        path="/confirm-email/:id/:confirmToken"
        component={Gateway.ConfirmEmail}
      />
      <Route path="/access" component={Gateway.Access} />
      <Route
        path="/we-are-having-problems"
        component={Gateway.Error}
        status={500}
      />
      <Route path="/not-found" component={Gateway.Error} status={404} />
      <Route path="*" component={Gateway.Error} status={404} />
    </Route>
  </Route>
);

export default routes;
