import React from 'react';
import PropTypes from 'prop-types';
import { withProps, withState, pure, compose, shouldUpdate } from 'recompose';
import { injectIntl } from 'react-intl';
import { Video, Image, Transformation } from 'cloudinary-react';

import cx from 'classnames';
import noop from 'lodash/noop';
import last from 'lodash/last';

import Modal from 'source/components/common/modal';
import { Icon } from 'source/components/common';
import Spinner from 'source/components/common/spinner';

const withHandleItemDeleteHandler = withProps((ownerProps) => ({
  handleItemDelete: (id) => () => ownerProps.onDelete(id),
}));

const LoadingProgress = compose(
  injectIntl,
  shouldUpdate(
    // NOTE: Stop updating if browser has finished uploading the files, but request is still pending
    (props, nextProps) =>
      (!nextProps.isLoading && nextProps.percentCompleted === 1) ||
      (nextProps.percentCompleted > props.percentCompleted &&
        nextProps.percentCompleted < 1),
  ),
)(({ intl, percentCompleted }) => (
  <div className="media-gallery-item__percent-completed">
    <div className="media-gallery-item__percent-completed__value">
      {intl.formatNumber(percentCompleted, {
        style: 'percent',
      })}
    </div>
  </div>
));

LoadingProgress.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  percentCompleted: PropTypes.number.isRequired,
};

const enhance = compose(
  pure,
  injectIntl,
  withState('isZoomed', 'setIsZoomed', null),
);

const ZoomableMedia = enhance(
  ({
    type,
    url,
    file,
    filename,
    assetId,
    isZoomed,
    setIsZoomed,
    intl,
    disableZoom,
  }) => {
    const openModal = () => setIsZoomed(true);
    const closeModal = () => setIsZoomed(false);

    let renderedMedia;

    if (type === 'image') {
      renderedMedia = (
        <div className="media-gallery-item__media">
          {!assetId && (
            <img
              className="media-gallery-item__image"
              alt=""
              src={url || URL.createObjectURL(file)}
            />
          )}

          {assetId && (
            <Image
              secure
              className="media-gallery-item__image"
              cloudName="eqolot"
              publicId={assetId}
            >
              <Transformation transformation={['influencer-asset']} />
            </Image>
          )}

          {!disableZoom && !isZoomed && url && (
            <div
              className="media-gallery-item__zoom"
              role="button"
              tabIndex="-1"
              onClick={openModal}
              title={intl.formatMessage({
                id: 'mediaGallery.zoomFile',
                defaultMessage: 'View',
              })}
            >
              <Icon name="zoom" />
            </div>
          )}
        </div>
      );
    }

    if (type === 'video') {
      renderedMedia = (
        <div className="media-gallery-item__media" onClick={openModal}>
          {!isZoomed && url && (
            <div
              className="media-gallery-item__play"
              role="button"
              tabIndex="-1"
              onClick={openModal}
              title={intl.formatMessage({
                id: 'mediaGallery.playFile',
                defaultMessage: 'View',
              })}
            >
              <Icon name="play" />
            </div>
          )}

          {assetId && (
            <Video
              secure
              controls={isZoomed}
              cloudName="eqolot"
              publicId={assetId}
              format="mp4"
            >
              <Transformation fetchFormat="auto" />
            </Video>
          )}
          {/* eslint-disable jsx-a11y/media-has-caption */}
          {!assetId && (
            <video key={url} controls={isZoomed}>
              <source src={url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
          {/* eslint-enable jsx-a11y/media-has-caption */}
        </div>
      );
    }

    if (isZoomed) {
      const title = filename || file.name || last(url.split('/'));
      return (
        <Modal
          isOpen
          onRequestClose={closeModal}
          overlayClassName="aurora zoom-media-modal-overlay"
          className="zoom-media-modal"
        >
          <header>
            <span>{title}</span>
            <div className="close-btn" onClick={closeModal}>
              <Icon name="cross" />
            </div>
          </header>
          <main>{renderedMedia}</main>
        </Modal>
      );
    }

    return renderedMedia;
  },
);

ZoomableMedia.propTypes = {
  type: PropTypes.string,
  file: PropTypes.object,
  filename: PropTypes.string,
  url: PropTypes.string,
  disableZoom: PropTypes.bool,
};

ZoomableMedia.defaultProps = {
  type: 'image',
  file: {},
  url: '',
  filename: '',
  disableZoom: false,
};

export const MediaGalleryItem = compose(
  pure,
  injectIntl,
  withHandleItemDeleteHandler,
)((props) => (
  <div
    className={cx('media-gallery-item', {
      'media-gallery-item--loading': props.loading,
      'media-gallery-item--errored': props.error,
    })}
  >
    {!props.loading && (
      <ZoomableMedia
        type={props.type}
        url={props.url}
        file={props.file}
        filename={props.filename}
        assetId={props.assetId}
        disableZoom={!props.zoomable}
      />
    )}

    {props.badgeIndex && (
      <div className="media-gallery-item__index">
        <div className="index">{props.badgeIndex}</div>
      </div>
    )}

    {props.removable && (
      <div
        className="media-gallery-item__remove"
        role="button"
        tabIndex="-1"
        title={props.intl.formatMessage({
          id: 'mediaGallery.deleteFile',
          defaultMessage: 'Delete file',
        })}
        onClick={props.handleItemDelete(props.id)}
      >
        <Icon name="trash" />
      </div>
    )}

    {props.draggable && (
      <div
        className="media-gallery-item__reorder"
        title={props.intl.formatMessage({
          id: 'mediaGallery.reorderFile',
          defaultMessage: 'Click and drag to reorder',
        })}
      >
        <Icon name="reorder" />
      </div>
    )}

    {props.error && (
      <div className="media-gallery-item__error">
        <Icon name="warning" />
      </div>
    )}

    {props.loading && (
      <div className="thrush media-gallery-item__loader">
        <Spinner />
      </div>
    )}

    {props.showProgress && props.loading && (
      <LoadingProgress
        isLoading={props.loading}
        percentCompleted={props.percentCompleted}
      />
    )}
  </div>
));

MediaGalleryItem.propTypes = {
  id: PropTypes.string.isRequired,
  badgeIndex: PropTypes.number,
  file: PropTypes.object,
  url: PropTypes.string,
  filename: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onDelete: PropTypes.func,
  removable: PropTypes.bool,
  draggable: PropTypes.bool,
  zoomable: PropTypes.bool,
  showProgress: PropTypes.bool,
  percentCompleted: PropTypes.number,
};

MediaGalleryItem.defaultProps = {
  file: {},
  url: null,
  loading: false,
  error: null,
  removable: true,
  draggable: false,
  zoomable: true,
  onDelete: noop,
  showProgress: false,
  percentCompleted: 0.01,
};

function MediaGallery(props) {
  return (
    <div className={cx('media-gallery', props.className)}>{props.children}</div>
  );
}

MediaGallery.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

MediaGallery.defaultProps = {
  className: '',
  children: null,
};

export default pure(MediaGallery);
