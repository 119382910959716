const { developers } = require('./shared');

module.exports = {
  app: {
    url: process.env.SELF_APP_URL,
  },

  api: {
    url: process.env.IRON_URL,
    timeout: 25000,
  },

  sentry: {
    disabled: true,
  },

  facebook: {
    disabled: true,
  },

  features: {
    insightsMaintenance: {
      enabled: false,
    },

    counterOffer: {
      allowedUsers: [...developers],
    },

    instagramApi: {
      allowedUsers: [...developers],
    },

    demoPrivateNetworks: {
      allowedUsers: [...developers],
    },
    hiddenPrivateNetworks: {
      allowedUsers: [...developers],
    },
  },

  optimizely: {
    writeKey: 6495521950,
  },

  GA: {},

  experiments: {
    imageUrl: 6501651152,
  },

  insights: {
    instagram: {
      insightsTtl: 30,
      insightsIntermediateTtl: 23,
    },
    tiktok: {
      insightsTtl: 30,
      insightsIntermediateTtl: 23,
    },
  },
  privateNetworks: {
    demoPrivateNetworks: [],
    hiddenPrivateNetworks: [],
  },
};
