import moment from 'moment';
import { createAsyncSelector } from '@blogfoster/redux-async-utils';

import { isPendingReadyState } from 'source/scenes/profile/utils/channel';

// NOTE @alexspri exporting the config here so we can modify it from the outside (tests)
export const config = {
  ConfirmationStartDate: moment('2017-01-09').startOf('day'),
  newUserOffset: 7,
  existingUserOffset: 30,
};

const getInsightsChannels = (state) => state.sponsoredPosts.channels;

export const getAsyncHasReadyOrPendingChannel = createAsyncSelector(
  { channels: getInsightsChannels },
  ({ channels }) =>
    channels.some(
      (channel) =>
        channel.ready ||
        (channel.platform === 'ga' && isPendingReadyState(channel.readyState)),
    ),
);
