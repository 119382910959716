import React from 'react';
import PropTypes from 'prop-types';

function CampaignImage({ campaign, disableLogo = false }) {
  return (
    <div className="sponsored-posts-campaign-image">
      <div className="sponsored-posts-campaign-preview">
        <img
          src={campaign.details.previewImageUrl}
          alt={campaign.details.headline}
        />
      </div>
      {!disableLogo ? (
        <div className="sponsored-posts-campaign-logo">
          <img
            src={campaign.details.companyLogoUrl}
            alt={campaign.details.mission}
          />
        </div>
      ) : null}
    </div>
  );
}

CampaignImage.propTypes = {
  campaign: PropTypes.shape({
    details: PropTypes.shape({
      headline: PropTypes.string.isRequired,
      mission: PropTypes.string.isRequired,
      companyLogoUrl: PropTypes.string.isRequired,
      previewImageUrl: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  disableLogo: PropTypes.bool,
};

CampaignImage.defaultProps = {
  disableLogo: false,
};

export default CampaignImage;
