import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { requestConfirmationMail } from 'source/actions/application';
import Icon from 'source/components/common/icon';
import InfoCard from 'source/components/common/infoCard';

function ProfileLink() {
  return (
    <Link to="/profile">
      <FormattedMessage
        id="completeProfileCard.item.channels.profileLink"
        defaultMessage="start here"
      />
    </Link>
  );
}

function RequestConfirmationEmailLink({ onClick }) {
  return (
    <Link to="#" onClick={onClick}>
      <FormattedMessage
        id="completeProfileCard.item.email.requestConfirmationEmailLink"
        defaultMessage="click here"
      />
    </Link>
  );
}

RequestConfirmationEmailLink.propTypes = {
  onClick: PropTypes.func.isRequired,
};

function ProfileCheckItem({ done, children, ...props }) {
  return (
    <div className="profile-check-item" {...props}>
      {!done && <Icon name="circle" />}
      {done && <Icon name="check-circle" />}
      {children}
    </div>
  );
}

ProfileCheckItem.propTypes = {
  done: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

class ProfileCheck extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmationEmailTriggered: false,
    };
  }

  requestConfirmationMail = () => {
    this.props.onRequestConfirmationMail();
    this.setState((prevState) => ({
      ...prevState,
      confirmationEmailTriggered: true,
    }));
  };

  render() {
    const {
      intl,
      userHasChannels,
      userIsConfirmed,
      onRequestConfirmationMail,
      ...props
    } = this.props;

    return (
      <div {...props}>
        <InfoCard
          title={intl.formatMessage({
            id: 'completeProfileCard.title',
            defaultMessage: 'Complete your profile to show campaign prices',
          })}
        >
          <div className="profile-check-items-list">
            <ProfileCheckItem done={userIsConfirmed}>
              {this.state.confirmationEmailTriggered && !userIsConfirmed && (
                <FormattedMessage
                  id="confirmation.alert.sponsoredPosts.emailSent"
                  defaultMessage="Thanks! We have sent you an email. Once you receive it, please click the contained link to confirm your email address. It may take a few minutes to arrive."
                />
              )}
              {!this.state.confirmationEmailTriggered && !userIsConfirmed && (
                <FormattedMessage
                  id="completeProfileCard.item.email"
                  defaultMessage="Confirm your email address. If you need another confirmation email, {requestConfirmationEmailLink}"
                  values={{
                    requestConfirmationEmailLink: (
                      <RequestConfirmationEmailLink
                        onClick={this.requestConfirmationMail}
                      />
                    ),
                  }}
                />
              )}
              {userIsConfirmed && (
                <FormattedMessage
                  id="confirmEmail.completed.description"
                  defaultMessage="You have successfully confirmed your email."
                />
              )}
            </ProfileCheckItem>
            <ProfileCheckItem done={userHasChannels}>
              <FormattedMessage
                id="completeProfileCard.item.channels"
                defaultMessage="Connect your Instagram, TikTok or other channels, {profileLink}"
                values={{
                  profileLink: <ProfileLink />,
                }}
              />
            </ProfileCheckItem>
          </div>
        </InfoCard>
      </div>
    );
  }
}

ProfileCheck.propTypes = {
  userHasChannels: PropTypes.bool.isRequired,
  userIsConfirmed: PropTypes.bool.isRequired,
  onRequestConfirmationMail: PropTypes.func.isRequired,
  className: PropTypes.string,
};
ProfileCheck.defaultProps = {
  className: '',
};

export default compose(
  injectIntl,
  connect(null, {
    onRequestConfirmationMail: requestConfirmationMail,
  }),
)(ProfileCheck);
