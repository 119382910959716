import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router';

import { Alert } from 'source/components/common';

function SuccessHeading({ redirectResponse, onClose }) {
  const { platform, error } = redirectResponse || {};

  if (!redirectResponse || error) {
    return null;
  }

  if (platform === 'ga') {
    return null;
  }

  const alertText =
    platform === 'instagram' ? (
      <FormattedMessage
        id="channels.oauthRedirect.success.instagram"
        defaultMessage="Your Instagram profiles are now connected to EQOLOT. Nice!"
      />
    ) : (
      <FormattedMessage
        id="channels.oauthRedirect.success"
        defaultMessage="Success! Please select categories for your channel to complete your configuration."
      />
    );

  return (
    <section className="p-t-1 p-b-1">
      <Alert type="success" text={alertText} onClose={onClose}>
        {platform === 'instagram' && (
          <Link to="/sponsored-posts/campaigns" className="font-weight-bold">
            <FormattedMessage
              id="channels.oauthRedirect.success.browseCampaigns"
              defaultMessage="Browse campaigns"
            />
          </Link>
        )}
      </Alert>
    </section>
  );
}

SuccessHeading.propTypes = {
  onClose: PropTypes.func.isRequired,
  redirectResponse: PropTypes.shape({
    error: PropTypes.string,
  }),
};

const ErrorHeading = injectIntl(({ intl, redirectResponse, onClose }) => {
  const { error, platform } = redirectResponse || {};

  if (!error) {
    return null;
  }

  let textId = `channels.errors.${error}`;
  if (platform === 'instagram' && error.startsWith('instagram')) {
    textId = 'channels.instagram.genericError';
  }

  return (
    <section className="p-t-1 p-b-1">
      <Alert
        type="error"
        textId={textId}
        values={{
          ...redirectResponse,
          howItWorksLink: (
            <a
              href={intl.formatMessage({
                id: 'channels.instagram.genericError.howItWorksLink.url',
                defaultMessage: 'https://link.eqolot.com/eUJyA7Ec3',
              })}
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage
                id="channels.instagram.genericError.howItWorksLink.label"
                defaultMessage="how it works"
              />
            </a>
          ),
        }}
        onClose={onClose}
      />
    </section>
  );
});

ErrorHeading.propTypes = {
  onClose: PropTypes.func.isRequired,
  redirectResponse: PropTypes.shape({
    error: PropTypes.string,
    platform: PropTypes.string,
  }),
};

function Header({ redirectResponse, onClose }) {
  return (
    <>
      <ErrorHeading redirectResponse={redirectResponse} onClose={onClose} />
      <SuccessHeading redirectResponse={redirectResponse} onClose={onClose} />
    </>
  );
}

Header.propTypes = {
  onClose: PropTypes.func.isRequired,
  redirectResponse: PropTypes.object,
};

export default Header;
