import React, { Component } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import cx from 'classnames';
import get from 'lodash/get';
import noop from 'lodash/noop';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  ToolTipController,
  Select as ToolTipWrapper,
} from 'react-tooltip-controller';

import {
  ActivityStreamItem,
  ActivityStreamHeader,
  ActivityStreamBody,
  ActivityStreamTitle,
  ActivityStreamSubtitle,
  ActivityStreamRadioSelection,
  ActivityStreamSelectionOptions,
} from 'source/components/common/activityStream';
import Button, { SubmitButton } from 'source/components/common/button';
import Form, {
  FormGroup,
  FormActions,
  Checkbox,
  Input,
  Label,
  FormControlFeedback,
} from 'source/components/common/form';
import Markdown from 'source/components/common/markdown';
import { MessageCard } from 'source/components/common/message';
import { CountrySelection, Icon, Tooltip } from 'source/components/common';
import { hasAttributes } from 'source/utils';

function ProfileLink() {
  return (
    <Link to="/account-settings/profile">
      <FormattedMessage
        id="navigation.links.profile"
        defaultMessage="Profile"
      />
    </Link>
  );
}

function AddressFormItem({ attr, label, className, data, message }) {
  return (
    <div className={className}>
      <Label htmlFor={attr}>{label}:</Label>
      <Input
        id={attr}
        value={data[attr] || ''}
        danger={!!get(data, ['errors', attr])}
        onChange={noop}
      />

      <FormControlFeedback message={message} />
    </div>
  );
}

AddressFormItem.propTypes = {
  attr: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  message: PropTypes.string,
};

AddressFormItem.defaultProps = {
  className: '',
  message: '',
};

function AddressFormCheckbox({ attr, label, className, data }) {
  return (
    <div className={className}>
      <Checkbox id={attr} checked={data[attr] || false} onChange={noop}>
        {label}
      </Checkbox>
    </div>
  );
}

AddressFormCheckbox.propTypes = {
  attr: PropTypes.string.isRequired,
  label: PropTypes.any.isRequired,
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
};

AddressFormCheckbox.defaultProps = {
  className: '',
};

function AddressCountrySelector({ label, attr, message, className, ...props }) {
  return (
    <div className={cx(className, 'activity-stream-country-select')}>
      <Label htmlFor={attr}>{label}:</Label>
      <CountrySelection id={attr} className="form-control" {...props} />

      <FormControlFeedback message={message} />
    </div>
  );
}

AddressCountrySelector.propTypes = {
  label: PropTypes.string.isRequired,
  attr: PropTypes.string.isRequired,
  className: PropTypes.string,
  message: PropTypes.string,
};

AddressCountrySelector.defaultProps = {
  className: '',
  message: '',
};

class ProductShipment extends Component {
  constructor(props) {
    super(props);

    this.statusToRender = {
      inactive: this.renderInactive,
      active: this.renderActive,
      done: this.renderDone,
      expired: this.renderExpired,
    };
  }

  state = {
    otherProduct: '',
    selectedProduct: null,
  };

  getErrorMessage = (attr, data) => {
    const { intl } = this.props;

    return get(data, ['errors', attr])
      ? intl.formatMessage({ id: get(data, ['errors', attr]) })
      : null;
  };

  handleReactivate = () => {
    const { formName, onAction } = this.props;

    return onAction(formName, 'reactivate');
  };

  handleProductChoiceSelection = (e) => {
    const {
      target: { value, id },
    } = e;
    const { formName, onChange } = this.props;

    const selectedProduct =
      id === 'otherProduct' ? this.state.otherProduct : value;

    onChange(formName, 'product', selectedProduct);
    this.setState({ ...this.state, selectedProduct });
  };

  handleOtherProductChange = (e) => {
    this.setState({ ...this.state, otherProduct: e.target.value }, () =>
      this.handleProductChoiceSelection({ target: { id: 'otherProduct' } }),
    );
  };

  renderInactive() {
    const { intl } = this.props;

    return (
      <ActivityStreamItem key="inactive" iconName="package">
        <ActivityStreamHeader
          title={intl.formatMessage({
            id: 'sponsoredPosts.campaigns.address',
            defaultMessage: 'Provide address',
          })}
        />
      </ActivityStreamItem>
    );
  }

  renderActive() {
    const {
      intl,
      form,
      formName,
      onChange,
      onSubmit,
      workflow: {
        payload: { description, productChoices = [], allowOtherChoice },
      },
    } = this.props;
    const data = form[formName] || {};
    const { product: selectedProduct } = data;

    const productOptions = productChoices.map((product, index) => (
      <label
        className="activity-stream-option transparent"
        htmlFor={product + index}
        key={product}
      >
        <input
          id={product + index}
          type="radio"
          name="product"
          value={product}
          onChange={this.handleProductChoiceSelection}
          checked={this.state.selectedProduct === product}
        />
        <span>{product}</span>
      </label>
    ));

    if (allowOtherChoice) {
      productOptions.push(
        <label
          className="activity-stream-option transparent align-items-center m-t-0"
          htmlFor="otherProduct"
        >
          <input
            id="otherProduct"
            type="radio"
            name="product"
            value="otherProduct"
            onChange={this.handleProductChoiceSelection}
            checked={this.state.selectedProduct === this.state.otherProduct}
          />
          <Input
            value={this.state.otherProduct}
            onChange={this.handleOtherProductChange}
          />
        </label>,
      );
    }

    const isProductSelected = productOptions.length === 0 || !!selectedProduct;

    return (
      <ActivityStreamItem key="active" status="active" iconName="package">
        <ActivityStreamHeader
          title={intl.formatMessage({
            id: 'sponsoredPosts.campaigns.address.title',
            defaultMessage: 'Product shipping',
          })}
        />
        <ActivityStreamBody>
          <ActivityStreamRadioSelection>
            <h6>
              <Markdown typeName="span" markdown={description} noParagraphs />
            </h6>
            <ActivityStreamSelectionOptions options={productOptions} />
          </ActivityStreamRadioSelection>
          <p className="m-t-1">
            <FormattedMessage
              id="sponsoredPosts.campaigns.address.message"
              defaultMessage="For this campaign we need your address to be able to send you the product. If the shipment is made by the brand team themselves, we will pass your address on."
            />
          </p>
          <Form id={formName} onChange={onChange} onSubmit={onSubmit}>
            <FormGroup
              className="row"
              hasDanger={hasAttributes(data.errors, ['firstname', 'lastname'])}
            >
              <AddressFormItem
                className="col-md-4"
                attr="firstname"
                label={intl.formatMessage({
                  id: 'sponsoredPosts.campaigns.address.firstname',
                  defaultMessage: 'First name',
                })}
                data={data}
                message={this.getErrorMessage('firstname', data)}
              />

              <AddressFormItem
                className="col-md-4"
                attr="lastname"
                label={intl.formatMessage({
                  id: 'sponsoredPosts.campaigns.address.lastname',
                  defaultMessage: 'Last name',
                })}
                data={data}
                message={this.getErrorMessage('lastname', data)}
              />
            </FormGroup>
            <FormGroup
              className="row"
              hasDanger={hasAttributes(data.errors, ['street', 'streetNumber'])}
            >
              <AddressFormItem
                className="col-md-6"
                attr="street"
                label={intl.formatMessage({
                  id: 'sponsoredPosts.campaigns.address.street',
                  defaultMessage: 'Street',
                })}
                data={data}
                message={this.getErrorMessage('street', data)}
              />

              <AddressFormItem
                className="col-md-2"
                attr="streetNumber"
                label={intl.formatMessage({
                  id: 'sponsoredPosts.campaigns.address.streetNumber',
                  defaultMessage: 'Street number',
                })}
                data={data}
                message={this.getErrorMessage('streetNumber', data)}
              />
            </FormGroup>
            <FormGroup
              className="row"
              hasDanger={hasAttributes(data.errors, ['postalCode', 'city'])}
            >
              <AddressFormItem
                className="col-md-2"
                attr="postalCode"
                label={intl.formatMessage({
                  id: 'sponsoredPosts.campaigns.address.postalCode',
                  defaultMessage: 'Postal code',
                })}
                data={data}
                message={this.getErrorMessage('postalCode', data)}
              />

              <AddressFormItem
                className="col-md-6"
                attr="city"
                label={intl.formatMessage({
                  id: 'sponsoredPosts.campaigns.address.city',
                  defaultMessage: 'City',
                })}
                data={data}
                message={this.getErrorMessage('city', data)}
              />
            </FormGroup>
            <FormGroup
              className="row"
              hasDanger={hasAttributes(data.errors, ['country'])}
            >
              <AddressCountrySelector
                className="col-md-10"
                label={intl.formatMessage({
                  id: 'sponsoredPosts.campaigns.address.country',
                  defaultMessage: 'Country',
                })}
                attr="country"
                onChange={onChange}
                value={data.country}
                message={this.getErrorMessage('country', data)}
              />
            </FormGroup>
            <FormActions className="activity-stream-flex-row">
              <SubmitButton
                disabled={
                  hasAttributes(data.errors) ||
                  !isProductSelected ||
                  data.submitting
                }
              >
                <FormattedMessage
                  id="sponsoredPosts.campaigns.address.send"
                  defaultMessage="Send"
                />
              </SubmitButton>
              <AddressFormCheckbox
                className="m-l-2"
                attr="saveAddress"
                label={
                  <FormattedMessage
                    id="sponsoredPosts.campaigns.address.saveAddressCheckbox"
                    defaultMessage="This is my main address, save it in my {link}"
                    values={{ link: <ProfileLink /> }}
                  />
                }
                data={data}
              />

              <ToolTipController detect="hover" offsetX={-113}>
                <ToolTipWrapper>
                  <div className="address-icon">
                    <Icon name="ask" />
                  </div>
                </ToolTipWrapper>
                <Tooltip className="tooltip-address">
                  <FormattedMessage
                    id="sponsoredPosts.campaigns.address.saveAddressTooltip"
                    defaultMessage="Your Profile address will be the default shipping address. It is also used to match you with relevant campaigns."
                  />
                </Tooltip>
              </ToolTipController>
            </FormActions>
          </Form>
        </ActivityStreamBody>
      </ActivityStreamItem>
    );
  }

  renderReceivedHint() {
    const {
      workflow: { payload },
    } = this.props;

    if (!payload || !payload.showHint) {
      return null;
    }

    return (
      <ActivityStreamBody>
        <MessageCard type="info">
          <strong>
            <FormattedMessage
              id="sponsoredPosts.campaigns.address.submitted.title"
              defaultMessage="We received your address."
            />
          </strong>
          <span>
            <FormattedMessage
              id="sponsoredPosts.campaigns.address.submitted.message"
              defaultMessage="The delivery should not take longer than three days."
            />
          </span>
        </MessageCard>
      </ActivityStreamBody>
    );
  }

  renderProductTitle() {
    const product = get(this.props, 'application.productShipment.product');

    if (!product) {
      return null;
    }

    return (
      <small>
        <strong>
          <FormattedMessage
            id="sponsoredPosts.campaigns.address.productChoice"
            defaultMessage="Product choice:"
          />
        </strong>
        <span>{product}</span>
      </small>
    );
  }

  renderAddressTitle() {
    const address = get(this.props, 'application.productShipment.address');

    if (!address) {
      return null;
    }

    return (
      <small>
        <strong>
          <FormattedMessage
            id="sponsoredPosts.campaigns.address.specified"
            defaultMessage="Address provided:"
          />
        </strong>
        <span>
          {address.firstname} {address.lastname},
        </span>
        <span>
          &nbsp; {address.street} {address.streetNumber},
        </span>
        <span>
          &nbsp; {address.postalCode} {address.city}
        </span>
      </small>
    );
  }

  renderEditButton() {
    const {
      workflow: { payload },
    } = this.props;

    if (!payload || !payload.editable) {
      return null;
    }

    return (
      <Button onClick={this.handleReactivate}>
        <FormattedMessage
          id="sponsoredPosts.campaigns.address.change"
          defaultMessage="Change address"
        />
      </Button>
    );
  }

  renderDone() {
    const {
      workflow: { lastUpdate },
    } = this.props;

    return (
      <ActivityStreamItem key="done" status="done" iconName="package">
        <ActivityStreamHeader>
          <ActivityStreamTitle date={lastUpdate}>
            <strong>
              <FormattedMessage
                id="sponsoredPosts.campaigns.address.title"
                defaultMessage="Product shipping"
              />
            </strong>
          </ActivityStreamTitle>
          {this.renderEditButton()}
        </ActivityStreamHeader>
        <ActivityStreamSubtitle>
          {this.renderProductTitle()}
          {this.renderAddressTitle()}
        </ActivityStreamSubtitle>
        {this.renderReceivedHint()}
      </ActivityStreamItem>
    );
  }

  renderExpired() {
    const {
      intl,
      workflow: { lastUpdate },
    } = this.props;

    return (
      <ActivityStreamItem key="expired" status="failed" iconName="package">
        <ActivityStreamHeader
          title={intl.formatMessage({
            id: 'sponsoredPosts.campaigns.address.title',
            defaultMessage: 'Product shipping',
          })}
          date={lastUpdate}
        />

        <ActivityStreamBody>
          <MessageCard type="info">
            <strong>
              <FormattedMessage
                id="sponsoredPosts.campaigns.accept.expired.title"
                defaultMessage="What a pity!"
              />
            </strong>
            <span>
              <FormattedMessage
                id="sponsoredPosts.campaigns.accept.expired.message"
                defaultMessage="The deadline for application has expired, unfortunately."
              />
            </span>
          </MessageCard>
        </ActivityStreamBody>
      </ActivityStreamItem>
    );
  }

  render() {
    const {
      workflow: { status },
    } = this.props;

    const renderFn = this.statusToRender[status];
    if (!renderFn) {
      return this.renderInactive();
    }

    return renderFn.call(this);
  }
}

ProductShipment.propTypes = {
  formName: PropTypes.string,
  workflow: PropTypes.shape({
    status: PropTypes.oneOf(['inactive', 'active', 'done', 'expired'])
      .isRequired,
    payload: PropTypes.shape({
      showHint: PropTypes.bool,
      editable: PropTypes.bool,
      description: PropTypes.string,
      productChoices: PropTypes.arrayOf(PropTypes.string),
      allowOtherChoice: PropTypes.bool,
    }),

    lastUpdate: PropTypes.string,
  }).isRequired,
  form: PropTypes.object.isRequired,
  application: PropTypes.shape({
    productShipment: PropTypes.shape({
      address: PropTypes.shape({
        firstname: PropTypes.string.isRequired,
        lastname: PropTypes.string.isRequired,
        street: PropTypes.string.isRequired,
        streetNumber: PropTypes.string.isRequired,
        postalCode: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
      }),
    }),
  }),

  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

ProductShipment.defaultProps = {
  formName: 'productShipment',
  application: null,
};

export default injectIntl(ProductShipment);
