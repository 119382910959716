import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import matchesProperty from 'lodash/matchesProperty';
import isEmpty from 'lodash/isEmpty';
import property from 'lodash/property';
import negate from 'lodash/negate';
import compose from 'lodash/flowRight';
import find from 'lodash/find';

import {
  loadStateReducer,
  createAsyncSelector,
} from '@blogfoster/redux-async-utils';

import { actionTypes } from './actions';

// Helpers

// Default max applications shown
const APPLICATIONS_LIMIT_INIT = 10;
// Visible application increment when clicking "Show More"
const APPLICATIONS_LIMIT_INCREMENT = 5;

const isPending = (application) =>
  matchesProperty('status', 'new')(application) ||
  matchesProperty('status', 'shortlisted')(application) ||
  matchesProperty('status', 'review')(application) ||
  matchesProperty('status', 'selected')(application);

const isApproved = matchesProperty('status', 'approved');
const isRejected = matchesProperty('status', 'rejected');
const hasSocialMediaSharing = compose(
  negate(isEmpty),
  property('campaign.socialMediaSharing'),
);
const hasSocialMediaSharingAccepted = matchesProperty(
  'socialMediaSharingVerification.status',
  'accepted',
);
const hasPublicationAccepted = matchesProperty(
  'publication.status',
  'accepted',
);
const hasPostTasks = (application) =>
  Boolean(find(application.tasks, { list: 'postTasks' }));
const hasPostTasksFinished = (application) =>
  application.finishedTaskLists &&
  application.finishedTaskLists.includes('postTasks');
const hasAfterPublicationUploads = property(
  'campaign.afterPublicationUploadsRequired',
);
const hasAfterPublicationUploadsFinished = matchesProperty(
  'afterPublicationUploadsVerification.status',
  'accepted',
);

const isWorkflowDone = (application) => {
  if (!hasPublicationAccepted(application)) {
    return false;
  }

  if (
    hasSocialMediaSharing(application) &&
    !hasSocialMediaSharingAccepted(application)
  ) {
    return false;
  }

  if (hasPostTasks(application) && !hasPostTasksFinished(application)) {
    return false;
  }

  if (
    hasAfterPublicationUploads(application) &&
    !hasAfterPublicationUploadsFinished(application)
  ) {
    return false;
  }

  return true;
};

const statusFilters = {
  pending: isPending,
  onMission: (application) =>
    isApproved(application) && !isWorkflowDone(application),
  finished: isWorkflowDone,
  rejected: isRejected,
};

// Selectors

const getAsyncApplications = (state) => state.applications;

const getApplicationStatusFilter = (state) => state.applicationStatusFilter;

const getApplicationsLimit = (state) => state.applicationsLimit;

const getAsyncApplicationsFiltered = createAsyncSelector(
  { applications: getAsyncApplications },
  { applicationStatusFilter: getApplicationStatusFilter },
  ({ applications, applicationStatusFilter }) =>
    applicationStatusFilter === 'all'
      ? applications
      : applications.filter(statusFilters[applicationStatusFilter]),
);

// Sorts applications in descending updatedAt order
const getAsyncApplicationsSorted = createAsyncSelector(
  { filteredApplications: getAsyncApplicationsFiltered },
  ({ filteredApplications }) =>
    sortBy(filteredApplications, ({ updatedAt }) =>
      updatedAt ? -new Date(updatedAt) : 0,
    ),
);

const getAsyncApplicationsLimited = createAsyncSelector(
  { sortedApplications: getAsyncApplicationsSorted },
  { applicationsLimit: getApplicationsLimit },
  ({ sortedApplications, applicationsLimit }) =>
    sortedApplications.slice(0, applicationsLimit),
);

const isShowMoreButtonVisible = createSelector(
  getAsyncApplicationsFiltered,
  getApplicationsLimit,
  (filteredApplications, applicationsLimit) =>
    filteredApplications.loaded &&
    filteredApplications.data.length > applicationsLimit,
);

export const selectors = {
  getAsyncApplications,
  getApplicationStatusFilter,
  getApplicationsLimit,
  getAsyncApplicationsFiltered,
  getAsyncApplicationsLimited,
  isShowMoreButtonVisible,
};

// Reducers

const applicationsReducer = loadStateReducer(actionTypes.APPLICATIONS_FETCH);

const applicationStatusFilterReducer = (state = 'all', action) =>
  action.type === actionTypes.APPLICATION_STATUS_FILTER_CHANGE
    ? action.payload
    : state;

const applicationsLimitReducer = (state = APPLICATIONS_LIMIT_INIT, { type }) =>
  type === actionTypes.SHOW_MORE ? state + APPLICATIONS_LIMIT_INCREMENT : state;

export default combineReducers({
  applications: applicationsReducer,
  applicationStatusFilter: applicationStatusFilterReducer,
  applicationsLimit: applicationsLimitReducer,
});
