import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'source/components/common/modal';
import Icon from 'source/components/common/icon';

import OAuthChannel from './OAuthChannel';
import TikTokChannel from './TikTokChannel';
import InstagramChannel from './InstagramChannel';
import WebsiteChannel from './WebsiteChannel';
import GaChannel from './GaChannel';

const bodiesByPlatform = {
  website: WebsiteChannel,
  ga: GaChannel,
  tiktok: TikTokChannel,
  instagram: InstagramChannel,
  twitter: OAuthChannel,
  pinterest: OAuthChannel,
  youtube: OAuthChannel,
};

function ChannelDetail({ onClose, channel, formsActions, ...otherProps }) {
  if (!channel) {
    return null;
  }

  const ChannelBody = bodiesByPlatform[channel.platform];

  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      className="channel-detail-modal"
      overlayClassName="bf-plain"
    >
      <div className="channel-list-container">
        <ChannelBody
          {...otherProps}
          {...channel}
          {...formsActions[channel.platform]}
          closeButton={
            <div className="close-btn clickable" onClick={onClose}>
              <Icon name="cross" />
            </div>
          }
        />
      </div>
    </Modal>
  );
}

ChannelDetail.propTypes = {
  channel: PropTypes.object,
  formsActions: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ChannelDetail;
