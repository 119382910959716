import { createConstants } from 'source/utils';

const constants = createConstants(
  // auth actions
  'ACCOUNT_FORM_RESET',
  'ACCOUNT_FORM_CHANGE',
  'ACCOUNT_FORM_ERRORS',
  'ACCOUNT_FORM_ERRORS_CLEAN',
  'ACCOUNT_ACCESS_TOKEN_FETCHING',
  'ACCOUNT_ACCESS_TOKEN_FETCHED',
  'ACCOUNT_ACCESS_TOKEN_FETCH_FAILED',
  'ACCOUNT_SERVER_ERRORS_CLEAN',
  'ACCOUNT_REQUEST_PASSWORD_RESET_FAILED',
  'ACCOUNT_EMAIL_CONFIRMED',
  'ACCOUNT_EMAIL_CONFIRMATION_FAILED',
  'ACCOUNT_LANGUAGE_CHANGE',
  'ACCOUNT_CREATED',
  'ACCOUNT_REDIRECT_TO_SIGNIN',

  // accept terms
  'ACCEPT_TERMS_STATE_LOADING',
  'ACCEPT_TERMS_STATE_LOADED',
  'ACCEPT_TERMS_STATE_FAILED',
  'ACCEPT_TERMS_SUBMITTING',
  'ACCEPT_TERMS_SUBMITTED',
  'ACCEPT_TERMS_FAILED',

  // application/generic
  'APPLICATION_STATE_LOADING',
  'APPLICATION_STATE_LOADED',
  'APPLICATION_STATE_LOADING_FAILED',
  'APPLICATION_SET_LANGUAGE',
  'APPLICATION_SYNCHRONIZE_LANGUAGE',
  'APPLICATION_STATE_USER_SETTINGS_UPDATED',

  // mainframe (application) actions
  'APPLICATION_SIDEBAR_NAVIGATION_TOGGLE',
  'APPLICATION_USER_SETTINGS_TOGGLE',

  // dashboard
  'DASHBOARD_STATE_LOADING',
  'DASHBOARD_STATE_LOADING_FAILED',
  'DASHBOARD_STATE_LOADED',

  // application/account-settings actions
  'ACCOUNT_SETTINGS_STATE_LOADING',
  'ACCOUNT_SETTINGS_STATE_LOADING_FAILED',
  'ACCOUNT_SETTINGS_STATE_LOADED',
  'ACCOUNT_SETTINGS_FORM_RESET',
  'ACCOUNT_SETTINGS_FORM_ERRORS',
  'ACCOUNT_SETTINGS_ALERT_CLEAR',
  'ACCOUNT_SETTINGS_FORM_CHANGE',
  'ACCOUNT_SETTINGS_PASSWORD_CHANGE_APPLIED_SUCCESSFULLY',
  'ACCOUNT_SETTINGS_DELETED_ACCOUNT',
  'ACCOUNT_SETTINGS_PASSWORD_CHANGE_FAILED',
  'ACCOUNT_SETTINGS_DELETE_ACCOUNT_FAILED',
  'ACCOUNT_SETTINGS_DELETE_ACCOUNT_TOGGLE_CONFIRM',
  'ACCOUNT_SETTINGS_TAB_CHANGED',
  'ACCOUNT_SETTINGS_PAYMENT_DATA_SAVING',
  'ACCOUNT_SETTINGS_PAYMENT_DATA_SAVED',
  'ACCOUNT_SETTINGS_PAYMENT_DATA_SAVE_FAILED',
  'ACCOUNT_SETTINGS_NOTIFICATIONS_UPDATE_FAILED',
  'ACCOUNT_SETTINGS_FORM_SUBMITTING',
  'ACCOUNT_SETTINGS_FORM_SUBMITTED',
  'ACCOUNT_SETTINGS_FORM_SUBMITTING_FAILED',

  // channels
  'CHANNELS_FETCH',
  'UPLOAD_INSIGHTS_MODAL_OPEN',
  'UPLOAD_INSIGHTS_MODAL_CLOSE',

  // sponsored posts - confirm email
  'CONFIRM_EMAIL_SENDING',
  'CONFIRM_EMAIL_SENT',

  // social
  'SOCIAL_OAUTH_CONNECTING',

  // experiments
  'EXPERIMENTS_SET_CURRENT_EXPERIMENT',

  // global events
  'GLOBAL_GENERIC_REQUEST_ERROR',
  'GLOBAL_SERVER_REQUEST_ERROR',
  'GLOBAL_RESET_STORE',
);

export default constants;
