import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { branch, renderComponent } from 'recompose';

import Icon from 'source/components/common/icon';
import Table, { TableHeaderCell } from 'source/components/common/table';
import Button, { ButtonLink } from 'source/components/common/button';
import Tabs from 'source/components/common/tabsNav';
// @sean TODO Don't reach into other scene's folder
import CampaignImage from 'source/scenes/exploreCampaigns/components/CampaignImage';
import Markdown from 'source/components/common/markdown';
import { trim } from 'source/utils';

// Helpers

const preventDefault = (e) => e.preventDefault();

const tabsDefinition = [
  {
    id: 'pending',
    label: 'sponsoredPosts.activities.filter.pending',
  },

  {
    id: 'onMission',
    label: 'sponsoredPosts.activities.filter.onMission',
  },

  {
    id: 'finished',
    label: 'sponsoredPosts.activities.filter.finished',
  },

  {
    id: 'rejected',
    label: 'sponsoredPosts.activities.filter.rejected',
  },

  {
    id: 'all',
    label: 'sponsoredPosts.activities.filter.all',
  },
];

// Components

/**
 * A link which can be "switched" off, ie. disabled
 */
function SwitchLink({ disabled, to, className, onClick, children, ...props }) {
  return (
    <Link
      {...props}
      className={cx(className, { disabled })}
      to={disabled ? null : to}
      onClick={disabled ? preventDefault : onClick}
    >
      {children}
    </Link>
  );
}

SwitchLink.propTypes = {
  ...Link.propTypes,
  disabled: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

SwitchLink.defaultProps = {
  ...Link.defaultProps,
  disabled: false,
};

function ApplicationImageCell({
  data: {
    id: applicationId,
    campaign,
    campaign: { status, type, details },
  },
}) {
  return (
    <td className="sponsored-posts-overview-list-campaign-image">
      <div
        className={cx(
          'sponsored-posts-overview-list-campaign-type',
          'label',
          type,
        )}
      >
        <Icon name={type} />
      </div>
      <SwitchLink
        disabled={status === 'archived'}
        to={`/sponsored-posts/applications/${applicationId}`}
      >
        {details ? <CampaignImage campaign={campaign} disableLogo /> : null}
      </SwitchLink>
    </td>
  );
}

ApplicationImageCell.propTypes = {
  // Data is provided by table component
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    campaign: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      details: PropTypes.object.isRequired,
      status: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

function ApplicationDescriptionCell({
  data: {
    id: applicationId,
    active,
    campaign: { status, details },
  },
}) {
  const disabled = status === 'archived';
  const className = cx('sponsored-posts-overview-list-campaign', { active });

  return (
    <td className={className}>
      <SwitchLink
        disabled={disabled}
        to={`/sponsored-posts/applications/${applicationId}`}
      >
        <div className="h2 sponsored-posts-campaign-name">
          {details.headline}
        </div>
        <span className="sponsored-posts-campaign-description">
          <Markdown
            markdown={trim(details.mission)}
            typeName="span"
            noParagraphs
          />
        </span>
      </SwitchLink>
    </td>
  );
}

ApplicationDescriptionCell.propTypes = {
  // Data is provided by table component
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    active: PropTypes.bool,
    campaign: PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      details: PropTypes.shape({
        headline: PropTypes.string.isRequired,
        mission: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }),
};

function ActionsCell({
  data: {
    id: applicationId,
    active,
    campaign: { status },
  },

  title,
  archivedTitle,
}) {
  const notPublished = status !== 'published';
  const className = cx('sponsored-posts-overview-list-actions', {
    active,
    disabled: notPublished,
  });

  const btnClassName = cx({ disabled: notPublished });

  return (
    <td className={className}>
      <ButtonLink
        className={btnClassName}
        to={`/sponsored-posts/applications/${applicationId}`}
      >
        {notPublished ? archivedTitle : title}
      </ButtonLink>
    </td>
  );
}

ActionsCell.propTypes = {
  // Data is provided by table component
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    campaign: PropTypes.shape({
      id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),

    active: PropTypes.bool,
  }),

  title: PropTypes.object.isRequired,
  archivedTitle: PropTypes.object.isRequired,
};

function NoContentMessage({ applicationStatusFilter }) {
  return (
    <div className="row">
      <div className="text-xs-center">
        <FormattedMessage
          id={
            applicationStatusFilter === 'all'
              ? 'sponsoredPosts.activities.table.noContent'
              : 'sponsoredPosts.activities.table.noneMatchingFilter'
          }
        />
      </div>
    </div>
  );
}

NoContentMessage.propTypes = {
  applicationStatusFilter: PropTypes.string.isRequired,
};

function ShowMoreButton({ onShowMoreApplications }) {
  return (
    <div className="more">
      <Button onClick={onShowMoreApplications}>
        <FormattedMessage
          id="sponsoredPosts.activities.table.moreButton"
          defaultMessage="Older Campaigns"
        />
      </Button>
    </div>
  );
}

ShowMoreButton.propTypes = {
  onShowMoreApplications: PropTypes.func.isRequired,
};

const ApplicationsTableBody = branch(
  ({ applications }) => !applications.length,
  renderComponent(NoContentMessage),
)(({ applications }) => (
  <Table
    columns={[
      {
        headerElement: <TableHeaderCell className="table-image" />,
        bodyElement: <ApplicationImageCell />,
      },

      {
        headerElement: <TableHeaderCell className="table-entity" />,
        bodyElement: <ApplicationDescriptionCell />,
      },

      {
        headerElement: <TableHeaderCell className="table-number" />,
        bodyElement: (
          <ActionsCell
            title={
              <FormattedMessage
                id="sponsoredPosts.campaigns.campaign.link.title"
                defaultMessage="View campaign"
              />
            }
            archivedTitle={
              <FormattedMessage
                id="sponsoredPosts.campaigns.campaign.link.archived"
                defaultMessage="Campaign over"
              />
            }
          />
        ),
      },
    ]}
    data={applications}
  />
));

function ApplicationsTable({
  applications,
  showMoreButton,
  applicationStatusFilter,
  onShowMoreApplications,
  onSelectApplicationStatusFilter,
}) {
  return (
    <div className="sponsored-posts-overview-list">
      <div className="sponsored-posts-tabs-container">
        <Tabs
          onSelectTab={onSelectApplicationStatusFilter}
          currentTab={applicationStatusFilter}
          tabs={tabsDefinition}
        />
      </div>
      <ApplicationsTableBody
        applications={applications}
        applicationStatusFilter={applicationStatusFilter}
      />

      {showMoreButton ? (
        <ShowMoreButton onShowMoreApplications={onShowMoreApplications} />
      ) : (
        <div className="p-b-3" />
      )}
    </div>
  );
}

ApplicationsTable.propTypes = {
  applications: PropTypes.array.isRequired,
  applicationStatusFilter: PropTypes.oneOf([
    'all',
    'new',
    'onMission',
    'finished',
    'rejected',
  ]).isRequired,
  showMoreButton: PropTypes.bool.isRequired,
  onSelectApplicationStatusFilter: PropTypes.func.isRequired,
  onShowMoreApplications: PropTypes.func.isRequired,
};

export default ApplicationsTable;
