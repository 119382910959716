import { connect } from 'react-redux';

import SignUp from 'source/components/gateway/signup';

import {
  formChange,
  formErrors,
  cleanupErrors,
  createAccount,
  cleanupServerErrors,
  changeLanguage,
} from 'source/actions/account';

const mapStateToProps = (state, props) => ({
  id: 'signup',
  form: state.account.signup,
  language: state.application.locale,
  languageOptions: state.account.languageOptions,
  serverError: state.account.signup.serverError,
  referrer: props.location.query.ref,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeLanguage: (language) => dispatch(changeLanguage(language)),
  onErrorClose: () => dispatch(cleanupServerErrors('signup')),
  onChange: (form, field, value) => {
    dispatch(formChange(form, field, value));
    dispatch(cleanupErrors(form, field));
  },
  onErrors: (form, errors) => dispatch(formErrors(form, errors)),
  onSubmit: (id, form) => dispatch(createAccount(form, '/profile')),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
