import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import Tabs from 'source/components/common/tabsNav';

function LanguagePicker({ className, onChange, options, value }) {
  const tabs = options.map((langDef) => ({
    id: langDef.value,
    label: (
      <span>
        {langDef.img}
        {langDef.label}
      </span>
    ),
  }));

  return (
    <div className="aurora">
      <div className={cx('language-picker', className)}>
        <Tabs
          className={className}
          onSelectTab={onChange}
          currentTab={value}
          tabs={tabs}
        />
      </div>
    </div>
  );
}

LanguagePicker.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

LanguagePicker.defaultProps = {
  className: '',
};

export default LanguagePicker;
