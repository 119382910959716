import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import {
  ActivityStreamItem,
  ActivityStreamHeader,
  ActivityStreamBody,
} from 'source/components/common/activityStream';
import { MessageCard } from 'source/components/common/message';
import Markdown from 'source/components/common/markdown';

function RejectedHint({ feedback }) {
  return (
    <MessageCard type="info">
      <strong>
        <FormattedMessage
          id="sponsoredPosts.campaigns.verification.rejected.feedback"
          defaultMessage="We have some feedback for you."
        />
      </strong>
      <span>
        {feedback ? (
          <Markdown typeName="span" markdown={feedback} noParagraphs />
        ) : (
          <FormattedMessage
            id="sponsoredPosts.campaigns.verification.rejected.message"
            defaultMessage="We have sent you an email with our feedback. Please check the points and send us an email back when you're ready or have any questions."
          />
        )}
      </span>
    </MessageCard>
  );
}

RejectedHint.propTypes = {
  feedback: PropTypes.string,
};

function VerificationRejected(props) {
  const {
    intl,
    children,
    workflow: {
      lastUpdate,
      payload: { showHint, note },
    },
  } = props;

  return (
    <ActivityStreamItem status="failed" iconName="check-document">
      <ActivityStreamHeader
        title={intl.formatMessage({
          id: 'sponsoredPosts.campaigns.verification.rejected',
          defaultMessage: 'Verification failed',
        })}
        date={lastUpdate}
      />

      <ActivityStreamBody>
        {showHint ? <RejectedHint feedback={note} /> : null}
        {children}
      </ActivityStreamBody>
    </ActivityStreamItem>
  );
}

VerificationRejected.propTypes = {
  children: PropTypes.node,
  workflow: PropTypes.shape({
    payload: PropTypes.shape({
      showHint: PropTypes.bool,
      note: PropTypes.string,
    }),
    lastUpdate: PropTypes.string,
  }).isRequired,
};

export default injectIntl(VerificationRejected);
