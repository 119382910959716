import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import noop from 'lodash/noop';

function LanguageSelection({
  intl,
  id,
  value,
  languages,
  emptyOption,
  onChange = noop,
}) {
  return (
    <select id={id} value={value} onChange={onChange}>
      {emptyOption && (
        <option key="empty" value="-">
          {intl.formatMessage({
            id: 'options.empty.text',
            defaultMessage: 'Please select',
          })}
        </option>
      )}

      {languages.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
}

LanguageSelection.defaultProps = {
  emptyOption: false,
};

LanguageSelection.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  languages: PropTypes.array.isRequired,
  emptyOption: PropTypes.bool,
};

export default injectIntl(LanguageSelection);
