import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import cx from 'classnames';

function ProgressRadialIndicator({ progress, completed }) {
  const percent = parseInt(progress * 100, 10);
  const level = completed ? 'complete' : 'incomplete';

  return (
    <div
      className={cx(
        'progress-radial',
        `progress-${percent}`,
        `progress-${level}`,
      )}
    >
      <div className="overlay">
        {/* eslint-disable-next-line react/style-prop-object */}
        <FormattedNumber value={progress} style="percent" />
        <small>
          <FormattedMessage
            id="common.progressRadialIndicator.filledOut"
            defaultMessage="filled out"
          />
        </small>
      </div>
    </div>
  );
}

ProgressRadialIndicator.propTypes = {
  progress: PropTypes.number.isRequired,
  completed: PropTypes.bool.isRequired,
};

export default ProgressRadialIndicator;
