import { combineReducersFlat } from 'source/utils/redux';
import { asyncActionReducer, asyncStates } from '@blogfoster/redux-async-utils';

import { actionTypes } from './actions';

const getInitialJoinFormState = (privateNetworkId) => ({
  privateNetworkId,
  pitch: '',
  error: null,
  errors: {},
  submitting: false,
  submitted: false,
});

const getInitialState = () => ({
  brandClubs: {
    data: [],
    loading: false,
    loaded: false,
  },
  campaignMatches: {
    data: [],
    loading: false,
    loaded: false,
  },
  applications: {
    data: [],
    loading: false,
    loaded: false,
  },
  channels: {
    data: [],
    loading: false,
    loaded: false,
  },
  campaignTypeFilter: 'all',
  brandClubsModal: {
    selectedBrandClub: null,
  },
  joinForm: getInitialJoinFormState(),
});

const channelsReducer = asyncActionReducer(actionTypes.FETCH_CHANNELS, {
  [asyncStates.pending]: (state) => ({
    ...state,
    channels: {
      ...state.channels,
      loading: true,
      loaded: false,
    },
  }),
  [asyncStates.success]: (state, { payload }) => ({
    ...state,
    channels: {
      ...state.channels,
      data: payload.data,
      loading: false,
      loaded: true,
    },
  }),
  [asyncStates.failure]: (state, { payload: error }) => ({
    ...state,
    channels: {
      error,
      loading: false,
      loaded: true,
    },
  }),
});

const brandClubsReducer = asyncActionReducer(actionTypes.FETCH_BRAND_CLUBS, {
  [asyncStates.pending]: (state) => ({
    ...state,
    brandClubs: {
      ...state.brandClubs,
      loading: true,
      loaded: false,
    },
  }),
  [asyncStates.success]: (state, { payload }) => ({
    ...state,
    brandClubs: {
      ...state.brandClubs,
      data: payload.data,
      loading: false,
      loaded: true,
    },
  }),
  [asyncStates.failure]: (state, { payload: error }) => ({
    ...state,
    brandClubs: {
      error,
      loading: false,
      loaded: true,
    },
  }),
});

const requestToJoinReducer = asyncActionReducer(actionTypes.SEND_JOIN_REQUEST, {
  [asyncStates.pending]: (state) => ({
    ...state,
    joinForm: {
      ...state.joinForm,
      submitting: true,
      submitted: false,
      error: null,
    },
  }),
  [asyncStates.success]: (state, { payload }) => {
    const brandIndex = state.brandClubs.data.findIndex(
      (bc) => bc.id === payload.data.id,
    );

    const brandClubs = [...state.brandClubs.data];
    brandClubs[brandIndex] = { ...brandClubs[brandIndex], ...payload.data };

    return {
      ...state,
      brandClubs: {
        ...state.brandClubs,
        data: brandClubs,
      },
      joinForm: {
        ...state.joinForm,
        submitting: false,
        submitted: true,
        error: null,
      },
    };
  },
  [asyncStates.failure]: (state, { payload: error }) => ({
    ...state,
    joinForm: {
      ...state.joinForm,
      submitting: false,
      submitted: true,
      error,
    },
  }),
});

const campaignMatchesReducer = asyncActionReducer(
  actionTypes.CAMPAIGN_MATCHES_FETCH,
  {
    [asyncStates.pending]: (state) => ({
      ...state,
      campaignMatches: {
        ...state.campaignMatches,
        loading: true,
        loaded: false,
      },
    }),
    [asyncStates.success]: (state, { payload }) => ({
      ...state,
      campaignMatches: {
        ...state.campaignMatches,
        data: payload.data,
        loading: false,
        loaded: true,
      },
    }),
    [asyncStates.failure]: (state, { payload: error }) => ({
      ...state,
      campaignMatches: {
        error,
        loading: false,
        loaded: true,
      },
    }),
  },
);

const applicationsReducer = asyncActionReducer(actionTypes.APPLICATIONS_FETCH, {
  [asyncStates.pending]: (state) => ({
    ...state,
    applications: {
      ...state.applications,
      loading: true,
      loaded: false,
    },
  }),
  [asyncStates.success]: (state, { payload }) => ({
    ...state,
    applications: {
      ...state.applications,
      data: payload.data,
      loading: false,
      loaded: true,
    },
  }),
  [asyncStates.failure]: (state, { payload: error }) => ({
    ...state,
    applications: {
      error,
      loading: false,
      loaded: true,
    },
  }),
});

const campaignTypeFilterReducer = (state, action) => {
  if (action.type === actionTypes.CAMPAIGN_TYPE_FILTER_CHANGE) {
    return {
      ...state,
      campaignTypeFilter: action.payload,
    };
  }
  return state;
};

const brandModalReducer = (state = {}, action) => {
  if (action.type === actionTypes.OPEN_BRAND_MODAL) {
    const { selectedBrandClub } = action.payload;

    return {
      ...state,
      brandClubsModal: {
        selectedBrandClub,
      },
      joinForm: getInitialJoinFormState(selectedBrandClub?.id),
    };
  }
  if (action.type === actionTypes.CLOSE_BRAND_MODAL) {
    return {
      ...state,
      brandClubsModal: {
        selectedBrandClub: null,
      },
      campaignMatches: {
        data: [],
        loading: false,
        loaded: false,
      },
      joinForm: getInitialJoinFormState(),
      campaignTypeFilter: 'all',
    };
  }
  return state;
};

const joinFormChangeReducer = (state, { type, payload }) => {
  if (type !== actionTypes.JOIN_FORM_CHANGE) {
    return state;
  }
  return {
    ...state,
    joinForm: {
      ...payload,
      submitted: false,
    },
  };
};

const resetStateReducer = (state = {}, action) => {
  if (action.type !== actionTypes.RESET_SCENE) {
    return state;
  }
  return getInitialState();
};

const initialState = getInitialState();

export default combineReducersFlat(
  [
    brandClubsReducer,
    brandModalReducer,
    joinFormChangeReducer,
    requestToJoinReducer,
    campaignMatchesReducer,
    applicationsReducer,
    channelsReducer,
    resetStateReducer,
    campaignTypeFilterReducer,
  ],
  initialState,
);
