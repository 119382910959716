import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';

import { links } from 'source/i18n';
import { Alert, FormError } from 'source/components/common';
import forms from 'source/utils/forms';
import { messageProp } from 'source/utils/propTypes';

function ConfirmDeleteButton({
  showConfirm,
  onDeleteClick,
  onConfirmClick,
  onCancelClick,
}) {
  const renderButton = () => (
    <button type="submit" className="btn btn-secondary" onClick={onDeleteClick}>
      <FormattedMessage
        id="accountSettings.deleteAccount.deleteAccountButton"
        defaultMessage="Delete Account"
      />
    </button>
  );

  const renderConfirmPrompt = () => (
    <section>
      <p>
        <FormattedMessage
          id="accountSettings.deleteAccount.deleteAccountWarning"
          defaultMessage="This action <strong>CANNOT</strong> be undone. This will delete your account permanently. Are you sure?"
        />
      </p>
      <div className="btn-toolbar">
        <button
          type="submit"
          className="btn btn-primary"
          onClick={onCancelClick}
        >
          <FormattedMessage
            id="accountSettings.deleteAccount.cancelDeleteAccountButton"
            defaultMessage="No, I'm still with you."
          />
        </button>
        <button
          type="submit"
          className="btn btn-danger"
          onClick={onConfirmClick}
        >
          <FormattedMessage
            id="accountSettings.deleteAccount.confirmDeleteAccountButton"
            defaultMessage="Yes, delete my account."
          />
        </button>
      </div>
    </section>
  );

  return <div>{showConfirm ? renderConfirmPrompt() : renderButton()}</div>;
}

ConfirmDeleteButton.propTypes = {
  showConfirm: PropTypes.bool.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
};

const validateForm = (form) => {
  const errors = {};

  if (isEmpty(form.reason)) {
    errors.reason = 'accountSettings.deleteAccount.errorMissingReason';
  }

  return errors;
};

class DeleteAccountForm extends Component {
  handleAlertClose = () => {
    const { id, onAlertClose } = this.props;

    return onAlertClose(id);
  };

  renderError(name) {
    const {
      form: { errors },
    } = this.props;

    const error = errors[name];
    return error ? <FormError error={{ messageId: error }} /> : null;
  }

  renderAlert() {
    const {
      form: { message },
      intl,
    } = this.props;

    if (!message) {
      return null;
    }

    return (
      <div className="alert-container">
        <Alert
          {...message}
          values={{
            termsAndConditionsLink: (
              <a
                target="_blank"
                rel="noreferrer"
                href={intl.formatMessage(links.termsAndConditionsLink)}
              >
                <FormattedMessage
                  id="termsAndConditionsLink.label"
                  defaultMessage="Terms & Conditions"
                />
              </a>
            ),
          }}
          onClose={this.handleAlertClose}
        />
      </div>
    );
  }

  render() {
    const { intl, form, onChange, onShowConfirm, onSubmit, onCancel } =
      this.props;

    return (
      <div>
        <div className="alert alert-warning" role="alert">
          <FormattedMessage
            id="accountSettings.deleteAccount.deleteAccountHint"
            defaultMessage="<strong>Attention!</strong> The deletion of your account can't be undone."
          />
        </div>
        <p>
          <FormattedMessage
            id="accountSettings.deleteAccount.text"
            defaultMessage="A dark day for EQOLOT: You can delete your account here. Please tell us why you are leaving so we can improve our service and product."
          />
        </p>
        <form onChange={onChange}>
          <div className="row">
            <div className="col-md-12">
              <fieldset
                className={cx('form-group', {
                  'has-danger': !!form.errors.reason,
                })}
              >
                <textarea
                  className="form-control"
                  id="reason"
                  rows="2"
                  value={form.reason}
                  placeholder={intl.formatMessage({
                    id: 'accountSettings.deleteAccount.reason',
                    defaultMessage:
                      'Please tell us why you want to delete your account.',
                  })}
                  onChange={noop}
                />

                {this.renderError('reason')}
              </fieldset>
            </div>
          </div>
          {this.renderAlert()}
          <ConfirmDeleteButton
            showConfirm={form.showConfirm}
            onDeleteClick={onShowConfirm}
            onConfirmClick={onSubmit}
            onCancelClick={onCancel}
          />
        </form>
      </div>
    );
  }
}

DeleteAccountForm.propTypes = {
  id: PropTypes.string.isRequired,

  form: PropTypes.shape({
    reason: PropTypes.string.isRequired,
    showConfirm: PropTypes.bool.isRequired,
    message: messageProp,
    errors: PropTypes.object.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onAlertClose: PropTypes.func.isRequired,
  onShowConfirm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default injectIntl(forms({ validateForm })(DeleteAccountForm));
