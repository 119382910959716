/* eslint-disable consistent-return */
import get from 'lodash/get';
import { clearFields } from 'redux-form';
import { withHandlers } from 'recompose';

/**
 * Because redux-form doesn't clear fields when they are unmounted we need to
 * do it manually.
 *
 * The function takes a list of predicates that should return an array of
 * fields to clear the values for. The first predicate that returns a non-empty
 * list wins and the returned fields will be cleared. Each predicate function
 * will be given all arguments that the `onChange` handler of `redux-form`
 * receives.
 *
 * TODO: Figure out how to do this in a nicer way.
 */
const clearTaxOn = (...predicates) =>
  withHandlers(() => ({
    onChange: () => (values, dispatch, props, previousValues) => {
      predicates.forEach((getFieldsToClear) => {
        const fields = getFieldsToClear(
          values,
          dispatch,
          props,
          previousValues,
        );

        if (fields && fields.length > 0) {
          return dispatch(clearFields(props.form, false, false, ...fields));
        }
      });
    },
  }));

export default clearTaxOn(
  function getCountryChangeFields(values, dispatch, props, previousValues) {
    const previousCountry = get(previousValues, 'address.country');
    const nextCountry = get(values, 'address.country');

    if (previousCountry !== nextCountry) {
      return props.taxFields;
    }
  },
  function getVatNotRegisteredChangeFields(
    values,
    dispatch,
    props,
    previousValues,
  ) {
    const previousVatNotRegistered = get(
      previousValues,
      'tax.vatNotRegistered',
    );
    const nextVatNotRegistered = get(values, 'tax.vatNotRegistered');

    if (previousVatNotRegistered !== nextVatNotRegistered) {
      return [
        'tax.taxDeclarationConfirmation',
        'tax.vatId',
        'tax.taxId',
        'tax.taxIdCode',
      ];
    }
  },
  function getHasVatIdChangeFields(values, dispatch, props, previousValues) {
    const previousHasVatId = get(previousValues, 'tax.hasVatId');
    const nextHasVatId = get(values, 'tax.hasVatId');

    if (previousHasVatId !== nextHasVatId) {
      return ['tax.taxDeclarationConfirmation', 'tax.vatId'];
    }
  },
);
/* eslint-enable consistent-return */
