import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function Card(props) {
  return (
    <div className={cx('card', { [props.className]: props.className })}>
      <div className="card-block">{props.children}</div>
    </div>
  );
}

Card.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

Card.defaultProps = {
  children: null,
  className: '',
};

export default Card;
