import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import OutsideClickHandler from 'react-outside-click-handler';

import { Card, Icon } from 'source/components/common';
import { platformLabels } from 'source/scenes/profile/utils/channel';

function ConnectPlatform({ platform, onClick }) {
  return (
    <Card className={`text-center channel channel-${platform}`}>
      <div className="card-block" onClick={onClick} role="button" tabIndex={0}>
        <Icon name={platform === 'pinterest' ? 'pinterest-white' : platform} />
        <p>
          <FormattedMessage {...platformLabels[platform]} />
        </p>
      </div>
    </Card>
  );
}

ConnectPlatform.propTypes = {
  platform: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

class ChannelsPicker extends Component {
  handleProfileSelected = (type, options) => (e) => {
    e.preventDefault();

    const { onCollapse, onSelected } = this.props;

    onCollapse();
    onSelected(type, options);
  };

  trackConnectClick = () => {
    if (!window.dataLayer) {
      return;
    }

    window.dataLayer.push({
      event: 'virtualPageView',
      page: 'profile/add_channel',
    });
  };

  handleAddProfile = (e) => {
    e.preventDefault();

    const { onExpand } = this.props;

    onExpand();
    this.trackConnectClick();
  };

  // triggered by outsideClick wrapper component..
  handleClickOutside = () => {
    const { expanded, onCollapse } = this.props;

    // only fore onCollapse if it's not already collapsed
    if (expanded) {
      onCollapse();
    }
  };

  renderSelector = (channelPlatforms) => (
    <div>
      {!this.props.hasChannels && (
        <div className="channels-picker-empty">
          <h4 className="text-center font-weight-bold">
            <FormattedMessage
              id="channels.picker.fresh.title"
              defaultMessage="The journey begins!"
            />
          </h4>
          <p className="text-center">
            <FormattedMessage
              id="channels.picker.fresh.message"
              defaultMessage="Pick the platform where you are most active:"
            />
          </p>
        </div>
      )}
      <div className="card-group">
        {channelPlatforms.map((platform) => (
          <ConnectPlatform
            key={platform}
            platform={platform}
            onClick={this.handleProfileSelected(platform)}
          />
        ))}
      </div>
    </div>
  );

  renderConnectButton() {
    return (
      <div
        className="btn btn-primary"
        onClick={this.handleAddProfile}
        role="button"
        tabIndex={0}
      >
        <FormattedMessage
          id="channels.picker.connectChannel"
          defaultMessage="Connect another Channel"
        />
      </div>
    );
  }

  render() {
    const { expanded, hasChannels } = this.props;

    const channelPlatforms = [
      'instagram',
      'ga',
      'youtube',
      'tiktok',
      'pinterest',
      'twitter',
    ];

    return (
      <OutsideClickHandler onOutsideClick={this.handleClickOutside}>
        <div className="flex flex-center">
          {expanded || !hasChannels
            ? this.renderSelector(channelPlatforms)
            : this.renderConnectButton()}
        </div>
      </OutsideClickHandler>
    );
  }
}

ChannelsPicker.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onSelected: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  onCollapse: PropTypes.func.isRequired,
  hasChannels: PropTypes.bool.isRequired,
};

export default ChannelsPicker;
