import React from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import cx from 'classnames';
import { defaultMemoize as memoize } from 'reselect';
import { pure, withHandlers, compose } from 'recompose';

import Icon from 'source/components/common/icon';

// Helpers

const updateWhere = (list, predicate, update) => {
  const index = findIndex(list, predicate);
  if (index < 0) {
    return list;
  }
  const listCopy = [...list];
  listCopy.splice(index, 1, update);
  return listCopy;
};

// Redux

const getTotalPoints = memoize((state) =>
  state.reduce((sum, { value }) => sum + value, 0),
);

export const actionTypes = {
  CHANGE: 'pointDistribution/CHANGE',
};

export const selectors = {
  getTotalPoints,
};

export const pointDistributionUpdate = (name) => (points) => ({
  type: actionTypes.CHANGE,
  payload: points,
  name,
});

export const pointDistributionReducer = (name) => (state, action) =>
  action.type === actionTypes.CHANGE && action.name === name
    ? action.payload
    : state;

// Component

function PointsControl({
  className,
  points,
  maxPoints,
  onAddPoint,
  onRemovePoint,
}) {
  return (
    <div className={cx(className, 'point-dist__controls')}>
      <button
        className="btn point-dist__btn point-dist__btn--remove"
        onClick={onRemovePoint}
      >
        <Icon name="minus" />
      </button>
      <div className="point-dist__point-list">
        {range(maxPoints).map((i) => (
          <div
            key={`point-${i}`}
            className={cx('point-dist__point', {
              active: i < points,
            })}
          />
        ))}
      </div>
      <button
        className="btn point-dist__btn point-dist__btn--add"
        onClick={onAddPoint}
      >
        <Icon name="plus" />
      </button>
    </div>
  );
}

PointsControl.propTypes = {
  className: PropTypes.string,
  maxPoints: PropTypes.number.isRequired,
  points: PropTypes.number.isRequired,
  onAddPoint: PropTypes.func.isRequired,
  onRemovePoint: PropTypes.func.isRequired,
};

PointsControl.defaultProps = {
  className: '',
};

const withAddRemoveHandlers = withHandlers({
  onAddPoint:
    ({ onAddPoint, name }) =>
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onAddPoint(name);
    },

  onRemovePoint:
    ({ onRemovePoint, name }) =>
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onRemovePoint(name);
    },
});

const PointsControlWrapped = compose(
  pure,
  withAddRemoveHandlers,
)(PointsControl);

function PointDistributionForm({ className, initialValues, values, ...props }) {
  const state = values || initialValues;
  return (
    <div className={`${className} point-dist`}>
      {state.map(({ name, label, value }) => (
        <div key={name} className="point-dist__attribute">
          <div className="point-dist__attribute__label">{label}</div>
          <PointsControlWrapped
            {...props}
            className="point-dist__attribute__controls"
            name={name}
            points={value}
          />
        </div>
      ))}
    </div>
  );
}

const attributeShape = PropTypes.shape({
  name: PropTypes.any.isRequired,
  label: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
});

PointDistributionForm.propTypes = {
  className: PropTypes.string,
  maxPoints: PropTypes.number.isRequired,
  initialValues: PropTypes.arrayOf(attributeShape),
  values: PropTypes.arrayOf(attributeShape),
  onChange: PropTypes.func.isRequired,
};

PointDistributionForm.defaultProps = {
  className: '',
  initialValues: [],
  values: undefined,
};

const withAddRemoveGuards = withHandlers({
  onAddPoint:
    ({ onChange, maxPoints, initialValues, values }) =>
    (name) => {
      const state = values || initialValues;
      const totalPoints = getTotalPoints(state);
      if (totalPoints + 1 > maxPoints) {
        return;
      }
      const attribute = find(state, { name });

      onChange(
        updateWhere(
          state,
          { name },
          {
            ...attribute,
            value: attribute.value + 1,
          },
        ),
      );
    },

  onRemovePoint:
    ({ onChange, initialValues, values }) =>
    (name) => {
      const state = values || initialValues;
      const attribute = find(state, { name });
      const valueNext = attribute.value - 1;
      if (valueNext < 0) {
        return;
      }

      onChange(
        updateWhere(
          state,
          { name },
          {
            ...attribute,
            value: valueNext,
          },
        ),
      );
    },
});

export default compose(pure, withAddRemoveGuards)(PointDistributionForm);
