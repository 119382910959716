import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import * as CustomPropTypes from 'source/utils/propTypes';
import ConnectButton from './common/connectButton';

import UploadInsightsModal from '../insights/UploadInsightsModal';
import InsightsStatus from '../insights/InsightsStatus';
import DisconnectConfirm from '../disconnectChannel/disconnectConfirm';
import DisconnectError from '../disconnectChannel/disconnectError';

const instructionVideoUrls = defineMessages({
  instagram: {
    id: 'channels.instagram.insights.uploadModal.instructionsVideoUrl',
    defaultMessage: 'https://www.youtube.com/embed/Vtpf7uTXWaA',
  },

  tiktok: {
    id: 'channels.tiktok.insights.uploadModal.instructionsVideoUrl',
    defaultMessage: 'https://www.youtube.com',
  },
});

function ComingSoon({ platform }) {
  return (
    <div className="text-center">
      <h4>
        <FormattedMessage id={`channels.${platform}.comingSoon`} />
      </h4>
    </div>
  );
}

ComingSoon.propTypes = {
  platform: PropTypes.string,
};

function Connect({ id, platform, nextOnConnect, onNextStep, onConnect }) {
  return (
    <div className="channel-form">
      <ConnectButton
        id={id}
        platform={platform}
        onClick={nextOnConnect ? onNextStep : onConnect}
      />
    </div>
  );
}

Connect.propTypes = {
  id: PropTypes.string,
  platform: PropTypes.string,
  nextOnConnect: PropTypes.bool,
  onNextStep: PropTypes.func,
  onConnect: PropTypes.func,
};

function ContactSupportMessage({ userId, channelId }) {
  return (
    <FormattedMessage
      id="channels.disconnect.contactSupport"
      defaultMessage="To rename this channel, please {supportLink}"
      values={{
        supportLink: (
          <a
            href={`mailto:support@eqolot.com?subject=User:${userId}&body=Channel:${channelId}`}
          >
            <FormattedMessage
              id="channels.disconnect.contactSupport.linkLabel"
              defaultMessage="contact support."
            />
          </a>
        ),
      }}
    />
  );
}

ContactSupportMessage.propTypes = {
  channelId: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
};

class ChannelPreStep extends React.PureComponent {
  enterConfirmPrompt = (e) => {
    e.preventDefault();
    const { id, onEnterDisconnect } = this.props;
    onEnterDisconnect(id, true);
  };

  exitConfirmPrompt = () => {
    const { id, onEnterDisconnect } = this.props;
    onEnterDisconnect(id, false);
  };

  handleConfirmClick = () => {
    const { id, onDisconnect } = this.props;
    onDisconnect({ id });
  };

  openInsightsUploadModal = () => {
    this.props.onInsightsUploadModalOpen(this.props.id);
  };

  closeInsightsUploadModal = () => {
    this.props.onInsightsUploadModalClose(this.props.id);
  };

  handleNextStep = () => {
    this.props.onNextStep();

    if (!window.dataLayer) {
      return;
    }

    window.dataLayer.push({
      event: 'virtualPageView',
      page: `profile?id=${this.props.id}&panel=config`,
    });
  };

  renderGeneric = () => {
    const { id, userId } = this.props;
    return (
      <>
        <h5 className="m-b-1">
          <FormattedMessage
            id="channels.editProfileHeadline"
            defaultMessage="You can edit your channel configuration here"
          />
        </h5>
        <button className="btn btn-primary m-b-1" onClick={this.handleNextStep}>
          <FormattedMessage
            id="channels.editProfileButton"
            defaultMessage="Edit Channel Configuration"
          />
        </button>
        <p>
          <ContactSupportMessage userId={userId} channelId={id} />
        </p>
      </>
    );
  };

  renderInstagram = () => {
    const {
      id: channelId,
      userId,
      platform,
      channelName,
      extension = {},
      showInsightsUpload,
      form: { screenshots },
      onSubmitInsights,
      intl: { formatMessage },
    } = this.props;

    return (
      <>
        {extension.apiVersion ? (
          <h5 className="m-b-1">
            <FormattedMessage
              id="channels.editProfileHeadline"
              defaultMessage="You can edit your channel configuration here"
            />
          </h5>
        ) : (
          <>
            <UploadInsightsModal
              isOpen={showInsightsUpload}
              channelId={channelId}
              platform={platform}
              channelName={channelName}
              screenshots={screenshots}
              instructionsVideoUrl={formatMessage(
                instructionVideoUrls.instagram,
              )}
              onClose={this.closeInsightsUploadModal}
              onSubmitInsights={onSubmitInsights}
            />
            <button
              className="btn btn-primary m-b-1"
              onClick={this.openInsightsUploadModal}
            >
              <FormattedMessage
                id="channels.insights.uploadButton"
                defaultMessage="Upload Insights"
              />
            </button>
            <p>
              <InsightsStatus platform={platform} screenshots={screenshots} />
            </p>
          </>
        )}
        <div className="flex flex-center-vertical m-b-1">
          <button className="btn btn-primary" onClick={this.handleNextStep}>
            <FormattedMessage
              id="channels.editProfileButton"
              defaultMessage="Edit Channel Configuration"
            />
          </button>
        </div>

        <p>
          <ContactSupportMessage userId={userId} channelId={channelId} />
        </p>
      </>
    );
  };

  renderTiktok = () => {
    const {
      id: channelId,
      userId,
      platform,
      channelName,
      showInsightsUpload,
      form: { screenshots },
      onSubmitInsights,
      intl: { formatMessage },
    } = this.props;

    return (
      <>
        <UploadInsightsModal
          isOpen={showInsightsUpload}
          channelId={channelId}
          platform={platform}
          channelName={channelName}
          screenshots={screenshots}
          instructionsVideoUrl={formatMessage(instructionVideoUrls.tiktok)}
          onClose={this.closeInsightsUploadModal}
          onSubmitInsights={onSubmitInsights}
        />

        <button
          className="btn btn-primary m-b-1"
          onClick={this.openInsightsUploadModal}
        >
          <FormattedMessage
            id="channels.insights.uploadButton"
            defaultMessage="Upload Insights"
          />
        </button>
        <p>
          <InsightsStatus platform={platform} screenshots={screenshots} />
        </p>
        <div className="flex flex-center-vertical m-b-1">
          <button className="btn btn-primary" onClick={this.handleNextStep}>
            <FormattedMessage
              id="channels.editProfileButton"
              defaultMessage="Edit Channel Configuration"
            />
          </button>
        </div>

        <p>
          <ContactSupportMessage userId={userId} channelId={channelId} />
        </p>
      </>
    );
  };

  renderChannel = () => {
    const { platform } = this.props;

    if (platform === 'instagram') {
      return this.renderInstagram();
    }
    if (platform === 'tiktok') {
      return this.renderTiktok();
    }

    return this.renderGeneric();
  };

  render() {
    const {
      id,
      channelName,
      userId,
      restrictAccess,
      platform,
      status,
      newChannel,
      nextOnConnect,
      onConnect,
      form: { disconnect, errorStateId },
    } = this.props;

    const isGaError = platform === 'ga' && !newChannel && errorStateId;

    /**
     * NOTE
     *   If channel was previously integrated (`status` == true), we want to show
     *   a `DisconnectButton`, otherwise we restrict integration via
     *   `restrictAccess` flag.
     */

    if (restrictAccess) {
      return <ComingSoon platform={platform} />;
    }

    if (disconnect.confirmation) {
      // disconnect confirmation view
      return (
        <DisconnectConfirm
          name={channelName}
          id={id}
          userId={userId}
          submitting={disconnect.submitting}
          onConfirm={this.handleConfirmClick}
          onCancel={this.exitConfirmPrompt}
        />
      );
    }

    if (disconnect.submitFailed) {
      // disconnect error view
      return (
        <DisconnectError
          id={id}
          userId={userId}
          onExit={this.exitConfirmPrompt}
          failureReason={disconnect.failureReason}
        />
      );
    }

    if (!status && platform !== 'website' && !isGaError) {
      // initial view if user needs to connect
      return (
        <Connect
          id={id}
          platform={platform}
          nextOnConnect={nextOnConnect}
          onNextStep={this.handleNextStep}
          onConnect={onConnect}
        />
      );
    }

    return (
      <div className="container">
        {isGaError ? (
          <div className="m-b-2 wizard-ga-error">
            <FormattedMessage
              id={errorStateId}
              values={{
                supportLink: (
                  <a href="mailto:support@eqolot.com">support@eqolot.com</a>
                ),
              }}
            />
          </div>
        ) : (
          this.renderChannel()
        )}

        <div>
          <a href="#" onClick={this.enterConfirmPrompt}>
            <FormattedMessage
              id="channels.disconnect.disconnectLink"
              defaultMessage="Disconnect Channel"
            />
          </a>
        </div>
      </div>
    );
  }
}

ChannelPreStep.propTypes = {
  id: PropTypes.string.isRequired,
  channelName: PropTypes.string.isRequired,
  form: CustomPropTypes.form.isRequired,
  status: PropTypes.bool.isRequired,
  newChannel: PropTypes.bool,
  restrictAccess: PropTypes.bool,
  platform: PropTypes.string.isRequired,
  showInsightsUpload: PropTypes.bool,
  nextOnConnect: PropTypes.bool,
  onNextStep: PropTypes.func,
  onConnect: PropTypes.func,
  onSubmitInsights: PropTypes.func,
  onDisconnect: PropTypes.func.isRequired,
  onEnterDisconnect: PropTypes.func.isRequired,
  onInsightsUploadModalOpen: PropTypes.func,
  onInsightsUploadModalClose: PropTypes.func,
  userId: PropTypes.number.isRequired,
  extension: PropTypes.shape({
    apiVersion: PropTypes.string,
  }),
};

ChannelPreStep.defaultProps = {
  id: '',
  status: false,
  channelName: '',
  newChannel: false,
  restrictAccess: false,
  nextOnConnect: false,
  userId: null,
  showInsightsUpload: false,
  onInsightsUploadModalOpen: noop,
  onInsightsUploadModalClose: noop,
};

const mapStateToProps = (state) => ({
  userId: state.application.user.id,
});

export default connect(mapStateToProps)(injectIntl(ChannelPreStep));
