import noop from 'lodash/noop';
import { connect } from 'react-redux';

import { submitForm } from 'source/scenes/accountSettings/legacy/actions';

import { changeLanguage } from 'source/actions/account';

import { loadApplicationState } from 'source/actions/application';

import { getSignupCountries } from 'source/i18n/signupCountries.js';
import { languageOptions } from 'source/utils/languages';

import CheckProfile from 'source/components/gateway/checkProfile';

const mapStateToProps = (state) => ({
  id: 'profile',
  language: state.application.locale,
  signupCountries: getSignupCountries(state.application.locale),
  languageOptions,
});

const mapDispatchToProps = {
  onSubmit: (id, form) => (dispatch) =>
    dispatch(submitForm(id, form)).then(() => dispatch(loadApplicationState())),
  onChangeLanguage: changeLanguage,
  onChange: noop,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckProfile);
