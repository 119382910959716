import { quadraticEasingInOut as ease } from './math';

export const scrolling = (element, direction, distance, duration) => {
  let startTime = null;
  let accumulation = 0;

  const animation = (currentTime) => {
    if (!startTime) {
      startTime = currentTime;
    }
    const timeElapsed = currentTime - startTime;
    const startingPosition = 0;

    const run = ease(timeElapsed, startingPosition, distance, duration);
    accumulation += run;

    if (direction === 'left') {
      element.scrollLeft -= run;
    }
    if (direction === 'right') {
      element.scrollLeft += run;
    }

    if (direction === 'top') {
      element.scrollTop -= run;
    }
    if (direction === 'bottom') {
      element.scrollTop += run;
    }

    if (timeElapsed < duration && distance > accumulation) {
      window.requestAnimationFrame(animation);
    }
  };

  window.requestAnimationFrame(animation);
};
