import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

function Failure({ failureReason, onRepeat, onRedirect }) {
  return (
    <div className="container">
      <div className="modal-body wizard-modal wizard-modal-failure">
        <div className="wizard-step">
          <div className="row">
            <div className="col-md-6 col-md-offset-3 wizard-headlines">
              <h4>
                <FormattedMessage
                  id="createWebsite.failure.notAdded"
                  defaultMessage="Sorry, we could not add your website."
                />
              </h4>
              <div className="failure-reason">
                {failureReason ? (
                  <span className="text-danger">
                    <FormattedMessage {...failureReason} />
                  </span>
                ) : null}
              </div>
            </div>
            <div className="col-md-8 col-md-offset-2">
              <img
                src="/images/website-creation-failed.png"
                alt="website creation failed"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer modal-footer-failure">
        <button className="btn btn-secondary" onClick={onRedirect}>
          <FormattedMessage
            id="createWebsite.failure.btn.gotoDashboard"
            defaultMessage="Go to Dashboard"
          />
        </button>
        <button className="btn btn-primary" onClick={onRepeat}>
          <FormattedMessage
            id="createWebsite.failure.btn.addWebsite"
            defaultMessage="Add Another Website"
          />
        </button>
      </div>
    </div>
  );
}

Failure.propTypes = {
  failureReason: PropTypes.object,

  onRepeat: PropTypes.func.isRequired,
  onRedirect: PropTypes.func.isRequired,
};

export default Failure;
