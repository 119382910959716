/* eslint global-require: 0 */

import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import get from 'lodash/get';

import { getInitialState } from 'source/reducers';
import { createStorage } from 'source/utils';
import experiments from 'source/utils/experiments';
import { auth } from 'source/utils/auth';
import { browserLocale, defaultLocale, supportedLocales } from 'source/i18n';

const getFallbackLocale = () => {
  if (supportedLocales.includes(browserLocale)) {
    return browserLocale;
  }

  return defaultLocale;
};

export default function createApplicationStore(rootReducer, browserHistory) {
  const appStorage = createStorage();

  const locale = appStorage.read('language') || getFallbackLocale();

  const accessToken = auth.getAccessToken();
  const env = process.env.NODE_ENV || 'development';

  let middlewares = [thunk, routerMiddleware(browserHistory)];
  let composeEnhancers = compose;

  if (process.env.NODE_ENV !== 'production') {
    middlewares = [...middlewares, logger];
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }

  // TODO: (alexbeletsky) - try to get rid of this
  // resetState reducer, for cleaning up state on login/logout and other redirections
  // http://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
  const resetStateReducer = (reducer) => (state, action) => {
    if (action.type === 'GLOBAL_RESET_STORE') {
      const accessToken =
        get(state, 'account.accessToken') || auth.getAccessToken();
      const locale =
        get(state, 'application.locale') ||
        appStorage.read('language') ||
        getFallbackLocale();

      const next = reducer(getInitialState(accessToken, locale, env), action);

      // preserve routing part of state, to avoid route reload by react-router-redux middleware
      return { ...next, routing: state.routing };
    }

    return reducer(state, action);
  };

  const store = createStore(
    resetStateReducer(rootReducer),
    getInitialState(accessToken, locale, env),
    composeEnhancers(applyMiddleware(...middlewares)),
  );

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  // initialize experiments module..
  experiments.setStore(store);

  return store;
}
