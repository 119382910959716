import { push } from 'react-router-redux';
import { actionAuthRequest, requestFlow } from 'source/utils/axios';
import { createStorage } from 'source/utils';
import constants from 'source/constants';
import { defaultLocale } from 'source/i18n';

// general functions
export const toggleNavigation = () => ({
  type: constants.APPLICATION_SIDEBAR_NAVIGATION_TOGGLE,
});

export const toggleUserSettings = () => ({
  type: constants.APPLICATION_USER_SETTINGS_TOGGLE,
});

export const loadingApplicationState = () => ({
  type: constants.APPLICATION_STATE_LOADING,
});

export const loadedApplicationState = (user, channels = []) => ({
  type: constants.APPLICATION_STATE_LOADED,
  payload: {
    user: {
      ...user,
      hasChannels: channels.length !== 0,
    },
  },
});

export const loadingApplicationStateFailed = (error, resp) => ({
  type: constants.APPLICATION_STATE_LOADING_FAILED,
  payload: { error, resp },
});

export const setLanguage = (language) => {
  // save language also to localstorage
  createStorage().save('language', language);

  return { type: constants.APPLICATION_SET_LANGUAGE, payload: language };
};

export const synchronizeLanguageSetting = (user) => (dispatch) => {
  // use the language from user settings, fallback to default locale in case
  // of language settings reset to null..

  const language = user.language || defaultLocale;

  dispatch(setLanguage(language));
};

export const requestConfirmationMail = () => (dispatch, getState) => {
  const onBefore = () => dispatch({ type: constants.CONFIRM_EMAIL_SENDING });
  const onSuccess = () => dispatch({ type: constants.CONFIRM_EMAIL_SENT });

  const request = () =>
    actionAuthRequest(dispatch, getState).post(
      '/v1/users/confirm-email/request',
    );

  return requestFlow(request, { onBefore, onSuccess });
};

export const loadApplicationState = () => (dispatch, getState) => {
  dispatch(loadingApplicationState());

  const fields = [
    'id',
    'email',
    'firstname',
    'lastname',
    'salutation',
    'yearOfBirth',
    'streetName',
    'streetNumber',
    'postalCode',
    'city',
    'phone',
    'boarded',
    'confirmed',
    'language',
    'country',
    'createdAt',
    'updatedAt',
    'lastLogin',
    'settings',
    'acceptedTerms',
    'groups',
  ];

  const fetchUser = () =>
    actionAuthRequest(dispatch, getState).get(`/v2/users`, {
      params: { fields: JSON.stringify(fields) },
    });

  const fetchUserChannels = () =>
    actionAuthRequest(dispatch, getState).get('/v2/channels', {
      params: { fields: JSON.stringify(['id']) },
    });

  return Promise.all([fetchUser(), fetchUserChannels()])
    .then(([user, channels]) => {
      dispatch(synchronizeLanguageSetting(user.data));
      dispatch(loadedApplicationState(user.data, channels.data));
    })
    .catch((err) => {
      dispatch(
        loadingApplicationStateFailed(
          new Error('loading application state failed'),
          err.data,
        ),
      );
      dispatch(push('/signin'));
    });
};
