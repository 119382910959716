import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isRegExp from 'lodash/isRegExp';

import config from 'config';

import { trim } from '.';

export const isFeatureAvailable = (feature) => (user) => {
  if (!user) {
    throw new Error(
      trim(`missing user, when checking for feature! did you forget to
            pass the signed in user to the "isFeatureAvailable" function`),
    );
  }

  const userGroupsIds = get(user, 'groups', []).map((group) => group.id);
  const allowedGroups = get(config, ['features', feature, 'allowedGroups'], []);

  const intersectionData = intersection(userGroupsIds, allowedGroups);
  const allowedByGroup = intersectionData.length !== 0;

  const allowedUsers = get(config, ['features', feature, 'allowedUsers'], []);

  const allowedByUser = allowedUsers.some((allowedEmail) => {
    if (isRegExp(allowedEmail)) {
      return allowedEmail.test(user.email);
    }

    return allowedEmail === user.email;
  });

  return allowedByUser || allowedByGroup;
};
