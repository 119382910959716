import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import {
  ToolTipController,
  Select as ToolTipWrapper,
} from 'react-tooltip-controller';

import Card, { CardBlock, CardTitle } from 'source/components/common/card';
import Icon from 'source/components/common/icon';
import Tooltip from 'source/components/common/tooltip';
import Markdown from 'source/components/common/markdown';
import { trim } from 'source/utils';

import { publishDateMessageProps, campaignShape } from './campaignCardHelpers';
import CampaignImage from './CampaignImage';
import CampaignCardLabel from './CampaignCardLabel';
import CampaignTypeLabel from './CampaignTypeLabel';

function CampaignCardHeader({
  campaign: {
    details: { headline, mission },
  },
}) {
  return (
    <div className="sponsored-posts-campaign-header-container">
      <div className="sponsored-posts-campaign-header">
        <CardTitle className="sponsored-posts-campaign-name">
          {headline}
        </CardTitle>
        <div className="sponsored-posts-campaign-description">
          <Markdown markdown={trim(mission)} typeName="span" noParagraphs />
        </div>
      </div>
    </div>
  );
}

CampaignCardHeader.propTypes = {
  campaign: campaignShape.isRequired,
};

CampaignCardHeader.defaultProps = {
  available: true,
};

function DetailsBody({ campaign: { timeline } }) {
  return (
    <div className="sponsored-posts-campaign-card-meta">
      <div className="sponsored-posts-campaign-publish-date">
        <FormattedMessage {...publishDateMessageProps(timeline)} />
      </div>
      <div className="sponsored-posts-campaign-net-price-box">
        <div className="sponsored-posts-campaign-price-info">
          <span className="price-info">
            <FormattedMessage
              id="netPrice.teaser"
              defaultMessage="Complete Profile"
            />
          </span>
          &nbsp;
          <ToolTipController detect="hover" offsetX={-198}>
            <ToolTipWrapper>
              <span className="icon-wrapper">
                <Icon name="info" />
              </span>
            </ToolTipWrapper>
            <Tooltip className="tooltip-net-price">
              <FormattedMessage
                id="netPrice.teaser.tooltip"
                defaultMessage="Connect your channel"
              />
            </Tooltip>
          </ToolTipController>
        </div>
        <div className="sponsored-posts-campaign-price sponsored-posts-campaign-price-card sponsored-posts-campaign-price-card-blurred">
          <strong className="h1">1111,11€</strong>
        </div>
      </div>
    </div>
  );
}

DetailsBody.propTypes = {
  campaign: PropTypes.shape({
    timeline: PropTypes.object.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    payment: PropTypes.object.isRequired,
  }).isRequired,
};

function CampaignCardTeaser({ campaign, label }) {
  return (
    <div className="sponsored-posts-campaign-card-container">
      <Card className={cx('sponsored-posts-campaign-card')}>
        <CampaignTypeLabel type={campaign.type} />
        {label ? <CampaignCardLabel label={label} /> : null}
        <CardBlock element={<CampaignImage campaign={campaign} />} />
        <CardBlock>
          <CampaignCardHeader campaign={campaign} />
          <DetailsBody campaign={campaign} />
        </CardBlock>
      </Card>
    </div>
  );
}

CampaignCardTeaser.propTypes = {
  campaign: campaignShape,
  label: PropTypes.string,
};

CampaignCardTeaser.defaultProps = {
  label: '',
};

export default CampaignCardTeaser;
