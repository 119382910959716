import set from 'lodash/set';
import get from 'lodash/get';
import omit from 'lodash/omit';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';

export const nextItemId = (collection) => {
  const lastItem = collection[collection.length - 1];

  // if there is now item in the list start with 0
  if (!lastItem) {
    return 0;
  }

  // if the last item in the list is a number just increase the counter
  const lastId = Number(lastItem.id);
  if (lastId >= 0) {
    return lastItem.id + 1;
  }

  // We might have non-numbers in the list, so we need to scan
  // the entire list for possible ids, so we can calculate the
  // ==> `max(ids) + 1`.
  const ids = collection.map((item) => {
    const parsedId = Number(item.id);

    // set non-numbers to 0
    return parsedId >= 0 ? parsedId : 0;
  });

  return Math.max(...ids) + 1;
};

export const updateItem = (item, field, value) => ({ ...item, [field]: value });

export const push = (collection, item) => [...collection, item];

export const unshift = (collection, item) => [item, ...collection];

export const insertBy = (collection, item, attr) => {
  const itemIndex = findIndex(
    collection,
    (collectionItem) => collectionItem[attr] > item[attr],
  );

  if (itemIndex === 0) {
    return unshift(collection, item);
  }
  if (itemIndex === -1) {
    return push(collection, item);
  }

  return [
    ...collection.slice(0, itemIndex),
    item,
    ...collection.slice(itemIndex),
  ];
};

export const update = (collection, item, { index } = {}) => {
  const itemIndex = index >= 0 ? index : findIndex(collection, { id: item.id });
  if (itemIndex === -1) {
    console.warn('could not find item', item.id, 'in collection', collection); // eslint-disable-line no-console
    return collection;
  }

  return [
    ...collection.slice(0, itemIndex),
    item,
    ...collection.slice(itemIndex + 1),
  ];
};

export const remove = (collection, item, { index } = {}) => {
  const itemIndex = index >= 0 ? index : findIndex(collection, { id: item.id });
  if (itemIndex === -1) {
    return collection;
  }

  return [
    ...collection.slice(0, itemIndex),
    ...collection.slice(itemIndex + 1),
  ];
};

export const swapByIndex = (collection, firstIndex, secondIndex) => {
  const collectionCopy = [...collection];

  const tmp = collection[firstIndex];
  collectionCopy[firstIndex] = collection[secondIndex];
  collectionCopy[secondIndex] = tmp;

  return collectionCopy;
};

export const swap = (collection, first, second) => {
  const firstIndex = findIndex(collection, { id: first.id });
  const secondIndex = findIndex(collection, { id: second.id });

  return swapByIndex(collection, firstIndex, secondIndex);
};

export const moveUp = (collection, item) => {
  const itemIndex = findIndex(collection, { id: item.id });
  const neighbor = collection[itemIndex - 1];

  if (!neighbor) {
    return collection;
  }

  return swap(collection, item, neighbor);
};

export const moveDown = (collection, item) => {
  const itemIndex = findIndex(collection, { id: item.id });
  const neighbor = collection[itemIndex + 1];

  if (!neighbor) {
    return collection;
  }

  return swap(collection, neighbor, item);
};

export const without = omit;

/**
 * Object transforms
 */

export const setIn = (data, path, value) => set(cloneDeep(data), path, value);

/**
 * Push to a nested list, returning the parent object
 */
export const pushIn = (data, path, value) => {
  const list = get(data, path);

  if (!list) {
    return data;
  }

  return setIn(data, path, [...list, value]);
};

/**
 * Replace element in a nested list, returning the parent object
 */
export const replaceIn = (data, path, predicate, value) => {
  const list = get(data, path, []);
  const index = findIndex(list, predicate);

  if (index === -1) {
    return data;
  }

  const copy = [...list];
  copy.splice(index, 1, value);

  return setIn(data, path, copy);
};
