import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import { createStructuredSelector, createSelector } from 'reselect';

import config from 'config';

import wizardRedux from './components/Wizard/redux';

import { tmpChannelPrefix } from './reducer';
import { userProfileSelector } from '../accountSettings/selector';

const getStateLoaded = (state) => state.scenes.profile.stateLoaded;
const getPlatform = (state, ownProps) =>
  get(ownProps, 'location.query.platform');
const getQuery = (state, ownProps) => ownProps.location.query;
const getChannelIds = (state) => state.scenes.profile.channelIds;
const getChannelForms = (state) => state.scenes.profile.channelsForms;
const getCategories = (state) => state.scenes.profile.categories;
const getRedirectResponse = (state) => state.scenes.profile.redirectResponse;
const getVerificationCodes = (state) =>
  state.scenes.profile.verificationCodesByChannel;
const getVerification = (state) => state.scenes.profile.verificationByChannel;
const getChannelWizards = (state) => state.scenes.profile.wizard;
const getChannelsRaw = (state) => state.scenes.profile.channels;
const getSelectedChannelId = (state) => state.scenes.profile.selectedChannelId;
const getChannels = createSelector(
  [getChannelIds, getChannelForms],
  (ids, channelForms) => ids.map((id) => channelForms[id]),
);

const getAllowAccess = (state) => {
  const {
    application: { env, user },
  } = state;

  if (env === 'development' || env === 'stage') {
    return true;
  }

  // currently allowAccess flag controls access for 1 network, Facebook
  const allowAccess = get(config, 'features.channels.allowAccess', []);

  return allowAccess.includes(user.id);
};

// Creates a mapping function which merges from the given key-val store
// into the iterated object
const withMergeFrom = (store, indexKey, toKey, fallback) => (item) => ({
  ...item,
  [toKey]: isUndefined(store[item[indexKey]])
    ? fallback
    : store[item[indexKey]],
});

const mergeCategoriesToChannel = (channels, categories) =>
  channels.map((channel) => ({
    ...channel,
    categories,
  }));

const allowAccessPermissions = (allowAccess) => (channel) => {
  // currently allowAccess flag controls access for 1 network, Facebook
  if (channel.platform === 'facebook') {
    return { ...channel, restrictAccess: !allowAccess };
  }

  return channel;
};

const getTransformedChannels = createSelector(
  getChannels,
  getCategories,
  getVerificationCodes,
  getVerification,
  getAllowAccess,
  getChannelWizards,
  (
    channels,
    categories,
    verificationCodes,
    verification,
    allowAccess,
    wizards,
  ) =>
    mergeCategoriesToChannel(channels, categories)
      .map(allowAccessPermissions(allowAccess))
      .map(
        withMergeFrom(verificationCodes, 'id', 'verificationCode', {
          loaded: false,
        }),
      )
      .map(
        withMergeFrom(verification, 'id', 'verification', {
          loaded: false,
        }),
      )
      .map((channel) => ({
        ...channel,
        wizard: {
          step: wizardRedux.selectors.getStep(wizards, { id: channel.id }),
        },
      }))
      .map((channel) => ({
        ...channel,
        temporary: get(channel, 'id', '').startsWith(tmpChannelPrefix),
      })),
);

export const getProfileState = createStructuredSelector({
  stateLoaded: getStateLoaded,
  channels: getChannelsRaw,
  channelsForms: getTransformedChannels,
  selectedChannelId: getSelectedChannelId,
  redirectResponse: getRedirectResponse,
  userProfile: userProfileSelector,
  platform: getPlatform,
  query: getQuery,
});
