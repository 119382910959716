import get from 'lodash/get';
import pick from 'lodash/pick';
import uniqueId from 'lodash/uniqueId';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import merge from 'lodash/merge';
import has from 'lodash/has';

import { combineReducersFlat } from 'source/utils/redux';

import { createReducer } from 'source/utils';
import { without } from 'source/utils/imCollection';

import { actionTypes } from './actions';
import wizardRedux from './components/Wizard/redux';

export const tmpChannelPrefix = 'tmp-channel-';

export const getInitialState = () => ({
  stateLoaded: false,

  channels: [],
  categories: [],
  selectedChannelId: null,

  channelIds: [], // gives order to the `channelsForms` object
  channelsForms: {},

  wizard: {},

  redirectResponse: null,

  verificationCodesByChannel: {},
  verificationByChannel: {},
});

const resolveFailureReason = (resp) => {
  let reason = null;

  const messages = {
    'website-already-exists': {
      id: 'signup.errors.blogUrlAlreadyRegistered',
    },
    Conflict: {
      titleId: 'channels.disconnect.error.conflict.title',
      bodyId: 'channels.disconnect.error.conflict.body',
    },
  };

  const respCode = get(resp, 'code');
  if (respCode) {
    reason = messages[resp.code];
  }

  const respStatusText = get(resp, 'statusText');
  if (respStatusText) {
    reason = messages[respStatusText];
  }

  return reason;
};

/**
 * Returns a message id if the given channel is in an invalid state and the
 * form for it should display an error message.
 */
const getErrorStateId = (channel) => {
  if (!channel.ready) {
    // prettier-ignore
    if (
      !channel.readyState.accessTokenValid
    ) {
      return 'channels.ga.errors.invalidAccessToken';
    }

    if (
      channel.readyState.accountSettingsAvailable &&
      channel.readyState.accessTokenValid &&
      channel.readyState.categoriesCompleted &&
      !channel.readyState.hasData
    ) {
      return 'channels.ga.errors.noData';
    }

    if (
      channel.readyState.accountSettingsAvailable &&
      channel.readyState.accessTokenValid &&
      channel.readyState.hasData &&
      channel.readyState.categoriesCompleted &&
      !channel.readyState.hasCachedData
    ) {
      return 'channels.ga.errors.noCachedData';
    }
  }

  return null;
};

const getRootCategories = (channelCategories, { categories }) => {
  const rootCodes = uniq(
    channelCategories.map((cat) => cat.parent || cat.code.split('-')[0]),
  );

  return categories.filter((cat) => rootCodes.includes(cat.code));
};

const getWeightDistribution = (channelCategories) =>
  channelCategories.reduce(
    (memo, cat) => ({
      ...memo,
      [cat.code]: cat.weight || 0,
    }),
    {},
  );

const getCategoryFormData = (channelCategories, { categories }) => ({
  selectedCategories: getRootCategories(channelCategories, { categories }),
  selectedSubCategories: channelCategories,
  weightDistribution: getWeightDistribution(channelCategories, { categories }),
});

const getGAChannelName = (channel) => {
  if (channel.newChannel) {
    return '';
  }

  // for GA channels we use temporary name, which is hidden
  // before the URL of the site is submitted..
  const hasUrl = has(channel, 'data.url');

  if (!hasUrl) {
    return '';
  }

  return get(channel, 'name');
};

const getDisconnectInitState = () => ({
  confirmation: false,
  submitting: false,
  submitFailed: false,
});

const channelFormsFactory = (platform, channel = {}, { categories }) => {
  const formByPlatform = {
    website: () => ({
      errors: [],

      url: get(channel, 'data.url', ''),
      name: get(channel, 'data.name', ''),
      software: get(channel, 'data.software', ''),
      description: get(channel, 'data.description', ''),

      website: get(channel, 'data', null),
      ...getCategoryFormData(get(channel, 'categories', []), { categories }),

      resolvingBlogTitle: false,
      status: 'default',

      submitFailed: false,
      failureReason: null,
      readyState: channel.readyState,
      disconnect: getDisconnectInitState(),
    }),

    ga: () => ({
      errors: [],

      url: get(channel, 'data.url', ''),
      name: getGAChannelName(channel),
      ...getCategoryFormData(get(channel, 'categories', []), { categories }),

      accountSummaries: null,
      selectedAccount: pick(get(channel, 'data', {}), [
        'url',
        'accountId',
        'propertyId',
        'viewId',
      ]),
      submitFailed: false,
      failureReason: null,
      readyState: channel.readyState,
      errorStateId: getErrorStateId(channel),
      disconnect: getDisconnectInitState(),
    }),

    twitter: () => ({
      errors: [],

      name: get(channel, 'name', ''),
      ...getCategoryFormData(get(channel, 'categories', []), { categories }),
      readyState: channel.readyState,
      disconnect: getDisconnectInitState(),
    }),

    facebook: () => ({
      errors: [],

      name: get(channel, 'name', ''),
      ...getCategoryFormData(get(channel, 'categories', []), { categories }),
      readyState: channel.readyState,
      disconnect: getDisconnectInitState(),
    }),

    instagram: () => ({
      errors: [],

      name: get(channel, 'name', ''),
      platform,
      ...getCategoryFormData(get(channel, 'categories', []), { categories }),
      readyState: channel.readyState,
      screenshots: channel.screenshots,
      disconnect: getDisconnectInitState(),
    }),

    pinterest: () => ({
      errors: [],

      name: get(channel, 'name', ''),
      ...getCategoryFormData(get(channel, 'categories', []), { categories }),
      readyState: channel.readyState,
      disconnect: getDisconnectInitState(),
    }),

    youtube: () => ({
      errors: [],

      name: get(channel, 'name', ''),
      ...getCategoryFormData(get(channel, 'categories', []), { categories }),
      readyState: channel.readyState,
      disconnect: getDisconnectInitState(),
    }),

    tiktok: () => ({
      errors: [],

      name: get(channel, 'name', ''),
      platform,
      ...getCategoryFormData(get(channel, 'categories', []), { categories }),
      readyState: channel.readyState,
      screenshots: channel.screenshots,
      disconnect: getDisconnectInitState(),
    }),
  };

  return formByPlatform[platform]();
};

const channelToForm = (
  channel,
  overrides = {},
  { categories, preSelections = {} },
) => {
  const isSelected = preSelections.channelId === channel.id;

  return {
    id: channel.id,
    name:
      channel.platform === 'website' ? get(channel, 'data.name') : channel.name,
    status: channel.ready,
    readyState: {
      ...channel.readyState,
      qualityReview: get(channel, 'qualityReview', false),
    },
    platform: channel.platform,
    form: channelFormsFactory(channel.platform, channel, { categories }),
    showInsightsUpload: isSelected && preSelections.showInsightsUpload,
    referenceChannel: channel.referenceChannel,
    extension: channel.extension,
    ...overrides,
  };
};

const channelsToForms = (channels, { categories, preSelections }) =>
  keyBy(
    channels.map((channel) =>
      channelToForm(channel, {}, { categories, preSelections }),
    ),
    'id',
  );

const actionHandlers = {
  [actionTypes.STATE_LOADING]: (state) => ({
    ...state,
    stateLoaded: false,
  }),

  [actionTypes.STATE_LOADED]: (
    state,
    { categories, channels, preSelections },
  ) => ({
    ...state,
    stateLoaded: true,
    channelIds: channels.map((c) => c.id),
    channelsForms: channelsToForms(channels, {
      categories,
      preSelections,
    }),
    selectedChannelId: preSelections.channelId,
    channels,
    categories,
  }),

  [actionTypes.ADD_CHANNEL]: (state, { type, options }) => {
    const id = uniqueId(tmpChannelPrefix);
    const { categories } = state;

    return {
      ...state,
      channelIds: [...state.channelIds, id],
      selectedChannelId: id,
      channelsForms: {
        ...state.channelsForms,
        // Here we generate temporary channel data
        [id]: channelToForm(
          // channel
          {
            id,
            ready: false,
            readyState: {
              accountSettingsAvailable: false,
              categoriesCompleted: false,
              accessTokenValid: false,
              hasData: false,
            },
            name: '',
            platform: type,
            ...options,
          },
          // overrides
          {
            expanded: true,
            newChannel: true,
          },
          // extended information
          { categories },
        ),
      },
    };
  },

  [actionTypes.SELECT_CHANNEL]: (state, { id }) => ({
    ...state,
    selectedChannelId: id,
  }),

  [actionTypes.UNSELECT_CHANNEL]: (state) => ({
    ...state,
    selectedChannelId: null,
  }),

  [actionTypes.REDIRECT_RESPONSE_OCCURRED]: (
    state,
    { error, platform, handle },
  ) => ({
    ...state,
    redirectResponse: {
      error,
      platform,
      handle,
    },
  }),

  [actionTypes.REDIRECT_RESPONSE_CLOSED]: (state) => ({
    ...state,
    redirectResponse: null,
  }),

  [actionTypes.FORM_CHANGE]: (state, { id, field, value }) => ({
    ...state,
    channelsForms: {
      ...state.channelsForms,
      [id]: {
        ...state.channelsForms[id],
        form: {
          ...state.channelsForms[id].form,
          [field]: value,
          errors: state.channelsForms[id].form.errors.filter(
            (e) => e.id !== field,
          ),
          status: 'default',
        },
      },
    },
  }),

  [actionTypes.FORM_ERRORS]: (state, { id, errors }) => ({
    ...state,
    channelsForms: {
      ...state.channelsForms,
      [id]: {
        ...state.channelsForms[id],
        form: {
          ...state.channelsForms[id].form,
          errors,
        },
      },
    },
  }),

  [actionTypes.FORM_RESET]: (state, { id }) => ({
    ...state,
    channelsForms: {
      ...state.channelsForms,
      [id]: {
        ...state.channelsForms[id],
        form: channelFormsFactory(state.channelsForms[id].type, {
          categories: state.categories,
        }),
      },
    },
  }),

  [actionTypes.CHECKING_INTEGRATION_STATUS]: (state, { id }) => ({
    ...state,
    channelsForms: {
      ...state.channelsForms,
      [id]: {
        ...state.channelsForms[id],
        form: {
          ...state.channelsForms[id].form,
          status: 'checking',
        },
      },
    },
  }),

  [actionTypes.CHECKED_INTEGRATION_STATUS]: (state, { id, status }) => ({
    ...state,
    channelsForms: {
      ...state.channelsForms,
      [id]: {
        ...state.channelsForms[id],
        form: {
          ...state.channelsForms[id].form,
          status: status.insights.integrated ? 'integrated' : 'notIntegrated',
        },
        status: status.insights.integrated,
      },
    },
  }),

  [actionTypes.CHECK_INTEGRATION_STATUS_FAILED]: (state, { id }) => ({
    ...state,
    channelsForms: {
      ...state.channelsForms,
      [id]: {
        ...state.channelsForms[id],
        form: {
          ...state.channelsForms[id].form,
          status: 'notIntegrated',
        },
      },
    },
  }),

  [actionTypes.UPLOAD_INSIGHTS_MODAL_OPEN]: (state, { id }) => ({
    ...state,
    channelsForms: {
      ...state.channelsForms,
      [id]: {
        ...state.channelsForms[id],
        showInsightsUpload: true,
      },
    },
  }),

  [actionTypes.UPLOAD_INSIGHTS_MODAL_CLOSE]: (state, { id }) => ({
    ...state,
    channelsForms: {
      ...state.channelsForms,
      [id]: {
        ...state.channelsForms[id],
        showInsightsUpload: false,
      },
    },
  }),

  [actionTypes.FORM_REMOVE]: (state, { id }) => ({
    ...state,
    channelIds: state.channelIds.filter((channelId) => channelId !== id),
    channels: state.channels.filter((channel) => channel.id !== id),
    channelsForms: without(state.channelsForms, id),
  }),

  [actionTypes.REDIRECT_RESPONSE_OCCURRED]: (
    state,
    { error, platform, handle },
  ) => ({
    ...state,
    redirectResponse: {
      error,
      platform,
      handle,
    },
  }),

  [actionTypes.REDIRECT_RESPONSE_CLOSED]: (state) => ({
    ...state,
    redirectResponse: null,
  }),

  [actionTypes.VERIFICATION_CODE_LOADING]: (state, { channelId }) => ({
    ...state,
    verificationCodesByChannel: {
      ...state.verificationCodesByChannel,
      [channelId]: {
        loaded: false,
        error: null,
      },
    },
  }),

  [actionTypes.VERIFICATION_CODE_LOADING_FAILED]: (
    state,
    { channelId, data },
  ) => ({
    ...state,
    verificationCodesByChannel: {
      ...state.verificationCodesByChannel,
      [channelId]: {
        loaded: false,
        error: data,
      },
    },
  }),

  [actionTypes.VERIFICATION_CODE_LOADED]: (state, { channelId, data }) => ({
    ...state,
    verificationCodesByChannel: {
      ...state.verificationCodesByChannel,
      [channelId]: {
        data: data.code,
        loaded: true,
        error: null,
      },
    },
  }),

  // Channel creation reducers currently handle both channel verification
  // and the newly POSTed channel data

  [actionTypes.CREATE_PENDING]: (state, { channelId }) => ({
    ...state,
    verificationByChannel: {
      ...state.verificationByChannel,
      [channelId]: {
        loading: true,
        loaded: false,
        error: null,
      },
    },
    channelsForms: {
      ...state.channelsForms,
      [channelId]: {
        ...state.channelsForms[channelId],
        form: {
          ...state.channelsForms[channelId].form,
          submitFailed: false,
        },
      },
    },
  }),

  [actionTypes.CREATE_FAILED]: (state, { channelId, data }) => ({
    ...state,
    verificationByChannel: {
      ...state.verificationByChannel,
      [channelId]: {
        loading: false,
        loaded: false,
        error: data,
      },
    },
    channelsForms: {
      ...state.channelsForms,
      [channelId]: {
        ...state.channelsForms[channelId],
        form: {
          ...state.channelsForms[channelId].form,
          submitFailed: true,
        },
      },
    },
  }),

  // When a channel is successfully created, we then have its real id
  // so we must transfer related state over from the temporary id to the real one
  [actionTypes.CREATE_SUCCESS]: (state, { channelId, data }) => ({
    ...state,
    verificationCodesByChannel: {
      ...omit(state.verificationCodesByChannel, [channelId]),
      [data.id]: state.verificationCodesByChannel[channelId],
    },
    verificationByChannel: {
      ...omit(state.verificationByChannel, [channelId]),
      [data.id]: {
        loading: false,
        loaded: true,
        error: null,
        data,
      },
    },
    channelIds: (() => {
      const currentIndex = state.channelIds.indexOf(channelId);
      return [
        ...state.channelIds.slice(0, currentIndex),
        data.id,
        ...state.channelIds.slice(currentIndex + 1),
      ];
    })(),
    channelsForms: {
      ...omit(state.channelsForms, [channelId]),
      [data.id]: channelToForm(
        // channel
        data,
        // overrides
        {
          expanded: true,
          submitFailed: false,
        },
        // extensional data
        { categories: state.categories },
      ),
    },
    wizard: {
      ...omit(state.wizard, [channelId]),
      [data.id]: state.wizard[channelId],
    },
    channels: [...state.channels, data],
    selectedChannelId: data.id,
  }),

  // Reducers for updating a channel
  [actionTypes.PATCH]: (
    state,
    { status, id, data, showInsightsUpload = false },
  ) => {
    if (status === 'submitting') {
      return merge({}, state, {
        channelsForms: {
          [id]: {
            form: { submitting: true, submitFailed: false },
          },
        },
      });
    }

    if (status === 'failed') {
      return merge({}, state, {
        channelsForms: {
          [id]: {
            form: {
              submitting: false,
              submitFailed: true,
              failureReason: resolveFailureReason(data),
            },
          },
        },
      });
    }

    if (status === 'success') {
      return merge({}, state, {
        channelsForms: {
          [id]: channelToForm(
            data,
            {
              expanded: true,
              showInsightsUpload,
              form: {
                submitting: false,
                submitFailed: false,
                ...channelFormsFactory(data.platform, data, {
                  categories: state.categories,
                }),
              },
            },
            { categories: state.categories },
          ),
        },
      });
    }

    return state;
  },

  [actionTypes.LOADING_ACCOUNT_SUMMARIES]: (state, { id }) => ({
    ...state,
    channelsForms: {
      ...state.channelsForms,
      [id]: {
        ...state.channelsForms[id],
        form: {
          ...state.channelsForms[id].form,
          accountSummaries: {
            loading: true,
            error: false,
            data: null,
          },
        },
      },
    },
  }),

  [actionTypes.LOADED_ACCOUNT_SUMMARIES]: (
    state,
    { id, accountSummaries },
  ) => ({
    ...state,
    channelsForms: {
      ...state.channelsForms,
      [id]: {
        ...state.channelsForms[id],
        form: {
          ...state.channelsForms[id].form,
          accountSummaries: {
            loading: false,
            error: false,
            data: accountSummaries,
          },
        },
      },
    },
  }),

  [actionTypes.LOADING_ACCOUNT_SUMMARIES_FAILED]: (state, { id, error }) => ({
    ...state,
    channelsForms: {
      ...state.channelsForms,
      [id]: {
        ...state.channelsForms[id],
        form: {
          ...state.channelsForms[id].form,
          accountSummaries: {
            loading: false,
            error: true,
            data: error,
          },
        },
      },
    },
  }),

  [actionTypes.UPDATED_ACCOUNT_SUMMARIES]: (state, { id, data }) => ({
    ...state,
    channelsForms: {
      ...state.channelsForms,
      [id]: {
        ...state.channelsForms[id],
        form: {
          ...state.channelsForms[id].form,
          name: data.name,
        },
      },
    },
  }),

  [actionTypes.DISCONNECT]: (state, { id, err, status }) => {
    if (status === 'submitting') {
      return {
        ...state,
        channelsForms: {
          ...state.channelsForms,
          [id]: {
            ...state.channelsForms[id],
            form: {
              ...state.channelsForms[id].form,
              disconnect: {
                ...state.channelsForms[id].form.disconnect,
                submitting: true,
              },
            },
          },
        },
      };
    }

    if (status === 'failed') {
      return {
        ...state,
        channelsForms: {
          ...state.channelsForms,
          [id]: {
            ...state.channelsForms[id],
            form: {
              ...state.channelsForms[id].form,
              disconnect: {
                ...state.channelsForms[id].form.disconnect,
                submitting: false,
                submitFailed: true,
                confirmation: false,
                failureReason: resolveFailureReason(err),
              },
            },
          },
        },
      };
    }

    return state;
  },

  [actionTypes.ENTER_DISCONNECT]: (state, { id, confirmation }) => ({
    ...state,
    channelsForms: {
      ...state.channelsForms,
      [id]: {
        ...state.channelsForms[id],
        form: {
          ...state.channelsForms[id].form,
          disconnect: {
            ...state.channelsForms[id].form.disconnect,
            submitFailed: false,
            confirmation,
          },
        },
      },
    },
  }),
};

const wizardReducer = wizardRedux.reducers.handleSteps('channels');
const resetStateReducer = (state = {}, action) => {
  if (action.type !== actionTypes.RESET_SCENE) {
    return state;
  }

  return getInitialState();
};
const baseReducer = createReducer(getInitialState(), actionHandlers);

const rootReducer = combineReducersFlat(
  [baseReducer, resetStateReducer],
  getInitialState(),
);

export default (state = getInitialState(), action) => {
  const rootState = rootReducer(state, action);
  const wizardState = wizardReducer(rootState.wizard, action);

  return {
    ...rootState,
    wizard: wizardState,
  };
};
