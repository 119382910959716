import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import { createEventListener } from 'source/utils';

function AccordionTab({ tab, selected, onClick }) {
  return (
    <div className="panel panel-default">
      <div className="panel-heading" role="tab">
        <h4 className="panel-title">
          <span
            className={cx({ collapsed: !selected })}
            role="button"
            href="#"
            onClick={onClick}
            aria-expanded={selected}
            data-toggle="collapse"
            tabIndex={0}
          >
            {tab.name}
            <svg className="icon">
              <use xlinkHref="#icon-chevron-down" />
            </svg>
          </span>
        </h4>
      </div>
      {/* eslint-disable jsx-a11y/role-supports-aria-props */}
      <div
        className={cx('panel-collapse', 'collapse', { in: selected })}
        role="tabpanel"
        aria-expanded={selected}
        style={{ height: selected ? null : '0px' }}
      >
        <div className="panel-body">{tab.component}</div>
      </div>
      {/* eslint-enable jsx-a11y/role-supports-aria-props */}
    </div>
  );
}

AccordionTab.propTypes = {
  tab: PropTypes.shape({
    name: PropTypes.object.isRequired,
    component: PropTypes.object.isRequired,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

function Accordion({ tabs, currentTab, onTabSelection }) {
  const handleTabSelection = (id, e) => {
    e.preventDefault();

    // if id is different id from before: select it
    // if it's the same: close the accordion, so that no tab is selected
    if (id === currentTab) {
      return onTabSelection(-1);
    }

    return onTabSelection(id);
  };

  const renderAccordionTab = (tab, index) => (
    <AccordionTab
      key={index}
      tab={tab}
      selected={index === currentTab}
      onClick={createEventListener(handleTabSelection, index)}
    />
  );

  return (
    <div className="panel-group" role="tablist" aria-multiselectable="true">
      <h2>
        <FormattedMessage
          id="accountSettings.headline"
          defaultMessage="Your Account Settings"
        />
      </h2>
      <p>
        <FormattedMessage
          id="accountSettings.text"
          defaultMessage="On this page you can update your account, define your profile data and change your password.<br></br>IMPORTANT: Your account data is required to generate invoices and pay you what you are owed."
        />
      </p>
      {tabs.map(renderAccordionTab)}
    </div>
  );
}

Accordion.propTypes = {
  tabs: PropTypes.array.isRequired,
  currentTab: PropTypes.number.isRequired,
  onTabSelection: PropTypes.func.isRequired,
};

export default Accordion;
