import { loadStateReducer } from '@blogfoster/redux-async-utils';
import { combineReducers } from 'redux';

import { actionTypes } from './actions';

export const selectors = {
  getPayments: (state) => state.payments,
};

const paymentsReducer = loadStateReducer(actionTypes.PAYMENTS_FETCH);

export default combineReducers({
  payments: paymentsReducer,
});
