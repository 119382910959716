import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

/**
 * Renders a `FormattedMessage` component from `react-intl` if an `id` prop is
 * given.
 *
 * @param {string} className CSS classname for the error container
 * @param {boolean} hide     Whether to hide the error or not
 * @param {string} id        react-intl string id
 */
function FormattedError({ className, hide, id }) {
  return !hide && id ? (
    <div className={className}>
      <FormattedMessage id={id} />
    </div>
  ) : null;
}

FormattedError.propTypes = {
  className: PropTypes.string,
  hide: PropTypes.bool,
  id: PropTypes.string,
};

FormattedError.defaultProps = {
  className: 'error',
  hide: false,
  id: null,
};

export default FormattedError;
