import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Markdown from 'source/components/common/markdown';
import Icon from 'source/components/common/icon';

class SponsoredPostsCampaignBriefing extends Component {
  renderMission() {
    const {
      campaign: {
        details: { mission, missionPreview },
      },
    } = this.props;

    if (missionPreview) {
      /* eslint-disable react/no-danger */
      return (
        <p
          className="markdown"
          dangerouslySetInnerHTML={{ __html: missionPreview }}
        />
      );

      /* eslint-enable react/no-danger */
    }

    return <p>{mission}</p>;
  }

  renderGoal() {
    const {
      campaign: {
        details: { goal, goalPreview },
      },
    } = this.props;

    if (goalPreview) {
      /* eslint-disable react/no-danger */
      return (
        <p
          className="markdown"
          dangerouslySetInnerHTML={{ __html: goalPreview }}
        />
      );

      /* eslint-enable react/no-danger */
    }

    return <p>{goal}</p>;
  }

  render() {
    const { campaign } = this.props;

    return (
      <div className="sponsored-posts-campaign-briefing">
        <ul className="icon-list">
          <li className="icon-list-item">
            <p>
              <strong className="icon-list-title">
                <Icon name="mail" />
                <FormattedMessage
                  id="sponsoredPosts.campaigns.mission"
                  defaultMessage="Mission"
                />
              </strong>
            </p>
            {this.renderMission()}
          </li>
          <li className="icon-list-item">
            <p>
              <strong className="icon-list-title">
                <Icon name="clipboard" />
                <FormattedMessage
                  id="sponsoredPosts.campaigns.tasks"
                  defaultMessage="Tasks"
                />
              </strong>
            </p>
            <ul className="list">
              {campaign.tasks.map((task) => (
                <li key={task.id}>
                  <Markdown markdown={task.headline} noParagraphs />
                </li>
              ))}
            </ul>
          </li>
          <li className="icon-list-item">
            <p>
              <strong className="icon-list-title">
                <Icon name="target" />
                <FormattedMessage
                  id="sponsoredPosts.campaigns.goal"
                  defaultMessage="Goal"
                />
              </strong>
            </p>
            {this.renderGoal()}
          </li>
        </ul>
      </div>
    );
  }
}

SponsoredPostsCampaignBriefing.propTypes = {
  campaign: PropTypes.shape({
    details: PropTypes.shape({
      missionPreview: PropTypes.string,
      goalPreview: PropTypes.string,
      mission: PropTypes.string.isRequired,
      goal: PropTypes.string.isRequired,
    }).isRequired,
    tasks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        headline: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

export default SponsoredPostsCampaignBriefing;
