import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { compose, withState, withHandlers } from 'recompose';

import { ProgressBar } from 'source/components/common';

import UserProfile from './UserProfile';
import GaUpgradePrompt from './GaUpgradePrompt';
import Header from './Header';
import ChannelsList from './ChannelsList';
import ChannelsPicker from './ChannelsPicker';
import ChannelDetail from './ChannelDetail';

const withPickerState = compose(
  withState('expanded', 'setExpanded', false),
  withHandlers({
    openPicker:
      ({ setExpanded }) =>
      () =>
        setExpanded(true),
    closePicker:
      ({ setExpanded }) =>
      () =>
        setExpanded(false),
  }),
);

function Profile({
  stateLoaded,
  expanded,
  openPicker,
  closePicker,
  userProfile,
  channels,
  channelsForms,
  selectedChannelId,
  onAddProfile,
  onSelectChannel,
  onUnselectChannel,
  formsActions,
  redirectResponse,
  onRedirectResponseClose,
}) {
  if (!stateLoaded) {
    return <ProgressBar />;
  }

  const selectedChannel = selectedChannelId
    ? find(channelsForms, (channel) => channel.id === selectedChannelId)
    : null;

  return (
    <div className="channels-view">
      <div className="row">
        <div className="col-sm-12">
          <ChannelDetail
            channel={selectedChannel}
            formsActions={formsActions}
            onClose={onUnselectChannel}
          />
          <Header
            redirectResponse={redirectResponse}
            onClose={onRedirectResponseClose}
          />
          <ChannelsList channels={channels} onSelectChannel={onSelectChannel} />
          <div className="channel-selector-container">
            <ChannelsPicker
              hasChannels={!isEmpty(channels)}
              expanded={expanded}
              onSelected={onAddProfile}
              onExpand={openPicker}
              onCollapse={closePicker}
            />
          </div>
          <GaUpgradePrompt channels={channels} />
          <UserProfile user={userProfile} />
        </div>
      </div>
    </div>
  );
}

Profile.propTypes = {
  stateLoaded: PropTypes.bool.isRequired,
  channels: PropTypes.arrayOf(PropTypes.object).isRequired,
  userProfile: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  openPicker: PropTypes.func.isRequired,
  closePicker: PropTypes.func.isRequired,
  onAddProfile: PropTypes.func.isRequired,
  onSelectChannel: PropTypes.func.isRequired,
  onUnselectChannel: PropTypes.func.isRequired,
  formsActions: PropTypes.object.isRequired,
  channelsForms: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedChannelId: PropTypes.string,
  redirectResponse: PropTypes.object,
  onRedirectResponseClose: PropTypes.func.isRequired,
};
Profile.defaultProps = {};

export default withPickerState(Profile);
