import validator from 'validator';
import normalizeUrl from 'normalize-url';
import UrlParser from 'url-parse';

/**
 * Replicated in aurum. Validates if the given URL is valid and has a host
 * matching the specified social media platform.
 * TODO: Create npm module for this function and use it here and in aurum.
 *
 * @param {string} platform
 * @param {stirng} url
 */
export const isValidSocialMediaUrl = (platform, url) => {
  const urlOptions = {
    protocols: ['http', 'https'],
    require_protocol: true,
    host_whitelist: false,
  };

  const urlValidatorsByPlatform = {
    instagram: (url) =>
      validator.isURL(url, {
        ...urlOptions,
        host_whitelist: ['instagram.com', 'instagr.am'],
      }),
    facebook: (url) =>
      validator.isURL(url, {
        ...urlOptions,
        host_whitelist: [
          'business.facebook.com',
          'm.facebook.com',
          'facebook.com',
        ],
      }),
    // Ignore the top-level domain for Pinterest URL's
    // and only make sure that the host is "pinterest".
    pinterest: (url) =>
      validator.isURL(url, urlOptions) &&
      new UrlParser(url).host.includes('pinterest'),
    youtube: (url) =>
      validator.isURL(url, {
        ...urlOptions,
        host_whitelist: ['youtube.com'],
      }),
    twitter: (url) =>
      validator.isURL(url, {
        ...urlOptions,
        host_whitelist: ['twitter.com'],
      }),
  };

  const urlValidator = urlValidatorsByPlatform[platform];
  if (!urlValidator) {
    // eslint-disable-next-line no-console
    console.error('no `urlValidator` configured for platform:', platform);
    return false;
  }

  return urlValidator(normalizeUrl(url));
};

export { default as isValidVatId } from './isValidVatId';
export { default as isValidGermanTaxId } from './isValidGermanTaxId';
export { default as isValidGermanTaxIdCode } from './isValidGermanTaxIdCode';
