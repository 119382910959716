import constants from 'source/constants';

import { actionAuthRequest, requestFlow } from 'source/utils/axios';

const acceptTermsStateLoading = () => ({
  type: constants.ACCEPT_TERMS_STATE_LOADING,
});

const acceptTermsStateLoaded = (user) => ({
  type: constants.ACCEPT_TERMS_STATE_LOADED,
  payload: { user },
});

const acceptTermsStateFailed = (error) => ({
  type: constants.ACCEPT_TERMS_STATE_FAILED,
  payload: { error },
});

const acceptTermsSubmitting = () => ({
  type: constants.ACCEPT_TERMS_SUBMITTING,
});

const acceptTermsSubmitted = (user) => ({
  type: constants.ACCEPT_TERMS_SUBMITTED,
  payload: { user },
});

const acceptTermsFailed = (error) => ({
  type: constants.ACCEPT_TERMS_FAILED,
  payload: { error },
});

export const loadUser = () => (dispatch, getState) => {
  const getUser = () => actionAuthRequest(dispatch, getState).get('/v1/users');

  const onBefore = () => dispatch(acceptTermsStateLoading());
  const onSuccess = (user) => dispatch(acceptTermsStateLoaded(user.data));
  const onError = (error) => dispatch(acceptTermsStateFailed(error));

  return requestFlow(getUser, { onBefore, onSuccess, onError });
};

export const acceptTerms = () => (dispatch, getState) => {
  const patchUser = () =>
    actionAuthRequest(dispatch, getState).patch('/v1/users', {
      acceptedTerms: true,
    });

  const onBefore = () => dispatch(acceptTermsSubmitting());
  const onSuccess = (user) => dispatch(acceptTermsSubmitted(user.data));
  const onError = (error) => dispatch(acceptTermsFailed(error));

  return requestFlow(patchUser, { onBefore, onSuccess, onError });
};
