import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  ActivityStreamItem,
  ActivityStreamHeader,
  ActivityStreamBody,
} from 'source/components/common/activityStream';
import { MessageCard } from 'source/components/common/message';

function VerificationExpired({ intl }) {
  return (
    <ActivityStreamItem status="failed" iconName="check-document">
      <ActivityStreamHeader
        title={intl.formatMessage({
          id: 'sponsoredPosts.campaigns.verification',
          defaultMessage: 'Verification',
        })}
      />

      <ActivityStreamBody>
        <MessageCard type="info">
          <strong>
            <FormattedMessage
              id="sponsoredPosts.campaigns.accept.expired.title"
              defaultMessage="What a pity!"
            />
          </strong>
          <span>
            <FormattedMessage
              id="sponsoredPosts.campaigns.accept.expired.message"
              defaultMessage="The deadline for application has expired, unfortunately."
            />
          </span>
        </MessageCard>
      </ActivityStreamBody>
    </ActivityStreamItem>
  );
}

VerificationExpired.propTypes = {};

export default injectIntl(VerificationExpired);
