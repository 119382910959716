import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { lifecycle, compose } from 'recompose';

import {
  loadApplications,
  changeApplicationStatusFilter,
  showMore,
} from '../components/MyCampaigns/actions';

import {
  getAsyncApplications,
  getApplicationStatusFilter,
  isShowMoreButtonVisible,
} from '../selectors';

import MyCampaigns from '../components/MyCampaigns';

const withApplicationsFetch = lifecycle({
  componentDidMount() {
    this.props.onLoadApplications();
  },
});

export default compose(
  connect(
    createStructuredSelector({
      applications: getAsyncApplications,
      applicationStatusFilter: getApplicationStatusFilter,
      showMoreButton: isShowMoreButtonVisible,
    }),
    {
      onShowMoreApplications: showMore,
      onLoadApplications: loadApplications,
      onSelectApplicationStatusFilter: changeApplicationStatusFilter,
    },
  ),
  withApplicationsFetch,
)(MyCampaigns);
