import { connect } from 'react-redux';

import ResetPasswordCompleted from 'source/components/gateway/resetPasswordCompleted';

import { changeLanguage } from 'source/actions/account';

const mapStateToProps = (state) => ({
  language: state.application.locale,
  languageOptions: state.account.languageOptions,
});

const mapDispatchToProps = {
  onChangeLanguage: changeLanguage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPasswordCompleted);
