import PropTypes from 'prop-types';
import React from 'react';

function SponsoredPostsNavigation({ children }) {
  return <div className="sponsored-posts-overview">{children}</div>;
}

SponsoredPostsNavigation.propTypes = {
  children: PropTypes.any,
};

export default SponsoredPostsNavigation;
