import moment from 'moment';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

export const getKPILabelsByPlatform = (channel) => {
  const { platform, extension } = channel;

  if (platform === 'website') {
    return {
      id: 'insights.v2.overview.kpi.website.label',
      defaultMessage: 'Page views per Month',
    };
  }

  if (platform === 'twitter') {
    return {
      id: 'insights.v2.overview.kpi.twitter.label',
      defaultMessage: 'Followers',
    };
  }

  if (platform === 'instagram') {
    return {
      id: 'insights.v2.overview.kpi.instagram.label',
      defaultMessage: 'Followers',
    };
  }

  if (platform === 'pinterest' && extension.apiVersion === 'v3-analytics-api') {
    return {
      id: 'insights.v2.overview.kpi.pinterest.v3.label',
      defaultMessage: 'Followers',
    };
  }

  if (platform === 'pinterest' && extension.apiVersion === 'v5-analytics-api') {
    return {
      id: 'insights.v2.overview.kpi.pinterest.v5.label',
      defaultMessage: 'Impressions',
    };
  }

  if (platform === 'tiktok') {
    return {
      id: 'insights.v2.overview.kpi.tiktok.label',
      defaultMessage: 'Followers',
    };
  }

  if (platform === 'youtube') {
    return {
      id: 'insights.v2.overview.kpi.youtube.label',
      defaultMessage: 'Subscribers',
    };
  }

  if (platform === 'ga') {
    return {
      id: 'insights.v2.overview.kpi.ga.label',
      defaultMessage: 'Page views per Month',
    };
  }

  throw new Error(`missing label for platform ${platform}`);
};

const KPIConfigByPlatform = {
  website: {
    valueKey: 'pageviews',
    totalsKey: 'totals.pageviews',
  },
  twitter: {
    valueKey: 'followersCount',
    totalsKey: 'totals.followersCount',
  },
  pinterest: {
    valueKey: 'followers',
    totalsKey: 'totals.followers',
  },
  youtube: {
    valueKey: 'subscriberCount',
    totalsKey: 'totals.subscriberCount',
  },
  ga: {
    valueKey: 'pageviews',
    totalsKey: 'totals.pageviews',
  },
  tiktok: {
    valueKey: 'followers',
    totalsKey: 'totals.followers',
  },
};

const getSeriesFromMetricsField = ({ platform, metrics }) => {
  const kpiConfig = KPIConfigByPlatform[platform];

  const { byDate } = metrics;
  if (!byDate || !kpiConfig) {
    return [];
  }

  return byDate.map((metric, i) => [i, get(metric, kpiConfig.valueKey)]);
};

const getSeriesFromKeyMetricsField = (keyMetrics = {}) => {
  if (!keyMetrics.followersHistory) {
    if (keyMetrics.followers) {
      return [[0, keyMetrics.followers]];
    }

    return [];
  }

  const sortedHistory = sortBy(keyMetrics.followersHistory, ({ month }) =>
    moment(month).format(),
  );

  return sortedHistory.map((value, i) => [i, value.followers]);
};

/**
 * Helper to calculate 2D series data [ [ x, y ], ... ] from
 * a channel's insights
 */
export const getKPISeries = (channel) => {
  if (channel.platform === 'instagram') {
    return getSeriesFromKeyMetricsField(channel.keyMetrics);
  }

  // @alexbeletsky [sc11935]: Pinterest V5 no longer support followers
  // metrics, we don't render it anymore
  if (
    channel.platform === 'pinterest' &&
    channel.extension.apiVersion === 'v5-analytics-api'
  ) {
    return [];
  }

  return getSeriesFromMetricsField(channel);
};

export const getKPI = (channel) => {
  if (channel.platform === 'instagram') {
    return get(channel.keyMetrics, 'followers', 0);
  }

  if (channel.platform === 'pinterest') {
    return get(channel.keyMetrics, 'impressionMedian', 0);
  }

  const kpiConfig = KPIConfigByPlatform[channel.platform];

  return get(channel.metrics, kpiConfig.totalsKey);
};
