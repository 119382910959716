import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

function DynamicLink({ to, ...otherProps }) {
  if ((to && to.startsWith('https://')) || to.startsWith('http://')) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a href={to} {...otherProps} />;
  }
  return <Link to={to} {...otherProps} />;
}

DynamicLink.propTypes = {
  to: PropTypes.string,
};

DynamicLink.defaultProps = {
  to: '',
};

export function PlainLogo({ title, compact }) {
  return (
    <svg style={{ height: '100%', width: '100%' }}>
      <title>{title}</title>
      <use xlinkHref={compact ? '#logo-compact' : '#logo'} />
    </svg>
  );
}

PlainLogo.propTypes = {
  compact: PropTypes.bool,
  title: PropTypes.string,
};

PlainLogo.defaultProps = {
  compact: false,
  title: 'EQOLOT',
};

function Logo({ className, compact, title, ...otherProps }) {
  return (
    <div className={className}>
      <DynamicLink {...otherProps}>
        <PlainLogo title={title} compact={compact} />
      </DynamicLink>
    </div>
  );
}

Logo.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  compact: PropTypes.bool,
  title: PropTypes.string,
};

Logo.defaultProps = {
  to: '#',
  className: '',
  compact: false,
  title: 'EQOLOT',
};

export default Logo;
