import { asyncActionCreator } from '@blogfoster/redux-async-utils';
import { actionAuthRequest } from 'source/utils/axios';
import keyBy from 'lodash/keyBy';
import pick from 'lodash/pick';

const namespace = 'scenes/brand-clubs';

export const actionTypes = {
  FETCH_BRAND_CLUBS: `${namespace}/FETCH_BRAND_CLUBS`,
  OPEN_BRAND_MODAL: `${namespace}/OPEN_BRAND_MODAL`,
  CLOSE_BRAND_MODAL: `${namespace}/CLOSE_BRAND_MODAL`,
  JOIN_FORM_CHANGE: `${namespace}/JOIN_FORM_CHANGE`,
  SEND_JOIN_REQUEST: `${namespace}/SEND_JOIN_REQUEST`,
  CAMPAIGN_MATCHES_FETCH: `${namespace}/CAMPAIGN_MATCHES_FETCH`,
  APPLICATIONS_FETCH: `${namespace}/APPLICATIONS_FETCH`,
  CAMPAIGN_TYPE_FILTER_CHANGE: `${namespace}/CAMPAIGN_TYPE_FILTER_CHANGE`,
  FETCH_CHANNELS: `${namespace}/FETCH_CHANNELS`,
  RESET_SCENE: `${namespace}/RESET_SCENE`,
};

const loadChannels = asyncActionCreator(
  actionTypes.FETCH_CHANNELS,
  () => (dispatch, getState) =>
    actionAuthRequest(dispatch, getState, {
      dispatchErrors: false,
    }).get('/v2/channels', {
      params: {
        fields: JSON.stringify(['id', 'platform']),
      },
    }),
);

const loadBrandClubs = asyncActionCreator(
  actionTypes.FETCH_BRAND_CLUBS,
  () => (dispatch, getState) =>
    actionAuthRequest(dispatch, getState, {
      dispatchErrors: true,
    }).get('/v1/private-networks'),
);

const sendJoinRequest = asyncActionCreator(
  actionTypes.SEND_JOIN_REQUEST,
  (payload) => (dispatch, getState) =>
    actionAuthRequest(dispatch, getState, {
      dispatchErrors: true,
    }).post('/v1/private-networks/applications', payload),
);

const openBrandModal = (clubId) => (dispatch, getState) => {
  const brandClubsScene = getState().scenes.brandClubs;
  const selectedBrandClub = brandClubsScene.brandClubs.data.find(
    (bc) => bc.id === clubId,
  );
  dispatch({
    type: actionTypes.OPEN_BRAND_MODAL,
    payload: { selectedBrandClub },
  });
};

const closeBrandModal = () => (dispatch) => {
  dispatch({ type: actionTypes.CLOSE_BRAND_MODAL });
};

const validateJoinForm = (form) => {
  const errors = [];

  if (!form.pitch) {
    errors.push({
      id: 'pitch',
      messageId: 'brandClubs.join.requiredField',
    });
  }

  return errors;
};

const updateJoinForm = (form, attribute, value) => (dispatch) => {
  const updatedForm = {
    ...form,
    [attribute]: value,
  };
  const errors = validateJoinForm(updatedForm);

  updatedForm.errors = keyBy(errors, 'id');

  dispatch({
    type: actionTypes.JOIN_FORM_CHANGE,
    payload: updatedForm,
  });
};

const handleJoinRequest = () => (dispatch, getState) => {
  const {
    scenes: {
      brandClubs: { joinForm },
    },
  } = getState();

  const errors = validateJoinForm(joinForm);

  if (errors.length > 0) {
    joinForm.errors = keyBy(errors, 'id');

    return dispatch({
      type: actionTypes.JOIN_FORM_CHANGE,
      payload: joinForm,
    });
  }

  const payload = pick(joinForm, ['privateNetworkId', 'pitch']);

  return dispatch(sendJoinRequest(payload));
};

const loadCampaignMatches = asyncActionCreator(
  actionTypes.CAMPAIGN_MATCHES_FETCH,
  (privateNetworkId) => (dispatch, getState) =>
    actionAuthRequest(dispatch, getState).get('/v2/campaigns/matches', {
      params: { privateNetworkId },
    }),
);

const loadApplications = asyncActionCreator(
  actionTypes.APPLICATIONS_FETCH,
  (privateNetworkId) => (dispatch, getState) =>
    actionAuthRequest(dispatch, getState).get('/v2/applications', {
      params: { privateNetworkId },
    }),
);

const changeCampaignTypeFilter = (filter) => ({
  type: actionTypes.CAMPAIGN_TYPE_FILTER_CHANGE,
  payload: filter,
});

const resetScene = () => ({ type: actionTypes.RESET_SCENE });

export default {
  onLoadBrandClubs: loadBrandClubs,
  onOpenBrandModal: openBrandModal,
  onCloseBrandModal: closeBrandModal,
  onJoinFormChange: updateJoinForm,
  onJoinFormSubmit: handleJoinRequest,
  onLoadCampaignMatches: loadCampaignMatches,
  onLoadApplications: loadApplications,
  onLoadChannels: loadChannels,
  onChangeCampaignTypeFilter: changeCampaignTypeFilter,
  onResetScene: resetScene,
};
