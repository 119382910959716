import qs from 'qs';

import config from 'config';
import constants from 'source/constants';

export const connectSocialPlatform =
  ({ platform, redirectUri, id }) =>
  (dispatch, getState) => {
    // eslint-disable-line import/prefer-default-export
    const {
      account: { accessToken },
    } = getState();

    const endpoint = `${config.api.url}/v1/social/${platform}/oauth`;

    const querystring = qs.stringify({
      access_token: accessToken,
      redirect_uri: redirectUri,
      reference_channel_id: id,
    });

    window.location = `${endpoint}?${querystring}`;

    return dispatch({
      type: constants.SOCIAL_OAUTH_CONNECTING,
      payload: { platform },
    });
  };
