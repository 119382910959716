import React from 'react';
import { Field, FormSection } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import { Input } from './reduxFormComponents';

function AddressFields() {
  return (
    <div style={{ marginTop: '40px' }}>
      <h4 className="headline-form">
        <FormattedMessage
          id="accountSettings.payment.address"
          defaultMessage="Address"
        />
      </h4>

      <FormSection name="address">
        <Field
          name="street"
          component={Input}
          labelId="accountSettings.payment.street"
        />

        <Field
          name="number"
          component={Input}
          labelId="accountSettings.payment.streetNumber"
        />

        <Field
          name="postalCode"
          component={Input}
          labelId="accountSettings.payment.postalCode"
        />

        <Field
          name="city"
          component={Input}
          labelId="accountSettings.payment.city"
        />
      </FormSection>
    </div>
  );
}

export default AddressFields;
