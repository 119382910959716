import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import * as propTypes from 'source/utils/propTypes';
import { insights } from 'config';
import {
  StatusMessageHtml,
  StatusMessageCombined,
  StatusMessageDaysCombined,
} from './statusMessages';

function ScreenshotStatus({ platform, screenshot }) {
  if (isEmpty(screenshot)) {
    return <StatusMessageHtml messageHtml="missingMessage" />;
  }

  const updatedAt = get(screenshot, 'updatedAt');

  const today = moment().startOf('day');
  const updatedAtDate = moment(updatedAt).startOf('day');
  const uploadDaysAfter = moment(today).diff(updatedAtDate, 'days');

  const { insightsTtl, insightsIntermediateTtl } = insights[platform];

  if (!updatedAt) {
    return <StatusMessageHtml messageHtml="missingMessage" />;
  }

  if (uploadDaysAfter >= insightsTtl) {
    return <StatusMessageHtml messageHtml="expiredMessage" />;
  }

  if (uploadDaysAfter === insightsTtl - 1) {
    return (
      <StatusMessageCombined
        messageHtml="staleMessage"
        message="staleMessage.1day"
      />
    );
  }

  if (
    uploadDaysAfter > insightsIntermediateTtl &&
    uploadDaysAfter <= insightsTtl - 2
  ) {
    return (
      <StatusMessageDaysCombined
        message="staleMessage"
        messageDays="staleAgeHint"
        ageInDays={insightsTtl - uploadDaysAfter}
      />
    );
  }

  if (uploadDaysAfter <= insightsIntermediateTtl) {
    return (
      <StatusMessageDaysCombined
        message="freshMessage"
        messageDays="freshDateMessage"
        ageInDays={insightsTtl - uploadDaysAfter}
      />
    );
  }

  return null;
}

ScreenshotStatus.propTypes = {
  screenshot: propTypes.screenshot,
  platform: PropTypes.string.isRequired,
};

export default ScreenshotStatus;
