import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Accordion, {
  Panel,
  PanelHeader,
  PanelContent,
} from 'source/components/common/accordion';

import InvoiceItem from './InvoiceItem';

function InvoicePanels({ selectedYear, invoicePanels, invoices, intl }) {
  return (
    <Accordion className="invoices-months">
      {invoicePanels.map((panel) => {
        const monthLocalized = intl.formatMessage({ id: panel.monthId });
        const panelTitle = `${monthLocalized} ${selectedYear}`;

        return (
          <Panel
            className="invoice-month"
            key={panel.monthId}
            name={panel.monthId}
          >
            <PanelHeader
              className="invoice-month__header"
              title={panelTitle}
              allowToggle
            />
            <PanelContent className="invoice-month__content" noAnimation>
              <ul className="invoice-month__list">
                {panel.invoices.map((invoice) => (
                  <InvoiceItem
                    key={invoice.id}
                    invoice={invoice}
                    invoices={invoices}
                  />
                ))}
              </ul>
            </PanelContent>
          </Panel>
        );
      })}
    </Accordion>
  );
}

InvoicePanels.propTypes = {
  selectedYear: PropTypes.number.isRequired,
  invoicePanels: PropTypes.arrayOf(
    PropTypes.shape({
      monthId: PropTypes.string.isRequired,
      invoices: PropTypes.arrayOf(PropTypes.object),
    }),
  ).isRequired,
  invoices: PropTypes.arrayOf(PropTypes.object),
};

export default injectIntl(InvoicePanels);
