import _ from 'lodash';

/**
 * Validates German Steuerliche Identifikationsnummer
 *
 * https://ec.europa.eu/taxation_customs/tin/?locale=en
 *
 * (see link to https://ec.europa.eu/taxation_customs/tin/specs/FS-TIN%20Algorithms-Public.docx)
 * (java implementation https://polzer.wordpress.com/2011/10/06/ein-validator-der-steuer-identifikationsnummer-in-java/)
 * (StackOverflow: https://stackoverflow.com/questions/24282827/php-validate-german-tax-id-steueridentifikationsnummer)
 *
 * @param {*} taxIdCode
 *
 * @returns {boolean}
 */
export default function isValidGermanTaxIdCode(taxIdCode) {
  if (typeof taxIdCode !== 'string') {
    return false;
  }

  const taxIdCodeWithoutSpaces = taxIdCode.replace(/\s/g, '');

  if (taxIdCodeWithoutSpaces.length !== 11) {
    return false;
  }

  if (taxIdCodeWithoutSpaces[0] === '0') {
    return false;
  }

  if (_.some(taxIdCodeWithoutSpaces, (c) => !_.isInteger(Number(c)))) {
    return false;
  }

  const taxIdCodeArray = taxIdCodeWithoutSpaces.slice(0, -1).split('');

  const checkDigitsValidation = () => {
    const countDigits = taxIdCodeArray.reduce((memo, digit) => {
      if (!memo[digit]) {
        memo[digit] = 1;
      }

      memo[digit] += 1;

      return memo;
    }, {});

    const countDigitsLength = _.keys(countDigits).length;

    if (countDigitsLength !== 8 && countDigitsLength !== 9) {
      return false;
    }

    return true;
  };

  const checkSumValidation = () => {
    const checkSumDigit = () => {
      const product = taxIdCodeArray.reduce((product, digit) => {
        let sum = (Number(digit) + product) % 10;

        sum = sum === 0 ? 10 : sum;

        return (sum * 2) % 11;
      }, 10);

      const check = 11 - product;

      return check === 10 ? 0 : check;
    };

    const checkDigit = checkSumDigit();

    if (Number(taxIdCodeWithoutSpaces[10]) !== checkDigit) {
      return false;
    }

    return true;
  };

  return checkDigitsValidation() && checkSumValidation();
}
