import PropTypes from 'prop-types';
import React from 'react';
import max from 'lodash/max';
import min from 'lodash/min';
import cx from 'classnames';

import SVG from 'source/components/common/svg';
import AreaChart from 'source/components/common/vis/areaChart';

// This is just to set the aspect ratio of the chart
const width = 999;
const height = 666;

function GrowthChart({ className, data }) {
  // eslint-disable-next-line no-unused-vars
  const ys = data.map(([x, y]) => y);
  const limitUpper = max(ys);
  const limitLower = min(ys);

  return (
    <SVG
      className={cx('thrush-growth-chart', className)}
      width={width}
      height={height}
      preserveAspectRatio="none"
    >
      <AreaChart
        width={width}
        height={height}
        domainX={[0, data.length - 1]}
        domainY={[limitLower, limitUpper]}
        rangeY={[height * 0.8, height * 0.2]}
        curve="basis"
        data={data}
      />
    </SVG>
  );
}

GrowthChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
};

export default GrowthChart;
