import { asyncActionCreator } from '@blogfoster/redux-async-utils';

import moment from 'moment';

import { actionAuthRequest } from 'source/utils/axios';

export const actionTypes = {
  PAYMENTS_FETCH: 'FETCH_PAYMENTS',
};

export const loadPayments = asyncActionCreator(
  actionTypes.PAYMENTS_FETCH,
  (selectedYear) => (dispatch, getState) => {
    const after = moment(`${selectedYear}`)
      .startOf('year')
      .format('YYYY-MM-DD');
    const before = moment(`${selectedYear}`).endOf('year').format('YYYY-MM-DD');

    return actionAuthRequest(dispatch, getState, {
      dispatchErrors: false,
    }).get('/v2/payments', {
      params: {
        filters: JSON.stringify([
          { createdAt: { after } },
          { createdAt: { before } },
        ]),
        fields: JSON.stringify([
          'amount',
          'currency',
          'description',
          'id',
          'references',
          'status',
        ]),
      },
    });
  },
);
