import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function InfoCard({ className, title, children, ...props }) {
  return (
    <div className={cx('info-card', className)} {...props}>
      <h1 className="info-card-title">{title}</h1>
      <div className="info-card-body">{children}</div>
    </div>
  );
}

InfoCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
};

InfoCard.defaultProps = {
  className: '',
};

export default InfoCard;
