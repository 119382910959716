import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { defaultMemoize as memoize } from 'reselect';
import keyBy from 'lodash/keyBy';
import noop from 'lodash/noop';

import { FormError, NextPrevButtonList } from 'source/components/common';
import { Categories } from 'source/components/common/categories';
import { validateCategories } from 'source/utils/validators/categories';

const filterRootCategories = memoize((categories) =>
  categories.filter((c) => !c.parent),
);

class CategoriesWizardStep extends React.PureComponent {
  handleOnNext = () => {
    const { form, onErrors, onNextStep } = this.props;
    const errors = [...validateCategories(form, { maxCategories: 3 })];

    return errors.length > 0 ? onErrors(errors) : onNextStep();
  };

  handleChange = (selectedCategories) => {
    const { onChange } = this.props;

    onChange('selectedCategories', selectedCategories);
  };

  renderErrorIfExists = (name) => {
    const { form } = this.props;
    const indexedErrors = keyBy(form.errors, 'id');
    const error = indexedErrors[name];

    return error ? <FormError error={error} /> : null;
  };

  render() {
    const { form, categories, onPrevStep } = this.props;
    const { selectedCategories = [] } = form;

    const rootCategories = filterRootCategories(categories);

    return (
      <div className="website-form">
        <div className="text-center">
          <h4>
            <FormattedMessage
              id="preferences.categories.whatCategories"
              defaultMessage="What topics is your channel about?"
            />
          </h4>
          {this.renderErrorIfExists('selectedCategories')}
        </div>

        <div className="pull-md-right">
          <FormattedMessage
            id="preferences.categories.selectedOf"
            defaultMessage="{selected} of {max} categories selected"
            values={{ selected: `${selectedCategories.length}`, max: 3 }}
          />
        </div>

        <Categories
          className="m-t-2 text-center"
          selectedCategories={selectedCategories}
          categories={rootCategories}
          maxCategories="3"
          onChange={this.handleChange}
        />

        <div className="modal-footer">
          <NextPrevButtonList
            onPrevStep={onPrevStep}
            onNextStep={this.handleOnNext}
          />
        </div>
      </div>
    );
  }
}

CategoriesWizardStep.propTypes = {
  form: PropTypes.object,
  categories: PropTypes.array,
  onChange: PropTypes.func,
  onErrors: PropTypes.func,
  onNextStep: PropTypes.func,
  onPrevStep: PropTypes.func,
};

CategoriesWizardStep.defaultProps = {
  form: {},
  categories: [],
  onChange: noop,
  onErrors: noop,
  onNextStep: noop,
  onPrevStep: noop,
};

export default CategoriesWizardStep;
