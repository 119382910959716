import { createSelector, createStructuredSelector } from 'reselect';

const isOpenPayment = (payment = {}) => payment.status === 'outstanding';

const getPaymentsRequestState = (state) =>
  state.scenes.revenues.openPayments.payments;

const getPayments = createSelector(
  getPaymentsRequestState,
  (requestState) => requestState.data,
);

const getOpenPayments = createSelector(
  getPayments,
  (payments) => payments && payments.filter(isOpenPayment),
);

const mapStateToProps = createStructuredSelector({
  paymentsRequestState: getPaymentsRequestState,
  openPayments: getOpenPayments,
});

export default mapStateToProps;
