import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';
import Clipboard from 'clipboard';
import select from 'select';
import noop from 'lodash/noop';

class ClipboardComponent extends Component {
  constructor(props) {
    super(props);

    this.targetNode = null;
    this.node = null;
  }

  componentDidMount() {
    const { target, text } = this.props;

    const element = this.node;
    const options = {};

    // copy from text is our first choice
    if (text) {
      options.text = () => text;
    }

    if (target) {
      this.targetNode = document.getElementById(target);

      // of no text was given we fallback to target
      if (!text) {
        options.target = () => this.targetNode;
      }
    }

    if (!text && !target) {
      throw new Error('missing text or target');
    }

    this.clipboard = new Clipboard(element, options);

    this.clipboard.on('success', this.handleSuccess);
    this.clipboard.on('error', this.handleError);
  }

  componentWillUnmount() {
    this.clipboard.destroy();
  }

  handleSuccess = (e) => {
    const { onSuccess } = this.props;

    return onSuccess(e);
  };

  handleError = (e) => {
    const { targetNode } = this;
    const { text, onError } = this.props;

    // NOTE   Errors likely happen when the clipboard cannot copy the data,
    //        so we should at least try to select the text. If no `text` prop
    //        was given this happens automatically through clipboard.js, otherwise
    //        we select it now on our own now.

    if (text && targetNode) {
      select(targetNode);
    }

    return onError(e);
  };

  render() {
    return Children.only(
      <span
        ref={(node) => {
          this.node = node;
        }}
      >
        {this.props.children}
      </span>,
    );
  }
}

ClipboardComponent.propTypes = {
  children: PropTypes.element.isRequired,
  target: PropTypes.string,
  text: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

ClipboardComponent.defaultProps = {
  onSuccess: noop,
  onError: noop,
};

export default ClipboardComponent;
