import find from 'lodash/find';
import get from 'lodash/get';
import some from 'lodash/some';

export const validateCategories = (
  form,
  { key = 'selectedCategories', maxCategories } = {},
) => {
  const errors = [];
  const selectedCategories = get(form, key, []);

  if (selectedCategories.length === 0) {
    errors.push({
      id: key,
      type: 'root',
      messageId: 'preferences.categories.categoriesNotSelected',
    });
  }

  if (maxCategories > 0 && selectedCategories.length > maxCategories) {
    errors.push({
      id: key,
      type: 'root',
      messageId: 'preferences.categories.tooManyCategoriesSelected',
    });
  }

  return errors;
};

export const validateSubcategories = (form, subCategories) => {
  const errors = [];
  const { selectedCategories } = form;

  const anySubcategorySelected = () =>
    some(selectedCategories, (c) => find(subCategories, { id: c }));

  if (subCategories.length > 0 && !anySubcategorySelected()) {
    errors.push({
      id: 'selectedCategories',
      type: 'sub',
      messageId: 'preferences.categories.subCategoriesNotSelected',
    });
  }

  return errors;
};
