export const getAsyncCampaignMatch = (state) =>
  state.scenes.campaignDetails.details.campaignMatch;

export const getAsyncApplication = (state) =>
  state.scenes.campaignDetails.details.application;

export const getAsyncWorkflow = (state) =>
  state.scenes.campaignDetails.workflow.workflow;

export const getCampaignForm = (state) =>
  state.scenes.campaignDetails.workflow.campaignForm;

export const getToastError = (state) =>
  state.scenes.campaignDetails.workflow.toastError;

export const getUser = (state) => state.application.user;
