/**
 * Simple SVG tag wrapper to ensure good cross-browser settings
 */

import PropTypes from 'prop-types';

import React from 'react';

function SVG({ width, height, children, ...rest }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox={`0 0 ${width} ${height}`}
      {...rest}
    >
      {children}
    </svg>
  );
}

SVG.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.any,
};

SVG.defaultProps = {
  width: 0,
  height: 0,
};

export default SVG;
