import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
  FormattedPlural,
  injectIntl,
  defineMessages,
} from 'react-intl';

import cx from 'classnames';
import every from 'lodash/every';
import get from 'lodash/get';
import noop from 'lodash/noop';

import {
  ActivityStreamItem,
  ActivityStreamHeader,
  ActivityStreamBody,
  ActivityStreamTitle,
} from 'source/components/common/activityStream';
import Button, {
  SubmitButton,
  CopyButton,
} from 'source/components/common/button';
import Form, { Textarea, Checkbox, Input } from 'source/components/common/form';
import { MessageCard } from 'source/components/common/message';
import Markdown from 'source/components/common/markdown';

const allTasksChecked = (tasks) => every(tasks, { checked: true });

const injectFormData =
  (formData = {}) =>
  (task) => ({
    ...task,
    checked: formData[task.taskIndex] || false,
  });

const taskMessages = defineMessages({
  defaultClientTaskBody: {
    id: 'sponsoredPosts.campaigns.tasks.clientDefaultBody',
    defaultMessage: 'This content will be provided shortly',
  },
});

class Tasks extends Component {
  constructor(props) {
    super(props);

    this.statusToRender = {
      inactive: this.renderInactive,
      active: this.renderActive,
      done: this.renderDone,
      expired: this.renderExpired,
    };

    this.typeToRenderTask = {
      text: this.renderTaskText,
      link: this.renderTaskLink,
      image: this.renderTaskImage,
      code: this.renderTaskCode,
    };
  }

  get formId() {
    const {
      formName,
      workflow: {
        payload: { list },
      },
    } = this.props;

    return `${formName}-${list}`;
  }

  getTasks() {
    const {
      workflow: {
        payload: { list },
      },

      application,
      campaign,
      form,
    } = this.props;

    // We need to fallback to campaign tasks when we don't have an
    // application, because we will still render the collapsed title
    // before the user has applied
    const tasks = get(application, 'tasks') || get(campaign, 'tasks');

    return (
      tasks
        // We need to inject the actual task index here to reference it to the
        // state completed object.
        .map((task, index) => ({ ...task, taskIndex: index }))
        .filter((task) => task.list === list)
        .map(injectFormData(form[this.formId]))
    );
  }

  getIncompleteTasks() {
    return this.getTasks().filter((task) => !task.checked);
  }

  handleTaskUpdate = (e) => {
    const {
      formName,
      workflow: {
        payload: { list },
      },

      onAction,
    } = this.props;

    return onAction(formName, 'updateTask', {
      list,
      taskIndex: Number(e.target.id),
      checked: e.target.checked,
    });
  };

  taskRenderer =
    ({ disabled = false } = {}) =>
    (task) => {
      const renderTaskFn = this.typeToRenderTask[task.type];
      if (!renderTaskFn) {
        return null;
      }

      return (
        <li className="checklist-item" key={task.taskIndex}>
          <Checkbox
            id={task.taskIndex}
            className={cx({ disabled })}
            checked={task.checked}
            onChange={disabled ? noop : this.handleTaskUpdate}
          >
            {this.renderTaskHeadline(task)}
          </Checkbox>
          {task.body || task.individual || task.clientAccess
            ? renderTaskFn.call(this, task)
            : null}
        </li>
      );
    };

  renderTaskTitleMessage(incompleteTasks) {
    const {
      intl,
      workflow: {
        payload: { list },
      },
    } = this.props;

    if (!incompleteTasks.length) {
      return (
        <FormattedMessage
          id="sponsoredPosts.campaigns.tasks.completed"
          defaultMessage="All {taskType} completed"
          values={{
            taskType: intl.formatMessage({
              id: `sponsoredPosts.campaigns.tasks.${list}.plural`,
            }),
          }}
        />
      );
    }

    const pluralizedMessage = (
      <FormattedPlural
        value={incompleteTasks.length}
        one={intl.formatMessage({
          id: `sponsoredPosts.campaigns.tasks.${list}.singular`,
        })}
        other={intl.formatMessage({
          id: `sponsoredPosts.campaigns.tasks.${list}.plural`,
        })}
      />
    );

    return (
      <FormattedMessage
        id="sponsoredPosts.campaigns.tasks.summary"
        defaultMessage="{tasks} {message} remaining:"
        values={{
          tasks: incompleteTasks.length,
          message: pluralizedMessage,
        }}
      />
    );
  }

  renderTitle(titleProps = {}) {
    const incompleteTasks = this.getIncompleteTasks();
    const headlines = incompleteTasks.map((t) => t.headline);

    return (
      <ActivityStreamTitle {...titleProps}>
        <strong>{this.renderTaskTitleMessage(incompleteTasks)}</strong>
        <Markdown
          typeName="span"
          markdown={headlines.join(', ')}
          noParagraphs
        />
      </ActivityStreamTitle>
    );
  }

  renderInactive() {
    return (
      <ActivityStreamItem iconName="clipboard">
        <ActivityStreamHeader>{this.renderTitle()}</ActivityStreamHeader>
      </ActivityStreamItem>
    );
  }

  renderTaskText(task) {
    const taskBody =
      task.body ||
      this.props.intl.formatMessage(taskMessages.defaultClientTaskBody);

    if (!taskBody) {
      return null;
    }

    return <Markdown markdown={taskBody} />;
  }

  renderTaskLink(task) {
    const taskId = `tasks-${task.id}`;
    const taskBody =
      task.body ||
      this.props.intl.formatMessage(taskMessages.defaultClientTaskBody);

    if (!taskBody) {
      return null;
    }

    return (
      <div className="integrate">
        <Input id={taskId} value={taskBody} readOnly code />
        <CopyButton target={taskId} text={taskBody}>
          <FormattedMessage
            id="sponsoredPosts.campaigns.tasks.copy"
            defaultMessage="Copy"
          />
        </CopyButton>
      </div>
    );
  }

  renderTaskImage(task) {
    if (!task.body) {
      return (
        <Markdown
          markdown={this.props.intl.formatMessage(
            taskMessages.defaultClientTaskBody,
          )}
        />
      );
    }

    return (
      <div className="task--image m-t-1">
        <img alt={task.headline} src={task.body} />
        <Button
          className="m-t-1"
          href={task.body}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage
            id="sponsoredPosts.campaigns.tasks.download"
            defaultMessage="Download"
          />
        </Button>
      </div>
    );
  }

  renderTaskCode(task) {
    const rows = Math.min(7, task.body.split('\n').length);
    const taskId = `tasks-${task.id}`;
    const taskBody =
      task.body ||
      this.props.intl.formatMessage(taskMessages.defaultClientTaskBody);

    if (!taskBody) {
      return null;
    }

    return (
      <div className="integrate">
        <Textarea id={taskId} rows={rows} readOnly code value={taskBody} />
        <CopyButton target={taskId} text={taskBody}>
          <FormattedMessage
            id="sponsoredPosts.campaigns.tasks.copy"
            defaultMessage="Copy"
          />
        </CopyButton>
      </div>
    );
  }

  renderTaskHeadline(task) {
    const headline = (
      <Markdown markdown={task.headline} typeName="span" noParagraphs />
    );

    if (task.checked) {
      return <del>{headline}</del>;
    }

    return headline;
  }

  renderActive() {
    const { onChange, onSubmit, form } = this.props;
    const tasks = this.getTasks();
    const { submitting } = form[this.formId];

    return (
      <ActivityStreamItem status="active" iconName="clipboard">
        <ActivityStreamHeader>{this.renderTitle()}</ActivityStreamHeader>
        <ActivityStreamBody>
          <Form id={this.formId} onChange={onChange} onSubmit={onSubmit}>
            <ul className="checklist">{tasks.map(this.taskRenderer())}</ul>
            <SubmitButton disabled={!allTasksChecked(tasks) || submitting}>
              <FormattedMessage
                id="sponsoredPosts.campaigns.tasks.continue"
                defaultMessage="Continue"
              />
            </SubmitButton>
          </Form>
        </ActivityStreamBody>
      </ActivityStreamItem>
    );
  }

  renderDone() {
    const {
      workflow: { lastUpdate },
    } = this.props;

    const tasks = this.getTasks();

    return (
      <ActivityStreamItem
        key="done"
        status="done"
        iconName="clipboard"
        initialExpanded={false}
        expandable
      >
        <ActivityStreamHeader>
          {this.renderTitle({
            date: lastUpdate,
          })}
        </ActivityStreamHeader>
        <ActivityStreamBody>
          <Form id={this.formId} onChange={noop} onSubmit={noop}>
            <ul className="checklist">
              {tasks.map(this.taskRenderer({ disabled: true }))}
            </ul>
          </Form>
        </ActivityStreamBody>
      </ActivityStreamItem>
    );
  }

  renderExpired() {
    const {
      workflow: { lastUpdate },
    } = this.props;

    return (
      <ActivityStreamItem status="failed" iconName="clipboard">
        <ActivityStreamHeader>
          {this.renderTitle({
            date: lastUpdate,
          })}
        </ActivityStreamHeader>
        <ActivityStreamBody>
          <MessageCard type="info">
            <strong>
              <FormattedMessage
                id="sponsoredPosts.campaigns.accept.expired.title"
                defaultMessage="What a pity!"
              />
            </strong>
            <span>
              <FormattedMessage
                id="sponsoredPosts.campaigns.accept.expired.message"
                defaultMessage="The deadline for application has expired, unfortunately."
              />
            </span>
          </MessageCard>
        </ActivityStreamBody>
      </ActivityStreamItem>
    );
  }

  render() {
    const {
      workflow: { status },
    } = this.props;

    const renderFn = this.statusToRender[status];
    if (!renderFn) {
      return this.renderInactive();
    }

    return renderFn.call(this);
  }
}

const tasksPropType = PropTypes.arrayOf(
  PropTypes.shape({
    type: PropTypes.string.isRequired,
    list: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    body: PropTypes.string,
    checked: PropTypes.bool,
  }),
);

Tasks.propTypes = {
  formName: PropTypes.string,
  workflow: PropTypes.shape({
    status: PropTypes.oneOf(['inactive', 'active', 'done', 'expired'])
      .isRequired,
    payload: PropTypes.shape({
      list: PropTypes.string.isRequired,
    }).isRequired,
    lastUpdate: PropTypes.string,
  }).isRequired,
  form: PropTypes.object.isRequired,
  campaign: PropTypes.shape({
    tasks: tasksPropType,
  }).isRequired,
  application: PropTypes.shape({
    tasks: tasksPropType,
  }),

  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

Tasks.defaultProps = {
  formName: 'tasks',
};

export default injectIntl(Tasks);
