export const browserLocale =
  typeof navigator !== 'undefined' && navigator.language.split(/[-_]/)[0];
export const defaultLocale = 'en';

export const supportedLocales = ['de', 'en'];

export const numberFormats = {
  de: {
    thousandSeparator:'.',
    decimalSeparator:',',
  },
  en: {
    thousandSeparator:',',
    decimalSeparator:'.'
  }
}

export { default as en } from './en.json';
export { default as de } from './de.json';
export { default as links } from './links';

// Intl is only supported by Chrome and Firefox, for IE and Safari polyfill is required.
// http://ianobermiller.com/blog/2015/06/01/conditionally-load-intl-polyfill-webpack/
const checkIntl = () => new Promise(resolve => {
    if (window.Intl) {
      return resolve();
    }

    return require.ensure(
      [
        'intl',
        // NOTE: how can we make this dynamic ?
        'intl/locale-data/jsonp/en.js',
        'intl/locale-data/jsonp/de.js',
      ],
      require => {
        require('intl');
        require('intl/locale-data/jsonp/en');
        require('intl/locale-data/jsonp/de');

        return resolve();
      },
    );
  })

export const ensureIntl = () => checkIntl();
