import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import mergeWith from 'lodash/mergeWith';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose, mapProps, branch, renderComponent } from 'recompose';

import { links } from 'source/i18n';
import Spinner from 'source/components/common/spinner';
import { scrolling } from 'source/utils/animations';
import actions from '../../actions';

import { getBrandClubCampaignsMatchState } from '../../selectors';

import { CampaignTypeTabs } from '../../../exploreCampaigns/components/ExploreCampaigns';
import CampaignCard from '../../../exploreCampaigns/components/CampaignCard';
import ArrowButton from './ArrowButton';

function Loading() {
  return (
    <div className="aurora">
      <Spinner className="spinner-view" />
    </div>
  );
}

const NoContent = injectIntl(({ intl }) => (
  <div className="formatted-message p-x-2">
    <FormattedMessage
      id="sponsoredPosts.explore.noContent.brandClubs"
      defaultMessage="No campaigns available or criteria not met. See our {faqLink} to find out about campaign requirements."
      values={{
        faqLink: (
          <a
            target="_blank"
            rel="noreferrer"
            href={intl.formatMessage(links.faqLink)}
          >
            FAQs
          </a>
        ),
      }}
    />
  </div>
));

const withCampaignMatches = mapProps(
  ({
    campaignMatchesByType,
    applicationMatchesByType,
    campaignTypeFilter,
    ...props
  }) => ({
    ...props,
    campaignMatches: campaignMatchesByType[campaignTypeFilter] || [],
    applicationMatches: applicationMatchesByType[campaignTypeFilter] || [],
  }),
);

const withNoContentMessage = branch(
  ({ campaignMatches, applicationMatches }) =>
    !campaignMatches.length && !applicationMatches.length,
  renderComponent(NoContent),
);

const enhance = compose(withCampaignMatches, withNoContentMessage);

const CampaignsList = enhance(
  ({ campaignMatches, applicationMatches, clubId }) => (
    <>
      {applicationMatches.map(({ applicationId, campaign, matches }) => (
        <div className="campaign-card" key={campaign.id}>
          <CampaignCard
            campaign={campaign}
            matches={matches}
            link={`/sponsored-posts/applications/${applicationId}`}
          />
        </div>
      ))}
      {campaignMatches.map(({ campaign, matches }) => (
        <div className="campaign-card" key={campaign.id}>
          <CampaignCard campaign={campaign} matches={matches} clubId={clubId} />
        </div>
      ))}
    </>
  ),
);

CampaignsList.propTypes = {
  campaignMatches: PropTypes.arrayOf(PropTypes.object).isRequired,
  clubId: PropTypes.string.isRequired,
};

CampaignsList.defaultProps = {
  campaignMatches: [],
};

const DetailsContentLink = injectIntl(({ intl, translationKey }) => (
  <a
    href={intl.formatMessage({
      id: `brandClubs.${translationKey}.detailsContent.url`,
    })}
  >
    <FormattedMessage id={`brandClubs.${translationKey}.name`} />
  </a>
));

DetailsContentLink.propTypes = {
  translationKey: PropTypes.string.isRequired,
};

class BrandClubDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.campaignsDiv = React.createRef();
  }

  componentDidMount() {
    const { brandClub } = this.props;

    this.props.onLoadCampaignMatches(brandClub.id);
    this.props.onLoadApplications(brandClub.id);
  }

  scrollCampaigns = (direction) =>
    scrolling(this.campaignsDiv.current, direction, 40, 350);

  render() {
    const {
      onChangeCampaignTypeFilter,
      campaignTypeFilter,
      campaignMatchesByType,
      campaignMatchesByType: { loaded: loadedCampaigns },
      applicationMatchesByType,
      applicationMatchesByType: { loaded: loadedApplications },
      brandClub: {
        id,
        translationKey,
        theme: { className, detailsHeaderUrl },
      },
    } = this.props;

    const loaded = loadedCampaigns && loadedApplications;

    const combinedMatchesByType = {
      data: mergeWith(
        {},
        applicationMatchesByType.data || {},
        campaignMatchesByType.data || {},
        (objValue = [], srcValue = []) => [...objValue, ...srcValue],
      ),
      loaded,
    };

    return (
      <div className={cx('details-container', className)}>
        <div className="close-bar">
          <Link className="close-btn" to="/brand-clubs">
            <svg className="icon">
              <use xlinkHref="#icon-cross" />
            </svg>
          </Link>
        </div>
        <div className="details-header">
          <img src={detailsHeaderUrl} alt="" />
        </div>
        {!loaded ? (
          <Loading />
        ) : (
          <div className="campaigns-container">
            <h5 className="title ">
              <FormattedMessage
                id="brandClubs.details.recentCampaigns"
                defaultMessage="Recent Campaigns"
              />
            </h5>
            <div className="aurora">
              <div className="brand-club-filters">
                <CampaignTypeTabs
                  {...combinedMatchesByType}
                  selectedTab={campaignTypeFilter}
                  onSelectTab={onChangeCampaignTypeFilter}
                />

                <div className="scroll-buttons m-t-2">
                  <ArrowButton
                    direction="left"
                    icon="chevron-left"
                    onClick={this.scrollCampaigns}
                  />

                  <ArrowButton
                    direction="right"
                    icon="chevron-right"
                    onClick={this.scrollCampaigns}
                    className="m-l-1"
                  />
                </div>
              </div>
            </div>
            <div className="campaigns-list aurora" ref={this.campaignsDiv}>
              <CampaignsList
                campaignMatchesByType={campaignMatchesByType.data}
                applicationMatchesByType={applicationMatchesByType.data}
                campaignTypeFilter={campaignTypeFilter}
                clubId={id}
              />
            </div>
          </div>
        )}
        <div className="campaigns-container">
          <FormattedMessage
            id={`brandClubs.${translationKey}.detailsContent.title`}
            values={{
              link: <DetailsContentLink translationKey={translationKey} />,
            }}
          />
          <div className="details-text row m-t-1">
            <div className="col-md-12">
              <FormattedMessage
                id={`brandClubs.${translationKey}.detailsContent`}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BrandClubDetails.propTypes = {
  brandClub: PropTypes.shape({
    id: PropTypes.string.isRequired,
    translationKey: PropTypes.string.isRequired,
    theme: PropTypes.shape({
      className: PropTypes.string.isRequired,
      detailsHeaderUrl: PropTypes.string.isRequired,
      detailsHeaderLinkUrl: PropTypes.string,
    }).isRequired,
  }),

  campaignMatchesByType: PropTypes.shape({
    data: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
  }).isRequired,
  applicationMatchesByType: PropTypes.shape({
    data: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
  }).isRequired,
  campaignTypeFilter: PropTypes.string.isRequired,
  onChangeCampaignTypeFilter: PropTypes.func.isRequired,
  onLoadCampaignMatches: PropTypes.func.isRequired,
  onLoadApplications: PropTypes.func.isRequired,
};

export default connect(
  getBrandClubCampaignsMatchState,
  actions,
)(BrandClubDetails);
