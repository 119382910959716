import React from 'react';
import pick from 'lodash/pick';
import { withRouter } from 'react-router';

import { auth } from 'source/utils/auth';

import redirect from './redirect';

export { default as requireAuthentication } from './requireAuthentication';

// redirect predicates..
const stateReady = (state) => state.stateLoaded;

const userComplete = (state) => {
  if (auth.isGodMode()) {
    return true;
  }

  const { user } = state;

  return Boolean(user.country && user.language);
};

const userHasAcceptedTerms = (state) => {
  if (auth.isGodMode()) {
    return true;
  }

  const { user } = state;

  return user.acceptedTerms;
};

export const redirectAcceptedTerms = (Component) =>
  redirect(
    Component,
    (state) =>
      stateReady(state.application) && userHasAcceptedTerms(state.application),
    '/',
  );

export const redirectNotAcceptedTerms = (Component) =>
  redirect(
    Component,
    (state) =>
      stateReady(state.application) && !userHasAcceptedTerms(state.application),
    '/accept-terms',
  );

export const redirectCompleteUser = (Component) =>
  redirect(
    Component,
    (state) => stateReady(state.application) && userComplete(state.application),
    '/',
  );

export const redirectNotCompleteUser = (Component) =>
  redirect(
    Component,
    (state) =>
      stateReady(state.application) && !userComplete(state.application),
    '/check-profile',
  );

/**
 * react-router helpers
 */

export const injectParams = (Component, keys) =>
  function (props) {
    // eslint-disable-next-line react/prop-types
    return <Component {...props} {...pick(props.params, keys)} />;
  };

export const withParams = (Component, keys) => {
  const WithParams = injectParams(Component, keys);
  const WithRouter = withRouter(WithParams);

  return function (props) {
    return <WithRouter {...props} />;
  };
};
