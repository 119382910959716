import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import omit from 'lodash/omit';
import forEach from 'lodash/forEach';

export const wrapRedirect = (Component, path, sideEffects) => {
  class Redirector extends React.PureComponent {
    componentDidMount() {
      this.maybeRedirect();
    }

    componentDidUpdate() {
      this.maybeRedirect();
    }

    maybeRedirect() {
      if (this.props.shouldRedirect) {
        this.props.dispatch(push(path));
        forEach(sideEffects, this.props.dispatch);
      }
    }

    render() {
      return this.props.shouldRedirect ? null : (
        <Component {...omit(this.props, ['shouldRedirect'])} />
      );
    }
  }

  Redirector.propTypes = {
    dispatch: PropTypes.func.isRequired,
    shouldRedirect: PropTypes.bool.isRequired,
  };

  return Redirector;
};

const shouldRedirect = (predicate) => (state, ownProps) => ({
  shouldRedirect: predicate(state, ownProps),
});

export default function withRedirect(Component, predicate, path, sideEffects) {
  return connect(shouldRedirect(predicate))(
    wrapRedirect(Component, path, sideEffects),
  );
}
