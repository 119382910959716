const { developers } = require('./shared');

module.exports = {
  app: {
    url: process.env.SELF_APP_URL || 'https://app.eqolot.com',
  },

  api: {
    url: process.env.IRON_URL || 'https://iron.eqolot.com',
    timeout: 25000,
  },

  sentry: {
    dsn: 'https://2852b20e39b740bb86665d2eba767ffd@o30840.ingest.sentry.io/75017',
  },

  facebook: {
    disabled: false,
  },

  features: {
    counterOffer: {
      allowedUsers: [...developers],
      allowedGroups: ['5f882e32750895289338c78e'],
    },

    insightsMaintenance: {
      enabled: false,
    },

    instagramApi: {
      allowedUsers: [],
      allowedGroups: ['5ffecd7097cb2d4e38931d60'],
    },

    demoPrivateNetworks: {
      allowedUsers: [...developers],
    },
    hiddenPrivateNetworks: {
      allowedUsers: [...developers],
    },
  },

  frontappKey: '25bdd27e4976545e88ec829c9c34aedf',

  optimizely: {
    writeKey: 6515700636,
  },

  GA: {
    id: 'GTM-WJQN6JF',
  },

  experiments: {
    imageUrl: 6528431505,
  },

  insights: {
    instagram: {
      insightsTtl: 30,
      insightsIntermediateTtl: 23,
    },
    tiktok: {
      insightsTtl: 30,
      insightsIntermediateTtl: 23,
    },
  },

  privateNetworks: {
    demoPrivateNetworks: [
      '5d480680c52daf0f0a872abd', // Demo Private Network
    ],
    hiddenPrivateNetworks: [
      '6278ee05e5721b2ceb6a10b8', // Danone
    ],
  },
};
